import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BillingAddressList from "../../../components/billing-address-list/BillingAddressList";
import AddressFormWithSearch from "../../../components/custom-input-field/AddressFormWithSearch";
import HeadingWithRightButton from "../../../components/heading/HeadingWithRightButton";
import NewAddressForm from "../../../components/new-address-form/NewAddressForm";
import { applicationUserType } from "../../../store/reducers/appSlice";
import { RootState } from "../../../store/store";
import RoundButton from "../../../components/round-button/RoundButton";
import { setBillingAddress } from "../../../store/reducers/userSlice";
import { EmptyAdupUserAddress } from "../../../interfaces/AdupUserAddress";
import { editSVG, plusSVG } from "../../../assets/js/allCustomSVGs";
import { AnimatePresence } from "framer-motion";

const FastCheckoutBillingAddress = () => {
  const { t } = useTranslation();

  // check if wallet payment method is in use
  const isWalletPaymentMethodInUse = useSelector(
    (state: RootState) => state.payment?.isWalletPaymentMethodInUse
  );

  // user's auth state
  const authState = useSelector((state: RootState) => state.app.auth);

  const dispatch = useDispatch();

  const [isEditingIconsVisible, setIsEditingIconsVisible] = useState(false);
  const [displayingNewAddressForm, setDisplayingNewAddressForm] =
    useState(false);

  useEffect(() => {
    if (authState.user === applicationUserType.NEW_USER)
      setDisplayingNewAddressForm(true);
  }, [authState.user]);

  if (
    isWalletPaymentMethodInUse &&
    authState.user === applicationUserType.NEW_USER
  ) {
    return null;
  }

  return (
    <div
      className="billing-details fast-checkout-page-segment"
      /* style={{ marginTop: -15 }} */
    >
      <span
        data-billingeditingiconsvisible={isEditingIconsVisible.toString()}
        className="billing-address-form-title"
      >
        {authState.user === applicationUserType.OLD_USER && (
          <HeadingWithRightButton
            headingText={t("BillingAddressTitle")}
            button={
              <Fragment>
                <div className="headingWithRightButton__button--container">
                  <RoundButton
                    color={"dark"}
                    icon={plusSVG}
                    onClick={() =>
                      setDisplayingNewAddressForm(!displayingNewAddressForm)
                    }
                    classString={`round-button-with-plus ${
                      displayingNewAddressForm
                        ? "icon-rotate-half-left"
                        : "icon-rotate-half-right"
                    }`}
                  />
                  <RoundButton
                    color={"dark"}
                    icon={editSVG}
                    onClick={() =>
                      setIsEditingIconsVisible(!isEditingIconsVisible)
                    }
                    classString="round-button-with-edit"
                  />
                  {/* <SearchShippingAddressButton onClick={() => null} /> */}
                </div>
              </Fragment>
            }
          />
        )}

        <AnimatePresence>
          {displayingNewAddressForm &&
            authState.user === applicationUserType.OLD_USER && (
              <NewAddressForm
                close={() => setDisplayingNewAddressForm(false)}
                addressType="billing"
              />
            )}
        </AnimatePresence>

        {authState.user === applicationUserType.NEW_USER && (
          <AddressFormWithSearch
            addressType="billing"
            changeListeners={[
              (address) => {
                if (address !== EmptyAdupUserAddress) {
                  dispatch(setBillingAddress(address));
                }
              },
            ]}
          />
        )}

        {authState.user === applicationUserType.OLD_USER && (
          <BillingAddressList />
        )}
      </span>
    </div>
  );
};

export default FastCheckoutBillingAddress;
