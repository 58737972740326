import { AdupUserAddress } from "../../../interfaces/AdupUserAddress";
import {
  setBillingAddress,
  setShippingAddress,
  updateExistingAddressById,
} from "../../../store/reducers/userSlice";
import store from "../../../store/store";
/* ================================================================================== */
import { RequestInfo } from "../../../interfaces/RequestInfo";
import { UPDATE_ADDRESS_API } from "../../constants/central-api";
import { asyncHandler } from "../async-handler";
import { t } from "i18next";
import { getCountryCodeByCountryName } from "../../utils/helper-functions";

export async function updateUserAddress(
  address: AdupUserAddress,
  addressType: string
): Promise<boolean> {
  // send request
  const addressData = await updateUserAddressRequest(address);
  console.log(addressType, "Address selected and updated");
  // save state
  if (addressData?.id) {
    store.dispatch(
      updateExistingAddressById({
        ...address,
        id: addressData?.id,
        country_name: addressData?.address?.country?.name,
        country: addressData?.address?.country,
        shipping_rate: addressData?.shipping_rate,
      })
    );
    if (address && addressType === "shipping") {
      store.dispatch(
        setShippingAddress({
          ...address,
          id: addressData?.id,
          country_name: addressData?.address?.country?.name,
          country: addressData?.address?.country,
          shipping_rate: addressData?.shipping_rate,
        })
      );
    } else if (address && addressType === "billing") {
      store.dispatch(
        setBillingAddress({
          ...address,
          id: addressData?.id,
          country_name: addressData?.address?.country?.name,
          country: addressData?.address?.country,
          shipping_rate: addressData?.shipping_rate,
        })
      );
    }
    return true;
  }

  return false;
}

/**
 * @param { AdupUserAddress } address - address object
 */
export async function updateUserAddressRequest(address: AdupUserAddress) {
  const key = store.getState().users.currentUser.token; // current user token
  const shopID = store.getState().cart.shop.id; // current shop id

  const requestInfo: RequestInfo = {
    url: UPDATE_ADDRESS_API,
    method: "PUT",
    body: JSON.stringify({
      id: address.id,
      address: {
        house_number: address.number,
        street: address.street,
        street2: address.street2,
        city: address.city,
        province: address.province,
        postcode: address.postcode,
        country: address.country_name,
        country_code:
          getCountryCodeByCountryName(address.country_name) ??
          address.country_name,
      },
      shop_id: shopID,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: key ? `Bearer ${key}` : "",
    },
  };

  const actionType = "UPDATE_USER_ADDRESS";
  const customMsg = t("AddressUpdatedSuccessfully");

  const res: any = await asyncHandler(requestInfo, actionType, customMsg, true);

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    const data = response?.data;
    return data;
  } else {
    return null;
  }
}
