import { truncate } from "../../lib/utils/helper-functions";
import "../../sass/themes/theme2/singleProductTheme2.scss";
// import plusSVG from "../../assets/svg/plus-black-SVG.svg";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomSVGs from "../../components/custom-svgs/CustomSVGs";
import { CartSVG } from "../../assets/js/allCustomSVGs";
import { useTranslation } from "react-i18next";

interface Props {
  image: string;
  name: string;
  maxNameLength: number;
  price: string | number;
  type: string;
  cartItemsNum: number;
  disabled: boolean;
  onClick: (e: any) => void;
  onAddToCartClick: () => void;
}

function SingleProductTheme2({
  image,
  name,
  price,
  type,
  maxNameLength,
  cartItemsNum,
  disabled,
  onClick,
  onAddToCartClick,
}: Props) {
  const { t } = useTranslation();
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  return (
    <div
      className="singleProductTheme2"
      style={{ cursor: disabled ? "not-allowed" : "default" }}
    >
      {disabled && (
        <div className="singleProductTheme2-overlay">
          <span>{t("ProductUnavailable")}</span>
        </div>
      )}
      <div
        className="singleProductTheme2__top"
        style={{ cursor: disabled ? "not-allowed" : "default" }}
      >
        {/* //TODO Add later when favorite feature is implemented
        <div className="singleProductTheme2__top--star">
          <CustomSVGs svg={StarEmptySVG} />
        </div> */}
        <div
          className="singleProductTheme2__top--img"
          onClick={(e: any) => {
            if (!disabled) onClick(e);
          }}
          style={{
            marginRight: 7,
            cursor: disabled ? "not-allowed" : "pointer",
          }}
        >
          <LazyLoadImage
            loading="lazy"
            src={image}
            alt={name}
            className={
              !isImgLoaded ? "singleProductTheme2__top--placeholder" : ""
            }
            onLoad={() => {
              setIsImgLoaded(true);
            }}
            onError={() => {
              setIsImgLoaded(true);
            }}
            style={{ cursor: disabled ? "not-allowed" : "pointer" }}
          />
        </div>
      </div>
      <div className="singleProductTheme2__bottom">
        <div
          onClick={(e: any) => {
            if (!disabled) onClick(e);
          }}
          style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        >
          <div className="singleProductTheme2__bottom--textHolder">
            <h3>{truncate(name, maxNameLength)}</h3>
          </div>
        </div>
        <div className="singleProductTheme2__bottom--currency">
          <span
            onClick={(e: any) => {
              if (!disabled) onClick(e);
            }}
            style={{
              cursor: disabled ? "not-allowed" : "pointer",
              textDecoration: disabled ? "line-through" : "",
            }}
          >
            {price}
          </span>
          {/* //* Only Simple products can be directly added to Cart via Overview Page */}
          {type === "simple" && !disabled && (
            <div
              onClick={() => {
                if (!disabled) onAddToCartClick();
              }}
              style={{ cursor: disabled ? "not-allowed" : "pointer" }}
            >
              <CustomSVGs svg={CartSVG} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleProductTheme2;
