import "../../../sass/layouts/layout2/mobile/AICheckoutMobileLayout2.scss";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeadingWithRightButton from "../../../components/heading/HeadingWithRightButton";
import PoweredByTheme2 from "../../../themes/theme2/PoweredByTheme2";
import { RootState } from "../../../store/store";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import FastCheckoutPageWrapper from "../../../components/page-wrappers/FastCheckoutPageWrapper";
import ProductDetailsThemeWise from "../../../components/theme-wise-switching/ProductDetailsThemeWise";
import Header from "../../../components/header/Header";
import CartBannerSC from "../../../components/socialCommerce/CartBannerSC";
import IntroBannerSC from "../../../components/socialCommerce/IntroBannerSC";
import ProductImagesSlider from "../../../components/socialCommerce/ProductImagesSlider";
import { generateFastCheckoutUrlUsingCartItems } from "../../../lib/utils/helper-functions";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";

type Props = {
  isHeartBtnActive: boolean;
  setIsHeartBtnActive: any;
  isDemoActive: boolean;
  productImages: any;
  setLocalAttributeSkuMap: any;
  productsData: any;
  onIncrement: any;
  onDecrement: any;
  addToFastcheckoutCart: any;
  componentRenderLogics: (componentType: string) => boolean | undefined;
};

const AICheckoutMobileLayout2 = ({
  isHeartBtnActive,
  setIsHeartBtnActive,
  isDemoActive,
  productImages,
  setLocalAttributeSkuMap,
  productsData,
  onIncrement,
  onDecrement,
  addToFastcheckoutCart,
  componentRenderLogics,
}: Props) => {
  const fastcheckoutCartItems: any =
    useSelector((state: RootState) => state.cart.fastcheckoutCartItems) ?? [];
  const shop: any = useSelector((state: RootState) => state.cart.shop);
  const allProducts: any = useSelector(
    (state: RootState) => state.cart.allProducts[0]
  );
  const isProductsAPILoading = useSelector(
    (state: RootState) => state.app.UI.isProductsAPILoading
  );
  const navigate = useNavigate();

  {
    return (
      <React.Suspense fallback={<div className="socialCommerce"></div>}>
        <Header hideCompactCart={true} />
        <div className="socialCommerce">
          <div className="max-width-container">
            <div className="max-width-container__content">
              <IntroBannerSC />
              {!isProductsAPILoading && (
                <>
                  {componentRenderLogics("cartBanner") && (
                    <CartBannerSC
                      isHeartFilled={isHeartBtnActive}
                      cartNumber={fastcheckoutCartItems.length}
                      onCartClick={() => {
                        const fastCheckoutUrl = `/ai-fast-checkout/${
                          shop?.slug
                        }/${generateFastCheckoutUrlUsingCartItems(
                          fastcheckoutCartItems
                        )}`;
                        if (!isDemoActive) navigate(fastCheckoutUrl);
                      }}
                      onHeartClick={() => {
                        setIsHeartBtnActive(!isHeartBtnActive);
                      }}
                    />
                  )}
                  {/* <div className="socialCommerce__desktop--container">
                    <div className="socialCommerce__desktop--container-left">
                      {allProducts && !showCookieConsent && (
                        <>
                          <div className="socialCommerce__desktop--container-left-mobileSlider">
                            <ImageSliderSC
                              imageArray={productImages}
                              isDemoActive={isDemoActive}
                            />
                          </div>
                          <div className="socialCommerce__desktop--container-left-desktopSlider">
                            <ProductImagesSlider
                              images={productImages}
                              isDemoActive={isDemoActive}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="socialCommerce__desktop--container-right">
                     

                      <ProductDetailsThemeWise
                        data={{
                          allProducts,
                          productName,
                          productCurrency,
                          productPrice,
                          productDescription,
                          delivery,
                          isNoItemSelected,
                          isInvalidVariantSelected,
                          attributes,
                          defaultSelectedAttributes,
                          productType,
                        }}
                        setLocalAttributeSkuMap={setLocalAttributeSkuMap}
                      /> */}
                  {/*  {chosenProduct && (
                    <CartOperatorSC
                      onIncrement={onIncrement}
                      onDecrement={onDecrement}
                      cartItemNumber={cartItemNumber}
                      onAddToCartClick={
                        !isDemoActive ? addToFastcheckoutCart : () => {}
                      }
                      isDisabled={lastSessionCallPayload === "loading"}
                    />
                  )} */}
                  {/*  {!allProducts && (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            color: "red",
                            paddingTop: "2vh",
                          }}
                        >
                          <span> {t("SelectedProductUnavailable")}</span>
                        </div>
                      )}
                      <h3
                        onClick={() => {
                          if (!isDemoActive) navigate(`/${shop?.slug}/products`);
                          dispatch(setInteractionsPerSessionCount());
                        }}
                        className="socialCommerce__browseMoreLink"
                        style={{ marginBottom: 0 }}
                      >
                        {t("BrowseMore")}
                      </h3>

                      {settings?.webshop_url && (
                        <h3 className="socialCommerce__goToLink">
                          {t("Or-go-to")}{" "}
                          <a
                            target={"_blank"}
                            href={settings?.webshop_url}
                            rel="noreferrer"
                          >
                            {t("Webshop")}
                          </a>
                        </h3>
                      )}
                    </div>
                  </div> */}

                  <div className="layout2Mobile">
                    <div className="layout2Mobile__container1">
                      <div className="layout2Mobile__container1--product">
                        <div className="layout2Mobile__container1--product-image">
                          {componentRenderLogics("productImagesSlider") && (
                            <ProductImagesSlider
                              images={productImages}
                              isDemoActive={isDemoActive}
                            />
                          )}
                        </div>
                        <div className="layout2Mobile__container1--product-detail">
                          <ProductDetailsThemeWise
                            data={{
                              allProducts,
                              ...productsData,
                            }}
                            setLocalAttributeSkuMap={setLocalAttributeSkuMap}
                          />
                        </div>
                      </div>
                      {/* =============================== */}
                    </div>
                    <div className="layout2Mobile__container2">
                      {componentRenderLogics("headingWithRightButton") && (
                        <HeadingWithRightButton
                          headingText={""}
                          // button={<img src={sloganSmall} />}
                          button={<PoweredByTheme2 />}
                        />
                      )}
                      {/* ============================= */}
                      {componentRenderLogics("fastCheckoutPageWrapper") && (
                        <FastCheckoutPageWrapper showHeader={false} />
                      )}
                      <div className="layout2Mobile__container2--review">
                        Customer Reviews
                      </div>
                    </div>
                  </div>

                  {/* <div className="divider">
                    <div className="divider-line"></div>
                  </div> */}
                </>
              )}
            </div>
          </div>
        </div>
        {isProductsAPILoading && <LoadingSpinner />}
      </React.Suspense>
    );
  }
};

export default AICheckoutMobileLayout2;
