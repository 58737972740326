import { debounce } from "lodash";
import { logOutUser } from "../../lib/state/clear-user-data";
import {
  setUserPhone,
  setUserVerified,
  setUserWebAuthn,
  verificationStates,
} from "../../store/reducers/userSlice";
import store from "../../store/store";
import { getShopperDetails, sendOTPCode } from "../../lib/api/otp-methods";
import { applicationUserType } from "../../store/reducers/appSlice";

declare const window: any;

// these listeners are all run after the onchange event for the phone number input, sequentially
export const phoneNumberChangeListeners: ((event: any) => void)[] = [
  async (event: any, meta?: any) => {
    // update the global state for the phone number
    const { country, number } = meta;
    const accountPhone = store.getState().users.currentUser.accountPhone;

    if (
      number &&
      country &&
      (accountPhone.country !== country || accountPhone.number !== number)
    ) {
      // If the phone number is changed, current verification status should be set to "unverified"
      const verificationState = store.getState().users.currentUser.verified;
      store.dispatch(
        setUserVerified({
          ...verificationState,
          state: verificationStates.unverified,
          firstPayment: "pending",
        })
      );

      if (number && country && number.length > 3) {
        // the phone number string may vary
        // if the first values of the phone number match the dial code
        phoneNumberChangeDebounce(country, number);
      } else {
        store.dispatch(
          setUserPhone({
            country: { countryCode: "", dialCode: "", flag: "", name: "" },
            number: "",
          })
        );
      }
    }
  },
];

export const phoneNumberCheck = async (country: any, number: any) => {
  const key = store.getState().users.currentUser.token;
  const authStateUser = store.getState().app?.auth?.user;
  if ((key && key !== "") || authStateUser === applicationUserType.OLD_USER) {
    // User cleared on phone number change, because phone is tied to a user
    logOutUser();
  }
  store.dispatch(
    setUserPhone({
      country: country,
      number: number,
    })
  );
  const shopperDetails = await getShopperDetails(
    country.dialCode.substring(1),
    number
  );

  if (
    window.Webpass.isSupported() &&
    shopperDetails &&
    shopperDetails?.webauthn
  ) {
    store.dispatch(setUserWebAuthn(true));
    const currentState = store.getState().users.currentUser.verified;
    store.dispatch(
      setUserVerified({
        ...currentState,
        state: verificationStates.trying,
        otp: {
          phone_number: number,
          country_code: country.dialCode.substring(1),
        },
        firstPayment: "pending",
      })
    );
  } else {
    store.dispatch(setUserWebAuthn(false));
    sendOTPCode(country.dialCode.substring(1), number);
  }
};

const phoneNumberChangeDebounce = debounce(
  (country, number) => phoneNumberCheck(country, number),
  400
);
