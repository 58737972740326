import store from "../../../../store/store";
import { handlePaymentRequest } from "../../../api/payment-post-request";

export async function cadeaukaartPayFunction() {
  const paymentState = store.getState().payment;

  const selectedPaymentMethod = paymentState.paymentMethodSelected;

  if (
    selectedPaymentMethod.serviceProvider !== "paynl" ||
    selectedPaymentMethod?.name !== "cadeaukaart"
  ) {
    return;
  }
  handlePaymentRequest();
}
