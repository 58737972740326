import React from "react";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

type Props = {
  onClick: () => void;
  checked: boolean;
  disabled?: boolean;
};

const FakeCheckbox: React.FC<Props> = ({
  onClick,
  checked,
  disabled = false,
}) => {
  const dispatch = useDispatch();

  function handleClick() {
    onClick();
  }

  return (
    <div
      className={"fake-checkbox " + (disabled ? "fake-checkbox-disabled" : "")}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      onClick={() => {
        if (!disabled) {
          dispatch(setInteractionsPerSessionCount());
          handleClick();
        }
      }}
    >
      <input
        type="checkbox"
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          fontFamily: "var(--main-font-secondary)",
        }}
        disabled={disabled}
        defaultChecked={checked ? true : false}
      />
    </div>
  );
};

export default FakeCheckbox;
