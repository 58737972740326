import Country from "../../interfaces/Country";

// creates a mapping of dial codes to country
export const buildDialCodeMap = (countries: any) => {
  const map: { [dial_code: string]: Country } = {};

  Object.keys(countries).forEach((key: any) => {
    const country = countries[key];
    map[country.dial_code] = {
      name: country.name,
      flag: country.flag,
      countryCode: key,
      dialCode: country.dial_code,
    };
  });

  return map;
};

export function convertToCountryMap(countries: { [key: string]: any }): {
  [idx: string]: Country;
} {
  return Object.keys(countries).reduce(
    (current: { [index: string]: Country }, k) => {
      const country = countries[k];
      current[k] = {
        countryCode: k,
        dialCode: country.dial_code,
        name: country.name,
        flag: country.flag,
      };
      return current;
    },
    {}
  );
}
