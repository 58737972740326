import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

type Props = {
  onChange: any;
  placeholder: string;
  value: string;
  extraClasses?: string;
  extraInputClasses?: string;
  name?: string;
  isLastInput?: boolean;
  isListInput?: boolean;
  readonly?: boolean;
};

const AnimatedTextFieldRegular = (props: Props) => {
  const {
    isLastInput,
    onChange,
    readonly = false,
    placeholder,
    value,
    extraClasses = "",
    extraInputClasses = "",
    name = "",
  } = props;
  const dispatch = useDispatch();
  return (
    <div className="flex-row">
      <div className={"animated-input-regular " + extraClasses}>
        <input
          className={"animated__input " + extraInputClasses}
          style={{ fontFamily: "var(--main-font-secondary)" }}
          id={isLastInput ? "border-bottom-none" : ""}
          type="text"
          onChange={onChange}
          disabled={readonly}
          readOnly={readonly}
          value={value}
          name={name}
          onFocus={() => {
            dispatch(setInteractionsPerSessionCount());
            window.innerHeight = 100;
          }}
        />
        <label className="animated__input--label">{placeholder}</label>
      </div>
    </div>
  );
};

export default AnimatedTextFieldRegular;
