import React from "react";

interface Props {
  isLastItemOnCart: boolean;
  currentAmount: number;
  stock: number;
  increase: (event: React.MouseEvent) => void;
  decrease: (event: React.MouseEvent) => void;
  isDisabled: boolean;
}

export const InputAmount: React.FC<Props> = ({
  isLastItemOnCart,
  decrease,
  currentAmount,
  stock,
  increase,
  isDisabled,
}) => {
  return (
    <div className="input-amount-with-controls">
      <button
        disabled={isLastItemOnCart || isDisabled}
        style={isLastItemOnCart ? { backgroundColor: "#dddddd" } : {}}
        onClick={decrease}
      >
        -
      </button>
      <span>{currentAmount}</span>
      <button
        disabled={currentAmount >= stock || isDisabled}
        style={currentAmount >= stock ? { backgroundColor: "#dddddd" } : {}}
        onClick={increase}
      >
        +
      </button>
    </div>
  );
};
