import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { RootState } from "../../../store/store";
import { logOutUser } from "../../../lib/state/clear-user-data";
import { useSelector } from "react-redux";
import { setIsAICheckout } from "../../../store/reducers/appSlice";
import store from "../../../store/store";
import { addShopperEvent } from "../../../lib/api/events-methods";
import { shopperLogOut } from "../../../lib/api/shopper-methods";
import DeleteAccountMsg from "../../../components/delete-account-message/DeleteAccountMsg";
import HeaderProfile from "../../../components/header/HeaderProfile";
import LogOutMsg from "../../../components/log-out-message/LogOutMsg";
import Footer from "../../../components/main-profile/Footer";
import ListNavigation from "../../../components/main-profile/ListNavigation";
import NameNumber from "../../../components/main-profile/NameNumber";
import SubViewPage from "../subViewPage/SubViewPage";
import { submitLogToBugsnag } from "../../../lib/api/log";

const ProfilePage: React.FC<any> = ({ userData, profile }) => {
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const fName = userData?.profile?.name?.first;
  const lName = userData?.profile?.name?.second;
  const countryCode = userData?.accountPhone?.country?.dialCode;
  const phoneNumber = userData?.accountPhone?.number;
  const userEmail = userData?.profile?.email;
  const slug = useSelector((state: RootState) => state.cart.shop.slug);
  const merchantTheme = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );
  const { UI } = useSelector((state: RootState) => state.app) as any;

  useEffect(() => {
    //* Triggering Events API on user actions
    addShopperEvent("view_page", {
      url: `${window.location.href}`,
      page: "profile",
      email: userEmail && userEmail !== "" ? userEmail : undefined,
    });
  }, [location?.pathname]);

  /* --------------logout stuff start---------------- */

  const [isLogOutPopupOpen, setIsLogOutPopupOpen] = useState(false);

  const onSignoutToggle = () => {
    setIsLogOutPopupOpen(!isLogOutPopupOpen);
  };

  const logOutClick = async () => {
    try {
      await shopperLogOut();
    } catch (error) {
      console.log("Error when revoking token", error);
      submitLogToBugsnag("error", `Error when revoking token: ${error}`);
    }
    logOutUser();
    window.open(`/${slug}/products`, "_self");
  };

  /* --------------logout stuff end------------------ */

  /* --------------delete account stuff start---------------- */

  const [isDeleteAccountPopupOpen, setIsDeleteAccountPopupOpen] =
    useState(false);

  const onDeleteAccountToggle = () => {
    setIsDeleteAccountPopupOpen(!isDeleteAccountPopupOpen);
  };

  /* --------------delete account stuff end------------------ */

  const accountList = [
    {
      linkTitle: `${t("AccountDetails")}`,
      buttonType: "normal",
      route: "/profile/account",
    },
    {
      linkTitle: "Past Orders",
      buttonType: "normal",
      route: "/profile/orders",
    },
    {
      linkTitle: `${t("Addresses")}`,
      buttonType: "normal",
      route: "/profile/addresses",
    },
    {
      linkTitle: `${t("SavedPaymentDetails")}`,
      buttonType: "normal",
      route: "/profile/payments",
    },
  ];

  const settingsList = [
    {
      linkTitle: `${t("Language")}`,
      buttonType: "normal",
      route: "/profile/language",
    },
    /* { linkTitle: `${t("Dark Mode")}`, buttonType: "toggle" }, */
  ];
  const legalList = [
    {
      linkTitle: t("Terms"),
      buttonType: "normal",
      hrefLink: `${merchantTheme?.terms_url}`,
    },
    {
      linkTitle: t("Privacy"),
      buttonType: "normal",
      hrefLink: `${merchantTheme?.privacy_url}`,
    },
    {
      linkTitle: t("FAQ"),
      buttonType: "normal",
      hrefLink: `${merchantTheme?.faq_url}`,
    },
  ];

  const othersList = [
    {
      linkTitle: `${t("DeleteAccount")}`,
      buttonType: "delete",
      handlerFunction: onDeleteAccountToggle,
    },
    {
      linkTitle: `${t("SignOut")}`,
      buttonType: "signout",
      handlerFunction: onSignoutToggle,
    },
  ];

  useEffect(() => {
    document.title = `Profile - ${fName} ${lName} - Powered by ${merchantTheme?.merchant_name}`;
    store.dispatch(setIsAICheckout(false));
  }, []);

  const profilePageContent = () => {
    return (
      <div className="main-wrapper">
        <HeaderProfile
          backRoute={
            UI?.lastCartUrl ? `${UI?.lastCartUrl}` : `/${slug}/products`
          }
          backRouteTitle={t("OverviewPageTitle")}
        />

        {userData?.profile?.id && (
          <NameNumber
            fname={fName}
            lname={lName}
            code={countryCode}
            number={phoneNumber}
          />
        )}
        {userData?.profile?.id && (
          <ListNavigation title={t("Account")} list={accountList} />
        )}
        <ListNavigation title={t("Settings")} list={settingsList} />
        <ListNavigation title={t("Legal")} list={legalList} />
        {userData?.profile?.id && (
          <ListNavigation title={t("Other")} list={othersList} />
        )}
        <Footer />
      </div>
    );
  };

  return (
    <>
      {activeLink === "profile" && profilePageContent()}
      <Routes>
        <Route
          path="/*"
          element={<SubViewPage userData={userData} profile={profile} />}
        />
      </Routes>
      {isLogOutPopupOpen && (
        <LogOutMsg
          onLogOutClick={logOutClick}
          onCancelClick={onSignoutToggle}
        />
      )}
      {isDeleteAccountPopupOpen && (
        <DeleteAccountMsg onCancelClick={onDeleteAccountToggle} />
      )}
    </>
  );
};

export default ProfilePage;
