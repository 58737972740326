import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPaymentMethodSelected,
  setIsPaymentMethodEditable,
  setIsPaymentMethodsLoading,
  setIsWalletPaymentMethodInUse,
  setIsWalletPaymentsSupportedByDevice,
  setPaymentMethodSelected,
} from "../../store/reducers/paymentSlice";
import store, { RootState } from "../../store/store";
import "./payment-request-components.scss";
import { handlePaymentRequest } from "../../lib/api/payment-post-request";
import { submitLogToBugsnag } from "../../lib/api/log";

declare const window: any;

const PaynlPaymentRequestAdupContainer = (
  paynlPaymentRequestAdupContainerProps: any
) => {
  const { t } = useTranslation();
  const accountPhone = store.getState().users.currentUser.accountPhone;
  const isPayButtonLoading = useSelector(
    (state: RootState) => state.app.UI.isPayButtonLoading
  );
  const isPaymentMethodEditable =
    store.getState().payment?.isPaymentMethodEditable;
  const [isApplePaySupported, setIsApplePaySupported] = useState(true);

  const dispatch = useDispatch();

  const otherPayments = () => {
    dispatch(clearPaymentMethodSelected(null));
    paynlPaymentRequestAdupContainerProps.setPaymentMethodChanged(true);
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsWalletPaymentsSupportedByDevice(false));
  };

  const skipAvailableWalletPayments = () => {
    dispatch(clearPaymentMethodSelected(null));
    dispatch(setIsWalletPaymentsSupportedByDevice(true));
    paynlPaymentRequestAdupContainerProps.setPaymentMethodChanged(true);
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsPaymentMethodEditable(false));
  };

  useEffect(() => {
    dispatch(setIsPaymentMethodsLoading(true));
  }, []);

  useEffect(() => {
    if (isApplePaySupported) dispatch(setIsWalletPaymentMethodInUse(true));
  }, [isApplePaySupported]);

  useEffect(() => {
    if (window.ApplePaySession) {
      try {
        setIsApplePaySupported(window.ApplePaySession.canMakePayments());
        console.log(
          "isApplePaySupported",
          window.ApplePaySession.canMakePayments()
        );
        dispatch(setIsPaymentMethodsLoading(false));
        if (isPaymentMethodEditable) {
          skipAvailableWalletPayments();
        }
      } catch (error) {
        console.log("ApplePay Not Supported", error);
        submitLogToBugsnag("error", `ApplePay Not Supported : ${error}`);
        dispatch(setIsPaymentMethodsLoading(false));
        otherPayments();
        setIsApplePaySupported(false);
        // dispatch(
        //   setToastMessage({
        //     text: `${error}`,
        //     type: "ERROR",
        //   })
        // );
      }
    } else {
      console.log("ApplePay Not Supported");
      dispatch(setIsPaymentMethodsLoading(false));
      otherPayments();
      setIsApplePaySupported(false);
    }
  }, []);

  if (
    !accountPhone ||
    !accountPhone.number ||
    !accountPhone.country.countryCode
  ) {
    skipAvailableWalletPayments();
  }

  if (isApplePaySupported && accountPhone.number) {
    return (
      <>
        <div className="change-pay-method-link" style={{ textAlign: "center" }}>
          <h2 onClick={skipAvailableWalletPayments}>
            {t("ChangePaymentMethod")}
          </h2>
        </div>
        <br />
        <AnimatePresence>
          <motion.div
            animate={{ transform: "translateY(20px" }}
            initial={{ transform: "translateY(500px)" }}
            transition={{
              delay: 0.2,
              duration: 0.51,
              type: "spring",
              bounce: 0.2,
            }}
            className="pay-button-holder-fixed"
          >
            <button
              className="pay-button-apple-pay"
              onClick={() => {
                store.dispatch(
                  setPaymentMethodSelected(
                    paynlPaymentRequestAdupContainerProps.paymentMethod
                  )
                );
                handlePaymentRequest();
              }}
            >
              <div
                style={{ fontSize: "25px" }}
                className="apple-pay-button apple-pay-button-black"
              >
                {/* {isPayButtonLoading ? (
                  <span style={{ marginLeft: 10, marginRight: 45 }}>
                    <AnimatedLoadingCircleColored />
                  </span>
                ) : (
                  <div></div>
                )} */}
              </div>
            </button>
          </motion.div>
        </AnimatePresence>
      </>
    );
  } else return null;
};

export default PaynlPaymentRequestAdupContainer;
