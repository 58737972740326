import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setDemoCompanyName,
  setDemoProductDescription,
  setDemoProductImage,
  setDemoProductName,
  setDemoProductPrice,
} from "../../store/reducers/appSlice";
import store, { RootState } from "../../store/store";
import logo from "../../assets/png/short-logo.png";
import { cssRedBorderClass } from "../../components/constants";
import { logOutUser } from "../../lib/state/clear-user-data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import React from "react";
import AnimatedTextAreaFieldRegular from "../../components/custom-input-field/AnimatedTextAreaFieldRegular";
import AnimatedTextFieldRegular from "../../components/custom-input-field/AnimatedTextFieldRegular";
import MaskedCurrencyInput from "../../components/custom-input-field/CurrencyInput";
import ImageInput from "../../components/custom-input-field/ImageInput";
import { setIsInitAPILoading } from "../../store/reducers/paymentSlice";

const DummyCheckoutGenerator = () => {
  const mainURL = window.location.origin;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { demoCheckoutInitData } = useSelector((state: RootState) => state.app);

  const [selectedIcon, setSelectedIcon] = useState();
  const [previewIcon, setPreviewIcon] = useState<string>();
  const [fileErrorIcon, setFileErrorIcon] = useState(false);
  const [isGenerateButtonEnabled, setIsGenerateButtonEnabled] = useState(false);

  useEffect(() => {
    document.title = `Demo - Powered by Ad Up`;
    logOutUser();
    store.dispatch(setIsInitAPILoading(false));
    store.dispatch(setDemoCompanyName(undefined));
    store.dispatch(setDemoProductName(undefined));
    store.dispatch(setDemoProductDescription(undefined));
    store.dispatch(setDemoProductPrice(undefined));
    store.dispatch(setDemoProductImage(undefined));
  }, []);

  useEffect(() => {
    if (
      demoCheckoutInitData?.shop?.name &&
      demoCheckoutInitData?.shop?.name !== "" &&
      demoCheckoutInitData.products[0]["demo-sku"].name &&
      demoCheckoutInitData.products[0]["demo-sku"].name !== "" &&
      demoCheckoutInitData.products[0]["demo-sku"].description &&
      demoCheckoutInitData.products[0]["demo-sku"].description !== "" &&
      demoCheckoutInitData.products[0]["demo-sku"].display_price &&
      demoCheckoutInitData.products[0]["demo-sku"].display_price !== "" &&
      previewIcon &&
      previewIcon !== ""
    ) {
      const timer = setTimeout(() => {
        setIsGenerateButtonEnabled(true);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setIsGenerateButtonEnabled(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [demoCheckoutInitData, previewIcon]);

  const setImagePreview = (objectUrl: any) => {
    let imageArray = [];
    imageArray.push(objectUrl);
    setPreviewIcon(objectUrl);
    store.dispatch(setDemoProductImage(objectUrl !== "" ? imageArray : []));
  };

  const [height, setHeight] = useState<any>();
  const ref = useRef(null);
  useEffect(() => {
    // @ts-ignore:next-line
    setHeight(ref?.current?.clientHeight);
  }, []);

  const getPreviewHeight = () => {
    if (height) {
      return `${height - 20}px`;
    }

    return "80vh";
  };

  useEffect(() => {
    if (demoCheckoutInitData && previewIcon && isGenerateButtonEnabled) {
      const timer = setTimeout(() => {
        setIsGenerateButtonEnabled(!isGenerateButtonEnabled);
        setIsGenerateButtonEnabled(isGenerateButtonEnabled);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [demoCheckoutInitData, previewIcon]);

  return (
    <React.Suspense fallback={<div className="max-width-container"></div>}>
      <div className="max-width-container">
        <div className="max-width-container__content">
          {/* //* dummy banner -- start */}
          <div className="introBannerSC">
            <div className="introBannerSC__left">
              <LazyLoadImage loading="lazy" src={logo} alt="adup logo" />
            </div>
            <div className="introBannerSC__right">
              <p>
                <span>Ad Up Social Commerce !</span>
              </p>
              <p> {t("DemoTitle-1")}</p>
            </div>
          </div>
          {/* //* dummy banner -- end */}

          {/* //* form -- start */}
          <div className="dummyCheckoutGenerator--title">
            <h2 className="heading-2-regular fast-checkout-segment-heading">
              {t("DemoTitle-2")}
            </h2>
            <a
              target={"_blank"}
              href="https://dashboard.adup.io/register"
              rel="noreferrer"
            >
              <button>{t("StartFreeTrial")}</button>
            </a>
          </div>
          <div className="dummyCheckoutGenerator">
            <div className="dummyCheckoutGenerator__left">
              <div className="account-form fast-checkout-page-segment">
                <span ref={ref}>
                  <AnimatedTextFieldRegular
                    placeholder={t("DemoCompanyName")}
                    onChange={(e: any) =>
                      store.dispatch(
                        setDemoCompanyName((e.target as HTMLInputElement).value)
                      )
                    }
                    key={"DemoCompanyName"}
                    name="DemoCompanyName"
                    value={demoCheckoutInitData?.shop?.name ?? ""}
                    extraClasses={false ? cssRedBorderClass : ""}
                  />
                  <AnimatedTextFieldRegular
                    placeholder={t("DemoProductName")}
                    onChange={(e: any) =>
                      store.dispatch(
                        setDemoProductName((e.target as HTMLInputElement).value)
                      )
                    }
                    key={"DemoProductName"}
                    name="DemoProductName"
                    value={
                      demoCheckoutInitData.products[0]["demo-sku"].name ?? ""
                    }
                    extraClasses={false ? cssRedBorderClass : ""}
                  />
                  <MaskedCurrencyInput
                    changeListeners={[
                      (value: any) => {
                        store.dispatch(setDemoProductPrice(Number(value)));
                      },
                    ]}
                    id="DemoProductPrice"
                    value={
                      demoCheckoutInitData.products[0]["demo-sku"].display_price
                    }
                    placeholder={t("DemoProductPrice")}
                    required={true}
                    type="text"
                    pattern="^[\d|\.|\,]+/"
                  />
                  <AnimatedTextAreaFieldRegular
                    placeholder={t("DemoProductDescription")}
                    onChange={(e: any) =>
                      store.dispatch(
                        setDemoProductDescription(
                          (e.target as HTMLInputElement).value
                        )
                      )
                    }
                    key={"DemoProductDescription"}
                    name="DemoProductDescription"
                    value={
                      demoCheckoutInitData.products[0]["demo-sku"]
                        .description ?? ""
                    }
                    extraClasses={false ? cssRedBorderClass : ""}
                  />
                  <ImageInput
                    mainText={t("DemoProductImage")}
                    subText="Accepted file types (.png, .jpeg)"
                    acceptedFileTypes={".png, .jpeg"}
                    id={"DemoProductImage"}
                    selectedFile={selectedIcon}
                    setSelectedFile={(file: any) => {
                      setSelectedIcon(file);
                    }}
                    preview={previewIcon}
                    setPreview={selectedIcon ? setImagePreview : () => {}}
                    fileError={fileErrorIcon}
                    setFileError={setFileErrorIcon}
                    manyImages={false}
                    removeImage={() => {
                      setSelectedIcon(undefined);
                      setImagePreview("");
                    }}
                  />
                  <div className="dummyCheckoutGenerator-btnHolder">
                    <button
                      data-is-generated-button-enabled={
                        isGenerateButtonEnabled ? "true" : "false"
                      }
                      disabled={!isGenerateButtonEnabled}
                      onClick={() => {
                        navigate("/demo/social-commerce");
                      }}
                    >
                      {t("GenerateDemo")}
                    </button>
                  </div>
                </span>
              </div>
            </div>

            <div className="dummyCheckoutGenerator__right">
              {!isGenerateButtonEnabled && (
                <div
                  style={{ height: getPreviewHeight() }}
                  className="dummyCheckoutGenerator__right--placeholder"
                >
                  <LazyLoadImage loading="lazy" src={logo} alt={logo} />
                  <h2>{t("LivePreview")}</h2>
                </div>
              )}
              {isGenerateButtonEnabled && (
                <div className="dummyCheckoutGenerator__right--iframeHolder">
                  <iframe
                    style={{ height: getPreviewHeight() }}
                    title="dummyCheckoutGeneratorIframe"
                    // src="https://www.google.com/"
                    src={`${mainURL}/demo/social-commerce`}
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Suspense>
  );
};

export default DummyCheckoutGenerator;
