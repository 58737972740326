import store from "../../../store/store";
import { RequestInfo } from "../../../interfaces/RequestInfo";
import { asyncHandler } from ".././async-handler";
import { ADD_TO_CART_API } from "../../constants/central-api";
import {
  setCartId,
  setCartPriceCalculations,
  setShopWiseCartIds,
  updateItemTotal,
} from "../../../store/reducers/cartSlice";
import {
  setIsAddToCartAPILoading,
  setLastAddToCartCallPayload,
} from "../../../store/reducers/paymentSlice";
import { setToastMessage } from "../../../store/reducers/appSlice";
import {
  generateAddToCartArrayUsingCartItems,
  getCountryCodeByCountryName,
  isNotEmptyString,
} from "../../utils/helper-functions";
import { isArray } from "lodash";
import { AdupUserAddress } from "../../../interfaces/AdupUserAddress";

interface CartItems {
  sku: string;
  qty: number;
}
/**
 * @param { CartItems[] } cartItems The corresponding Cart Items Array
 */

export async function addToCart(customAddress?: AdupUserAddress) {
  const app_mode = window?.location?.pathname.split("/")[1];
  if (app_mode === "" || app_mode === "thank-you" || app_mode === "profile") {
    //? prevent API call in unwanted pages
    store.dispatch(setIsAddToCartAPILoading(false));
    return null;
  }

  const key = store.getState().users?.currentUser?.token; // current user token
  const shopSlug = store.getState().cart?.shop?.slug; // current shop slug
  const cartId = store.getState().cart?.shopWiseCartIds?.[shopSlug]; // current cart id attached to the current shop slug
  const cartItems = store.getState().cart?.items; // current cart items
  const shippingAddress =
    store.getState().users?.currentUser?.shipping?.shippingAddress; // current user shipping address
  const lastAddToCartCallPayload =
    store.getState().payment?.lastAddToCartCallPayload;
  const isInitAPILoading = store.getState().payment?.isInitAPILoading;
  const isAnyUnavailableStocksDetected =
    store.getState().payment?.isAnyUnavailableStocksDetected;
  const paymentMethodType = store.getState().payment?.paymentMethodSelected
    .name as string;

  if (
    !shopSlug ||
    shopSlug === "" ||
    isInitAPILoading ||
    isAnyUnavailableStocksDetected
  ) {
    store.dispatch(setIsAddToCartAPILoading(false));
    return null;
  }

  //* Initializing add-to-cart API payload with shop_slug & items
  const sendingData = {
    shop_slug: shopSlug,
    items: generateAddToCartArrayUsingCartItems(cartItems),
  } as any;

  //* Adds cart_id to the payload if available
  if (cartId && isNotEmptyString(cartId)) {
    sendingData.cart_id = cartId;
  }

  //* Adds payment_method to the payload if available
  if (paymentMethodType && isNotEmptyString(paymentMethodType)) {
    sendingData.payment_method = paymentMethodType;
  }

  //* Adds the shipping_address to the payload if available
  //* Gives priority to customAddress if available else uses shippingAddress from redux store
  if (customAddress && isNotEmptyString(customAddress?.country_name)) {
    sendingData.shipping_address = {
      house_number:
        customAddress?.number && isNotEmptyString(customAddress?.number)
          ? customAddress?.number
          : "-",
      street:
        customAddress?.street && isNotEmptyString(customAddress?.street)
          ? customAddress?.street
          : "-",
      street2: customAddress?.street2,
      city:
        customAddress?.city && isNotEmptyString(customAddress?.city)
          ? customAddress?.city
          : "-",
      province:
        customAddress?.province && isNotEmptyString(customAddress?.province)
          ? customAddress?.province
          : "-",
      postcode:
        customAddress?.postcode && isNotEmptyString(customAddress?.postcode)
          ? customAddress?.postcode
          : "-",
      country: customAddress?.country_name,
      country_code:
        customAddress?.country_code &&
        isNotEmptyString(customAddress?.country_code)
          ? customAddress?.country_code
          : getCountryCodeByCountryName(customAddress?.country_name) ??
            customAddress?.country_name,
    };
  } else if (
    shippingAddress &&
    isNotEmptyString(shippingAddress?.country_name)
  ) {
    sendingData.shipping_address = {
      house_number:
        shippingAddress?.number && isNotEmptyString(shippingAddress?.number)
          ? shippingAddress?.number
          : "-",
      street:
        shippingAddress?.street && isNotEmptyString(shippingAddress?.street)
          ? shippingAddress?.street
          : "-",
      street2: shippingAddress?.street2,
      city:
        shippingAddress?.city && isNotEmptyString(shippingAddress?.city)
          ? shippingAddress?.city
          : "-",
      province:
        shippingAddress?.province && isNotEmptyString(shippingAddress?.province)
          ? shippingAddress?.province
          : "-",
      postcode:
        shippingAddress?.postcode && isNotEmptyString(shippingAddress?.postcode)
          ? shippingAddress?.postcode
          : "-",
      country: shippingAddress?.country_name,
      country_code:
        shippingAddress?.country_code &&
        isNotEmptyString(shippingAddress?.country_code)
          ? shippingAddress?.country_code
          : getCountryCodeByCountryName(shippingAddress?.country_name) ??
            shippingAddress?.country_name,
    };
  }

  if (lastAddToCartCallPayload !== JSON.stringify(sendingData)) {
    store.dispatch(setIsAddToCartAPILoading(true));
    const requestInfo: RequestInfo = {
      url: `${ADD_TO_CART_API}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: key ? `Bearer ${key}` : "",
      },
      body: JSON.stringify(sendingData),
    };

    const actionType = "ADD_TO_CART";
    const customMsg = "Added to cart Successfully.";

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;

    if (
      res &&
      res?.actionType === actionType &&
      response &&
      response?.success === true
    ) {
      console.log(response);
      store.dispatch(setCartId(response?.data?.id));
      store.dispatch(
        setShopWiseCartIds({ shopSlug: shopSlug, cartId: response?.data?.id })
      );
      store.dispatch(
        setCartPriceCalculations(response?.data?.price_calculations)
      );
      if (isArray(response?.data?.items)) {
        for (const item of response?.data?.items) {
          store.dispatch(
            updateItemTotal({
              sku: item?.sku,
              totalPriceFormatted: item?.total?.formatted,
              totalPriceNumeric: item?.total?.major,
            })
          );
        }
      }
      store.dispatch(setLastAddToCartCallPayload(JSON.stringify(sendingData)));
      store.dispatch(setIsAddToCartAPILoading(false));
      return response?.data;
    } else if (
      //TODO: remove this after fixing the API issue
      response?.message === "The selected cart id is invalid." ||
      response?.message ===
        "No query results for model [Modules\\Order\\Models\\Cart]." ||
      response?.message === "The cart does not belong to the logged-in user."
    ) {
      console.log("Add-To-Cart API failed : ", response?.message);
      console.log("Re executing Add-To-Cart API with new cart id");
      //* revoke cartId & data
      store.dispatch(setCartId(undefined));
      store.dispatch(
        setShopWiseCartIds({ shopSlug: shopSlug, cartId: undefined })
      );
      store.dispatch(setLastAddToCartCallPayload(undefined));
      addToCart();
    } else {
      store.dispatch(
        setToastMessage({
          text: response?.message,
          type: "ERROR",
        })
      );
      console.log("Add-To-Cart API failed : ", response?.message);
      store.dispatch(setIsAddToCartAPILoading(false));
      return null;
    }
  }
  console.log(
    "Prevented unnecessary Add-To-Cart API Calls due to unchanged data : ",
    lastAddToCartCallPayload
  );
  store.dispatch(setIsAddToCartAPILoading(false));
  return null;
}
