import store from "../../store/store";
import { RequestInfo } from "../../interfaces/RequestInfo";
import { asyncHandler } from "./async-handler";
import { UPDATE_PROFILE_API } from "../constants/central-api";
import { submitLogToBugsnag } from "./log";

export async function updateUserAccount(
  account: any,
  customMsg: string
): Promise<any> {
  // send request
  const result = await updateUserAccountRequest(account, customMsg);
  return result;
}
async function updateUserAccountRequest(account: any, customMsg: string) {
  const key = store.getState().users.currentUser.token;

  if (key) {
    try {
      const requestInfo: RequestInfo = {
        method: "PUT",
        url: UPDATE_PROFILE_API,
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: key ? `Bearer ${key}` : "",
        },
        body: JSON.stringify(account),
      };

      const actionType = "UPDATE_USER_PROFILE";

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const data = res?.data;

      if (
        res &&
        res?.actionType === actionType &&
        data &&
        data?.success === true
      ) {
        return data;
      }
    } catch (exception) {
      console.log("Error sending auth request");
      submitLogToBugsnag("error", `Error sending auth request : ${exception}`);
      console.error(exception);
      return null;
    }
  } else {
    return null;
  }
}
