import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Country from "../../interfaces/Country";
import { RootState } from "../../store/store";
import { phoneNumberChangeListeners } from "../account-form/phone-change-listeners";
import CustomPhoneField from "../custom-input-field/CustomPhoneField";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { UserDetailsSVG } from "../../assets/js/allCustomSVGs";

interface Props {
  hideBtns?: boolean;
}

const PhoneForm = ({ hideBtns }: Props) => {
  const { t } = useTranslation();
  const route = window.decodeURI(useLocation().pathname);

  const authState = useSelector((state: RootState) => state.app.auth);

  // current phone number attached to a user account
  const userPhone = useSelector(
    (state: RootState) => state.users.currentUser.accountPhone
  );

  // country returned from the server depending on the customer IP
  const appIPCountry = useSelector(
    (state: RootState) => state.app.settings.ipAddressCountryCode
  );

  // map of countries supported, key is country code ('UK', 'LK', 'US', 'AU', 'IN' etc)
  const countries = useSelector(
    (state: RootState) => state.app.phone.countryCodes
  ) as any;

  // map of countries supported, key is dial code ('+94', '+1' etc)
  const dialCodesMap = useSelector(
    (state: RootState) => state.app.phone.countryDialCodeMap
  );

  const [phone, setPhone] = useState(userPhone ? userPhone.number : "");

  const [country, setCountry] = useState<Country>(
    userPhone?.country?.countryCode && userPhone.country.countryCode !== ""
      ? userPhone.country
      : countries[appIPCountry]
  );

  useEffect(() => {
    if (userPhone?.country.countryCode !== "") {
      setPhone(userPhone.number);
      setCountry(userPhone.country);
    } else if (appIPCountry !== "") {
      setCountry((prev) => countries[appIPCountry]);
    }

    if (countries && countries[appIPCountry]) {
      if (countries[appIPCountry]) {
        setCountry((_) => countries[appIPCountry]);
      }
    }
  }, [userPhone, appIPCountry, countries]);

  return (
    <div className="account-phone-form-container fast-checkout-page-segment">
      <div className="account-phone-heading-holder">
        {/* <HeadingWithRightButton
          headingText={t("YourDetails")}
          button={
            authState.user === applicationUserType.OLD_USER &&
            route !== "/profile/account" ? (
              <LogoutButton />
            ) : null
          }
        /> */}
        <div className="account-phone-heading-holder__left">
          <CustomSVGs svg={UserDetailsSVG} />
          <h2 className="heading-2-regular">{t("YourDetails")}</h2>
        </div>
        <div className="account-phone-heading-holder__right">
          {/* {!hideBtns && (
            <>
              <Link to="/profile">
                <div
                  title={t("Profile")}
                  className="account-phone-heading-holder__right--profile"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z" />
                  </svg>
                </div>
              </Link>
            </>
          )} */}
        </div>
      </div>
      <CustomPhoneField
        initialCountry={country}
        initialPhone={phone}
        countries={countries}
        dialCodeToCountryCodeMap={dialCodesMap}
        changeListeners={
          route !== "/demo/social-commerce"
            ? phoneNumberChangeListeners
            : undefined
        }
        disabled={route === "/profile/account" ? true : false}
      />
    </div>
  );
};

export default PhoneForm;
