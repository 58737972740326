import { truncate } from "../../lib/utils/helper-functions";
import "../../sass/components/socialCommerce/singleProductSC.scss";
import plusSVG from "../../assets/svg/plus-black-SVG.svg";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

interface Props {
  image: string;
  name: string;
  price: string | number;
  type: string;
  cartItemsNum: number;
  disabled: boolean;
  onClick: (e: any) => void;
  onAddToCartClick: () => void;
}

const SingleProductSC = ({
  image,
  name,
  price,
  type,
  cartItemsNum,
  disabled,
  onClick,
  onAddToCartClick,
}: Props) => {
  const { t } = useTranslation();
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  return (
    <div
      className={`singleProductSC ${disabled ? "disabled" : ""}`}
      style={{ cursor: disabled ? "not-allowed" : "default" }}
    >
      <div
        className="singleProductSC__top"
        style={{ cursor: disabled ? "not-allowed" : "default" }}
      >
        <div
          className="singleProductSC__top--img"
          onClick={(e: any) => {
            if (!disabled) onClick(e);
          }}
          style={{
            marginRight: 7,
            cursor: disabled ? "not-allowed" : "pointer",
          }}
        >
          {/* <img
            loading="lazy"
            src={image}
            alt={name}
            className={!isImgLoaded ? "singleProductSC__top--placeholder" : ""}
            onLoad={() => {
              setIsImgLoaded(true);
            }}
            onError={() => {
              setIsImgLoaded(true);
            }}
            style={{ cursor: "pointer" }}
          /> */}
          <LazyLoadImage
            loading="lazy"
            src={image}
            alt={name}
            className={!isImgLoaded ? "singleProductSC__top--placeholder" : ""}
            onLoad={() => {
              setIsImgLoaded(true);
            }}
            onError={() => {
              setIsImgLoaded(true);
            }}
            style={{ cursor: disabled ? "not-allowed" : "pointer" }}
          />
        </div>
        {/* //* Only Simple products can be directly added to Cart via Overview Page */}
        {type === "simple" && !disabled && (
          <button
            className="singleProductSC__top--buttons-plusBtn"
            onClick={() => {
              if (!disabled) onAddToCartClick();
            }}
            style={{ cursor: disabled ? "not-allowed" : "pointer" }}
          >
            <LazyLoadImage loading="lazy" src={plusSVG} alt="add" />
          </button>
        )}
      </div>
      <div
        className="singleProductSC__bottom"
        onClick={(e: any) => {
          if (!disabled) onClick(e);
        }}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        <div className="singleProductSC__bottom--textHolder">
          <h3>{truncate(name, 38)}</h3>
          {disabled && (
            <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
              {t("ProductUnavailable")}
            </h3>
          )}
        </div>
        <span style={{ textDecoration: disabled ? "line-through" : "" }}>
          {price}
        </span>
      </div>
    </div>
  );
};

export default SingleProductSC;
