import store from "../../store/store";

export default function getImages(
  imagesObj: any,
  imageSize: "small" | "medium" | "large" | "original" = "small",
  amountOfImages: "all_images_array" | "only_first" = "only_first"
) {
  const imageStaticUrl = store.getState().app?.appData?.static_base_url;
  const defaultImage = `${imageStaticUrl}/medium/default.jpg`;

  let firstImage = [defaultImage];
  if (imagesObj) {
    if (imagesObj?.length > 0) {
      if (typeof imagesObj[0] === "string") {
        return imagesObj;
      }
    }

    const values: any = Object?.values(imagesObj);
    if (values?.length > 0) {
      if (amountOfImages === "all_images_array") {
        const imagesArray = values?.map((image: any) => {
          return image?.small ? image[imageSize] : defaultImage;
        });
        return imagesArray;
      }
      if (amountOfImages === "only_first") {
        firstImage = [values[0]?.small ? values[0][imageSize] : defaultImage];
        return firstImage;
      }
    }

    return [defaultImage];
  } else {
    return [defaultImage];
  }
}
