import { useEffect, useState } from "react";
import { FastCheckoutDesktopLayout2 } from "./desktop/FastCheckoutDesktopLayout2";
import { FastCheckoutMobileLayout2 } from "./mobile/FastCheckoutMobileLayout2";
import { useLocation } from "react-router-dom";
import FastCheckoutDesktopExtraLayout2 from "./desktop/FastCheckoutDesktopExtraLayout2";
import FastCheckoutMobileExtraLayout2 from "./mobile/FastCheckoutMobileExtraLayout2";

interface Props {
  app_mode: any;
  componentRenderLogics: (componentType: string) => boolean | undefined;
}

const FastCheckoutLayout2 = ({ app_mode, componentRenderLogics }: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const location = useLocation();
  const isFastCheckoutPage = location.pathname.includes("ai-fast-checkout/");
  const isOverviewPage = location.pathname.includes("/products");

  const renderView = () => {
    if (windowWidth > 750) {
      if (isFastCheckoutPage || isOverviewPage) {
        return (
          <FastCheckoutDesktopExtraLayout2
            app_mode={app_mode}
            componentRenderLogics={componentRenderLogics}
          />
        );
      } else {
        return (
          <FastCheckoutDesktopLayout2
            app_mode={app_mode}
            componentRenderLogics={componentRenderLogics}
          />
        );
      }
    } else {
      if (isFastCheckoutPage || isOverviewPage) {
        return (
          <FastCheckoutMobileExtraLayout2
            app_mode={app_mode}
            componentRenderLogics={componentRenderLogics}
          />
        );
      } else {
        return (
          <FastCheckoutMobileLayout2
            app_mode={app_mode}
            componentRenderLogics={componentRenderLogics}
          />
        );
      }
    }
  };
  return <>{renderView()}</>;
};

export default FastCheckoutLayout2;
