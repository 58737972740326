import { INIT_API } from "../constants/central-api";
import { RequestInfo } from "../../interfaces/RequestInfo";
import { asyncHandler } from "./async-handler";
import store from "../../store/store";
import {
  setIsInitAPILoading,
  setIsInitApiFetched,
} from "../../store/reducers/paymentSlice";
import { isNotEmptyString } from "../utils/helper-functions";
import { setUserToken } from "../../store/reducers/userSlice";

/**
 * @param { string } params INIT params
 */
export async function getAppInitData(params: string) {
  store.dispatch(setIsInitApiFetched(true));
  store.dispatch(setIsInitAPILoading(true));
  const { token } = store.getState().users.currentUser;

  //* Extracting cacheBustToken from URL if there is any
  const queryParameters = new URLSearchParams(window.location.search);
  const cacheBustToken = queryParameters.get(
    "cacheBustToken"
  ) as unknown as string;

  const requestInfo: RequestInfo = {
    url: `${INIT_API}${params}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: isNotEmptyString(token)
        ? `Bearer ${token}`
        : isNotEmptyString(cacheBustToken)
        ? `Bearer ${cacheBustToken}`
        : "",
    },
  };

  const actionType = "GET_APP_INIT_DATA";
  const customMsg = "INIT API Loaded Successfully.";

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;

  //* updating user token in case if it is changed
  store.dispatch(
    setUserToken(
      isNotEmptyString(token)
        ? token
        : isNotEmptyString(cacheBustToken)
        ? cacheBustToken
        : undefined
    )
  );

  //* Removing cacheBustToken from browser URL
  queryParameters?.delete("cacheBustToken");
  window.history.replaceState(
    {},
    "",
    `${window?.location?.pathname}${
      isNotEmptyString(queryParameters.toString()) ? `?${queryParameters}` : ""
    }`
  );

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    store.dispatch(setIsInitAPILoading(false));
    return response.data;
  } else {
    window.open(`/`, "_self");
    store.dispatch(setIsInitApiFetched(false));
    store.dispatch(setIsInitAPILoading(false));
    return null;
  }
}
