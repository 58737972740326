import React, { ChangeEvent, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { AdupUserAddress } from "../../interfaces/AdupUserAddress";
import EditButtonLight from "../button/EditButton";
import AddressField from "../manual-address-field/AddressField";
import { useDispatch, useSelector } from "react-redux";
import DeleteButton from "../button/DeleteButton";
import { deleteUserAddress } from "../../lib/api/address-calls/delete-address";
import RoundButton from "../round-button/RoundButton";
import { RootState } from "../../store/store";
import { getAddressFromAddressesObj } from "../../lib/utils/helper-functions";
import { useTranslation } from "react-i18next";
import {
  setBillingAddress,
  setShippingAddress,
} from "../../store/reducers/userSlice";
import { crossSVG, floppyDiskSVG } from "../../assets/js/allCustomSVGs";
import { updateUserAddress } from "../../lib/api/address-calls/update-address";
import AddressCountrySelector from "../manual-address-field/AddressCountrySelector";

export interface AddressDetailsProps {
  retracted: boolean;
  key?: any;
  address: AdupUserAddress;
  isSelected?: boolean;
  retractFunction?: () => void | undefined;
  onClick: () => void;
  changeListeners?: ((newAddress: AdupUserAddress, index: number) => void)[];
  index: number;
  addressType?: string;
}

export const AddressDetailsFolded: React.FC<AddressDetailsProps> = ({
  address,
  isSelected,
  onClick: changeAddressState,
  retractFunction = () => null,
}) => {
  const { t } = useTranslation();
  const { street, number, city } = address;

  const dispatch = useDispatch();
  const shippingAddress = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.shippingAddress
  );
  const billingAddress = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.billingAddress
  );
  const addressState = useSelector(
    (state: RootState) => state.users.currentUser.addresses
  );

  async function deleteAddress() {
    const res = await deleteUserAddress(address);
    const AddressFromAddressesObj = getAddressFromAddressesObj(
      addressState.savedAddresses
    );
    //if deleted address === selected shipping address then set first address of addresses as the new shipping address
    if (res && shippingAddress.id === address.id && AddressFromAddressesObj) {
      dispatch(setShippingAddress(AddressFromAddressesObj));
    }
    //if deleted address === selected billing address then set first address of addresses as the new billing address
    if (res && billingAddress.id === address.id && AddressFromAddressesObj) {
      dispatch(setBillingAddress(AddressFromAddressesObj));
    }
  }

  return (
    <motion.div
      key="form--address-details-retracted"
      initial={{ opacity: 0.3, height: "auto" }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0.3, height: "auto" }}
      transition={{ duration: 0.3, ease: "easeInOut", delay: 0.1 }}
      onClick={() => changeAddressState()}
      className={
        "address-details-retracted " +
        (isSelected && "selected-address-details-retracted")
      }
    >
      <div className="retracted-address-text">
        <h2>
          {street} {city}
        </h2>
        <span> {`${number}, ${street}, ${city}`} </span>
      </div>

      <EditButtonLight onClick={() => retractFunction()} />
      <DeleteButton
        mainTitle={t("DeleteAddress")}
        confirmDeleteMsg={t("RemoveSelectedAddress")}
        onClick={() => deleteAddress()}
      />
    </motion.div>
  );
};

const AddressDetailsExpanded: React.FC<AddressDetailsProps> = ({
  index,
  address,
  isSelected,
  onClick: changeAddressState,
  retractFunction = () => null,
  retracted: isRetracted,
  onClick,
  changeListeners,
  addressType = "none",
}) => {
  const { t } = useTranslation();
  // const { street, number, city } = address;

  // const dispatch = useDispatch();
  const [editedAddress, setEditedAddress] = useState(address);
  const countries = useSelector(
    (state: RootState) => state.app.appData.countries
  );

  console.log(editedAddress);
  console.log(editedAddress);

  function cancelEdit() {
    retractFunction();
  }

  async function saveEdit() {
    const res = await updateUserAddress(editedAddress, addressType);
    if (res) cancelEdit();
  }

  return (
    <motion.div
      key="form--address-details-expanded"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 50 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className={
        "form--address-details" +
        (!isRetracted ? " address-details-expanded" : "") +
        (isSelected ? " address-details-expanded-selected" : "")
      }
    >
      <div className="retracted-address-text">
        <h2>
          {editedAddress?.street} {editedAddress?.city}
        </h2>
        <span>
          {" "}
          {`${editedAddress?.number}, ${editedAddress?.street}, ${editedAddress?.city}`}{" "}
        </span>

        <div className="edit-address-buttons-container">
          {/* <TickButton onClick={() => saveEdit()} />
          <CrossButton onClick={() => cancelEdit()} /> */}
          <RoundButton
            color={"light"}
            icon={floppyDiskSVG}
            onClick={() => saveEdit()}
          />
          <RoundButton
            color={"light"}
            icon={crossSVG}
            onClick={() => cancelEdit()}
          />
        </div>
      </div>

      <div className="expanded-address-details-fields">
        <div className="autocomplete-field-container"></div>
        <div className="manual-fields-container">
          {/* <AddressField
            id={address.id + "-manual-field-country"}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEditedAddress((a) => ({
                ...a,
                country_name: event.target.value,
              }))
            }
            placeHolder={t("Country")}
            value={editedAddress?.country_name ?? editedAddress.country?.name}
            required={true}
          /> */}
          <AddressCountrySelector
            id={address.id + "-manual-field-country"}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEditedAddress((a) => ({
                ...a,
                country_code: e.target.value ? e.target.value : "",
                country_name: e.target.value
                  ? countries[e.target.value]?.name
                  : "",
              }))
            }
            placeHolder={t("Country")}
            value={editedAddress?.country_code ?? editedAddress.country?.iso2}
            required={true}
          />
          <AddressField
            id={address.id + "-manual-field-city"}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEditedAddress((a) => ({ ...a, city: event.target.value }))
            }
            placeHolder={t("City")}
            value={editedAddress.city}
            required={true}
          />
          <AddressField
            id={address.id + "-manual-field-province"}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEditedAddress((a) => ({ ...a, province: event.target.value }))
            }
            placeHolder={t("Province")}
            value={editedAddress.province}
            required={true}
          />
          <AddressField
            id={address.id + "-manual-field-postcode"}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEditedAddress((a) => ({ ...a, postcode: event.target.value }))
            }
            placeHolder={t("PostalCode")}
            value={editedAddress.postcode}
            required={true}
          />
          <AddressField
            id={address.id + "-manual-field-street"}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEditedAddress((a) => ({ ...a, street: event.target.value }))
            }
            placeHolder={t("Street")}
            value={editedAddress.street}
            required={true}
          />
          <AddressField
            id={address.id + "-manual-field-street2"}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEditedAddress((a) => ({ ...a, street2: event.target.value }))
            }
            placeHolder={t("OtherStreet")}
            value={editedAddress.street2}
            required={false}
          />
          <AddressField
            id={address.id + "-manual-field-number"}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEditedAddress((a) => ({ ...a, number: event.target.value }))
            }
            placeHolder={t("HouseNumber")}
            value={editedAddress.number}
            required={true}
          />
        </div>
      </div>
    </motion.div>
  );
};

export const AddressDetails: React.FC<AddressDetailsProps> = ({
  address,
  retracted,
  isSelected,
  onClick,
  index,
  addressType = "none",
  changeListeners,
}) => {
  const [isMapShown, setIsMapShown] = useState(false);
  const [isRetracted, setIsRetracted] = useState(retracted);

  return (
    <div className="address-details-inner address-details-inner-main">
      <AnimatePresence>
        {isRetracted ? (
          <AddressDetailsFolded
            index={index}
            address={address}
            retracted={retracted}
            retractFunction={() => setIsRetracted(false)}
            isSelected={isSelected}
            onClick={onClick}
          ></AddressDetailsFolded>
        ) : (
          <AddressDetailsExpanded
            index={index}
            address={address}
            retracted={retracted}
            retractFunction={() => setIsRetracted(true)}
            isSelected={isSelected}
            onClick={onClick}
            addressType={addressType}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
