import React, { useState } from "react";
import CustomSVGs from "../custom-svgs/CustomSVGs";

type Props = {
  isExpandedInitially?: boolean;
  title: string;
  icon: any;
  children: React.ReactNode;
};

function HeadingExpandable({
  title,
  icon,
  isExpandedInitially = false,
  children,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(isExpandedInitially);
  return (
    <div className="headingExpandable">
      <div className="headingExpandable__top">
        <h2>{title}</h2>
        {!isExpanded && (
          <div
            onClick={() => {
              setIsExpanded(true);
            }}
          >
            <CustomSVGs svg={icon} />
          </div>
        )}
      </div>
      <div
        className={`headingExpandable__bottom ${
          !isExpanded ? "headingExpandable__bottom--expanded" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default HeadingExpandable;
