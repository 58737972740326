import { RequestInfo } from "../../interfaces/RequestInfo";
import { setToastMessage } from "../../store/reducers/appSlice";
import store from "../../store/store";
import generateErrorMessage from "../utils/generateErrorMessage";
import { submitLogToBugsnag } from "./log";
import { capitalizeFirstLetter } from "../utils/helper-functions";
import { logOutUser } from "../state/clear-user-data";

export const asyncHandler = async (
  requestInfo: RequestInfo,
  actionType: string,
  customMsg: string,
  shouldShowMsg = true
) => {
  try {
    const response = await fetch(requestInfo.url, {
      method: requestInfo.method,
      headers: requestInfo.headers,
      body: requestInfo.body || null,
    });

    if (actionType !== "LOGIN_VIA_OTP_CODE" && response?.status === 401) {
      logOutUser();
      window.localStorage.clear();
      window.location.reload();
      return null;
    }

    if (response?.status === 204) {
      return { data: null, actionType };
    }

    const data = await response.json();
    console.log(actionType);

    if (data) {
      let message, messageType;
      if (data.success === true && data.messages?.length > 0) {
        message = data.messages;
        messageType = "success";
      } else if (
        data.success === true &&
        Object.values(data.messages ? data.messages : {}).length > 0
      ) {
        message = Object.values(data.messages).join(`, `);
        messageType = "success";
      } else if (data.success === true) {
        message = customMsg;
        messageType = "success";
      } else if (data.success === false) {
        console.log(generateErrorMessage(data));
        message = generateErrorMessage(data);
        messageType = "error";
        submitLogToBugsnag("error", generateErrorMessage(data));
      } else {
        const errorActionType = capitalizeFirstLetter(
          actionType?.replaceAll("_", " ")
        );
        submitLogToBugsnag(
          "error",
          `Something went wrong in ${errorActionType} request.`
        );
        throw new Error("Something went wrong");
      }

      /* console.log(shouldShowMsg); */
      if (shouldShowMsg) {
        store.dispatch(
          setToastMessage({
            text: message,
            type:
              messageType === "error"
                ? "ERROR"
                : messageType === "success"
                ? "SUCCESS"
                : "INFO",
          })
        );
      }

      return { data, actionType };
    }
  } catch (err: any) {
    console.log(err.message);
    submitLogToBugsnag("error", `Error at asyncHandler: ${err.message ?? err}`);
    if (shouldShowMsg) {
      store.dispatch(setToastMessage({ text: err.message, type: "ERROR" }));
    }
    return null;
  }
};
