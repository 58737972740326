import { setIsPayButtonLoading } from "../../../../store/reducers/appSlice";
import store from "../../../../store/store";
import { handlePaymentRequest } from "../../../api/payment-post-request";

export async function paypalPayFunction() {
  store.dispatch(setIsPayButtonLoading(true));
  const paymentState = store.getState().payment;

  const selectedPaymentMethod = paymentState.paymentMethodSelected;

  if (
    (selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "mollie" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "paynl" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !==
        "aduppay") ||
    selectedPaymentMethod?.name !== "paypal"
  ) {
    store.dispatch(setIsPayButtonLoading(false));
    return;
  }

  if (
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "mollie" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "paynl" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "aduppay"
  ) {
    handlePaymentRequest();
  }
}
