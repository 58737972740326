import { Loader } from "@googlemaps/js-api-loader";
import React, {
  FocusEvent,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AdupUserAddress,
  EmptyAdupUserAddress,
} from "../../interfaces/AdupUserAddress";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";
import { useDispatch, useSelector } from "react-redux";
import { submitLogToBugsnag } from "../../lib/api/log";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import { setToastMessage } from "../../store/reducers/appSlice";

type Props = {
  title: string;
  changeListeners?: ((address: AdupUserAddress) => void)[];
  focusListeners?: ((event: FocusEvent, meta?: any) => void)[];
  validators?: [];
  classes: string | string[];
  inputId: string;
  initialValue?: string;
  googleMapsApiKey: string;
  inputEnabled: boolean;
  defaultValue: any;
};

const AutocompleteAddressField: React.FC<Props> = ({
  classes,
  title,
  focusListeners = [],
  changeListeners,
  inputId: id,
  googleMapsApiKey,
  inputEnabled,
  defaultValue,
}) => {
  const [classesList, setClassesList] = useState(
    Array.isArray(classes) ? classes.join(" ") : classes
  );
  const [value, setValue] = useState<string>("");
  const countries = useSelector(
    (state: RootState) => state.app.appData.countries
  );
  const { t } = useTranslation();

  const mainField = useRef() as MutableRefObject<HTMLInputElement>;

  const loader = useMemo(
    () =>
      new Loader({
        apiKey: googleMapsApiKey,
        version: "weekly",
        libraries: ["places"],
      }),
    [googleMapsApiKey]
  );

  const [customAddress, setCustomAddress] =
    useState<AdupUserAddress>(EmptyAdupUserAddress);

  useEffect(() => {
    if (
      defaultValue &&
      defaultValue !== "" &&
      defaultValue !== EmptyAdupUserAddress
    ) {
      setValue(
        `${defaultValue?.number !== "" ? defaultValue?.number + " " : ""}` +
          `${defaultValue?.street !== "" ? defaultValue?.street + " " : ""}` +
          `${defaultValue?.street2 !== "" ? defaultValue?.street2 + " " : ""}` +
          `${defaultValue?.city !== "" ? defaultValue?.city + " " : ""}` +
          `${
            defaultValue?.province !== "" ? defaultValue?.province + " " : ""
          }` +
          `${
            defaultValue?.country_name !== ""
              ? defaultValue?.country_name + " "
              : ""
          }` +
          `${defaultValue?.postcode !== "" ? defaultValue?.postcode + " " : ""}`
      );
    }
  }, [defaultValue]);

  useEffect(() => {
    loader
      .load()
      .then((google) => {
        if (google && mainField.current) {
          const autoComplete = new google.maps.places.Autocomplete(
            mainField?.current
          );
          autoComplete.addListener("place_changed", (e: any) => {
            const place = autoComplete.getPlace();
            const fields = place.address_components;

            const processedAddress: AdupUserAddress = {
              id: "",
              number: "",
              street: "",
              street2: "",
              city: "",
              province: "",
              country_name: "",
              country_code: "",
              postcode: "",
            };

            if (fields) {
              for (const field of fields) {
                if (field.types.includes("route")) {
                  processedAddress.street = field.long_name;
                } else if (field.types.includes("locality")) {
                  processedAddress.city = field.long_name;
                } else if (
                  field.types.includes("administrative_area_level_2")
                ) {
                  // processedAddress.district = field.long_name;
                } else if (
                  field.types.includes("administrative_area_level_1")
                ) {
                  processedAddress.province = field.long_name;
                } else if (field.types.includes("country")) {
                  // set the state only if the country is in the list of init countries list else show error
                  if (countries[field.short_name]) {
                    processedAddress.country_name = field.long_name;
                    processedAddress.country_code = field.short_name;
                  } else {
                    processedAddress.country_name = "";
                    processedAddress.country_code = "";
                    dispatch(
                      setToastMessage({
                        text: t("DoNotShipToThisCountry"),
                        type: "ERROR",
                      })
                    );
                  }
                } else if (field.types.includes("postal_code")) {
                  processedAddress.postcode = field.long_name;
                } else if (field.types.includes("street_number")) {
                  processedAddress.number = field.long_name;
                }
              }
              setCustomAddress(processedAddress);
              if (place.formatted_address !== undefined) {
                setValue((value) =>
                  place.formatted_address ? place.formatted_address : value
                );
              }
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
        submitLogToBugsnag("error", e);
      });
  }, [loader]);

  useEffect(() => {
    if (customAddress && customAddress !== EmptyAdupUserAddress) {
      changeListeners?.forEach((listener) => listener(customAddress));
    }
  }, [customAddress]);

  useEffect(() => {
    setClassesList(Array.isArray(classes) ? classes.join(" ") : classes);
  }, [classes]);
  const dispatch = useDispatch();
  return (
    <div>
      <div
        className={classesList ? "form-group " + classesList : "form-group "}
      >
        <input
          disabled={!inputEnabled}
          onFocus={(e) => {
            dispatch(setInteractionsPerSessionCount());

            setClassesList((current) => {
              if (!current.includes("focused"))
                return current.concat(" focused");
              return current;
            });

            for (const listener of focusListeners) {
              listener(e);
            }
          }}
          onBlur={(e) => {
            if (value === "") {
              setClassesList(
                Array.isArray(classes) ? classes.join(" ") : classes
              );
            } else {
              setClassesList((current) => {
                if (!current.includes("filled"))
                  return current.concat(" filled");
                return current;
              });
            }
          }}
          ref={mainField}
          id={id}
          className="form-input"
          style={{ fontFamily: "var(--main-font-secondary)" }}
          type="search"
          autoComplete="new-password"
          value={value}
          // defaultValue={!value ? defaultValue : undefined}
          placeholder=""
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />

        <label
          className="form-label"
          htmlFor="first"
          onClick={() => {
            mainField.current.focus();
            if (value === "") {
              setClassesList((current) => {
                if (!current.includes("focused"))
                  return current.concat(" focused");
                return current;
              });
            } else {
            }
          }}
        >
          {title}
        </label>
      </div>
    </div>
  );
};

export default AutocompleteAddressField;
