import "../../sass/themes/theme2/payButtonTheme2.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { arrowHeadLeftSVG } from "../../assets/js/allCustomSVGs";

function PayButtonTheme2() {
  const cartData = useSelector((state: RootState) => state.cart);

  const { t } = useTranslation();

  const cartGrandTotal = cartData?.priceCalculations?.total?.formatted;
  const finalAmount = `${t("PayNow")} ${cartGrandTotal}`;
  return (
    <div className="payButtonTheme2">
      <h2>{finalAmount}</h2>
      {arrowHeadLeftSVG}
    </div>
  );
}

export default PayButtonTheme2;
