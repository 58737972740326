import FastCheckoutLayout1 from "../../layout/layout1/FastCheckoutLayout1";
import FastCheckoutLayout2 from "../../layout/layout2/FastCheckoutLayout2";
import FastCheckoutLayoutForPopups from "../../layout/layoutForPopups/FastCheckoutLayoutForPopups";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useLocation } from "react-router-dom";
import {
  applicationUserType,
  setIsOtpOverlayVisible,
  setToastMessage,
} from "../../store/reducers/appSlice";
import {
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import { useTranslation } from "react-i18next";
import { setIsRequiredFieldsNotFilled } from "../../store/reducers/paymentSlice";
import { addShopperEvent } from "../../lib/api/events-methods";
import { submitLogToBugsnag } from "../../lib/api/log";
import { PaymentMethodConfigs } from "../../lib/config/payment-methods-config";

interface Props {
  showHeader: boolean;
  callingPage?: string;
}

const FastCheckoutPage = ({ showHeader, callingPage }: Props) => {
  const app_layout: any = useSelector(
    (state: RootState) => state.cart.shop.theme?.app_layout
  );

  const showCookieConsent = useSelector(
    (state: RootState) => state.app.UI.showCookieConsent
  );

  const uiState = useSelector(
    (state: RootState) => state.app.UI.pages.fastCheckout
  );

  const isBillingShipping = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.isBillingShipping
  );

  /* =================================================================== */
  const route = window.decodeURI(useLocation().pathname);
  const q = useLocation().search;

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      q
        .substring(1)
        .split("&")
        .find((v) => v === "adup_redirect_status=failed")
    ) {
      console.log(q.substring(1).split("&"));
      submitLogToBugsnag("error", t("PaymentFailed"));
      dispatch(
        setToastMessage({
          text: t("PaymentFailed"),
          type: "ERROR",
        })
      );
    }
  }, [q]);

  const paymentState = useSelector((state: RootState) => state.payment);
  const [paymentMethod, setPaymentMethod] = useState<any>(null);

  useEffect(() => {
    setPaymentMethod(paymentState.paymentMethodSelected);
  }, [paymentState.paymentMethodSelected]);

  const { t } = useTranslation();

  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  const userEmail = useSelector(
    (state: RootState) => state.users.currentUser?.profile?.email
  );

  // auth
  const authState = useSelector((state: RootState) => state.app.auth);

  // get the user's verification state, if they are trying to validate, display OTP popup
  const userData = useSelector((state: RootState) => state.users.currentUser);
  useEffect(() => {
    if (
      userData.verified.state === verificationStates.trying ||
      userData.verified.state === verificationStates.failed
    ) {
      dispatch(setIsOtpOverlayVisible(true));
    } else {
      dispatch(setIsOtpOverlayVisible(false));
    }
  }, [userData.verified]);

  useEffect(() => {
    const fields: Element[] = Array.from(
      document.getElementsByClassName("form-input")
    );
    fields.forEach((field: Element) => {
      if ((field as HTMLInputElement).value) {
        const parent = field.parentElement;
        parent?.classList.add("focused");
        parent?.classList.add("filled");
      }
    });
    if (authState.user === applicationUserType.NEW_USER) {
      dispatch(setIsRequiredFieldsNotFilled(true));
    }
    if (!verificationState?.firstPayment) {
      dispatch(
        setUserVerified({
          ...verificationState,
          firstPayment: "pending",
        })
      );
    }
  }, []);

  const app_mode = route.split("/")[1];
  const products = route.split("/")[3];
  useEffect(() => {
    if (app_mode === "ai-fast-checkout" && products && products !== "") {
      //* Triggering Events API on user actions
      addShopperEvent("view_page", {
        url: `${window.location.href}`,
        page: "ai-fast-checkout",
        email: userEmail && userEmail !== "" ? userEmail : undefined,
      });
    }
  }, [route]);

  // useEffect(() => {
  //   if (authState.user === applicationUserType.NEW_USER) {
  //     dispatch(setIsRequiredFieldsNotFilled(true));
  //   } else {
  //     dispatch(setIsRequiredFieldsNotFilled(false));
  //   }
  // }, [authState?.user]);

  /* ======================= component render logics ========================= */
  const componentRenderLogics = (componentType: string) => {
    if (componentType === "userSummary") {
      return authState.user === applicationUserType.OLD_USER;
    }

    if (componentType === "phoneInput") {
      return !uiState.hiddenSegments?.ACCOUNT_SEGMENT;
    }

    if (componentType === "profileDetailsForm") {
      return (
        !uiState.hiddenSegments?.DETAILS_SEGMENT &&
        verificationState?.state !== verificationStates.trying &&
        verificationState?.state !== verificationStates.failed
      );
    }

    if (componentType === "userDetails") {
      return (
        !uiState.hiddenSegments?.DETAILS_SEGMENT &&
        verificationState?.state !== verificationStates.trying &&
        verificationState?.state !== verificationStates.failed
      );
    }

    if (componentType === "shippingAddress") {
      return (
        !uiState.hiddenSegments?.SHIPPING_ADDRESS_SEGMENT &&
        authState.user === applicationUserType.OLD_USER &&
        verificationState?.state !== verificationStates.trying &&
        verificationState?.state !== verificationStates.failed &&
        !showCookieConsent
      );
    }

    if (componentType === "billingAddress") {
      return (
        !uiState.hiddenSegments?.BILLING_ADDRESS_SEGMENT &&
        !isBillingShipping &&
        verificationState?.state !== verificationStates.trying &&
        verificationState?.state !== verificationStates.failed &&
        !showCookieConsent
      );
    }

    if (componentType === "billingAddressToggle") {
      return (
        !uiState.hiddenSegments?.BILLING_ADDRESS_SEGMENT &&
        verificationState?.state !== verificationStates.trying &&
        verificationState?.state !== verificationStates.failed
      );
    }
    if (componentType === "paymentMount") {
      return (
        !paymentState.errorOccurred &&
        verificationState?.state !== verificationStates.pending &&
        verificationState?.state !== verificationStates.trying &&
        verificationState?.state !== verificationStates.failed &&
        !showCookieConsent
      );
    }
    if (componentType === "paymentMethods") {
      return (
        (!uiState.hiddenSegments?.PAYMENT_METHODS_SEGMENT ||
          !paymentState?.availablePaymentMethods ||
          paymentState?.availablePaymentMethods === null ||
          Object.keys(paymentState?.availablePaymentMethods).length <= 0 ||
          !paymentState?.paymentMethodLastUsed ||
          paymentState?.paymentMethodLastUsed === null ||
          paymentState?.paymentMethodLastUsed === "" ||
          paymentState?.paymentMethodLastUsed === "apple_pay" ||
          paymentState?.paymentMethodLastUsed === "google_pay") &&
        verificationState?.state !== verificationStates.trying &&
        verificationState?.state !== verificationStates.failed &&
        !showCookieConsent
      );
    }

    if (componentType === "payButton") {
      return (
        PaymentMethodConfigs[paymentMethod?.type]?.mountForm !== true &&
        // verificationState?.state !== verificationStates.pending &&
        // verificationState?.state !== verificationStates.trying &&
        paymentMethod?.type !== "apple_pay" &&
        paymentMethod?.type !== "google_pay" &&
        !paymentState?.isWalletPaymentMethodInUse &&
        !showCookieConsent
      );
    }

    if (componentType === "header") {
      return showHeader;
    }

    if (componentType === "profileLink") {
      return app_mode === "ai-fast-checkout" && products && products !== ""
        ? true
        : false;
    }
  };

  /* =================================================================== */
  const renderLayout = () => {
    if (app_layout === "2") {
      return (
        <FastCheckoutLayout2
          app_mode={app_mode}
          componentRenderLogics={componentRenderLogics}
        />
      );
    } else if (app_layout === "1") {
      return (
        <FastCheckoutLayout1
          app_mode={app_mode}
          componentRenderLogics={componentRenderLogics}
        />
      );
    } else {
      return (
        <FastCheckoutLayoutForPopups
          app_mode={app_mode}
          componentRenderLogics={componentRenderLogics}
        />
      );
    }
  };

  return renderLayout();
};

export default FastCheckoutPage;
