import { isNotEmptyString } from "./helper-functions";

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getThemeColorEl() {
  return document.getElementById("theme-color");
}

export default function appThemeEngine(
  appRoute: string,
  isInitAPILoading: boolean,
  imageStaticUrl: any,
  settings: any,
  merchantTheme: any
) {
  const root: any = document.documentElement;
  const favicon: any = getFaviconEl(); // Accessing favicon element
  const themeColor: any = getThemeColorEl(); // Accessing header theme-color element
  const defaultFont = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;`;

  //setting theme color content
  if (themeColor !== null) {
    if (
      settings?.main_brand_color &&
      isNotEmptyString(settings?.main_brand_color)
    ) {
      themeColor.content = settings?.main_brand_color;
    } else {
      themeColor.content = "#000000";
    }
  }

  //setting theme color
  if (
    settings?.main_brand_color &&
    isNotEmptyString(settings?.main_brand_color)
  ) {
    root?.style.setProperty("--theme-color", settings?.main_brand_color);
  } else {
    root?.style.setProperty("--theme-color", "#000000");
  }

  //setting font color
  if (settings?.font_color && isNotEmptyString(settings?.font_color)) {
    root?.style.setProperty("--font-color", settings?.font_color);
  } else {
    root?.style.setProperty("--font-color", "#f6f6f6");
  }

  //setting font family
  if (
    merchantTheme?.font_family &&
    isNotEmptyString(merchantTheme?.font_family)
  ) {
    root?.style.setProperty("--main-font-primary", merchantTheme?.font_family);
  } else {
    root?.style.setProperty("--main-font-primary", defaultFont);
  }
  if (
    merchantTheme?.font_family_secondary &&
    isNotEmptyString(merchantTheme?.font_family_secondary)
  ) {
    root?.style.setProperty(
      "--main-font-secondary",
      merchantTheme?.font_family_secondary
    );
  } else {
    root?.style.setProperty("--main-font-secondary", defaultFont);
  }

  //setting background color
  if (isInitAPILoading || appRoute === "/") {
    root?.style.setProperty("--back-color", "#f6f6f6");
  } else if (
    settings?.background_color &&
    isNotEmptyString(settings?.background_color)
  ) {
    root?.style.setProperty("--back-color", settings?.background_color);
  } else {
    root?.style.setProperty("--back-color", "#f6f6f6");
  }

  //setting favicon
  if (favicon !== null) {
    if (settings?.merchant_icon && isNotEmptyString(settings?.merchant_icon)) {
      favicon.href = `${imageStaticUrl}/medium/${settings?.merchant_icon}`;
    } else if (
      merchantTheme?.merchant_favicon &&
      isNotEmptyString(merchantTheme?.merchant_favicon)
    ) {
      favicon.href = `${imageStaticUrl}/medium/${merchantTheme?.merchant_favicon}`;
    } else {
      favicon.href = ``;
    }
  }

  //setting background image
  if (
    settings?.background_image &&
    isNotEmptyString(settings?.background_image)
  ) {
    root?.style.setProperty(
      "--back-image",
      isInitAPILoading || appRoute === "/"
        ? "#f6f6f6"
        : `url('${imageStaticUrl}/medium/${settings?.background_image}')`
    );
    return `${imageStaticUrl}/medium/${settings?.background_image}`;
  } else {
    root?.style.setProperty("--back-image", "#f6f6f6");
    return undefined;
  }
}
