import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdupCartItem } from "../../interfaces/AdupCartItem";
import { PriceCalculations } from "../../interfaces/PriceCalculations";

const initialItems: AdupCartItem[] = [];
export const initialPriceCalculations: PriceCalculations = {
  subtotal: { minor: 0, major: 0, formatted: "" },
  shipping: { minor: 0, major: 0, formatted: "" },
  convenience_fee: { minor: 0, major: 0, formatted: "" },
  tax: { minor: 0, major: 0, formatted: "" },
  discount: { minor: 0, major: 0, formatted: "" },
  total: { minor: 0, major: 0, formatted: "" },
};

export const initialShop = {
  id: -1,
  initial: "",
  name: "",
  slug: "",
  currency: "EUR",
  location: "",
  sellerId: -1,
  status: "",
  theme: {
    app_layout: "1",
    header_theme: "default",
    intro_banner_theme: "default",
    product_details_theme: "default",
    user_info_theme: "default",
    payment_methods_theme: "default",
    order_summary_theme: "default",
    pay_button_theme: "default",
    footer_theme: "default",
  },
  settings: {},
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    id: undefined,
    shopWiseCartIds: {
      null: undefined,
    } as any,
    priceCalculations: initialPriceCalculations,
    items: initialItems,
    fastcheckoutCartItems: initialItems,
    shipping: 0,
    convenienceFee: 0,
    total: 0,
    discount: 0,
    shop: initialShop,
    allProducts: [],
    attributeSkuMap: [],
    grandTotalBeforeConvenienceFee: 0,
    grandTotal: 0,
    selectedItems: null,
  },
  reducers: {
    incrementItemQuantity: (state, action) => {
      state.items = state.items.map((item: AdupCartItem) => {
        if (item.id === action.payload.id)
          return { ...item, quantity: item.quantity + 1 };
        else return item;
      });

      state.total = state.items
        .map((item) => item.quantity * item.priceNumeric)
        .reduce((previous, current) => current + previous, 0);
    },
    decrementItemQuantity: (state, action) => {
      state.items = state.items.map((item: AdupCartItem | any) => {
        if (item.id === action.payload.id) {
          if (item.quantity > 1) {
            return { ...item, quantity: item.quantity - 1 };
          }
        }
        // return { ...item, quantity: Math.max(1, item.quantity - 1) };
        else return item;
      });
      state.total = state.items
        .map((item) => item.quantity * item.priceNumeric)
        .reduce((previous, current) => current + previous, 0);
    },
    addItem: (state, action) => {
      state.items = state.items.concat(action.payload);
      state.total = state.items
        .map((item) => item.quantity * item.priceNumeric)
        .reduce((previous, current) => current + previous, 0);
    },
    removeItem: (state, action) => {
      if (action.payload?.id) {
        state.items = state.items.filter(
          (item: AdupCartItem) => item.id !== action.payload.id
        );
      } else {
        state.items = state.items.filter(
          (item: AdupCartItem) => !item.selected
        );
      }
      state.total = state.items
        .map((item) => item.quantity * item.priceNumeric)
        .reduce((previous, current) => current + previous, 0);
    },
    updateItemTotal: (state, action) => {
      state.items = state.items.map((item: AdupCartItem) => {
        if (item.sku === action.payload.sku)
          return {
            ...item,
            totalPriceFormatted: action.payload.totalPriceFormatted,
            totalPriceNumeric: action.payload.totalPriceNumeric,
          };
        else return item;
      });
    },
    resetCartQty: (state, action) => {
      state.items = state.items.map((item) => {
        return {
          ...item,
          quantity: action.payload,
        };
      });
      state.total = state.items
        .map((item) => item.quantity * item.priceNumeric)
        .reduce((previous, current) => current + previous, 0);
    },
    emptyCart: (state, action) => {
      state.items = [];
      state.total = 0;
      state.priceCalculations = initialPriceCalculations;
    },
    setCart: (state, action) => {
      state.shop = action.payload.shop;
      state.items = action.payload.cart;
      state.total = action.payload.total;
    },
    setCartId: (state, action) => {
      state.id = action.payload;
    },
    setShopWiseCartIds: (
      state,
      action: PayloadAction<{ shopSlug: string; cartId: string | undefined }>
    ) => {
      if (state.shopWiseCartIds) {
        state.shopWiseCartIds[action.payload.shopSlug] = action.payload.cartId;
      } else {
        state.shopWiseCartIds = {
          [action.payload.shopSlug]: action.payload.cartId,
        };
      }
    },
    clearCartIds: (state, _) => {
      state.id = undefined;
      state.shopWiseCartIds = {};
    },
    setCartPriceCalculations: (state, action) => {
      state.priceCalculations = action.payload;
    },
    setShop: (state, action) => {
      const {
        currency,
        seller_id,
        is_active,
        location,
        name,
        id,
        initial,
        slug,
        settings,
        theme,
      } = action.payload;
      const formattedSettings = {} as any;

      Object.keys(settings).forEach((category) => {
        settings[category].forEach((setting: any) => {
          let value = setting.value;
          if (value === "true") value = true;
          else if (value === "false") value = false;
          else if (value === "null") value = null;

          formattedSettings[setting.key] = value;
        });
      });

      state.shop.id = id;
      state.shop.initial = initial;
      state.shop.name = name;
      state.shop.slug = slug;
      state.shop.currency = currency;
      state.shop.location = location;
      state.shop.sellerId = seller_id;
      state.shop.status = is_active;
      state.shop.settings = formattedSettings;
      state.shop.theme = theme;
    },
    setAllProducts: (state, action) => {
      state.allProducts = action.payload;
    },
    setAttributeSkuMap: (state, action) => {
      state.attributeSkuMap = action.payload;
    },
    markItemAsSelected: (
      state,
      action: PayloadAction<{ id: string; mark: boolean }>
    ) => {
      const idx = state.items.findIndex((i) => i.id === action.payload.id);
      if (idx || idx === 0) {
        // state.items = state.items.splice(idx, 0)
        state.items[idx].selected = action.payload.mark;
      }
    },
    setShippingCost: (state, action) => {
      state.shipping = typeof action.payload === "number" ? action.payload : 0;
    },
    setConvenienceFee: (state, action) => {
      state.convenienceFee =
        typeof action.payload === "number" ? action.payload : 0;
    },
    setGrandTotalBeforeConvenienceFee: (state, action) => {
      state.grandTotalBeforeConvenienceFee = action.payload;
    },
    setGrandTotal: (state, action) => {
      state.grandTotal = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
      state.total = state.items
        .map((item) => item.quantity * item.priceNumeric)
        .reduce((previous, current) => current + previous, 0);
    },
    setFastcheckoutCartItems: (state, action) => {
      state.fastcheckoutCartItems = action.payload;
    },
    setSelectedItems: (state, action) => {
      state = {
        ...state,
        selectedItems: action.payload,
      };
    },
  },
});

export const {
  decrementItemQuantity,
  incrementItemQuantity,
  addItem,
  removeItem,
  updateItemTotal,
  resetCartQty,
  emptyCart,
  setCart,
  setCartId,
  setShopWiseCartIds,
  clearCartIds,
  setCartPriceCalculations,
  setShop,
  markItemAsSelected,
  setAllProducts,
  setAttributeSkuMap,
  setShippingCost,
  setConvenienceFee,
  setGrandTotalBeforeConvenienceFee,
  setGrandTotal,
  setItems,
  setFastcheckoutCartItems,
  setSelectedItems,
} = cartSlice.actions;

export default cartSlice.reducer;
