import { idealBankPayFunction } from "../payment-handlers/payment-methods/method-submission/ideal-bank-pay";
import cardPayFunction from "../payment-handlers/payment-methods/method-submission/card-pay";
import { bancontactPayFunction } from "../payment-handlers/payment-methods/method-submission/bancontact-pay";
import { epsPayFunction } from "../payment-handlers/payment-methods/method-submission/eps-pay";
import { giropayPayFunction } from "../payment-handlers/payment-methods/method-submission/giropay-pay";
import { klarnaPayFunction } from "../payment-handlers/payment-methods/method-submission/klarna-pay";
import { p24PayFunction } from "../payment-handlers/payment-methods/method-submission/p24-pay";
import { blikPayFunction } from "../payment-handlers/payment-methods/method-submission/blik-pay";
import { sepaPayFunction } from "../payment-handlers/payment-methods/method-submission/sepa-pay";
import { sofortPayFunction } from "../payment-handlers/payment-methods/method-submission/sofort-pay";
import { billinkPayFunction } from "../payment-handlers/payment-methods/method-submission/billink-pay";
import { paypalPayFunction } from "../payment-handlers/payment-methods/method-submission/paypal-pay";
import { cadeaukaartPayFunction } from "../payment-handlers/payment-methods/method-submission/cadeaukaart-pay";
import { creditclickPayFunction } from "../payment-handlers/payment-methods/method-submission/creditclick-pay";
import { fashiongiftcardPayFunction } from "../payment-handlers/payment-methods/method-submission/fashiongiftcard-pay";
import { in3PayFunction } from "../payment-handlers/payment-methods/method-submission/in3-pay";
import { multibancoPayFunction } from "../payment-handlers/payment-methods/method-submission/multibanco-pay";
import { overboekingPayFunction } from "../payment-handlers/payment-methods/method-submission/overboeking-pay";
import { paybybankPayFunction } from "../payment-handlers/payment-methods/method-submission/paybybank-pay";
import { payconiqPayFunction } from "../payment-handlers/payment-methods/method-submission/payconiq-pay";
import { paysafecardPayFunction } from "../payment-handlers/payment-methods/method-submission/paysafecard-pay";
import { pinPayFunction } from "../payment-handlers/payment-methods/method-submission/pin-pay";
import { yourgiftPayFunction } from "../payment-handlers/payment-methods/method-submission/yourgift-pay";

//* Frontend configuration related to each payment methods which is available.
export const PaymentMethodConfigs: { [index: string]: PaymentMethodConfig } =
  Object.freeze({
    card: {
      disabled: false,
      mountForm: true,
      disableFromList: false,
      payFunction: cardPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    ideal: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: idealBankPayFunction,
      meta: {
        showBankSelector: true,
        usesPaymentRequest: false,
      },
    },
    apple_pay: {
      disabled: false,
      mountForm: true,
      disableFromList: true,
      meta: {
        usesPaymentRequest: true,
        showBankSelector: false,
      },
    },
    google_pay: {
      disabled: false,
      mountForm: true,
      disableFromList: true,
      meta: {
        usesPaymentRequest: true,
        showBankSelector: false,
      },
    },
    klarna: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: klarnaPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    bancontact: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: bancontactPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    p24: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: p24PayFunction,
      meta: {
        showBankSelector: true,
        usesPaymentRequest: false,
      },
    },
    eps: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: epsPayFunction,
      meta: {
        showBankSelector: true,
        usesPaymentRequest: false,
      },
    },
    giropay: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: giropayPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    sofort: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: sofortPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    sepa_debit: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: sepaPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    billink: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: billinkPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    blik: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      payFunction: blikPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    paypal: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: paypalPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    cadeaukaart: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: cadeaukaartPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    creditclick: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: creditclickPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    fashion_giftcard: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: fashiongiftcardPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    in3: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: in3PayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    multibanco: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: multibancoPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    overboeking: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: overboekingPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    paybybank: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: paybybankPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    payconiq: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: payconiqPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    paysafecard: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: paysafecardPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    pin: {
      disabled: false,
      mountForm: false,
      disableFromList: false,
      payFunction: pinPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    yourgift: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      payFunction: yourgiftPayFunction,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    afterpay_clearpay: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    alipay: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    boleto: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    fpx: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    grabpay: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    konbini: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    oxxo: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    paynow: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
    wechat_pay: {
      disabled: true,
      mountForm: false,
      disableFromList: false,
      meta: { showBankSelector: false, usesPaymentRequest: false },
    },
  });

interface PaymentMethodConfig {
  disabled: boolean;
  disableFromList: boolean;
  meta: MetaConfig;
  mountForm: boolean;
  payFunction?: (meta: any) => void;
}

interface MetaConfig {
  showBankSelector: boolean;
  usesPaymentRequest: boolean;
}
