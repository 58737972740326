import { Loader } from "@googlemaps/js-api-loader";
import React, {
  ChangeEvent,
  Fragment,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AdupUserAddress,
  EmptyAdupUserAddress,
} from "../../interfaces/AdupUserAddress";
import { GOOGLE_MAPS_API_KEY } from "../../lib/constants/central-api";
import HeadingWithRightButton from "../heading/HeadingWithRightButton";
import RoundButton from "../round-button/RoundButton";
import { t } from "i18next";
import AddressField from "../manual-address-field/AddressField";
import { arrowHeadDownSVG, floppyDiskSVG } from "../../assets/js/allCustomSVGs";
import { createUserAddress } from "../../lib/api/address-calls/create-address";
import { submitLogToBugsnag } from "../../lib/api/log";
import { RootState } from "../../store/store";
import { setToastMessage } from "../../store/reducers/appSlice";
import AddressCountrySelector from "../manual-address-field/AddressCountrySelector";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { motion, Transition } from "framer-motion";

type Props = {
  close: () => void;
  addressType: string;
};

const NewAddressForm: React.FC<Props> = ({ close, addressType }) => {
  const [editingManually, setEditingManually] = useState(false);

  const dispatch = useDispatch();
  const countries = useSelector(
    (state: RootState) => state.app.appData.countries
  );
  const [customAddress, setCustomAddress] =
    useState<AdupUserAddress>(EmptyAdupUserAddress);
  const mainField = useRef() as MutableRefObject<HTMLInputElement>;
  const loader = useMemo(
    () =>
      new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["places"],
      }),
    [GOOGLE_MAPS_API_KEY]
  );

  const [autocompletedValue, setAutoCompletedValue] = useState<string>("");
  const [autoCompleteEnabled, setAutoCompleteEnabled] = useState(true);

  useEffect(() => {
    loader
      .load()
      .then((google) => {
        if (google && mainField.current) {
          const autoComplete = new google.maps.places.Autocomplete(
            mainField?.current
          );
          autoComplete.addListener("place_changed", (e: any) => {
            const place = autoComplete.getPlace();
            const fields = place.address_components;

            const processedAddress: AdupUserAddress = {
              id: "",
              number: "",
              street: "",
              street2: "",
              city: "",
              province: "",
              country_name: "",
              country_code: "",
              postcode: "",
            };

            if (fields) {
              console.log(fields);
              for (const field of fields) {
                console.log(field);
                if (field.types.includes("route")) {
                  processedAddress.street = field.long_name;
                } else if (field.types.includes("locality")) {
                  processedAddress.city = field.long_name;
                } else if (
                  field.types.includes("administrative_area_level_2")
                ) {
                  // processedAddress.district = field.long_name;
                } else if (
                  field.types.includes("administrative_area_level_1")
                ) {
                  processedAddress.province = field.long_name;
                } else if (field.types.includes("country")) {
                  // set the state only if the country is in the list of init countries list else show error
                  if (countries[field.short_name]) {
                    processedAddress.country_name = field.long_name;
                    processedAddress.country_code = field.short_name;
                  } else {
                    processedAddress.country_name = "";
                    processedAddress.country_code = "";
                    dispatch(
                      setToastMessage({
                        text: t("DoNotShipToThisCountry"),
                        type: "ERROR",
                      })
                    );
                  }
                } else if (field.types.includes("postal_code")) {
                  processedAddress.postcode = field.long_name;
                } else if (field.types.includes("street_number")) {
                  processedAddress.number = field.long_name;
                }
              }
              setCustomAddress(processedAddress);
              if (place.formatted_address !== undefined) {
                setAutoCompletedValue((value) =>
                  place.formatted_address ? place.formatted_address : value
                );
              }
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
        submitLogToBugsnag("error", e);
      });
  }, [loader]);

  useEffect(() => {
    if (customAddress !== EmptyAdupUserAddress) {
      if (
        customAddress.city === "" ||
        customAddress.country_name === "" ||
        customAddress.number === "" ||
        customAddress.postcode === "" ||
        customAddress.province === "" ||
        customAddress.street === ""
      ) {
        setAutoCompleteEnabled(false);
        setEditingManually(true);
      }
    }
  }, [customAddress]);

  useEffect(() => {
    if (customAddress && customAddress !== EmptyAdupUserAddress) {
      setAutoCompletedValue(
        `${customAddress?.number !== "" ? customAddress?.number + " " : ""}` +
          `${customAddress?.street !== "" ? customAddress?.street + " " : ""}` +
          `${
            customAddress?.street2 !== "" ? customAddress?.street2 + " " : ""
          }` +
          `${customAddress?.city !== "" ? customAddress?.city + " " : ""}` +
          `${
            customAddress?.province !== "" ? customAddress?.province + " " : ""
          }` +
          `${
            customAddress?.country_name !== ""
              ? customAddress?.country_name + " "
              : ""
          }` +
          `${
            customAddress?.postcode !== "" ? customAddress?.postcode + " " : ""
          }`
      );
    }
  }, [customAddress]);

  function cancelSaveAddress() {
    setCustomAddress(EmptyAdupUserAddress);
    setAutoCompletedValue("");
    close();
  }

  async function saveAddress() {
    const res = await createUserAddress(customAddress, addressType);
    if (res) close();
  }

  return (
    <motion.div
      key="new-address-form"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }} // Increase the delay
      transition={{ duration: 0.3, ease: "easeInOut", delay: 0.1 }}
      className="new-shipping-address-form"
    >
      <div className="new-shipping-address-form-top">
        <HeadingWithRightButton
          headingText={t("AddNewAddress")}
          button={
            <Fragment>
              <div className="buttons-holder-flex-row">
                {/* <TickButton onClick={() => saveAddress()} /> */}

                <RoundButton
                  color={"light"}
                  icon={floppyDiskSVG}
                  onClick={() => saveAddress()}
                />
                {/* <RoundButton
                  color={"light"}
                  icon={closeIcon}
                  onClick={() => cancelSaveAddress()}
                /> */}
                {/* <CrossButton onClick={() => cancelSaveAddress()} /> */}
              </div>
            </Fragment>
          }
        />
      </div>
      <div className="new-address-autocomplete-container">
        <input
          // disabled={!autoCompleteEnabled}
          disabled={false}
          ref={mainField}
          id="add-new-shipping-address-autocomplete"
          className="form-input"
          style={{ fontFamily: "var(--main-font-secondary)" }}
          type="search"
          autoComplete="new-password"
          value={autocompletedValue}
          placeholder={t("SearchAddress")}
          onChange={(e) => {
            setAutoCompletedValue(e.target.value);
            // for (const listener of changeListeners) listener(e);
          }}
        />

        <div
          onClick={() => {
            setEditingManually(!editingManually);
            setAutoCompleteEnabled(false);
          }}
          className="new-address-arrow-svg"
          data-new-address-arrow-svg={editingManually ? "true" : "false"}
        >
          <CustomSVGs svg={arrowHeadDownSVG} />
        </div>
      </div>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: editingManually ? "auto" : 0,
          opacity: editingManually ? 1 : 0,
        }}
        transition={{ duration: 0.3 }}
        style={{ overflow: "hidden" }}
      >
        <div className="new-address-form-manual-fields">
          {/*     <AddressField
            placeHolder={t("Country")}
            id="new-address-country"
            value={customAddress.country_name}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress({
                ...customAddress,
                country_name: event.target.value,
              })
            }
            required={true}
          /> */}
          <AddressCountrySelector
            id="new-address-country"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                country_code: e.target.value ? e.target.value : "",
                country_name: e.target.value
                  ? countries[e.target.value]?.name
                  : "",
              }))
            }
            placeHolder={t("Country")}
            value={customAddress.country_code}
            required={true}
          />
          <AddressField
            placeHolder={t("City")}
            id="new-address-city"
            value={customAddress.city}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress({
                ...customAddress,
                city: event.target.value,
              })
            }
            required={true}
          />
          <AddressField
            placeHolder={t("Province")}
            id="new-address-province"
            value={customAddress.province}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress({
                ...customAddress,
                province: event.target.value,
              })
            }
            required={true}
          />
          <AddressField
            placeHolder={t("PostalCode")}
            id="new-address-postcode"
            value={customAddress.postcode}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress({
                ...customAddress,
                postcode: event.target.value,
              })
            }
            required={true}
          />
          <AddressField
            placeHolder={t("Street")}
            id="new-address-street1"
            value={customAddress.street}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress({
                ...customAddress,
                street: event.target.value,
              })
            }
            required={true}
          />
          <AddressField
            placeHolder={t("OtherStreet")}
            id="new-address-street2"
            value={customAddress.street2}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress({
                ...customAddress,
                street2: event.target.value,
              })
            }
            required={false}
          />
          <AddressField
            placeHolder={t("HouseNumber")}
            id="new-address-number"
            value={customAddress.number}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress({
                ...customAddress,
                number: event.target.value,
              })
            }
            required={true}
          />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default NewAddressForm;
