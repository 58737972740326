import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import "./payment-request-components.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  clearPaymentMethodSelected,
  setIsPaymentMethodEditable,
  setIsPaymentMethodsLoading,
  setIsWalletPaymentMethodInUse,
  setIsWalletPaymentsSupportedByDevice,
  setPaymentMethodLastUsed,
  setPaymentMethodSelected,
  setWalletPaymentMethodTypeInUse,
} from "../../store/reducers/paymentSlice";
import { AnimatePresence, motion } from "framer-motion";
import { setToastMessage } from "../../store/reducers/appSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createShopperViaWalletData } from "../../lib/api/shopper-methods";
import { setShippingAddress } from "../../store/reducers/userSlice";
import { submitLogToBugsnag } from "../../lib/api/log";

type Props = { stripePaymentRequestAdupContainerProps: any; key: number };

const StripePaymentRequestAdupButton: React.FC<Props> = (
  stripePaymentRequestAdupButtonProps
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [isWalletButtonAvailable, setIsWalletButtonAvailable] = useState(false);
  const navigate = useNavigate();

  // Get an instance of `PhoneNumberUtil`.
  const phoneUtil: any =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();

  const country = useSelector(
    (state: RootState) => state.app.settings.ipAddressCountryCode
  );
  const currency = useSelector((state: RootState) => state.cart.shop.currency);
  const grandTotal = useSelector(
    (state: RootState) => state.cart?.priceCalculations?.total?.major
  );
  const shippingCost = useSelector(
    (state: RootState) => state.cart?.priceCalculations?.shipping?.major
  );
  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );

  const selectedPaymentMethod = useSelector(
    (state: RootState) => state.payment.paymentMethodSelected
  );
  const isPaymentMethodEditable = useSelector(
    (state: RootState) => state.payment?.isPaymentMethodEditable
  );

  const paymentSession =
    stripePaymentRequestAdupButtonProps.stripePaymentRequestAdupContainerProps
      .paymentMethod?.session;

  useEffect(() => {
    dispatch(setIsPaymentMethodsLoading(true));
  }, []);

  const otherPayments = () => {
    dispatch(clearPaymentMethodSelected(null));
    stripePaymentRequestAdupButtonProps.stripePaymentRequestAdupContainerProps.setPaymentMethodChanged(
      true
    );
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsWalletPaymentsSupportedByDevice(false));
  };

  const skipAvailableWalletPayments = () => {
    dispatch(clearPaymentMethodSelected(null));
    dispatch(setIsWalletPaymentsSupportedByDevice(true));
    stripePaymentRequestAdupButtonProps.stripePaymentRequestAdupContainerProps.setPaymentMethodChanged(
      true
    );
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsPaymentMethodEditable(false));
  };

  useEffect(() => {
    setPaymentRequest(undefined);
    try {
      if (stripe) {
        dispatch(
          setPaymentMethodSelected(
            stripePaymentRequestAdupButtonProps
              .stripePaymentRequestAdupContainerProps.paymentMethod
          )
        );
        const pr = stripe.paymentRequest({
          country: "NL", // country //TODO harcoded since LK is not supported
          currency: currency.toLowerCase(),
          total: {
            label: `${merchantTheme?.merchant_name} Cart Total`,
            amount: grandTotal ?? 0.0,
          },
          requestPayerName: true,
          requestPayerEmail: true,
          requestPayerPhone: true,
          requestShipping: true,
          shippingOptions: [
            // The first shipping option in this list appears as the default
            // option in the browser payment interface.
            {
              id: "shipping",
              label: "Delivery",
              detail: "Your order will be delivered to your Shipping address",
              amount: shippingCost ?? 0.0,
            },
          ],
        });

        // Listen for changes to the shipping address
        pr.on("shippingaddresschange", (ev: any) => {
          let shippingContact = ev.shippingAddress;
          // Log the updated address
          if (shippingContact) {
            console.log(
              "Updated STRIPE Wallet Shipping Address:",
              shippingContact
            );
          }
          // Update the shipping address in state based on the selected address : which will also trigger address change & shipping cost Calculation
          var selectedShippingAddress = {
            id: "",
            number: shippingContact?.houseNumber ?? "",
            street: shippingContact?.street ?? "",
            street2: "",
            city: shippingContact?.city ?? "",
            province: shippingContact?.state ?? "",
            postcode: shippingContact?.postalCode ?? "",
            country_name: shippingContact?.country ?? "",
            country_code: shippingContact?.countryCode ?? "",
          };
          dispatch(setShippingAddress(selectedShippingAddress));
          setPaymentRequest(pr);
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment()
          .then((result) => {
            if (result) {
              console.log("success :", result);
              dispatch(setPaymentMethodLastUsed(""));
              if (
                stripePaymentRequestAdupButtonProps
                  .stripePaymentRequestAdupContainerProps.name === "apple_pay"
              ) {
                setIsWalletButtonAvailable(result.applePay);
                if (result.applePay) {
                  dispatch(setWalletPaymentMethodTypeInUse("apple_pay"));
                  dispatch(setIsWalletPaymentMethodInUse(true));
                  console.log("Wallet Method supported : apple_pay");
                }
              } else if (
                stripePaymentRequestAdupButtonProps
                  .stripePaymentRequestAdupContainerProps.name === "google_pay"
              ) {
                setIsWalletButtonAvailable(result.googlePay);
                if (result.googlePay) {
                  dispatch(setWalletPaymentMethodTypeInUse("google_pay"));
                  dispatch(setIsWalletPaymentMethodInUse(true));
                  console.log("Wallet Method supported : google_pay");
                }
              }
              if (!result.applePay && !result.googlePay) {
                otherPayments();
              } else {
                setPaymentRequest(pr);
                dispatch(setIsPaymentMethodsLoading(false));
                if (isPaymentMethodEditable) {
                  skipAvailableWalletPayments();
                }
              }
            } else {
              otherPayments();
              console.log("Error in canMakePayment result :", result);
            }
          })
          .catch((error) => {
            otherPayments();
            console.log("Error in canMakePayment method", error);
            submitLogToBugsnag(
              "error",
              `Error in canMakePayment method : ${error}`
            );
          });
      }
    } catch (exception) {
      otherPayments();
      console.log("Google/Apple pay could not be initialized :", exception);
      submitLogToBugsnag(
        "error",
        `Google/Apple pay could not be initialized : : ${exception}`
      );
    }
  }, [stripe, country, currency, grandTotal, paymentSession]);

  useEffect(() => {
    if (stripe && paymentSession?.client_secret && paymentRequest) {
      console.log("paymentRequest changed", paymentRequest);
      paymentRequest.on("paymentmethod", async (ev: any) => {
        console.log(
          "// Confirm the PaymentIntent without handling potential next actions (yet).",
          ev
        );

        //* Fetch Payer data from Wallet Payment - START *//
        let countryCode = "0";
        let phoneNumber = "0";
        try {
          const number = phoneUtil.parseAndKeepRawInput(
            ev?.payerPhone
              ? ev?.payerPhone.startsWith("+")
                ? ev?.payerPhone
                : `+${ev?.payerPhone}`
              : "0"
          );
          countryCode = number.getCountryCode();
          phoneNumber = number.getNationalNumber();
        } catch (error) {
          console.log("Wallet data phone number invalid :", error);
          submitLogToBugsnag(
            "error",
            `Wallet data phone number invalid : ${error}`
          );
        }
        const walletPayerInfo = {
          country_code: countryCode ?? "",
          phone_number: phoneNumber ?? "",
          first_name: ev?.payerName.split(" ")[0] ?? "",
          last_name: ev?.payerName.split(" ")[1] ?? "",
          shipping_address: {
            house_number: ev?.shippingAddress?.addressLine[0],
            street: ev?.shippingAddress?.addressLine[1],
            street2: "",
            city: ev?.shippingAddress?.city,
            province: ev?.shippingAddress?.region,
            postcode: ev?.shippingAddress?.postalCode,
            country: ev?.shippingAddress?.country,
          },
          billing_address: {
            house_number:
              ev?.paymentMethod?.billing_details?.address?.country !== ""
                ? ev?.paymentMethod?.billing_details?.address?.line1
                : ev?.shippingAddress?.addressLine[0],
            street:
              ev?.paymentMethod?.billing_details?.address?.country !== ""
                ? ev?.paymentMethod?.billing_details?.address?.line2
                : ev?.shippingAddress?.addressLine[1],
            street2: "",
            city:
              ev?.paymentMethod?.billing_details?.address?.country !== ""
                ? ev?.paymentMethod?.billing_details?.address?.city
                : ev?.shippingAddress?.city,
            province:
              ev?.paymentMethod?.billing_details?.address?.country !== ""
                ? ev?.paymentMethod?.billing_details?.address?.state
                : ev?.shippingAddress?.region,
            postcode:
              ev?.paymentMethod?.billing_details?.address?.country !== ""
                ? ev?.paymentMethod?.billing_details?.address?.postal_code
                : ev?.shippingAddress?.postalCode,
            country:
              ev?.paymentMethod?.billing_details?.address?.country !== ""
                ? ev?.paymentMethod?.billing_details?.address?.country
                : ev?.shippingAddress?.country,
          },
          email: ev?.payerEmail,
        };
        //* Fetch Payer data from Wallet Payment - END *//

        console.log("Wallet payer Info :", walletPayerInfo);
        const createdShopper = await createShopperViaWalletData(
          walletPayerInfo
        );
        console.log("Shopper created via wallet data :", createdShopper);

        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            paymentSession?.client_secret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );

        if (confirmError) {
          console.log("confirmError", confirmError);
          dispatch(
            setToastMessage({
              text: t("PaymentFailed") + confirmError,
              type: "ERROR",
            })
          );
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
        } else {
          console.log("else @ confirmError", confirmError);
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent?.status === "requires_action") {
            console.log("requires_action");
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(
              paymentSession?.client_secret
            );
            if (error) {
              console.log("requires_action Error :", error);
              dispatch(
                setToastMessage({
                  text: t("PaymentFailed") + confirmError,
                  type: "ERROR",
                })
              );
              // The payment failed -- ask your customer for a new payment method.
            } else {
              console.log("The payment has succeeded.");
              // store.dispatch(setPaymentSuccess({ success: true }));
              navigate(`/thank-you/${paymentSession?.cart_id}`);
              dispatch(
                setToastMessage({
                  text: t("PaymentSuccessful"),
                  type: "SUCCESS",
                })
              );
              // The payment has succeeded.
            }
          } else {
            console.log("The payment has succeeded.");
            // store.dispatch(setPaymentSuccess({ success: true }));
            navigate(`/thank-you/${paymentSession?.cart_id}`);
            dispatch(
              setToastMessage({ text: t("PaymentSuccessful"), type: "SUCCESS" })
            );
            // The payment has succeeded.
          }
        }
      });
    }
  }, [paymentRequest, selectedPaymentMethod]);

  if (paymentRequest && isWalletButtonAvailable) {
    return (
      <>
        <div className="change-pay-method-link" style={{ textAlign: "center" }}>
          <h2 onClick={skipAvailableWalletPayments}>
            {t("ChangePaymentMethod")}
          </h2>
        </div>
        <br />
        <AnimatePresence>
          <motion.div
            animate={{ transform: "translateY(20px" }}
            initial={{ transform: "translateY(500px)" }}
            transition={{
              delay: 0.2,
              duration: 0.51,
              type: "spring",
              bounce: 0.2,
            }}
            className="pay-button-holder-fixed"
          >
            <div style={{ width: "300px" }}>
              <PaymentRequestButtonElement
                options={{
                  paymentRequest,
                  style: {
                    paymentRequestButton: {
                      type: "default",
                      theme: "dark",
                      height: "50px",
                    },
                  },
                }}
              />
            </div>
          </motion.div>
        </AnimatePresence>
      </>
    );
  } else return null;
};

export default StripePaymentRequestAdupButton;
