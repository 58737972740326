import React, { useMemo } from "react";
import { PaymentMethodsView } from "../../../components/payment-methods-view/PaymentMethods";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setCartExpanded } from "../../../store/reducers/appSlice";
import { AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { BackArrowBlack } from "../../../assets/svg/svg-components/back-arrow-black";
import { motion } from "framer-motion";
import { AccountProfileForm } from "../../../components/account-profile-input-form/AccountProfileForm";
import SummaryThemeWise from "../../../components/theme-wise-switching/SummaryThemeWise";
import BillingAddressToggle from "../../../components/billing-is-shipping/BillingAddressToggle";
import Header from "../../../components/header/Header";
import OldUserSummary from "../../../components/old-user-summary/OldUserSummary";
import PhoneForm from "../../../components/phone-form/PhoneForm";
import UserAddresses from "../../../components/user-addresses/UserAddresses";
import FastCheckoutBillingAddress from "../../../pages/fast-checkout/segments/FastCheckoutBillingAddress";
import FastCheckoutPaymentMount from "../../../pages/fast-checkout/segments/FastCheckoutPaymentMount";
import FastCheckoutShippingAddress from "../../../pages/fast-checkout/segments/FastCheckoutShippingAddress";
import AnimatedPayButtonBlack from "../../../pages/fast-checkout/subcomponents/AnimatedPayButtonBlack";
import FastCheckoutSinglePageWrapper from "../../../pages/fast-checkout/subcomponents/FastCheckoutSinglePageWrapper";
import "../../../sass/layouts/layout2/mobile/FastCheckoutMobileLayout2.scss";

interface PageProps {
  app_mode: any;
  componentRenderLogics: (componentType: string) => boolean | undefined;
}

const FastCheckoutMobileExtraLayout2: React.FC<PageProps> = ({
  app_mode,
  componentRenderLogics,
}) => {
  // we use this here for checking and setting the cart expansion state
  const isCartExpanded = useSelector(
    (state: RootState) => state.app?.UI?.cart?.expanded
  );

  const profileDetailsForm = useMemo(() => {
    return (
      <>
        <AccountProfileForm isCompactUserName={true} />
        <UserAddresses />
      </>
    );
  }, []);
  const dispatch = useDispatch();

  const renderUserDetails = () => {
    return (
      <>
        {componentRenderLogics("phoneInput") && (
          <div id="phoneInputRef001">
            <PhoneForm />
          </div>
        )}

        {componentRenderLogics("profileDetailsForm") && (
          <div id="profileDetailsFormRef001">{profileDetailsForm}</div>
        )}
      </>
    );
  };

  const renderNormalThemeContent = () => {
    return (
      <React.Suspense fallback={<div className="main-content-holder"></div>}>
        <div className="main-content-holder">
          {componentRenderLogics("header") && (
            <Header forceShowCompactCart={true} />
          )}
        </div>
        <FastCheckoutSinglePageWrapper>
          <div className="max-width-container">
            <div className="max-width-container__content">
              <div className="main-content-holder">
                {/* ===================================================================== */}
                <div className="main-left-content-holder fastCheckoutMobileExtraLayout2-left">
                  <motion.span
                    className="heading-and-back-chevron"
                    onClick={() => dispatch(setCartExpanded(false))}
                  >
                    <AnimatePresence>
                      {isCartExpanded && (
                        <motion.div
                          key={"back-chevron-black-container"}
                          animate={{ x: 0, opacity: 1 }}
                          initial={{ x: 10, opacity: 0 }}
                          exit={{ x: -10, opacity: 0 }}
                        >
                          <BackArrowBlack />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.span>
                  {componentRenderLogics("profileLink") && (
                    <Link to={"/profile"}></Link>
                  )}

                  {componentRenderLogics("userSummary") && <OldUserSummary />}

                  <div className="fastCheckoutMobileExtraLayout2-userDetails">
                    {componentRenderLogics("userDetails") &&
                      renderUserDetails()}

                    {/* Only processed users can have multiple addresses saved */}
                    {componentRenderLogics("shippingAddress") && (
                      <FastCheckoutShippingAddress />
                    )}

                    {componentRenderLogics("billingAddress") && (
                      <FastCheckoutBillingAddress />
                    )}

                    {componentRenderLogics("billingAddressToggle") && (
                      <BillingAddressToggle />
                    )}
                  </div>
                </div>

                {/* ===================================================================== */}

                <div className="fastcheckout-page-container-flex main-right-content-holder fastCheckoutMobileExtraLayout2-right">
                  <div className="main-payment-content-holder">
                    <div
                      id="fastCheckoutPaymentMountRef001"
                      className="main-payment-card-content-holder"
                    >
                      {componentRenderLogics("paymentMount") && (
                        <FastCheckoutPaymentMount />
                      )}
                    </div>
                  </div>

                  {componentRenderLogics("paymentMethods") && (
                    <div id="paymentMethodsRef001">
                      <PaymentMethodsView />
                    </div>
                  )}
                </div>
              </div>

              {/* ===================================================================== */}

              {/* ===================================================================== */}

              {componentRenderLogics("payButton") && <AnimatedPayButtonBlack />}
            </div>
            <SummaryThemeWise app_mode={app_mode} isCompact={true} />
          </div>
        </FastCheckoutSinglePageWrapper>
      </React.Suspense>
    );
  };

  return <>{renderNormalThemeContent()}</>;
};

export default FastCheckoutMobileExtraLayout2;
