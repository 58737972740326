import { useEffect, useState } from "react";
import OverviewPageDesktopLayout1 from "./desktop/OverviewPageDesktopLayout1";
import OverviewPageMobileLayout1 from "./mobile/OverviewPageMobileLayout1";

type Props = {
  allProducts: any;
  isMoreProductsLoading: boolean;
  isShowMoreAvailable: boolean;
  addToFastcheckoutCart: (item: any) => void;
  fetchAllProducts: () => void;
  componentRenderLogics: (componentType: string) => boolean | undefined;
};

const OverviewPageLayout1 = ({
  allProducts,
  isMoreProductsLoading,
  isShowMoreAvailable,
  addToFastcheckoutCart,
  fetchAllProducts,
  componentRenderLogics,
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth > 750 ? (
        <OverviewPageDesktopLayout1
          allProducts={allProducts}
          isMoreProductsLoading={isMoreProductsLoading}
          isShowMoreAvailable={isShowMoreAvailable}
          addToFastcheckoutCart={addToFastcheckoutCart}
          fetchAllProducts={fetchAllProducts}
          componentRenderLogics={componentRenderLogics}
        />
      ) : (
        <OverviewPageMobileLayout1
          allProducts={allProducts}
          isMoreProductsLoading={isMoreProductsLoading}
          isShowMoreAvailable={isShowMoreAvailable}
          addToFastcheckoutCart={addToFastcheckoutCart}
          fetchAllProducts={fetchAllProducts}
          componentRenderLogics={componentRenderLogics}
        />
      )}
    </>
  );
};

export default OverviewPageLayout1;
