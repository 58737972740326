// Objective: Delete the user account from the server
import store from "../../store/store";
/* ================================================================================== */
import { RequestInfo } from "../../interfaces/RequestInfo";
import { DELETE_PROFILE_API } from "../constants/central-api";
import { asyncHandler } from "./async-handler";
import { t } from "i18next";

/**
 * @param { string } otpCode - The corresponding OTP Code
 */
export async function deleteUserAccountRequest(otpCode: string) {
  const key = store.getState().users.currentUser.token; // current user token

  const requestInfo: RequestInfo = {
    url: `${DELETE_PROFILE_API}?otp=${otpCode}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: key ? `Bearer ${key}` : "",
    },
  };

  const actionType = "USER_ACCOUNT_DELETE";
  const customMsg = t("UserAccountDeletedSuccess");

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    const is_deleted = response.success;

    return is_deleted;
  } else {
    return null;
  }
}
