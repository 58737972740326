import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

interface CartSummaryProps {
  subTotal: string;
  shipping: string;
  convenienceFee: string;
  tax: string;
  discount: string;
  grandTotal: string;
  app_mode: string;
}

export const CartSummary: React.FC<CartSummaryProps> = ({
  subTotal = 0,
  shipping = 0,
  tax = 0,
  discount = 0,
  convenienceFee = 0,
  grandTotal = 0,
  app_mode,
}) => {
  const { t } = useTranslation();

  const isAddToCartAPILoading = useSelector(
    (state: RootState) => state.payment?.isAddToCartAPILoading
  );
  const isProductsAPILoading = useSelector(
    (state: RootState) => state.app.UI.isProductsAPILoading
  );

  return (
    <motion.div
      key="expanded-cart-summary"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className="order-cart-summary"
    >
      <div className="order-amounts">
        <div className="order-amount-field">
          {app_mode === "ai-fast-checkout" ? t("CartTotal") : t("OrderTotal")}
          {isAddToCartAPILoading || isProductsAPILoading ? (
            <Skeleton duration={0.6} className="skeleton-loader" />
          ) : (
            <div>{subTotal}</div>
          )}
        </div>
        <div className="order-amount-field">
          {t("Shipping")}
          {isAddToCartAPILoading || isProductsAPILoading ? (
            <Skeleton duration={0.6} className="skeleton-loader" />
          ) : (
            <div>{shipping}</div>
          )}
        </div>
        {tax && tax !== "0" && (
          <div className="order-amount-field">
            {t("Tax")}
            {isAddToCartAPILoading || isProductsAPILoading ? (
              <Skeleton duration={0.6} className="skeleton-loader" />
            ) : (
              <div>{tax}</div>
            )}
          </div>
        )}
        {discount && discount !== "0" && (
          <div className="order-amount-field">
            {t("Discount")}
            {isAddToCartAPILoading || isProductsAPILoading ? (
              <Skeleton duration={0.6} className="skeleton-loader" />
            ) : (
              <div>{`- ${discount}`}</div>
            )}
          </div>
        )}
        {convenienceFee && convenienceFee !== "0" && (
          <div className="order-amount-field">
            {t("ConvenienceFee")}
            {isAddToCartAPILoading || isProductsAPILoading ? (
              <Skeleton duration={0.6} className="skeleton-loader" />
            ) : (
              <div>{convenienceFee}</div>
            )}
          </div>
        )}

        <div className="order-cart-summary-divider-line"></div>
        <div className="order-total-holder">
          {t("GrandTotal")}
          {isAddToCartAPILoading || isProductsAPILoading ? (
            <Skeleton duration={0.6} className="skeleton-loader" />
          ) : (
            <div>{grandTotal}</div>
          )}
        </div>
      </div>
    </motion.div>
  );
};
