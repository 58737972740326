import React, { useEffect, useState } from "react";
import AICheckoutDesktopLayout2 from "./desktop/AICheckoutDesktopLayout2";
import AICheckoutMobileLayout2 from "./mobile/AICheckoutMobileLayout2";

type Props = {
  isHeartBtnActive: boolean;
  setIsHeartBtnActive: any;
  isDemoActive: boolean;
  productImages: any;
  setLocalAttributeSkuMap: any;
  productsData: any;
  onIncrement: any;
  onDecrement: any;
  addToFastcheckoutCart: any;
  componentRenderLogics: (componentType: string) => boolean | undefined;
};

const AICheckoutLayout2 = ({
  isHeartBtnActive,
  setIsHeartBtnActive,
  isDemoActive,
  productImages,
  setLocalAttributeSkuMap,
  productsData,
  onIncrement,
  onDecrement,
  addToFastcheckoutCart,
  componentRenderLogics,
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {windowWidth > 750 ? (
        <AICheckoutDesktopLayout2
          isHeartBtnActive={isHeartBtnActive}
          setIsHeartBtnActive={setIsHeartBtnActive}
          isDemoActive={isDemoActive}
          productImages={productImages}
          setLocalAttributeSkuMap={setLocalAttributeSkuMap}
          productsData={productsData}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          addToFastcheckoutCart={addToFastcheckoutCart}
          componentRenderLogics={componentRenderLogics}
        />
      ) : (
        <AICheckoutMobileLayout2
          isHeartBtnActive={isHeartBtnActive}
          setIsHeartBtnActive={setIsHeartBtnActive}
          isDemoActive={isDemoActive}
          productImages={productImages}
          setLocalAttributeSkuMap={setLocalAttributeSkuMap}
          productsData={productsData}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          addToFastcheckoutCart={addToFastcheckoutCart}
          componentRenderLogics={componentRenderLogics}
        />
      )}
    </>
  );
};

export default AICheckoutLayout2;
