import { t } from "i18next";
import "../../sass/components/deleteCartItemMsg.scss";
import { useEffect } from "react";

type Props = {
  onDeleteClick: () => void;
  onCancelClick: () => void;
};

const DeleteCartItemMsg = ({ onDeleteClick, onCancelClick }: Props) => {
  const getCompactCartCountElement = (hide: boolean) => {
    const compactCart__count: any = document.querySelector(
      ".compactCart__count"
    );
    if (compactCart__count) {
      if (hide) {
        compactCart__count.style.display = "none";
      } else {
        compactCart__count.style.display = "flex";
      }
    }
  };

  useEffect(() => {
    getCompactCartCountElement(true);
  }, []);

  return (
    <div
      onClick={() => {
        onCancelClick();
        getCompactCartCountElement(false);
      }}
      className="deleteCartMsg__overlay"
    >
      <div className="deleteCartMsg__wrapper column">
        <div className="deleteCartMsg-content">
          <div className="deleteCartMsg-title">
            <h1> {t("RemoveCartItem")}</h1>
          </div>
          <div className="deleteCartMsg flex-column">
            <div className="textContent">{`${t("AreYouSure")} ${t(
              "RemoveSelectedCartItems"
            )} ?`}</div>
            <div className="buttonContainer">
              <div
                onClick={() => {
                  onDeleteClick();
                  getCompactCartCountElement(false);
                }}
                style={{ backgroundColor: "#e02020" }}
                className="btn1"
              >
                {t("Yes")}
              </div>
              <div
                onClick={() => {
                  onCancelClick();
                  getCompactCartCountElement(false);
                }}
                className="btn2"
              >
                {t("Cancel")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCartItemMsg;
