import { debounce } from "lodash";
import {
  AdupUserAddress,
  EmptyAdupUserAddress,
} from "../../../interfaces/AdupUserAddress";
import {
  initialPriceCalculations,
  setCartPriceCalculations,
} from "../../../store/reducers/cartSlice";
import store, { RootState } from "../../../store/store";
import { addToCart } from "../../api/cart-calls/add-to-cart";
import { submitLogToBugsnag } from "../../api/log";
import {
  setIsAddToCartAPILoading,
  setLastAddToCartCallPayload,
} from "../../../store/reducers/paymentSlice";

/**
 * Intent should be re-fetched when addresses are edited
 */
function selectShippingAddress(state: RootState) {
  return state.users?.currentUser?.shipping?.shippingAddress;
}

let currentShippingAddress: AdupUserAddress | undefined = EmptyAdupUserAddress;
async function handleAddressChange() {
  try {
    const currentCartItems = store.getState().cart?.items;
    let previousShippingAddress = currentShippingAddress;
    currentShippingAddress = selectShippingAddress(store.getState());

    if (previousShippingAddress !== currentShippingAddress) {
      console.log(
        "Shipping Addresses changed from",
        previousShippingAddress,
        "to",
        currentShippingAddress
      );

      //* Execute Add-To-Cart API call if cart is not empty
      if (currentCartItems && currentCartItems.length > 0) {
        store.dispatch(setIsAddToCartAPILoading(true));
        addToCartCallDebounce();
      } else {
        //* Set Price Calculations to initial state if cart is empty
        store.dispatch(setCartPriceCalculations(initialPriceCalculations));
        store.dispatch(setLastAddToCartCallPayload(undefined));
      }
    }
  } catch (error) {
    console.log("Error : ", error);
    submitLogToBugsnag("error", error);
  }
}

const addToCartCallDebounce = debounce(() => addToCart(), 400);

export const unsubscribeHandleAddressChange =
  store.subscribe(handleAddressChange);
// unsubscribe();
