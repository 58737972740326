import { useState } from "react";
import { useTranslation } from "react-i18next";
import HeadingWithRightButton from "../../../components/heading/HeadingWithRightButton";
import NewAddressForm from "../../../components/new-address-form/NewAddressForm";
import ShippingAddressList from "../../../components/shipping-address-list/ShippingAddressList";
import RoundButton from "../../../components/round-button/RoundButton";
import { editSVG, plusSVG } from "../../../assets/js/allCustomSVGs";
import { useSelector } from "react-redux";
import { applicationUserType } from "../../../store/reducers/appSlice";
import { RootState } from "../../../store/store";
import { AnimatePresence } from "framer-motion";

interface Props {
  profilePageAddress?: boolean;
}

const FastCheckoutShippingAddress = ({ profilePageAddress }: Props) => {
  const { t } = useTranslation();

  const [displayingNewAddressForm, setDisplayingNewAddressForm] =
    useState(false);

  const [isEditingIconsVisible, setIsEditingIconsVisible] = useState(false);

  // check if wallet payment method is in use
  const isWalletPaymentMethodInUse = useSelector(
    (state: RootState) => state.payment?.isWalletPaymentMethodInUse
  );

  // user's auth state
  const authState = useSelector((state: RootState) => state.app.auth);

  if (
    isWalletPaymentMethodInUse &&
    authState.user === applicationUserType.NEW_USER
  ) {
    return null;
  }

  return (
    <div
      style={{ marginTop: "1.6rem" }}
      className="shipping-details fast-checkout-page-segment"
    >
      <span
        data-shippingeditingiconsvisible={isEditingIconsVisible.toString()}
        className="shipping-address-form-title"
      >
        <HeadingWithRightButton
          headingText={
            profilePageAddress ? t("Addresses") : t("ShippingAddressTitle")
          }
          button={
            <div className="headingWithRightButton__button--container">
              {/* <AddShippingAddressButton
                onClick={() => setDisplayingNewAddressForm(true)}
              /> */}
              <RoundButton
                color={"dark"}
                icon={plusSVG}
                onClick={() =>
                  setDisplayingNewAddressForm(!displayingNewAddressForm)
                }
                classString={`round-button-with-plus ${
                  displayingNewAddressForm
                    ? "icon-rotate-half-left"
                    : "icon-rotate-half-right"
                }`}
              />
              <RoundButton
                color={"dark"}
                icon={editSVG}
                onClick={() => setIsEditingIconsVisible(!isEditingIconsVisible)}
                classString="round-button-with-edit"
              />
              {/* <SearchShippingAddressButton onClick={() => null} /> */}
            </div>
          }
        />

        <AnimatePresence>
          {displayingNewAddressForm && (
            <NewAddressForm
              addressType="shipping"
              close={() => setDisplayingNewAddressForm(false)}
            />
          )}
        </AnimatePresence>

        <ShippingAddressList profilePageAddress={profilePageAddress} />
      </span>
    </div>
  );
};

export default FastCheckoutShippingAddress;
