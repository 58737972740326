import { AdupUserAddress } from "../../../interfaces/AdupUserAddress";
import {
  addNewUserAddress,
  setBillingAddress,
  setShippingAddress,
} from "../../../store/reducers/userSlice";
import store from "../../../store/store";
/* ================================================================================== */
import { RequestInfo } from "../../../interfaces/RequestInfo";
import { CREATE_ADDRESS_API } from "../../constants/central-api";
import { asyncHandler } from "../async-handler";
import { t } from "i18next";
import { getCountryCodeByCountryName } from "../../utils/helper-functions";

export async function createUserAddress(
  address: AdupUserAddress,
  addressType: string
): Promise<boolean> {
  // send request
  const result: any = await createUserAddressRequest(address);

  // save state
  if (result?.id) {
    store.dispatch(
      addNewUserAddress({
        ...address,
        id: result?.id,
        country_name: result?.address?.country?.name,
        country: result?.address?.country,
        shipping_rate: result?.shipping_rate ? result?.shipping_rate : null,
      })
    );
    if (address && addressType === "shipping") {
      store.dispatch(
        setShippingAddress({
          ...address,
          id: result?.id,
          country_name: result?.address?.country?.name,
          country: result?.address?.country,
          shipping_rate: result?.shipping_rate,
        })
      );
    } else if (address && addressType === "billing") {
      store.dispatch(
        setBillingAddress({
          ...address,
          id: result?.id,
          country_name: result?.address?.country?.name,
          country: result?.address?.country,
          shipping_rate: result?.shipping_rate,
        })
      );
    }

    return true;
  }

  return false;
}

/**
 * @param { AdupUserAddress } address - address object
 */
export async function createUserAddressRequest(address: AdupUserAddress) {
  const key = store.getState().users.currentUser.token; // current user token
  const shopID = store.getState().cart.shop.id; // current shop id

  const requestInfo: RequestInfo = {
    url: CREATE_ADDRESS_API,
    method: "POST",
    body: JSON.stringify({
      address: {
        house_number: address.number,
        street: address.street,
        street2: address.street2,
        city: address.city,
        province: address.province,
        postcode: address.postcode,
        country: address.country_name,
        country_code:
          getCountryCodeByCountryName(address.country_name) ??
          address.country_name,
      },
      shop_id: shopID,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: key ? `Bearer ${key}` : "",
    },
  };

  const actionType = "CREATE_USER_ADDRESS";
  const customMsg = t("NewAddressCreatedSuccessfully");

  const res: any = await asyncHandler(requestInfo, actionType, customMsg, true);

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    return response?.data;
  } else {
    return null;
  }
}
