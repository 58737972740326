import AICheckoutLayout1 from "../../layout/layout1/AICheckoutLayout1";
import AICheckoutLayout2 from "../../layout/layout2/AICheckoutLayout2";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setupInitialState } from "../../lib/api/initial-api-call";
import { setLastCartUrl, setToastMessage } from "../../store/reducers/appSlice";
import {
  decrementItemQuantity,
  incrementItemQuantity,
  setAttributeSkuMap,
  setCart,
} from "../../store/reducers/cartSlice";
import store, { RootState } from "../../store/store";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { addShopperEvent } from "../../lib/api/events-methods";
import { applicationUserType } from "../../store/reducers/appSlice";
import { setIsInitAPILoading } from "../../store/reducers/paymentSlice";
import { AdupCartItem } from "../../interfaces/AdupCartItem";
import { generateFastCheckoutUrlUsingCartItems } from "../../lib/utils/helper-functions";
import getImages from "../../lib/utils/get-images";

const SocialCommercePage = () => {
  const app_layout: any = useSelector(
    (state: RootState) => state.cart.shop.theme?.app_layout
  );

  const isProductsAPILoading = useSelector(
    (state: RootState) => state.app.UI.isProductsAPILoading
  );

  const fullCheckoutProduct: any = useSelector(
    (state: RootState) => state.cart.allProducts[0]
  );

  const demoCheckoutInitData = useSelector(
    (state: RootState) => state.app.demoCheckoutInitData
  );

  const apiAttributeSkuMap: any = useSelector(
    (state: RootState) => state.cart.attributeSkuMap
  );

  const userEmail = useSelector(
    (state: RootState) => state.users.currentUser?.profile?.email
  );

  const showCookieConsent = useSelector(
    (state: RootState) => state.app.UI.showCookieConsent
  );

  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );

  const authState = useSelector((state: RootState) => state.app.auth);

  const fastcheckoutCartItems: AdupCartItem[] =
    useSelector((state: RootState) => state.cart.fastcheckoutCartItems) ?? [];

  const shop: any = useSelector((state: RootState) => state.cart.shop);

  //* =================================================================== *//

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDemoActive, setIsDemoActive] = useState(false);
  const [isStateUpdateComplete, setIsStateUpdateComplete] = useState(false);
  const [isHeartBtnActive, setIsHeartBtnActive] = useState(false);
  const [chosenProduct, setChosenProduct] = useState();
  const [isInvalidVariantSelected, setIsInvalidVariantSelected] =
    useState(false);
  const [isNoItemSelected, setIsNoItemSelected] = useState(false);
  const route = window.decodeURI(useLocation().pathname);

  const [productId, setProductId] = useState<any>(fullCheckoutProduct?.id);
  const [productName, setProductName] = useState<any>(
    fullCheckoutProduct?.name
  );
  const [productDescription, setProductDescription] = useState<any>(
    fullCheckoutProduct?.description
  );
  const [productPrice, setProductPrice] = useState<string>(
    fullCheckoutProduct?.price?.values?.[fullCheckoutProduct?.price?.default]
      ?.price?.formatted ?? "0.00"
  );
  const [availableStock, setAvailableStock] = useState<number>(0);
  const [productAvailability, setProductAvailability] = useState<boolean>(true);
  const [productImages, setProductImages] = useState<any>(
    getImages(fullCheckoutProduct?.images, "large", "all_images_array")
  );
  const [localAttributeSkuMap, setLocalAttributeSkuMap] = useState<any>();
  const [defaultSelectedAttributes, setDefaultSelectedAttributes] =
    useState<any>();
  const [cartItemNumber, setCartItemNumber] = useState(
    fastcheckoutCartItems.find((item) => item.sku === chosenProduct)
      ?.quantity ?? 1
  );

  const productCurrency = fullCheckoutProduct?.price?.default;
  const productType = fullCheckoutProduct?.type;
  const attributes = fullCheckoutProduct?.attributes;
  let cartTotal: number = 0.0;
  let delivery: any = ""; //TODO

  //* =================================================================== *//

  /**
   //*  increments the number of units of an item to purchase
   * @param { React.MouseEvent } event
   */
  function onIncrement(event: React.MouseEvent) {
    setCartItemNumber((prev: number) => prev + 1);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    store.dispatch(incrementItemQuantity({ id: productId }));
  }

  /**
   //*  decrements the number of units of an item to purchase
   * @param { React.MouseEvent } event
   */
  function onDecrement(event: React.MouseEvent) {
    if (cartItemNumber > 1) {
      setCartItemNumber((prev: number) => prev - 1);
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      store.dispatch(decrementItemQuantity({ id: productId }));
    }
  }

  useEffect(() => {
    if (route === "/demo/social-commerce") {
      if (
        demoCheckoutInitData?.shop?.name &&
        demoCheckoutInitData?.shop?.name !== "" &&
        demoCheckoutInitData.products[0]["demo-sku"].name &&
        demoCheckoutInitData.products[0]["demo-sku"].name !== "" &&
        demoCheckoutInitData.products[0]["demo-sku"].description &&
        demoCheckoutInitData.products[0]["demo-sku"].description !== "" &&
        demoCheckoutInitData.products[0]["demo-sku"].display_price &&
        demoCheckoutInitData.products[0]["demo-sku"].display_price !== "" &&
        demoCheckoutInitData.products[0]["demo-sku"].images
      ) {
        store.dispatch(setIsInitAPILoading(true));
        setIsDemoActive(true);
        setupInitialState(demoCheckoutInitData, undefined, true);
        store.dispatch(setLastCartUrl(route));
        setTimeout(() => {
          store.dispatch(setIsInitAPILoading(false));
          setIsStateUpdateComplete(true);
        }, 1500);
      } else {
        navigate("/demo");
      }
    } else {
      //* Triggering Events API on user actions
      addShopperEvent("view_page", {
        url: `${window.location.href}`,
        page: "ai-full-checkout",
        email: userEmail && userEmail !== "" ? userEmail : undefined,
      });

      setIsDemoActive(false);
      store.dispatch(setLastCartUrl(route));
      setIsStateUpdateComplete(true);
    }
  }, [route]);

  useEffect(() => {
    if (!fullCheckoutProduct) {
      setProductId(null);
      setProductName(null);
      setProductDescription(null);
      setProductPrice("");
      setProductImages([]);
      setChosenProduct(undefined);
    }
  }, []);

  useEffect(() => {
    if (fullCheckoutProduct) {
      if (productType === "simple") {
        setDefaultSelectedAttributes(undefined);
        setChosenProduct(fullCheckoutProduct?.sku);
      } else if (productType === "variable") {
        if (
          fullCheckoutProduct?.variants_count > 0 &&
          fullCheckoutProduct?.attribute_sku_map
        ) {
          let firstVariant: any = fullCheckoutProduct?.variants[0];
          setDefaultSelectedAttributes(firstVariant?.attributes);
          setChosenProduct(firstVariant?.sku);

          const formattedAttributeSkuMap: any = Object.keys(
            fullCheckoutProduct?.attribute_sku_map
          ).map((key: any) => {
            return { [key]: fullCheckoutProduct?.attribute_sku_map[key] };
          });

          store.dispatch(setAttributeSkuMap(formattedAttributeSkuMap));
        } else {
          setDefaultSelectedAttributes(undefined);
          setChosenProduct(undefined);
          // store.dispatch(
          //   setToastMessage({
          //     text: t("ProductsError"),
          //     type: "ERROR",
          //   })
          // );
        }
      } else if (productType === "variant") {
        setDefaultSelectedAttributes(fullCheckoutProduct?.attributes);
        setChosenProduct(fullCheckoutProduct?.sku);
      }
    }
  }, [fullCheckoutProduct]);

  useEffect(() => {
    console.log("UI > attribute_sku_map  : ", localAttributeSkuMap);
    console.log("API > attribute_sku_map  : ", apiAttributeSkuMap);
    if (productType === "variable") {
      if (!localAttributeSkuMap) {
        console.log("No combination selected :", localAttributeSkuMap);
        setChosenProduct(undefined);
      } else {
        for (let i of apiAttributeSkuMap) {
          let selectedVariant = i[localAttributeSkuMap];
          if (selectedVariant) {
            console.log(
              "selectedVariant : ",
              selectedVariant,
              "=",
              localAttributeSkuMap
            );
            setIsInvalidVariantSelected(false);
            setIsNoItemSelected(false);
            setChosenProduct(selectedVariant);
            break;
          } else {
            console.log(
              "Selected combination is not available :",
              localAttributeSkuMap
            );
            setIsInvalidVariantSelected(true);
            setChosenProduct(undefined);
          }
        }
      }
    }
  }, [localAttributeSkuMap, fullCheckoutProduct, productType]);

  useEffect(() => {
    if (!isProductsAPILoading && isStateUpdateComplete) {
      const product: any =
        fullCheckoutProduct &&
        chosenProduct &&
        fullCheckoutProduct?.sku === chosenProduct
          ? fullCheckoutProduct
          : Array.isArray(fullCheckoutProduct?.variants)
          ? fullCheckoutProduct?.variants?.find(
              (variant: any) => variant.sku === chosenProduct
            )
          : undefined;
      if (product) {
        const id = product?.id;
        const name = product?.name;
        const description = product?.description;
        const priceNumeric =
          product?.price?.values?.[product?.price?.default]?.price?.major ??
          0.0;
        const priceFormatted =
          product?.price?.values?.[product?.price?.default]?.price?.formatted ??
          "0.00";
        const images = product?.images;
        const urlQuantity = fastcheckoutCartItems.find(
          (item) => item.sku === chosenProduct
        )?.quantity;
        const stock = product?.stock;
        const isProductUnavailable: boolean =
          (fullCheckoutProduct?.type === "variable" &&
            (fullCheckoutProduct?.variants_count <= 0 ||
              fullCheckoutProduct?.variants?.length <= 0 ||
              product?.stock <= 0)) ||
          (fullCheckoutProduct?.type === "simple" &&
            fullCheckoutProduct?.stock <= 0);

        setCartItemNumber(urlQuantity && !isNaN(urlQuantity) ? urlQuantity : 1);
        setProductId(id);
        setProductName(name);
        setProductDescription(description);
        setProductPrice(priceFormatted);
        setProductImages(getImages(images, "large", "all_images_array"));
        setAvailableStock(stock);
        setProductAvailability(!isProductUnavailable);
        cartTotal += priceNumeric
          ? Number(priceNumeric) *
            (urlQuantity && !isNaN(urlQuantity) ? urlQuantity : 1)
          : 0;
        store.dispatch(
          setCart({
            shop,
            cart: [
              {
                priceNumeric: priceNumeric ?? 0,
                priceFormatted: priceFormatted ?? "0.00",
                name: name,
                currency: product?.price?.default,
                image: images
                  ? getImages(images, "medium", "only_first")
                  : getImages({}, "medium", "only_first"),
                stock: stock,
                disabled: isProductUnavailable,
                quantity: urlQuantity && !isNaN(urlQuantity) ? urlQuantity : 1,
                id: id,
                sku: product?.sku,
              },
            ],
            total: cartTotal,
          })
        );
      } else {
        setProductId(fullCheckoutProduct?.id);
        setProductName(fullCheckoutProduct?.name);
        setProductDescription(fullCheckoutProduct?.description);
        setProductPrice(
          fullCheckoutProduct?.price?.values?.[
            fullCheckoutProduct?.price?.default
          ]?.price?.major ?? 0
        );
        setAvailableStock(0);
        setProductAvailability(false);
        setProductImages(
          getImages(fullCheckoutProduct?.images, "large", "all_images_array")
        );
        store.dispatch(
          setCart({
            shop,
            cart: [],
            total: cartTotal,
          })
        );
      }
    }
  }, [
    chosenProduct,
    fullCheckoutProduct,
    isProductsAPILoading,
    isStateUpdateComplete,
  ]);

  const addToFastcheckoutCart = () => {
    if (chosenProduct) {
      //? Checking if the product item is already in the Cart
      if (fastcheckoutCartItems?.some((item) => item.sku === chosenProduct)) {
        let tempItems = fastcheckoutCartItems?.map((item) => {
          if (item.sku === chosenProduct) {
            return { ...item, quantity: cartItemNumber }; //? update the QTY of the item
          }
          return item;
        });

        const fastCheckoutUrl = `/ai-fast-checkout/${
          shop?.slug
        }/${generateFastCheckoutUrlUsingCartItems(tempItems)}`;

        store.dispatch(
          setToastMessage({
            text: t("UpdatedCart"),
            type: "SUCCESS",
          })
        );

        navigate(fastCheckoutUrl);
      }
      //? if the product item is not in the Cart
      else {
        const fastCheckoutUrl = `/ai-fast-checkout/${
          shop?.slug
        }/${generateFastCheckoutUrlUsingCartItems(
          fastcheckoutCartItems
        )},${chosenProduct}[${cartItemNumber}]`;

        store.dispatch(
          setToastMessage({
            text: t("AddedToCart"),
            type: "SUCCESS",
          })
        );

        navigate(fastCheckoutUrl);
      }

      //* Triggering Events API on user actions
      addShopperEvent("add_to_cart", {
        url: `${window.location.href}`,
        product:
          fullCheckoutProduct?.sku === chosenProduct
            ? {
                id: fullCheckoutProduct?.id,
                sku: fullCheckoutProduct?.sku,
                name: fullCheckoutProduct?.name,
                price:
                  fullCheckoutProduct?.price?.values?.[
                    fullCheckoutProduct?.price?.default
                  ]?.price?.major ?? 0,
              }
            : undefined,
        quantity: cartItemNumber,
        email: userEmail && userEmail !== "" ? userEmail : undefined,
      });
    }
  };

  const productsData = {
    productName,
    productCurrency,
    productPrice,
    productDescription,
    delivery,
    isNoItemSelected,
    isInvalidVariantSelected,
    attributes,
    defaultSelectedAttributes,
    productType,
  };
  //* =================================================================== *//

  const componentRenderLogics = (componentType: string) => {
    if (componentType === "cartBanner") {
      return fastcheckoutCartItems?.length > 0 ? true : false;
    }
    if (componentType === "productImagesSlider") {
      return fullCheckoutProduct && !showCookieConsent ? true : false;
    }
    if (componentType === "headingWithRightButton") {
      return authState.user === applicationUserType.OLD_USER ? true : false;
    }
    if (componentType === "fastCheckoutPageWrapper") {
      return !showCookieConsent ? true : false;
    }
    if (componentType === "cartOperatorSC") {
      return chosenProduct ? true : false;
    }
    if (componentType === "productUnavailable") {
      return !fullCheckoutProduct || !productAvailability ? true : false;
    }
    if (componentType === "productStockUnavailable") {
      return cartItemNumber > availableStock ? true : false;
    }
    if (componentType === "goToWebshop") {
      return settings?.webshop_url ? true : false;
    }
    if (componentType === "customMessageBanner") {
      return settings?.is_show_custom_message && settings?.custom_message !== ""
        ? true
        : false;
    }
  };

  const renderLayout = () => {
    if (app_layout === "2") {
      return (
        <AICheckoutLayout2
          isHeartBtnActive={isHeartBtnActive}
          setIsHeartBtnActive={setIsHeartBtnActive}
          isDemoActive={isDemoActive}
          productImages={productImages}
          setLocalAttributeSkuMap={setLocalAttributeSkuMap}
          productsData={productsData}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          addToFastcheckoutCart={addToFastcheckoutCart}
          componentRenderLogics={componentRenderLogics}
        />
      );
    } else {
      return (
        <AICheckoutLayout1
          isHeartBtnActive={isHeartBtnActive}
          setIsHeartBtnActive={setIsHeartBtnActive}
          isDemoActive={isDemoActive}
          productImages={productImages}
          setLocalAttributeSkuMap={setLocalAttributeSkuMap}
          productsData={productsData}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          addToFastcheckoutCart={addToFastcheckoutCart}
          chosenProduct={chosenProduct}
          cartItemNumber={cartItemNumber}
          availableStock={availableStock}
          componentRenderLogics={componentRenderLogics}
        />
      );
    }
  };

  return renderLayout();
};

export default SocialCommercePage;
