import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { AdupUserProfile } from "../../interfaces/AdupUserProfile";
import { setUserProfile } from "../../store/reducers/userSlice";
import AnimatedTextFieldRegular from "../custom-input-field/AnimatedTextFieldRegular";
import { cssRedBorderClass } from "../constants";
import { useTranslation } from "react-i18next";
import {
  paymentProgressTypes,
  setIsRequiredFieldsNotFilled,
} from "../../store/reducers/paymentSlice";
import executeScrollOn from "../../lib/utils/executeScrollOn";
import { applicationUserType } from "../../store/reducers/appSlice";

/**
 * Account (profile data) form containing phone, name and address input fields
 */

interface Props {
  isCompactUserName?: boolean;
  isEmailReadonly?: boolean;
}
// export const AccountProfileForm = React.forwardRef((props, ref) => {
export const AccountProfileForm = ({
  isCompactUserName,
  isEmailReadonly = false,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // username associated with the phone number above
  const currentUserProfile: AdupUserProfile = useSelector(
    (state: RootState) => state.users.currentUser.profile
  );

  const loggedIn = useSelector(
    (state: RootState) => state.users.currentUser.phoneId
  );

  // check if wallet payment method is in use
  const isWalletPaymentMethodInUse = useSelector(
    (state: RootState) => state.payment?.isWalletPaymentMethodInUse
  );

  // user's auth state
  const authState = useSelector((state: RootState) => state.app.auth);

  const updateCurrentUserFirstName = (newName: string) => {
    dispatch(
      setUserProfile({
        ...currentUserProfile,
        name: { ...currentUserProfile.name, first: newName },
      })
    );
  };
  const updateCurrentUserSecondName = (newName: string) => {
    dispatch(
      setUserProfile({
        ...currentUserProfile,
        name: { ...currentUserProfile.name, second: newName },
      })
    );
  };
  const updateCurrentEmail = (newName: string) => {
    dispatch(
      setUserProfile({
        ...currentUserProfile,
        email: newName,
      })
    );
  };

  const paymentStatus = useSelector(
    (state: RootState) => state.payment.paymentProgress
  );

  const hasApiError = useSelector(
    (state: RootState) => state.payment.hasApiError
  ) as any;

  useEffect(() => {
    if (hasApiError && hasApiError?.email) setIsEmailInvalid(true);
  }, [hasApiError]);

  // for setting a class if the input field is incomplete
  const [namesIncomplete, setNamesIncomplete] = useState<{
    first: boolean | null;
    second: boolean | null;
    email: boolean | null;
  }>({ first: null, second: null, email: null });

  // for setting a class if the email input is invalid
  const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);

  // Validate inputs on submitting for payment
  useEffect(() => {
    if (paymentStatus === "PAYMENT_STARTED") {
      if (currentUserProfile.name.first === "") {
        setNamesIncomplete((namesIncomplete) => ({
          first: true,
          second: namesIncomplete.second,
          email: namesIncomplete.email,
        }));
      } else {
        setNamesIncomplete((namesIncomplete) => ({
          first: false,
          second: namesIncomplete.second,
          email: namesIncomplete.email,
        }));
      }

      if (currentUserProfile.name.second === "") {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: true,
          email: namesIncomplete.email,
        }));
      } else {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: false,
          email: namesIncomplete.email,
        }));
      }

      if (currentUserProfile.email === "") {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: namesIncomplete.second,
          email: true,
        }));
      } else {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: namesIncomplete.second,
          email: false,
        }));
      }
    }
    if (paymentStatus === paymentProgressTypes.PAYMENT_NOT_STARTED) {
      setNamesIncomplete({ first: null, second: null, email: null });
    }
  }, [
    paymentStatus,
    currentUserProfile.name?.first,
    currentUserProfile.name?.second,
    currentUserProfile.email,
  ]);

  useEffect(() => {
    if (
      (namesIncomplete.first ||
        namesIncomplete.second ||
        namesIncomplete.email ||
        isEmailInvalid) &&
      !(
        isWalletPaymentMethodInUse &&
        authState.user === applicationUserType.NEW_USER
      )
    ) {
      // executeScrollOnProfileError();
      executeScrollOn("profileDetailsFormRef001");
      dispatch(setIsRequiredFieldsNotFilled(true));
    } else {
      dispatch(setIsRequiredFieldsNotFilled(false));
    }
  }, [namesIncomplete, isEmailInvalid]);

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const renderUserNames = () => {
    return (
      <div className="account-form-compact">
        <div className="account-form-compact-left">
          <AnimatedTextFieldRegular
            placeholder={t("FirstName")}
            onChange={(e: any) =>
              updateCurrentUserFirstName((e.target as HTMLInputElement).value)
            }
            key={"fname"}
            name="first_name"
            value={currentUserProfile.name?.first}
            extraClasses={namesIncomplete.first ? cssRedBorderClass : ""}
          />
        </div>
        <div className="account-form-compact-right">
          <AnimatedTextFieldRegular
            placeholder={t("LastName")}
            onChange={(e: any) =>
              updateCurrentUserSecondName((e.target as HTMLInputElement).value)
            }
            key={"sname"}
            name="last_name"
            value={currentUserProfile.name?.second}
            extraClasses={namesIncomplete.second ? cssRedBorderClass : ""}
          />
        </div>
      </div>
    );
  };

  if (
    isWalletPaymentMethodInUse &&
    authState.user === applicationUserType.NEW_USER
  ) {
    return null;
  }

  return (
    <div
      data-account-form-reverse-is-email-readonly={
        isEmailReadonly ? "true" : "false"
      }
      className="account-form fast-checkout-page-segment"
    >
      <span>
        {renderUserNames()}
        <AnimatedTextFieldRegular
          readonly={isEmailReadonly}
          isLastInput={true}
          placeholder={
            t("Email") + (isEmailInvalid ? ` (${t("EmailInvalid")})` : "")
          }
          onChange={(e: any) => {
            if (validateEmail((e.target as HTMLInputElement).value)) {
              setIsEmailInvalid(false);
            } else {
              setIsEmailInvalid(true);
            }
            updateCurrentEmail((e.target as HTMLInputElement).value);
          }}
          key={"email"}
          name="email"
          value={currentUserProfile.email}
          extraClasses={
            namesIncomplete.email || isEmailInvalid ? cssRedBorderClass : ""
          }
        />
      </span>
    </div>
  );
};
