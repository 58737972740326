import store from "../../store/store";
import { setToastMessage } from "../../store/reducers/appSlice";
import { RequestInfo } from "../../interfaces/RequestInfo";
import { asyncHandler } from "./async-handler";
import { t } from "i18next";
import { ORDER_STATUS_API } from "../constants/central-api";

/**
 * @param { string } cartId The corresponding Cart ID
 */
export async function checkPaymentStatus(cartId: string) {
  const { token } = store.getState().users.currentUser;
  const lastCartUrl = store.getState().app.UI.lastCartUrl;
  const requestInfo: RequestInfo = {
    url: ORDER_STATUS_API(cartId),
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  const actionType = "GET_PAYMENT_STATUS_DATA";
  const customMsg = "Payment Status Data fetched Successfully.";

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    return response.data;
  } else {
    console.log("checkPaymentStatus API Error :");
    window.open(lastCartUrl, "_self");
    store.dispatch(
      setToastMessage({
        text: t("PaymentFailed"),
        type: "ERROR",
      })
    );
  }
}
