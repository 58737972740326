import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import store, { RootState } from "../../store/store";
import {
  setUserVerified,
  setUserWebAuthn,
  verificationStates,
} from "../../store/reducers/userSlice";
import { WebAuthnTypes } from "../../lib/webAuthn/detect-webAuthn-type";
import touchIdLogo from "../../assets/svg/webAuthn/Touch ID.svg";
import faceIdLogo from "../../assets/svg/webAuthn/Face ID.svg";
import androidFingerPrintLogo from "../../assets/svg/webAuthn/Android Fingerprint.svg";
import androidFaceUnlockLogo from "../../assets/svg/webAuthn/Android Face.svg";
import biometricsLogo from "../../assets/svg/webAuthn/Biometrics.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import {
  APP_ENV,
  WEBAUTHN_REGISTER_API,
} from "../../lib/constants/central-api";
import { useSelector } from "react-redux";
import { submitLogToBugsnag } from "../../lib/api/log";
import { isNotEmptyString } from "../../lib/utils/helper-functions";

declare const window: any;

interface RegisterWebAuthnProps {
  webAuthType: WebAuthnTypes;
  hideSegment: () => void;
}

const backdropOpacityAnimation = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
    delay: 0.5,
  },
  exit: {
    opacity: 0,
  },
};

const slideUpAnimation = {
  animate: {
    transform: "translateY(0px)",
  },
  initial: {
    transform: "translateY(1000px)",
  },
  exit: {
    transform: "translateY(1000px)",
  },
  transition: {
    delay: 0,
    duration: 0.5,
  },
};

export const RegisterWebAuthn: React.FC<RegisterWebAuthnProps> = ({
  webAuthType,
  hideSegment,
}) => {
  const { t } = useTranslation();

  const [verifyingWebAuthn, setVerifyingWebAuthn] = useState(false);

  const [failedWebAuthn, setFailedWebAuthn] = useState(false);

  const token = useSelector(
    (state: RootState) => state.users.currentUser.token
  );

  const currentState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  const merchantTheme = useSelector(
    (state: RootState) => state.users.merchant.config
  );

  const selfRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    selfRef.current.focus();
  }, []);

  async function triggerWebAuthn() {
    setFailedWebAuthn(false);
    setVerifyingWebAuthn(true);

    const registerUUID = uuidv4();

    const attestOptionsConfig = {
      path: `${WEBAUTHN_REGISTER_API}/options`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        phone_login: true,
        key: registerUUID,
      },
    };

    const attestConfig = {
      path: WEBAUTHN_REGISTER_API,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        key: registerUUID,
      },
    };

    try {
      console.log("WebAuthn : Registering...");

      const webpass = window.Webpass.create({
        rpId: APP_ENV === "LIVE" ? "adup.io" : "kodeia.com",
      });

      const { success }: any = await webpass.attest(
        attestOptionsConfig,
        attestConfig
      );

      if (success) {
        console.log("WebAuthn : Registering successful");

        // set the state to verified user
        store.dispatch(
          setUserVerified({
            ...currentState,
            state: verificationStates.verified,
            _: true,
            loggedInMethod: "webauthn",
            firstPayment: "pending",
          })
        );

        store.dispatch(setUserWebAuthn(true));
        console.log("WebAuthn : Registering successful!");

        backdropOpacityAnimation.transition = {
          ...backdropOpacityAnimation.transition,
          delay: 0.5,
        };
        slideUpAnimation.transition = {
          ...slideUpAnimation.transition,
          delay: 0,
        };
        hideSegment();
      } else {
        console.log("WebAuthn : Registering failed.");
        setFailedWebAuthn(true);

        backdropOpacityAnimation.transition = {
          ...backdropOpacityAnimation.transition,
          delay: 0.5,
        };
        slideUpAnimation.transition = {
          ...slideUpAnimation.transition,
          delay: 0,
        };
        hideSegment();
      }
    } catch (error) {
      console.log("WebAuthn : Registering failed.", error);
      submitLogToBugsnag(
        "error",
        `Error in webauthn register endpoint: ${error}`
      );

      setFailedWebAuthn(true);

      backdropOpacityAnimation.transition = {
        ...backdropOpacityAnimation.transition,
        delay: 0.5,
      };
      slideUpAnimation.transition = {
        ...slideUpAnimation.transition,
        delay: 0,
      };
      hideSegment();
    }

    setVerifyingWebAuthn(false);
  }

  return (
    <motion.div ref={selfRef} className="verify-phone-number" tabIndex={0}>
      <motion.div
        key="backdrop"
        className="backdrop"
        {...backdropOpacityAnimation}
      ></motion.div>
      <motion.div
        key="phonenumberprompt"
        {...slideUpAnimation}
        className="verify-phone-number-prompt"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <h1>
          {webAuthType} {t("Authentication")}
        </h1>
        <LazyLoadImage
          loading="lazy"
          src={
            webAuthType === "Face Id"
              ? faceIdLogo
              : webAuthType === "Touch Id"
              ? touchIdLogo
              : webAuthType === "Fingerprint"
              ? androidFingerPrintLogo
              : webAuthType === "Face Unlock"
              ? androidFaceUnlockLogo
              : webAuthType === "Biometrics"
              ? biometricsLogo
              : ""
          }
          width={webAuthType === "Biometrics" ? 200 : 100}
          style={{ margin: 20 }}
        ></LazyLoadImage>
        <p>
          {t("WebAuthnText-1", {
            webAuthType,
          })}
        </p>
        <p>
          {t("WebAuthnText-2")} <br />
          {isNotEmptyString(merchantTheme?.privacy_url) && (
            <div
              className="learn-more-link"
              onClick={() => {
                window.open(`${merchantTheme?.privacy_url}`, "_blank");
              }}
            >
              {t("LearnMore")}
            </div>
          )}
        </p>
        <div className="verification-methods">
          {failedWebAuthn && (
            <div
              style={{
                textAlign: "center",
                color: "red",
                paddingTop: "10px",
                fontSize: "15px",
              }}
            >
              {t("WebAuthnError")}
            </div>
          )}
          <div
            className="send-again-link"
            onClick={() => {
              if (!verifyingWebAuthn) {
                backdropOpacityAnimation.transition = {
                  ...backdropOpacityAnimation.transition,
                  delay: 0.5,
                };
                slideUpAnimation.transition = {
                  ...slideUpAnimation.transition,
                  delay: 0,
                };

                hideSegment();
              }
            }}
          >
            {t("RegisterLater")}
          </div>
          <button
            className={
              "btn-use-email" + (verifyingWebAuthn ? " lighten-button" : "")
            }
            onClick={() => {
              triggerWebAuthn();
            }}
            disabled={verifyingWebAuthn}
          >
            {verifyingWebAuthn ? (
              <div className="verifying-now-text">{t("Registering")}...</div>
            ) : failedWebAuthn ? (
              <div className="webAuthn-text"> {t("TryAgain")}</div>
            ) : (
              <div className="webAuthn-text">
                {t("Register")} {webAuthType}
              </div>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};
