import "../../sass/components/loadingSpinner.scss";
import spinner from "../../assets/json/lottie_animation/loading_animation.json";
import Lottie from "lottie-react";

interface Props {
  customLoadingMsg?: string;
}

const LoadingSpinner = ({ customLoadingMsg }: Props) => {
  return (
    <div className="loadingSpinner">
      <div className="loadingSpinner__lottie">
        <Lottie animationData={spinner} loop={true} />
      </div>
      {customLoadingMsg && (
        <div className="loadingSpinner__text">{customLoadingMsg}</div>
      )}
    </div>
  );
};

export default LoadingSpinner;
