import { debounce } from "lodash";
import store, { RootState } from "../../../store/store";
import { generateFastCheckoutUrlUsingCartItems } from "../../utils/helper-functions";
import { addToCart } from "../../api/cart-calls/add-to-cart";
import {
  initialPriceCalculations,
  setCartPriceCalculations,
  setFastcheckoutCartItems,
} from "../../../store/reducers/cartSlice";
import { AdupCartItem } from "../../../interfaces/AdupCartItem";
import {
  setIsAddToCartAPILoading,
  setIsAnyUnavailableStocksDetected,
  setLastAddToCartCallPayload,
} from "../../../store/reducers/paymentSlice";
import { submitLogToBugsnag } from "../../api/log";

/**
 * Intent should be re-fetched when the cart is edited
 */
function select(state: RootState) {
  return state.cart;
}

let currentCartItems: any = [];
function handleCartChange() {
  try {
    const route = window.decodeURI(window.location.pathname);

    const app_mode = route.split("/")[1];
    const shop_slug = route.split("/")[2];

    const isInitAPILoading = store.getState().payment?.isInitAPILoading;

    let previousCartItems = currentCartItems;
    currentCartItems = select(store.getState())?.items;

    if (previousCartItems !== currentCartItems) {
      console.log(
        "Cart Items changed from",
        previousCartItems,
        "to",
        currentCartItems
      );

      //* Update browser URL with updatedFastCheckoutUrl
      if (
        !isInitAPILoading &&
        currentCartItems &&
        currentCartItems.length > 0 &&
        app_mode === "ai-fast-checkout"
      ) {
        const updatedFastCheckoutUrl = `/${app_mode}/${shop_slug}/${generateFastCheckoutUrlUsingCartItems(
          currentCartItems
        )}`;
        console.log(
          "Updating browser URL with updatedFastCheckoutUrl : ",
          updatedFastCheckoutUrl
        );
        window.history.replaceState({}, "", `${updatedFastCheckoutUrl}`);
      }

      //* In order to persist the fast-checkout cart; copy items from cart to fastcheckoutCartItems while not in ai-full-checkout mode
      if (app_mode !== "ai-full-checkout") {
        console.log("Copying cart items to fastcheckoutCartItems");
        store.dispatch(setFastcheckoutCartItems(currentCartItems));
      }

      //* Checking unavailable stocks & disabling the pay button & payment methods
      const isAnyUnavailableStocksDetected =
        (currentCartItems &&
          currentCartItems?.reduce(
            (acc: boolean, item: AdupCartItem) =>
              acc || item.disabled === true || item.quantity > item.stock,
            false
          )) ||
        currentCartItems.length <= 0;
      if (isAnyUnavailableStocksDetected) {
        console.log(
          "!! Some items in the cart are out of stock : ",
          isAnyUnavailableStocksDetected
        );
      }
      store.dispatch(
        setIsAnyUnavailableStocksDetected(isAnyUnavailableStocksDetected)
      );

      //* Execute Add-To-Cart API call if cart is not empty
      if (currentCartItems && currentCartItems.length > 0) {
        store.dispatch(setIsAddToCartAPILoading(true));
        addToCartCallDebounce();
      } else {
        //* Set Price Calculations to initial state if cart is empty
        store.dispatch(setCartPriceCalculations(initialPriceCalculations));
        store.dispatch(setLastAddToCartCallPayload(undefined));
      }
    }
  } catch (error) {
    console.log("Error : ", error);
    submitLogToBugsnag("error", error);
  }
}
const addToCartCallDebounce = debounce(() => addToCart(), 400);

export const unsubscribeHandleCartChange = store.subscribe(handleCartChange);
// unsubscribe();
