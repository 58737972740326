import { useTranslation } from "react-i18next";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import xxlnutrition from "../../assets/png/merchants/xxlnutrition.png";
import buckaroo from "../../assets/png/merchants/buckaroo.png";
import paynl from "../../assets/png/merchants/paynl.png";
import browniebox from "../../assets/png/merchants/browniebox.png";
import convertcreator from "../../assets/png/merchants/convertcreator.png";

const RootPage = () => {
  const { t } = useTranslation();

  return (
    <React.Suspense fallback={<div className="max-width-container"></div>}>
      <div className="max-width-container">
        <div className="max-width-container__content">
          <div className="rootPage">
            <div className="rootPage__top">
              <h1>{t("SecureCheckoutMainText")}</h1>
              <h3>{t("SecureCheckoutSubText1")}</h3>
              <p>
                {t("SecureCheckoutSubText2")}{" "}
                <span
                  onClick={() => {
                    window.open("https://adup.io", "_blank");
                  }}
                >
                  AdUp
                </span>
                . {t("SecureCheckoutSubText3")}.
              </p>
              <span>{t("TrustedBy")}</span>
            </div>
            <div className="rootPage__center">
              <LazyLoadImage
                loading="lazy"
                src={xxlnutrition}
                alt={`xxlnutrition logo`}
              />
              <LazyLoadImage loading="lazy" src={paynl} alt={`paynl logo`} />
              <LazyLoadImage
                loading="lazy"
                src={convertcreator}
                alt={`convertcreator logo`}
              />
              <LazyLoadImage
                loading="lazy"
                src={buckaroo}
                alt={`buckaroo logo`}
              />
              <LazyLoadImage
                loading="lazy"
                src={browniebox}
                alt={`browniebox logo`}
              />
            </div>
            <div className="rootPage__bottom">
              <p>{t("SecureCheckoutFooterText1")}</p>
              <div className="rootPage__bottom--links">
                <span
                  onClick={() => {
                    window.open("https://adup.io/privacy", "_blank");
                  }}
                >
                  {t("PrivacyPolicy")}
                </span>
                <span
                  onClick={() => {
                    window.open("https://adup.io/cookiestatement", "_blank");
                  }}
                >
                  {t("CookiePolicy")}
                </span>
                <span
                  onClick={() => {
                    window.open("https://adup.io/terms", "_blank");
                  }}
                >
                  {t("TermsService")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Suspense>
  );
};

export default RootPage;
