import { t } from "i18next";
import { useState } from "react";
import { OTPCodeInputProfilePage } from "../otp-input/OTPCodeInputProfilePage";
import { sendOTPCodeProfilePage } from "../../lib/api/otp-methods";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import { deleteUserAccountRequest } from "../../lib/api/delete-user-account";
import { setToastMessage } from "../../store/reducers/appSlice";
import { shopperLogOut } from "../../lib/api/shopper-methods";
import { logOutUser } from "../../lib/state/clear-user-data";
import { submitLogToBugsnag } from "../../lib/api/log";

type Props = {
  onCancelClick: () => void;
};

const DeleteAccountMsg = ({ onCancelClick }: Props) => {
  const accountNumber = useSelector(
    (state: RootState) => state.users?.currentUser?.accountPhone
  );
  const slug = useSelector((state: RootState) => state.cart.shop.slug);

  const phoneNumber = accountNumber?.number;
  const countryCode = accountNumber?.country?.dialCode?.substring(1);

  // console.log(phoneNumber, countryCode);

  const [isOTPOpen, setIsOTPOpen] = useState(false);

  // As soon as the user clicks on 'Yes' delete account button, the OTP code is sent to the user's phone number
  const handleDeleteAccountClick = async () => {
    const res = await sendOTPCodeProfilePage(countryCode, phoneNumber);

    // If the OTP code is sent successfully, the OTP input field is displayed
    if (res) {
      setIsOTPOpen(true);
    }
  };

  // this function sends the OTP code to the server to delete the user account
  const deleteUserAccountAPICall = async (otpCode: string) => {
    try {
      const res = await deleteUserAccountRequest(otpCode);
      console.log("delete user function called with the code: ", otpCode);
      if (res) {
        store.dispatch(
          setToastMessage({
            text: t("UserAccountDeletedSuccess"),
            type: "SUCCESS",
          })
        );

        try {
          await shopperLogOut();
        } catch (error) {
          console.log("Error when revoking token", error);
          submitLogToBugsnag("error", error);
        }
        logOutUser();
        console.log("Account Deleted");
        window.open(`/${slug}/products`, "_self");
      }
    } catch (error) {
      console.log("Delete User Account API Error :", error);
      submitLogToBugsnag("error", `${t("DeleteUserAccountError")} : ${error}`);
      store.dispatch(
        setToastMessage({
          text: t("DeleteUserAccountError"),
          type: "ERROR",
        })
      );
    }
  };

  return (
    <>
      <div onClick={onCancelClick} className="deleteAddressMsg__overlay"></div>
      {isOTPOpen ? (
        <div className="deleteAddressMsg__wrapper column">
          <div className="deleteAddressMsg-title">
            <h1 style={{ marginBottom: "1rem" }}> {t("OTPSentToPhone")}</h1>
          </div>
          <OTPCodeInputProfilePage
            numberOfFields={4}
            onComplete={(code: string) => {
              console.log(code);

              // when the OTP code is entered, the user account is deleted
              deleteUserAccountAPICall(code);

              //close the OTP input field
              setIsOTPOpen(false);

              //close the modal
              onCancelClick();
            }}
          />
        </div>
      ) : (
        <div className="deleteAddressMsg__wrapper column">
          <div className="deleteAddressMsg-title">
            <h1> {t("DeleteThisAccount")}</h1>
          </div>
          <div className="deleteAddressMsg flex-column">
            <div className="textContent-top">{`${t("AreYouSure")} ${t(
              "DeleteThisAccount"
            )}?`}</div>
            <div className="textContent-bottom">
              {t("DeleteThisAccountInfo")}
            </div>
            <div className="buttonContainer">
              <div
                onClick={() => {
                  handleDeleteAccountClick();
                }}
                style={{ backgroundColor: "#e02020" }}
                className="btn1"
              >
                {t("Yes")}
              </div>
              <div onClick={onCancelClick} className="btn2">
                {t("Cancel")}
              </div>
            </div>
          </div>
        </div>
      )}
      <div />
    </>
  );
};

export default DeleteAccountMsg;
