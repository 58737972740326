import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  AdupUserAddress,
  EmptyAdupUserAddress,
} from "../../interfaces/AdupUserAddress";
import store, { RootState } from "../../store/store";
import AutocompleteAddressField from "../autocomplete-address-field/AutocompleteAddressField";
import AddressField from "../manual-address-field/AddressField";
import RoundButton from "../round-button/RoundButton";
import { setIsRequiredFieldsNotFilled } from "../../store/reducers/paymentSlice";
import { arrowHeadDownSVG } from "../../assets/js/allCustomSVGs";
import executeScrollOn from "../../lib/utils/executeScrollOn";
import { GOOGLE_MAPS_API_KEY } from "../../lib/constants/central-api";
import AddressCountrySelector from "../manual-address-field/AddressCountrySelector";
import { applicationUserType } from "../../store/reducers/appSlice";
import { motion } from "framer-motion";
import CustomSVGs from "../custom-svgs/CustomSVGs";

type Props = {
  changeListeners: ((address: AdupUserAddress) => void)[];
  addressType: string;
};

const AddressFormWithSearch: React.FC<Props> = ({
  changeListeners,
  addressType,
}) => {
  const { t } = useTranslation();
  const paymentStatus = useSelector(
    (state: RootState) => state.payment.paymentProgress
  );

  const countries = useSelector(
    (state: RootState) => state.app.appData.countries
  );

  const shippingAddress = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.shippingAddress
  );

  const billingAddress = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.billingAddress
  );

  // check if wallet payment method is in use
  const isWalletPaymentMethodInUse = useSelector(
    (state: RootState) => state.payment?.isWalletPaymentMethodInUse
  );

  // user's auth state
  const authState = useSelector((state: RootState) => state.app.auth);
  const isFieldRequired = useSelector(
    (state: RootState) => state.payment.isRequiredFieldsNotFilled
  );

  const [autocompleteEnabled, setAutocompleteEnabled] = useState(true);

  const [customAddress, setCustomAddress] = useState(EmptyAdupUserAddress);

  // console.log(customAddress);

  const [isEditingManually, setIsEditingManually] = useState(false);

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  useEffect(() => {
    const requiredFieldsFilled = Boolean(
      (shippingAddress?.number &&
        shippingAddress?.number !== "" &&
        shippingAddress?.street &&
        shippingAddress?.street !== "" &&
        shippingAddress?.city &&
        shippingAddress?.city !== "" &&
        shippingAddress?.province &&
        shippingAddress?.province !== "" &&
        shippingAddress?.postcode &&
        shippingAddress?.postcode !== "" &&
        shippingAddress?.country_name &&
        shippingAddress?.country_name !== "") ||
        (customAddress?.number &&
          customAddress?.number !== "" &&
          customAddress?.street &&
          customAddress?.street !== "" &&
          customAddress?.city &&
          customAddress?.city !== "" &&
          customAddress?.province &&
          customAddress?.province !== "" &&
          customAddress?.postcode &&
          customAddress?.postcode !== "" &&
          customAddress?.country_name &&
          customAddress?.country_name !== "")
    );
    if (
      !requiredFieldsFilled &&
      paymentStatus === "PAYMENT_STARTED" &&
      !(
        isWalletPaymentMethodInUse &&
        authState.user === applicationUserType.NEW_USER
      )
    ) {
      setIsFormValid(false);
      store.dispatch(setIsRequiredFieldsNotFilled(true));
      // executeScrollOnProfileError();
      executeScrollOn("profileDetailsFormRef001");
    } else if (requiredFieldsFilled) {
      setIsFormValid(true);
      store.dispatch(setIsRequiredFieldsNotFilled(false));
    }
  }, [paymentStatus, shippingAddress]);

  function checkManualFields(address: AdupUserAddress) {
    if (
      address.city === "" ||
      address.country_name === "" ||
      address.number === "" ||
      address.postcode === "" ||
      address.province === "" ||
      address.street === ""
    ) {
      setIsEditingManually(true);
    }
    setCustomAddress(address);
  }

  useEffect(() => {
    for (const listener of changeListeners) listener(customAddress);
  }, [customAddress]);

  if (
    isWalletPaymentMethodInUse &&
    authState.user === applicationUserType.NEW_USER
  ) {
    return null;
  }

  return (
    <div
      data-is-address-expanded={isEditingManually?.toString()}
      data-is-address-valid={isFormValid?.toString()}
      className="address-form-with-search"
    >
      <AutocompleteAddressField
        defaultValue={
          addressType === "shipping" ? shippingAddress : billingAddress
        }
        classes={
          !isFormValid && isEditingManually && isFieldRequired
            ? "plain-address-field " + "custom-input-red-border-bottom"
            : "plain-address-field "
        }
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        inputEnabled={autocompleteEnabled}
        inputId={"address-form-with-search-autocomplete-input"}
        title={t(
          addressType === "shipping"
            ? "ShippingAddressTitle"
            : "BillingAddressTitle"
        )}
        changeListeners={[...changeListeners, checkManualFields]}
      />

      <div
        onClick={() => setIsEditingManually(!isEditingManually)}
        className="address-search-arrow-svg"
        data-address-search-arrow-svg={isEditingManually ? "true" : "false"}
      >
        <CustomSVGs svg={arrowHeadDownSVG} />
      </div>

      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: isEditingManually ? "auto" : 0,
          opacity: isEditingManually ? 1 : 0,
        }}
        transition={{ duration: 0.3 }}
        style={{ overflow: "hidden" }}
      >
        <Fragment>
          <AddressCountrySelector
            id="address-form-country-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                country_code: e.target.value ? e.target.value : "",
                country_name: e.target.value
                  ? countries[e.target.value]?.name
                  : "",
              }))
            }
            placeHolder={t("Country")}
            value={customAddress.country_code}
            required={true}
          />
          <AddressField
            id="address-form-city-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                city: e.target.value,
              }))
            }
            placeHolder={t("City")}
            value={customAddress.city}
            required={true}
          />
          <AddressField
            id="address-form-province-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                province: e.target.value,
              }))
            }
            placeHolder={t("Province")}
            value={customAddress.province}
            required={true}
          />
          <AddressField
            id="address-form-postcode-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                postcode: e.target.value,
              }))
            }
            placeHolder={t("PostalCode")}
            value={customAddress.postcode}
            required={true}
          />
          <AddressField
            id="address-form-street-1-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                street: e.target.value,
              }))
            }
            placeHolder={t("Street")}
            value={customAddress.street}
            required={true}
          />
          <AddressField
            id="address-form-street-2-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                street2: e.target.value,
              }))
            }
            placeHolder={t("OtherStreet")}
            value={customAddress.street2}
            required={false}
          />
          <AddressField
            id="address-form-number-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                number: e.target.value,
              }))
            }
            placeHolder={t("HouseNumber")}
            value={customAddress.number}
            required={true}
          />
        </Fragment>
      </motion.div>
    </div>
  );
};

export default AddressFormWithSearch;
