import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import "../../sass/components/socialCommerce/imageSliderSC.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setUIStatistics } from "../../store/reducers/statisticsSlice";

interface Props {
  imageArray: string[];
  isDemoActive?: boolean;
}

const ImageSliderSC = ({ imageArray, isDemoActive }: Props) => {
  const { appData } = useSelector((state: RootState) => state.app) as any;
  const imageStaticUrl = appData?.static_base_url;
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );
  const uiStatistics: any = useSelector(
    (state: RootState) => state.statistics.ui
  );
  const dispatch = useDispatch();
  const imageRef = useRef<HTMLImageElement>(null); // Specify the type of the ref

  /*  useEffect(() => {
    if (imageRef.current) {
      const width = imageRef.current.offsetWidth;
      const height = imageRef.current.offsetHeight;

      if (width !== undefined && height !== undefined) {
        console.log("saving image size stat");
        const updatedUiStats = {
          ...uiStatistics,
          imageSize: `width: ${width}px, height: ${height}px`,
        };
        dispatch(setUIStatistics(updatedUiStats));
      }
    }
  }, [dispatch]); */

  let trimmedImageArray: string[];

  if (imageArray?.length > 6) {
    trimmedImageArray = imageArray.slice(0, 6);
  } else {
    trimmedImageArray = imageArray;
  }

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const swipeConfidenceThreshold = 1000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, trimmedImageArray?.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const renderImage = () => {
    if (trimmedImageArray && trimmedImageArray.length === 1) {
      return (
        // <img
        //   className={
        //     !isImgLoaded ? "imageSliderSC__container--placeholder" : ""
        //   }
        //   onLoad={() => {
        //     setIsImgLoaded(true);
        //   }}
        //   onError={() => {
        //     setIsImgLoaded(true);
        //   }}
        //   loading="lazy"
        //   alt="variants pic"
        //   src={
        //     isDemoActive
        //       ? trimmedImageArray[imageIndex]
        //       : `${imageStaticUrl}/medium/${trimmedImageArray[imageIndex]}`
        //   }
        // />

        <LazyLoadImage
          className={
            !isImgLoaded ? "imageSliderSC__container--placeholder" : ""
          }
          onLoad={() => {
            setIsImgLoaded(true);
          }}
          onError={() => {
            setIsImgLoaded(true);
          }}
          loading="lazy"
          alt="variants pic"
          src={trimmedImageArray[imageIndex]}
        />
      );
    } else {
      return (
        <AnimatePresence initial={false} custom={direction}>
          <motion.img
            key={page}
            loading="lazy"
            ref={imageRef}
            src={
              imageIndex.toString() !== "NaN"
                ? trimmedImageArray[imageIndex]
                : ""
            }
            custom={direction}
            whileTap={{ cursor: "grabbing" }}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "keyframes", stiffness: 30, damping: 1 },
              // x: { type: "spring", stiffness: 300, damping: 30 },
              // opacity: { duration: 0.2 },
              opacity: { duration: 0.1 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            // dragElastic={4}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          />
        </AnimatePresence>
      );
    }
  };

  return (
    <div className="imageSliderSC">
      <div
        data-mobile-slider-ratio={settings?.product_image_ratio}
        className="imageSliderSC__container"
      >
        <>{renderImage()}</>
        {trimmedImageArray?.length > 1 && (
          <>
            <div className="imageSliderSC__container--buttonHolder">
              {trimmedImageArray.map((img: string, i: number) => {
                return (
                  <span
                    data-image-slider-button={
                      i === imageIndex ? "true" : "false"
                    }
                    /*  onClick={() => {
                      setCurrentImg(i);
                    }} */
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageSliderSC;
