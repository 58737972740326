import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserVerified,
  verificationStates,
} from "../../../store/reducers/userSlice";
import { RootState } from "../../../store/store";
import { VerifyPhone } from "../../../components/verify-phone/VerifyPhone";
import { detectWebAuthnType } from "../../../lib/webAuthn/detect-webAuthn-type";
import { setIsOtpOverlayVisible } from "../../../store/reducers/appSlice";
import { LoginWebAuthn } from "../../../components/login-webauthn/LoginWebAuthn";

declare const window: any;

const FastCheckoutPageOTPSegment = () => {
  const userData = useSelector((state: RootState) => state.users.currentUser);
  const [isWebAuthnClosed, setIsWebAuthnClosed] = useState<Boolean>();
  const [isVerifyUsingSMS, setIsVerifyUsingSMS] = useState<Boolean>();
  const dispatch = useDispatch();
  const webAuthnType = detectWebAuthnType();
  const webauthn = useSelector(
    (state: RootState) => state.users.currentUser.webauthn
  );
  return (
    <div className="verify-phone-segment">
      {(!window.Webpass.isSupported() || !webauthn || isVerifyUsingSMS) && (
        <VerifyPhone
          hideSegment={() => {
            dispatch(setIsOtpOverlayVisible(false));
            dispatch(
              setUserVerified({
                ...userData.verified,
                state: verificationStates.pending,
                firstPayment: "pending",
              })
            );
          }}
        />
      )}
      {window.Webpass.isSupported() && webauthn && !isWebAuthnClosed && (
        <LoginWebAuthn
          hideSegment={() => {
            setIsWebAuthnClosed(true);
            setIsVerifyUsingSMS(true);
          }}
          webAuthType={webAuthnType}
        />
      )}
    </div>
  );
};

export default FastCheckoutPageOTPSegment;
