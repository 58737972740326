import SingleProductSC from "../../../components/socialCommerce/SingleProductSC";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";
import FastCheckoutPageWrapper from "../../../components/page-wrappers/FastCheckoutPageWrapper";
import TestWidgets from "../../../components/widgets/TestWidgets";
import Header from "../../../components/header/Header";
import IntroBannerSC from "../../../components/socialCommerce/IntroBannerSC";
import LinkBannerSC from "../../../components/socialCommerce/LinkBannerSC";
import CustomMessageBanner from "../../../components/custom-message-banner/CustomMessageBanner";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";

interface productsInterface {
  image: string;
  name: string;
  priceFormatted: string;
  priceNumeric: number;
  cartItemsNum: number;
  currency: string;
  sku: string;
  type: string;
  id: string | number;
  disabled: boolean;
}

type Props = {
  allProducts: any;
  isMoreProductsLoading: boolean;
  isShowMoreAvailable: boolean;
  addToFastcheckoutCart: (item: any) => void;
  fetchAllProducts: () => void;
  componentRenderLogics: (componentType: string) => boolean | undefined;
};

const OverviewPageMobileLayout1 = ({
  allProducts,
  isMoreProductsLoading,
  isShowMoreAvailable,
  addToFastcheckoutCart,
  fetchAllProducts,
  componentRenderLogics,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { appData, UI } = useSelector((state: RootState) => state.app) as any;
  const imageStaticUrl = appData?.static_base_url;
  const shop: any = useSelector((state: RootState) => state.cart.shop);
  const widgets = useSelector(
    (state: RootState) => state.users.merchant.widgets
  );

  {
    return (
      <React.Suspense fallback={<div className="socialCommerce"></div>}>
        <Header />
        <div className="socialCommerce">
          <div className="max-width-container">
            <div className="max-width-container__content">
              <IntroBannerSC />
              {componentRenderLogics("customMessageBanner") && (
                <CustomMessageBanner />
              )}
              {widgets.includes("test") && <TestWidgets />}
              {
                <>
                  {componentRenderLogics("overviewPageTitle") && (
                    <LinkBannerSC
                      onClick={() => {
                        navigate(`${UI?.lastCartUrl}`);
                      }}
                      title={`${t("OverviewPageTitle")}`}
                    />
                  )}
                  {componentRenderLogics("singleProduct") ? (
                    <>
                      <div className="socialCommerce__productsHolder">
                        {allProducts.map(
                          (item: productsInterface, i: number) => {
                            return (
                              <SingleProductSC
                                name={item.name}
                                price={item.priceFormatted}
                                type={item.type}
                                image={item.image}
                                cartItemsNum={item.cartItemsNum}
                                disabled={item?.disabled}
                                onClick={(e: any) => {
                                  if (e.ctrlKey || e.metaKey) {
                                    window.open(
                                      `/ai-full-checkout/${shop.slug}/${item?.sku}`,
                                      "_blank"
                                    );
                                  } else {
                                    navigate(
                                      `/ai-full-checkout/${shop.slug}/${item?.sku}`
                                    );
                                  }
                                }}
                                onAddToCartClick={() => {
                                  addToFastcheckoutCart(item);
                                }}
                              />
                            );
                          }
                        )}
                      </div>
                    </>
                  ) : componentRenderLogics("noMoreProductsLoading") ? (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: "red",
                        paddingTop: "2vh",
                      }}
                    >
                      <span> {`${shop?.name} ` + t("NoProducts")}</span>
                    </div>
                  ) : null}

                  {isMoreProductsLoading && <LoadingSpinner />}

                  {isShowMoreAvailable && !isMoreProductsLoading && (
                    <p
                      className={"socialCommerce__browseMoreLink"}
                      style={{ marginBottom: 0 }}
                      onClick={() => {
                        fetchAllProducts();
                      }}
                    >
                      {t("ShowMore")}
                    </p>
                  )}
                </>
              }
            </div>
          </div>
        </div>
        {componentRenderLogics("fastCheckoutPageWrapper") && (
          <FastCheckoutPageWrapper showHeader={false} />
        )}
      </React.Suspense>
    );
  }
};

export default OverviewPageMobileLayout1;
