import { t } from "i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { isNotEmptyString } from "../../lib/utils/helper-functions";

const PoweredByAdUp = () => {
  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );

  const imgURL = isNotEmptyString(merchantTheme?.merchant_icon)
    ? `${imageStaticUrl}/medium/${merchantTheme?.merchant_icon}`
    : undefined;

  return (
    <div className="poweredByAdUp">
      <div className="poweredByAdUp__left">
        {imgURL && (
          <LazyLoadImage
            loading="lazy"
            src={imgURL}
            alt={`${merchantTheme?.merchant_name} logo`}
          />
        )}
      </div>
      <div className="poweredByAdUp__right">
        <span> {t("PoweredBy")}</span>
        <h3>{merchantTheme?.merchant_name}</h3>
      </div>
    </div>
  );
};

export default PoweredByAdUp;
