/**
 * A saved address of an ADUP user
 */
export interface AdupUserAddress {
  id: string;
  number: string;
  street: string;
  street2: string;
  city: string;
  province: string;
  country_name: string;
  country_code: string;
  postcode: string;
  country?: CountryObject;
  shipping_rate?: ShippingRate;
}

export interface ShippingRate {
  type: "flat" | "percentage" | null;
  value: number | null;
  default_shipping_cost?: number;
}

export interface CountryObject {
  isEU: boolean;
  iso2: string;
  iso3: string;
  name: string;
  phone_code: string;
  currency_code: string;
}

/**
 * Empty Adup User Address
 */
export const EmptyAdupUserAddress: AdupUserAddress = {
  id: "",
  number: "",
  street: "",
  street2: "",
  city: "",
  province: "",
  country_name: "",
  country_code: "",
  postcode: "",
  country: {
    isEU: false,
    iso2: "",
    iso3: "",
    name: "",
    phone_code: "",
    currency_code: "",
  },
  shipping_rate: {
    type: null,
    value: null,
  },
};
