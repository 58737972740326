import React from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const FastCheckoutSinglePageWrapper: React.FC = ({ children }) => {
  const isOtpOverlayVisible = useSelector(
    (state: RootState) => state.app.UI?.isOtpOverlayVisible
  );

  const settings: any = useSelector(
    (state: RootState) => state.cart?.shop?.settings
  );

  return (
    <motion.div
      transition={{ duration: 3 }}
      data-checkout-bg-color={
        settings?.checkout_background_color ? "true" : "false"
      }
      className={
        "fast-checkout-page" +
        (isOtpOverlayVisible ? " overflow-hidden-for-scroll" : "")
      }
    >
      {children}
    </motion.div>
  );
};

export default FastCheckoutSinglePageWrapper;
