import store from "../../../../store/store";

export async function blikPayFunction() {
  const paymentState = store.getState().payment;

  const selectedPaymentMethod = paymentState.paymentMethodSelected;

  if (
    selectedPaymentMethod.serviceProvider !== "stripe" ||
    selectedPaymentMethod?.name !== "blik"
  )
    return;

  // const stripe = await loadStripe(selectedPaymentMethod?.data?.public_key);

  //! confirmBlikPayment not found in Stripe.js 🤔
  // stripe
  //   ?.confirmBlikPayment(paymentState.stripe.clientSecret, {
  //     payment_method: {
  //       blik: {},
  //     },
  //     payment_method_options: {
  //       blik: {
  //         code,
  //       },
  //     },
  //     // Return URL where the customer should be redirected after the authorization.
  //     // return_url: paymentState.stripe.returnURL,
  //   })
  //   .then(function (result) {
  //     if (result.error) {
  //       // Inform the customer that there was an error.
  //       console.error(result.error);
  //       store.dispatch(
  //         setToastMessage({
  //           text: result.error?.message
  //             ? result.error?.message
  //             : "An unexpected error occurred.",
  //           type: "ERROR",
  //         })
  //       );
  //     } else {
  //       alert("success");
  //     }
  //   })
  //   .catch((e) => {
  //     console.log(e);
  //   });
}
