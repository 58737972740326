import store from "../../../store/store";
import { RequestInfo } from "../../../interfaces/RequestInfo";
import { asyncHandler } from "../async-handler";
import {
  ORDER_PROCESS_API,
  ORDER_SESSION_API,
} from "../../constants/central-api";

export interface OrderProcessBody {
  cart_id: string;
  shop_slug: string;
  payment_method: string;
  payment_attributes: any;
  payment_details: any;
  shopper: Shopper;
  create_account: boolean | undefined;
}

export interface OrderSessionBody {
  cart_id: string;
  shop_slug: string;
  payment_method: string;
  payment_attributes?: any;
  payment_details?: any;
  shopper?: Shopper;
}

export interface Shopper {
  country_code: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  email: string;
  shipping_address?: Address;
  shipping_address_id?: string;
  billing_address?: Address;
  billing_address_id?: string;
}

export interface Address {
  house_number: string;
  street: string;
  street2: string;
  city: string;
  province: string;
  postcode: string;
  country: string;
  country_code: string;
}

/**
 * @param { OrderProcessBody } orderProcessBody The corresponding Order Process Body
 */

export async function orderProcessCall(orderProcessBody: OrderProcessBody) {
  const key = store.getState().users?.currentUser?.token; // current user token

  const requestInfo: RequestInfo = {
    url: `${ORDER_PROCESS_API}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: key ? `Bearer ${key}` : "",
    },
    body: JSON.stringify(orderProcessBody),
  };

  const actionType = "ORDER_PROCESS";
  const customMsg = "Order Process fetched Successfully.";

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    console.log(response);
    return response;
  } else {
    console.log("Order Process fetching failed : ", response?.message);
    return response;
  }
}

/**
 * @param { OrderSessionBody } orderSessionBody The corresponding Order Session Body
 */

export async function orderSessionCall(orderSessionBody: OrderSessionBody) {
  const key = store.getState().users?.currentUser?.token; // current user token

  const requestInfo: RequestInfo = {
    url: `${ORDER_SESSION_API}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: key ? `Bearer ${key}` : "",
    },
    body: JSON.stringify(orderSessionBody),
  };

  const actionType = "ORDER_SESSION";
  const customMsg = "Order Session fetched Successfully.";

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    console.log(response);
    return response;
  } else {
    console.log("Order Session fetching failed : ", response?.message);
    return response;
  }
}
