import { AdupUserAddress } from "../../../interfaces/AdupUserAddress";
import { deleteUserSavedAddress } from "../../../store/reducers/userSlice";
import store from "../../../store/store";
/* ================================================================================== */
import { RequestInfo } from "../../../interfaces/RequestInfo";
import { DELETE_ADDRESS_API } from "../../constants/central-api";
import { asyncHandler } from "../async-handler";
import { t } from "i18next";

export async function deleteUserAddress(
  address: AdupUserAddress
): Promise<boolean> {
  // send request
  const is_deleted = await deleteUserAddressRequest(address);

  // save state
  if (is_deleted) {
    store.dispatch(deleteUserSavedAddress(address));
    return true;
  }

  return false;
}

/**
 * @param { AdupUserAddress } address - address object
 */
export async function deleteUserAddressRequest(address: AdupUserAddress) {
  const key = store.getState().users.currentUser.token; // current user token

  const requestInfo: RequestInfo = {
    url: DELETE_ADDRESS_API,
    method: "DELETE",
    body: JSON.stringify({
      address_id: address.id,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: key ? `Bearer ${key}` : "",
    },
  };

  const actionType = "DELETE_USER_ADDRESS";
  const customMsg = t("AddressDeletedSuccessfully");

  const res: any = await asyncHandler(requestInfo, actionType, customMsg, true);

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    const is_deleted = response.success;

    return is_deleted;
  } else {
    return null;
  }
}
