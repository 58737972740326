import React, { useEffect, useRef, useState } from "react";
import { CartItem, CartItemLoading } from "../cart-item/CartItem";
import { AnimatePresence, motion } from "framer-motion";

interface CartViewProps {
  items: any[];
  stacked?: boolean;
  onExpand: ((e: boolean) => void)[];
  defaultExpanded: boolean;
  inEditMode: boolean;
  isDeletePopupOpen: boolean;
  setIsDeletePopupOpen: (para: boolean) => void;
  setDeleteItemId: (para: any) => void;
}

export const CartView: React.FC<CartViewProps> = ({
  items,
  stacked = true,
  onExpand,
  defaultExpanded,
  inEditMode,
  isDeletePopupOpen,
  setIsDeletePopupOpen,
  setDeleteItemId,
}) => {
  const [removed, setRemoved] = useState<number[]>([]);
  const [expanded, setExpanded] = useState(!stacked);

  const loaded = useRef(false);
  const loadedItems = useRef(0);

  useEffect(() => {
    if (defaultExpanded && items.length > 1) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [defaultExpanded]);

  useEffect(() => {
    if (loadedItems.current < items.length) {
      loadedItems.current = items.length;
    } else {
      loaded.current = true;
    }
  }, [items.length]);

  useEffect(() => {
    setExpanded((_) => inEditMode);
  }, [inEditMode]);

  useEffect(() => {
    for (const listener of onExpand) listener(expanded);
  }, [expanded]);

  useEffect(() => {
    if (items.length < 2) setExpanded(false);
  }, [items]);

  if (items.length === 0 && !loaded.current) {
    return <CartItemLoading />;
  }

  const reShuffle = (index: number) => {
    setRemoved((val) => removed.concat(index));
  };

  if (expanded && items.length > 1) {
    return (
      <div style={{ width: "100%", zIndex: -4 }}>
        {/* <div
          onClick={() => setExpanded(false)}
          className={"cart-backdrop" + (items.length > 1 ? "-cover" : "")}
        ></div> */}

        <motion.div className={"cart-view-list"}>
          <AnimatePresence>
            {items &&
              items.map((item, key) =>
                generateCartViewListItem(
                  item,
                  item.id,
                  reShuffle,
                  expanded || items.length === 1,
                  isDeletePopupOpen,
                  setIsDeletePopupOpen,
                  setDeleteItemId,
                  inEditMode && items.length > 1
                )
              )}
          </AnimatePresence>
        </motion.div>
      </div>
    );
  }

  return (
    <motion.div onClick={() => setExpanded(true)} className={"cart-view"}>
      <AnimatePresence>
        {items &&
          items.map((item, key) =>
            generateCartStackItem(
              item,
              key,
              expanded || items.length === 1,
              isDeletePopupOpen,
              setIsDeletePopupOpen,
              setDeleteItemId
            )
          )}
      </AnimatePresence>
    </motion.div>
  );
};

/**
 * @param { any } itemProps
 * @param { number } idx
 * @param { Function } shuffle
 * @param { boolean } isExpanded
 * @param { boolean } selectable
 */
function generateCartViewListItem(
  itemProps: any,
  idx: number,
  shuffle: (index: number) => void,
  isExpanded: boolean,
  isDeletePopupOpen: boolean,
  setIsDeletePopupOpen: (para: boolean) => void,
  setDeleteItemId: (para: any) => void,
  selectable?: boolean
): JSX.Element | null {
  const anim = {
    width: "100%",
  };

  const init = { width: "100%" };
  return (
    <CartItem
      {...itemProps}
      key={idx}
      transition={{ duration: 0.4 }}
      anim={anim}
      init={init}
      exit={{ transform: "translateX(-900px)" }}
      showSelector={selectable}
      index={idx}
      isExpanded={isExpanded}
      isDeletePopupOpen={isDeletePopupOpen}
      setIsDeletePopupOpen={setIsDeletePopupOpen}
      setDeleteItemId={setDeleteItemId}
    />
  );
}

/**
 * @param { any } itemProps
 * @param { number } idx
 * @param { boolean } isExpanded
 * @return {JSX.Element}
 */
function generateCartStackItem(
  itemProps: any,
  idx: number,
  isExpanded: boolean,
  isDeletePopupOpen: boolean,
  setIsDeletePopupOpen: (para: boolean) => void,
  setDeleteItemId: (para: any) => void
): JSX.Element {
  let opacity;
  let width = "";

  const properties: { [index: number]: any } = {
    0: {
      opacity: 1,
      relativeWidth: 1,
    },
    1: {
      opacity: 0.5,
      relativeWidth: 0.975,
    },
    2: {
      opacity: 0.35,
      relativeWidth: 0.95,
    },
  };

  if (idx in properties) {
    opacity = properties[idx].opacity;
    width = 100 * properties[idx].relativeWidth + "%";
  }

  if (idx > 2) opacity = 0;

  const anim = {
    top: idx * 8,
    left: idx * 2.5,
    width,
    opacity,
  };

  return (
    <CartItem
      {...itemProps}
      key={idx}
      transition={{ duration: 0.35 }}
      anim={anim}
      init={{ width: "100%" }}
      exit={{ top: 0, transform: "translateX(-900px)", zIndex: 30 }}
      index={idx}
      isExpanded={isExpanded}
      isDeletePopupOpen={isDeletePopupOpen}
      setIsDeletePopupOpen={setIsDeletePopupOpen}
      setDeleteItemId={setDeleteItemId}
    />
  );
}

export const MotionCartView = motion(CartView, { forwardMotionProps: true });
