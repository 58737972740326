import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import "../../sass/components/_payment-methods.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getAllPaymentMethodsAsArray from "../../lib/utils/get-all-payment-methods-array";
import { handleNewPaymentMethodSelection } from "../../lib/payment-handlers/payment-methods/method-selection";
import {
  clearPaymentMethodSelected,
  setIsPaymentMethodEditable,
  setIsWalletPaymentMethodInUse,
  setPaymentMethodLastUsed,
  setPaymentMethodSelected,
  setPaymentProgress,
} from "../../store/reducers/paymentSlice";
import { AnimatedLoadingCircle } from "../../assets/svg/animated-svg-components/AnimatedLoadingCircle";
import { useLocation } from "react-router-dom";
import { buildNewPaymentMethodIcon } from "../new-payment-method/NewPaymentMethod";
import { LazyLoadImage } from "react-lazy-load-image-component";
import executeScrollOn from "../../lib/utils/executeScrollOn";
import HeadingExpandable from "../heading/HeadingExpandable";
import {
  PaymentMethodsHeadingSVG,
  editSVG,
} from "../../assets/js/allCustomSVGs";
import CustomSVGs from "../custom-svgs/CustomSVGs";

const cardNumberFormat = (cardNumber: string) => {
  return "**** " + cardNumber.substring(cardNumber.length - 5);
};

export const PaymentMethodsView: React.FC<{}> = ({}: any) => {
  const route = window.decodeURI(useLocation().pathname);
  const paymentData = useSelector((state: RootState) => state.payment);
  const shopName: any = useSelector(
    (state: RootState) => state.cart?.shop?.name
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app?.appData?.static_base_url
  );

  const app_layout: any = useSelector(
    (state: RootState) => state.cart.shop.theme?.app_layout
  );
  const dispatch = useDispatch();
  const [isWalletMethodAvailable, setIsWalletMethodAvailable] = useState(false);
  const [isPaymentMethodChanged, setIsPaymentMethodChanged] = useState(false);

  const { t } = useTranslation();

  const [newPaymentMethods, setNewPaymentMethods] = useState<any>(
    getAllPaymentMethodsAsArray()
  );

  const onNewPaymentMethodClick = (method: any) => {
    if (route !== "/demo/social-commerce")
      dispatch(setPaymentProgress("PAYMENT_STARTED"));
    handleNewPaymentMethodSelection(method);
  };

  const isPaymentMethodsLoading = paymentData?.isPaymentMethodsLoading;

  useEffect(() => {
    setNewPaymentMethods(getAllPaymentMethodsAsArray());
  }, [paymentData?.allPaymentMethods]);

  useEffect(() => {
    if (
      !(
        newPaymentMethods &&
        newPaymentMethods.find((item: any) => item.name === "google_pay") ===
          undefined
      ) ||
      !(
        newPaymentMethods &&
        newPaymentMethods.find((item: any) => item.name === "apple_pay") ===
          undefined
      )
    ) {
      setIsWalletMethodAvailable(true);
    } else {
      setIsWalletMethodAvailable(false);
    }
  }, [paymentData.allPaymentMethods]);

  const renderPaymentMethods = (showTitle: boolean) => {
    return (
      <div className="payment-methods fast-checkout-page-segment">
        {showTitle && (
          <div className="payment-methods-heading">
            <CustomSVGs svg={PaymentMethodsHeadingSVG} />
            <h2 className="heading-2-regular fast-checkout-segment-heading">
              {t("PaymentMethods")}
            </h2>
          </div>
        )}
        {isPaymentMethodsLoading && (
          <>
            <span
              style={{
                height: "25px",
                width: "90px",
                alignSelf: "center",
                paddingLeft: 30,
                paddingTop: 20,
              }}
            >
              <AnimatedLoadingCircle />
            </span>
            <div style={{ textAlign: "center", paddingTop: 20 }}>
              <span> {t("PaymentMethodsLoading")}</span>
            </div>
          </>
        )}
        {(!paymentData?.availablePaymentMethods ||
          paymentData?.availablePaymentMethods === null ||
          Object.keys(paymentData?.availablePaymentMethods).length <= 0) && (
          <>
            <div
              style={{ textAlign: "left", fontSize: "1.4rem", color: "red" }}
            >
              <span> {`${shopName} ` + t("NoPaymentMethods")}</span>
            </div>
          </>
        )}

        <div className="payment-methods-new">
          <div className="payment-methods-list">
            {(paymentData.isWalletPaymentsSupportedByDevice &&
              isPaymentMethodChanged) ||
            (paymentData.isWalletPaymentsSupportedByDevice &&
              !paymentData?.isWalletPaymentMethodInUse) ? (
              <>
                {/* //* Apple Pay Selection */}
                {(paymentData?.walletPaymentMethodTypeInUse &&
                  paymentData?.walletPaymentMethodTypeInUse === "apple_pay") ||
                  (newPaymentMethods &&
                    newPaymentMethods.find(
                      (item: any) => item.name === "apple_pay"
                    ) && (
                      <div
                        className={
                          "new-payment-method-icon card-details-retracted "
                        }
                        onClick={() => {
                          dispatch(setPaymentMethodLastUsed(""));
                          dispatch(
                            setPaymentMethodSelected(
                              newPaymentMethods.find(
                                (item: any) => item.name === "apple_pay"
                              )
                            )
                          );
                          dispatch(clearPaymentMethodSelected(null));
                          dispatch(setIsWalletPaymentMethodInUse(true));
                          dispatch(setIsPaymentMethodEditable(false));
                          setIsPaymentMethodChanged(false);
                        }}
                      >
                        <>
                          <LazyLoadImage
                            loading="lazy"
                            src={`${imageStaticUrl}/small/payment-method/apple_pay.png`}
                            alt="apple_pay"
                          />
                          Apple Pay
                        </>
                      </div>
                    ))}

                {/* //* Google Pay Selection */}
                {(paymentData?.walletPaymentMethodTypeInUse &&
                  paymentData?.walletPaymentMethodTypeInUse === "google_pay") ||
                  (newPaymentMethods &&
                    newPaymentMethods.find(
                      (item: any) => item.name === "google_pay"
                    ) && (
                      <div
                        className={
                          "new-payment-method-icon card-details-retracted "
                        }
                        onClick={() => {
                          dispatch(setPaymentMethodLastUsed(""));
                          dispatch(
                            setPaymentMethodSelected(
                              newPaymentMethods.find(
                                (item: any) => item.name === "google_pay"
                              )
                            )
                          );
                          dispatch(clearPaymentMethodSelected(null));
                          dispatch(setIsWalletPaymentMethodInUse(true));
                          dispatch(setIsPaymentMethodEditable(false));
                          setIsPaymentMethodChanged(false);
                        }}
                      >
                        <>
                          <LazyLoadImage
                            loading="lazy"
                            src={`${imageStaticUrl}/small/payment-method/google_pay.png`}
                            alt="google_pay"
                          />
                          Google Pay
                        </>
                      </div>
                    ))}
              </>
            ) : null}

            {newPaymentMethods &&
              newPaymentMethods.map((method: any, key: any) =>
                buildNewPaymentMethodIcon({
                  name: method.name,
                  image: method.iconURL,
                  key: key,
                  onClick: () => {
                    if (paymentData?.isRequiredFieldsNotFilled)
                      executeScrollOn("profileDetailsFormRef001");
                    onNewPaymentMethodClick(method);
                  },
                  displayTitle: method.displayTitle,
                  paymentMethod: method,
                  walletMethodAvailable: isWalletMethodAvailable,
                  setPaymentMethodChanged: setIsPaymentMethodChanged,
                  paymentMethodChanged: isPaymentMethodChanged,
                  isDemoActive: route === "/demo/social-commerce",
                })
              )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {app_layout === "2" ? (
        <HeadingExpandable
          title={t("PaymentMethods")}
          icon={editSVG}
          isExpandedInitially={true}
        >
          {renderPaymentMethods(false)}
        </HeadingExpandable>
      ) : (
        renderPaymentMethods(true)
      )}
    </>
  );
};
