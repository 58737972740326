import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store/store";
import { motion } from "framer-motion";
import { AnimatedWhiteArrowRight } from "../../../assets/svg/animated-svg-components/AnimatedWhiteArrow";
import { setPaymentProgress } from "../../../store/reducers/paymentSlice";
import {
  AdupPaymentMethodSelection,
  AdupSavedPaymentDetails,
} from "../../../interfaces/AdupPaymentMethod";
import { PaymentMethodConfigs } from "../../../lib/config/payment-methods-config";
import { useTranslation } from "react-i18next";
import { AnimatedLoadingCircleColored } from "../../../assets/svg/animated-svg-components/AnimatedLoadingCircle2";
import { setToastMessage } from "../../../store/reducers/appSlice";
import { getKeyByValue } from "../../../lib/utils/helper-functions";
import { useLocation } from "react-router-dom";
import {
  setInteractionsPerSessionCount,
  setPayButtonClickCount,
  setUIStatistics,
} from "../../../store/reducers/statisticsSlice";
import executeScrollOn from "../../../lib/utils/executeScrollOn";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { submitLogToBugsnag } from "../../../lib/api/log";

const AnimatedPayButtonBlack: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const route = window.decodeURI(useLocation().pathname);

  const cartData = useSelector((state: RootState) => state.cart);

  const paymentMethodSelected = useSelector(
    (state: RootState) => state.payment?.paymentMethodSelected
  );

  const paymentErrorOccurred = useSelector(
    (state: RootState) => state.payment.errorOccurred
  );

  const isAnyUnavailableStocksDetected = useSelector(
    (state: RootState) => state.payment?.isAnyUnavailableStocksDetected
  );

  const isPayButtonLoading = useSelector(
    (state: RootState) => state.app.UI.isPayButtonLoading
  );

  const isAddToCartAPILoading = useSelector(
    (state: RootState) => state.payment?.isAddToCartAPILoading
  );

  const isProductsAPILoading = useSelector(
    (state: RootState) => state.app.UI.isProductsAPILoading
  );

  const shopSettings = useSelector(
    (state: RootState) => state.cart?.shop?.settings
  ) as any;

  const shippingAddress = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.shippingAddress
  );

  const phoneNumber = useSelector(
    (state: RootState) => state.users?.currentUser?.accountPhone?.number
  );

  const paymentState = useSelector((state: RootState) => state.payment);

  const uiStatistics: any = useSelector(
    (state: RootState) => state.statistics.ui
  );

  const [isPayButtonDisabled, setIsPayButtonDisabled] =
    useState<boolean>(false);

  const [bankOptions, setBankOptions] = useState();

  const [optionTitle, setOptionTitle] = useState("");

  const [paymentMethod, setPaymentMethod] = useState<
    AdupPaymentMethodSelection | AdupSavedPaymentDetails
  >(paymentMethodSelected);

  useEffect(() => {
    setPaymentMethod(paymentMethodSelected);
    setBankOptions(paymentMethodSelected?.banks);
  }, [paymentMethodSelected]);

  useEffect(() => {
    setBankOptions(paymentMethod?.banks);
  }, [paymentMethod]);

  useEffect(() => {
    if (bankOptions) {
      let caption = getKeyByValue(
        bankOptions,
        paymentState?.paymentMethodOptionSelected &&
          paymentState?.paymentMethodOptionSelected !== ""
          ? paymentState?.paymentMethodOptionSelected
          : bankOptions[Object.keys(bankOptions)[0]]
      );
      if (caption) setOptionTitle(`${t("PayButtonCaption")}` + caption);
    } else {
      setOptionTitle("");
    }
  }, [paymentMethod, paymentState.paymentMethodOptionSelected, bankOptions]);

  const buttonRef = useRef<HTMLButtonElement>(null); // Specify the type of the ref

  useEffect(() => {
    if (buttonRef.current) {
      const width = buttonRef.current.offsetWidth;
      const height = buttonRef.current.offsetHeight;

      if (width !== undefined && height !== undefined) {
        console.log("saving Pay Button size stats");
        const updatedUiStats = {
          ...uiStatistics,
          payButtonSize: `width: ${width}px, height: ${height}px`,
        };
        dispatch(setUIStatistics(updatedUiStats));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      isPayButtonLoading ||
      paymentErrorOccurred ||
      isAnyUnavailableStocksDetected ||
      route === "/demo/social-commerce"
    ) {
      setIsPayButtonDisabled(true);
    } else {
      setIsPayButtonDisabled(false);
    }
  }, [
    isPayButtonLoading,
    paymentErrorOccurred,
    isAnyUnavailableStocksDetected,
    route,
  ]);

  function handleClick() {
    dispatch(setPayButtonClickCount());
    dispatch(setInteractionsPerSessionCount());
    // dispatch(setIsPaymentOnPopUpViaIframe(false));
    if (cartData.items.length > 0) {
      dispatch(setPaymentProgress("PAYMENT_STARTED"));
      if (
        paymentState?.isRequiredFieldsNotFilled ||
        !shippingAddress?.number ||
        shippingAddress?.number === "" ||
        !shippingAddress?.street ||
        shippingAddress?.street === "" ||
        !shippingAddress?.city ||
        shippingAddress?.city === "" ||
        !shippingAddress?.province ||
        shippingAddress?.province === "" ||
        !shippingAddress?.postcode ||
        shippingAddress?.postcode === "" ||
        !shippingAddress?.country_name ||
        shippingAddress?.country_name === ""
      ) {
        submitLogToBugsnag("error", t("MissingProfileDetailsError"));
        store.dispatch(
          setToastMessage({
            text: t("MissingProfileDetailsError"),
            type: "INFO",
          })
        );
        // executeScrollOnProfileError();
        executeScrollOn("profileDetailsFormRef001");
      } else if (!phoneNumber || phoneNumber === "") {
        submitLogToBugsnag("error", t("PhoneNumberEmpty"));
        store.dispatch(
          setToastMessage({
            text: t("PhoneNumberEmpty"),
            type: "INFO",
          })
        );
        // executeScrollOnPhoneInputError();
        executeScrollOn("phoneInputRef001");
      } else if (!paymentMethod?.name || paymentMethod?.name === "") {
        submitLogToBugsnag("error", t("PaymentMethodEmpty"));
        store.dispatch(
          setToastMessage({
            text: t("PaymentMethodEmpty"),
            type: "INFO",
          })
        );
        // executeScrollOnPaymentMethodError();
        executeScrollOn("paymentMethodsRef001");
      } else {
        const payFunc = PaymentMethodConfigs[paymentMethod?.name]?.payFunction;
        if (payFunc) {
          payFunc({});
        } else {
          submitLogToBugsnag(
            "error",
            "payFunc is not defined in PaymentMethodConfigs"
          );
          store.dispatch(
            setToastMessage({
              text: t("PayGatewayError"),
              type: "ERROR",
            })
          );
        }
      }
    } else {
      submitLogToBugsnag("error", t("EmptyCartError"));
      store.dispatch(
        setToastMessage({
          text: t("EmptyCartError"),
          type: "INFO",
        })
      );
    }
  }

  const renderPayButton = () => {
    if (
      Number(cartData?.priceCalculations?.total?.major === 0) &&
      !isAddToCartAPILoading &&
      !isProductsAPILoading
    ) {
      return null;
    } else {
      return (
        <AnimatePresence>
          <motion.div
            animate={{ transform: "translateY(20px" }}
            initial={{ transform: "translateY(500px)" }}
            transition={{
              delay: 0.2,
              duration: 0.51,
              type: "spring",
              bounce: 0.2,
            }}
            className="pay-button-holder-fixed"
          >
            <button
              ref={buttonRef}
              className={`pay-button-black ${
                isPayButtonDisabled ? "disabled" : ""
              }`}
              onClick={() => handleClick()}
              disabled={
                isPayButtonDisabled ||
                isAddToCartAPILoading ||
                isProductsAPILoading
              }
              style={{
                cursor:
                  isPayButtonDisabled ||
                  isAddToCartAPILoading ||
                  isProductsAPILoading
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              <b
                data-display-title={
                  paymentMethod.displayTitle !== "" ? "true" : "false"
                }
              >
                {isAddToCartAPILoading || isProductsAPILoading ? (
                  <Skeleton
                    width={100}
                    duration={0.6}
                    baseColor={
                      isPayButtonDisabled
                        ? "#787878e4"
                        : shopSettings?.main_brand_color ?? "#000000"
                    }
                  />
                ) : (
                  <>
                    {t("PayAmount", {
                      currency: "", //? Currency will be sent along with the formatted price via BE
                      amount: cartData?.priceCalculations?.total?.formatted,
                    })}
                  </>
                )}
              </b>
              <p>
                {paymentMethod.displayTitle !== ""
                  ? `${t("Using")} ` + paymentMethod.displayTitle + optionTitle
                  : ""}
              </p>
              {isPayButtonLoading ? (
                <span style={{ width: 45, marginLeft: 250 }}>
                  <AnimatedLoadingCircleColored />
                </span>
              ) : (
                <AnimatedWhiteArrowRight />
              )}
            </button>
          </motion.div>
        </AnimatePresence>
      );
    }
  };

  return renderPayButton();
};

export default AnimatedPayButtonBlack;
