import React from "react";
import "../../sass/themes/theme2/cartOperatorTheme2.scss";
import plusSVG from "../../assets/svg/plus-black-SVG.svg";
import minusSVG from "../../assets/svg/minus-black-SVG.svg";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

type Props = {
  onIncrement: (event: React.MouseEvent) => void;
  onDecrement: (event: React.MouseEvent) => void;
  cartItemNumber: number;
  onAddToCartClick: (event: React.MouseEvent) => void;
  isDisabled: boolean;
};

function CartOperatorTheme2({
  onIncrement,
  onDecrement,
  cartItemNumber,
  onAddToCartClick,
  isDisabled,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        // dispatch(setInteractionsPerSessionCount());
      }}
      className="cartOperatorTheme2"
    >
      <h2>{t("Quantity")}</h2>
      <div className="cartOperatorTheme2__top">
        <button
          onClick={onDecrement}
          className="cartOperatorTheme2__top--minusBtn"
          disabled={cartItemNumber <= 1 || isDisabled}
          style={cartItemNumber <= 1 ? { backgroundColor: "#fff" } : {}}
        >
          <LazyLoadImage loading="lazy" src={minusSVG} alt="reduce" />
        </button>
        <p className="cartOperatorTheme2__top--number">{cartItemNumber}</p>
        <button
          onClick={onIncrement}
          className="cartOperatorTheme2__top--plusBtn"
          disabled={isDisabled}
        >
          <LazyLoadImage loading="lazy" src={plusSVG} alt="increase" />
        </button>
      </div>
      <div className="cartOperatorTheme2__info">
        Some text about product discounts taxes or additional charges.
      </div>
      <div className="cartOperatorTheme2__bottom">
        <button onClick={onAddToCartClick}>{t("AddToCart")}</button>
      </div>
    </div>
  );
}

export default CartOperatorTheme2;
