import { PRODUCTS_BY_SHOP_SLUG_API } from "../constants/central-api";
import { RequestInfo } from "../../interfaces/RequestInfo";
import { asyncHandler } from "./async-handler";
import store from "../../store/store";
import { setIsProductsAPILoading } from "../../store/reducers/appSlice";

/**
 * @param { string } slug Shop Slug
 * @param { number } page Current Page
 */
export async function getAllProductsByShopSlug(slug: string, page: number) {
  const { token } = store.getState().users.currentUser;
  const requestInfo: RequestInfo = {
    url: `${PRODUCTS_BY_SHOP_SLUG_API(slug)}?page=${page}&per_page=8`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  const actionType = "GET_ALL_PRODUCTS_BY_SHOP_SLUG";
  const customMsg = "Products Loaded Successfully.";

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    return response.data;
  } else {
    return null;
  }
}

/**
 * @param { string } slug Shop Slug
 * @param { string } skuList List of Product SKUs
 */
export async function getProductsByShopSlugAndSku(
  slug: string,
  skuList: string
) {
  store.dispatch(setIsProductsAPILoading(true));
  const { token } = store.getState().users.currentUser;
  const requestInfo: RequestInfo = {
    url: `${PRODUCTS_BY_SHOP_SLUG_API(slug)}?filter[sku]=${skuList}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  const actionType = "GET_PRODUCTS_BY_SHOP_SLUG_AND_SKU";
  const customMsg = "Products Loaded Successfully.";

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true
  ) {
    store.dispatch(setIsProductsAPILoading(false));
    return response.data;
  } else {
    store.dispatch(setIsProductsAPILoading(false));
    return null;
  }
}
