import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { CartSummary } from "../../../components/cart-summary/CartSummary";
import { useTranslation } from "react-i18next";
import OrderSummaryCompact from "../../../components/cart-summary/OrderSummaryCompact";
import CustomSVGs from "../../../components/custom-svgs/CustomSVGs";
import { OrderSummaryCartSVG } from "../../../assets/js/allCustomSVGs";
import { AnimatePresence } from "framer-motion";

const FastCheckoutOrderSummarySegment: React.FC<{
  app_mode: string;
  isCompact?: boolean;
}> = ({ app_mode, isCompact }: any) => {
  const cartData = useSelector((state: RootState) => state.cart);

  const { t } = useTranslation();

  const renderCartSummaryLogic = () => {
    if (isCompact) {
      return (
        <OrderSummaryCompact
          grandTotal={cartData?.priceCalculations?.total?.formatted}
        >
          <CartSummary
            subTotal={cartData?.priceCalculations?.subtotal?.formatted}
            shipping={cartData?.priceCalculations?.shipping?.formatted}
            tax={
              cartData?.priceCalculations?.tax?.major &&
              Number(cartData?.priceCalculations?.tax?.major) !== 0
                ? cartData?.priceCalculations?.tax?.formatted
                : "0"
            }
            discount={
              cartData?.priceCalculations?.discount?.major &&
              Number(cartData?.priceCalculations?.discount?.major) !== 0
                ? cartData?.priceCalculations?.discount?.formatted
                : "0"
            }
            convenienceFee={
              cartData?.priceCalculations?.convenience_fee?.major &&
              Number(cartData?.priceCalculations?.convenience_fee?.major) !== 0
                ? cartData?.priceCalculations?.convenience_fee?.formatted
                : "0"
            }
            grandTotal={cartData?.priceCalculations?.total?.formatted}
            app_mode={app_mode}
          />
        </OrderSummaryCompact>
      );
    } else {
      return (
        <CartSummary
          subTotal={cartData?.priceCalculations?.subtotal?.formatted}
          shipping={cartData?.priceCalculations?.shipping?.formatted}
          tax={
            cartData?.priceCalculations?.tax?.major &&
            Number(cartData?.priceCalculations?.tax?.major) !== 0
              ? cartData?.priceCalculations?.tax?.formatted
              : "0"
          }
          discount={
            cartData?.priceCalculations?.discount?.major &&
            Number(cartData?.priceCalculations?.discount?.major) !== 0
              ? cartData?.priceCalculations?.discount?.formatted
              : "0"
          }
          convenienceFee={
            cartData?.priceCalculations?.convenience_fee?.major &&
            Number(cartData?.priceCalculations?.convenience_fee?.major) !== 0
              ? cartData?.priceCalculations?.convenience_fee?.formatted
              : "0"
          }
          grandTotal={cartData?.priceCalculations?.total?.formatted}
          app_mode={app_mode}
        />
      );
    }
  };

  return (
    <div className="order-summary fast-checkout-page-segment">
      <div className="order-summary-heading">
        <CustomSVGs svg={OrderSummaryCartSVG} />
        <h2 className="heading-2-regular fast-checkout-segment-heading">
          {app_mode === "ai-fast-checkout"
            ? t("CartSummary")
            : t("OrderSummary")}
        </h2>
      </div>
      <AnimatePresence>{renderCartSummaryLogic()}</AnimatePresence>
    </div>
  );
};

export default FastCheckoutOrderSummarySegment;
