import {
  setCartSessionId,
  setLastSessionCallPayload,
  setPaymentErrorOccurred,
  setPaymentMethodOptionSelected,
  setStripeClientSecret,
  setStripeIntentId,
  setStripeIntentType,
  setStripePublicKey,
  setStripeReturnURL,
} from "../../store/reducers/paymentSlice";
import {
  setUserToken,
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import store from "../../store/store";
import { PAYMENT_SESSION_URL } from "../constants/urls";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  enableFastCheckoutUISegment,
  initialHiddenSegments,
  setIsPayButtonLoading,
  setToastMessage,
  updateUserType,
} from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";
import { t } from "i18next";
import { getKeyByValue } from "../utils/helper-functions";
import updateStateFromPaymentMethodsBlock from "../utils/payment-methods-to-state";
import updateStateFromShopperObject from "../utils/shopper-object-to-state";
import { submitLogToBugsnag } from "./log";

export async function getPaymentIntentForProcessing() {
  //TODO: remove later: OLD API session call
  return null;
  const verificationState = store.getState().users.currentUser.verified;
  const savedPaymentAttributes =
    store.getState().payment?.savedPaymentAttributes;

  if (verificationState?.state === verificationStates.pending) {
    console.log("Phone Number verification Pending");
    store.dispatch(
      setToastMessage({
        text: t("VerifyPrompt"),
        type: "INFO",
      })
    );
    store.dispatch(setIsPayButtonLoading(false));
    store.dispatch(setPaymentErrorOccurred(false));
  } else {
    try {
      store.dispatch(setIsPayButtonLoading(true));
      store.dispatch(setPaymentErrorOccurred(true));
      let paymentMethodType = "";
      let bankOptions: any;
      let paymentMethodGateway = "";

      const shop_id = store.getState().cart.shop.id;

      const {
        allPaymentMethods,
        paymentMethodOptionSelected,
        ibanNumber,
        lastSessionCallPayload,
        createAccountFlag,
      } = store.getState().payment;

      paymentMethodType = store.getState().payment.paymentMethodSelected?.name;
      bankOptions = store.getState().payment.paymentMethodSelected?.banks;
      paymentMethodGateway = store
        .getState()
        .payment.paymentMethodSelected.serviceProvider.toLocaleLowerCase();

      //* Prevent unwanted session calls in unsupported gateways
      if (paymentMethodGateway !== "stripe") {
        console.log(
          "Prevent unwanted session calls in unsupported gateway : ",
          paymentMethodGateway
        );
        store.dispatch(setPaymentErrorOccurred(false));
        store.dispatch(setIsPayButtonLoading(false));
        return;
      }

      const cartItems = store.getState().cart.items;
      const grandTotal = store.getState().cart?.priceCalculations?.total?.major;
      const cart: any = [];
      for (const item of cartItems) {
        // cart[item.id] = { quantity: item.quantity };
        cart.push({ sku: item.sku, qty: item.quantity });
      }
      const cartString = JSON.stringify(cart);
      const { token, accountPhone, profile } =
        store.getState().users.currentUser;
      const shippingAddress =
        store.getState().users?.currentUser?.shipping?.shippingAddress;
      const billingAddress =
        store.getState().users?.currentUser?.shipping?.billingAddress;

      const countryCode = accountPhone.country.dialCode.replace("+", "");

      const checkBankAvailability = (paymentMethod: string) => {
        if (bankOptions) {
          let bankAvailable = getKeyByValue(
            bankOptions,
            paymentMethodOptionSelected
          );
          if (!bankAvailable) {
            if (savedPaymentAttributes?.[paymentMethod]) {
              console.log(
                paymentMethodOptionSelected,
                ": Bank is not available",
                ", therefore previously saved bank is selected by default : ",
                savedPaymentAttributes?.[paymentMethod]
              );
              store.dispatch(
                setPaymentMethodOptionSelected({
                  key: savedPaymentAttributes?.[paymentMethod] ?? "",
                  optionData:
                    bankOptions[savedPaymentAttributes?.[paymentMethod]] ?? "",
                })
              );
              return savedPaymentAttributes?.[paymentMethod];
            } else {
              console.log(
                paymentMethodOptionSelected,
                ": Bank is not available",
                ", therefore 1st bank is selected by default : ",
                Object.keys(bankOptions)[0]
              );
              store.dispatch(
                setPaymentMethodOptionSelected({
                  key: Object.keys(bankOptions)[0] ?? "",
                  optionData: bankOptions[Object.keys(bankOptions)[0]] ?? "",
                })
              );
              return Object.keys(bankOptions)[0];
            }
          } else {
            console.log("Bank is available : ", paymentMethodOptionSelected);
            return bankAvailable;
          }
        }
      };

      let paymentAttributes;
      if (paymentMethodType === "ideal") {
        paymentAttributes = JSON.stringify({
          ideal_bank: checkBankAvailability("ideal_bank"),
        });
      } else if (paymentMethodType === "p24") {
        paymentAttributes = JSON.stringify({
          p24_bank: checkBankAvailability("p24_bank"),
        });
      } else if (paymentMethodType === "eps") {
        paymentAttributes = JSON.stringify({
          eps_bank: checkBankAvailability("eps_bank"),
        });
      } else if (ibanNumber && paymentMethodType === "sepa_debit") {
        paymentAttributes = JSON.stringify({
          sepa_debit_iban_number: ibanNumber,
        });
      }

      // const params = `?cart_session_id=${cartSessionId}&url=${window.location.href}&cart=${cartString}&pm_type=${paymentMethodType}&pm_psp=${selectedPaymentMethod.serviceProvider}`;
      // const intentResponse = await fetch(PAYMENT_INTENT_URL + params, {
      //   method: "get",
      //   headers: buildHeadersURLEncoded(token ? token : ""),
      // });

      const requireFields =
        cartString &&
        countryCode &&
        accountPhone?.number &&
        profile?.name?.first &&
        profile?.name?.second &&
        shippingAddress?.number &&
        shippingAddress?.street &&
        shippingAddress?.city &&
        shippingAddress?.province &&
        shippingAddress?.postcode &&
        shippingAddress?.country_name &&
        profile?.email &&
        paymentMethodType &&
        grandTotal > 0;

      if (!requireFields) {
        // Prevent Process API execution without required fields
        store.dispatch(setIsPayButtonLoading(false));
        return null;
      } else {
        const saveShopperObject = {
          country_code: countryCode,
          phone_number: accountPhone?.number,
          first_name: profile?.name?.first,
          last_name: profile?.name?.second,
          shipping_address: {
            house_number: shippingAddress?.number,
            street: shippingAddress?.street,
            street2: shippingAddress?.street2,
            city: shippingAddress?.city,
            province: shippingAddress?.province,
            postcode: shippingAddress?.postcode,
            country: shippingAddress?.country_name,
          },
          billing_address: {
            house_number:
              billingAddress?.country_name !== ""
                ? billingAddress?.number
                : shippingAddress?.number,
            street:
              billingAddress?.country_name !== ""
                ? billingAddress?.street
                : shippingAddress?.street,
            street2:
              billingAddress?.country_name !== ""
                ? billingAddress?.street2
                : shippingAddress?.street2,
            city:
              billingAddress?.country_name !== ""
                ? billingAddress?.city
                : shippingAddress?.city,
            province:
              billingAddress?.country_name !== ""
                ? billingAddress?.province
                : shippingAddress?.province,
            postcode:
              billingAddress?.country_name !== ""
                ? billingAddress?.postcode
                : shippingAddress?.postcode,
            country:
              billingAddress?.country_name !== ""
                ? billingAddress?.country_name
                : shippingAddress?.country_name,
          },
          email: profile?.email,
        };

        const updateShopperObject =
          shippingAddress?.id !== "" && shippingAddress?.id
            ? {
                country_code: countryCode,
                phone_number: accountPhone?.number,
                first_name: profile?.name?.first,
                last_name: profile?.name?.second,
                shipping_address_id:
                  shippingAddress?.id !== "" ? shippingAddress?.id : undefined,
                billing_address_id:
                  billingAddress?.id !== ""
                    ? billingAddress?.id
                    : shippingAddress?.id !== ""
                    ? shippingAddress?.id
                    : undefined,
                email: profile?.email,
              }
            : undefined;

        const saveShopperString = JSON.stringify(saveShopperObject);
        const updateShopperString = JSON.stringify(updateShopperObject);
        const shopperString = !token ? saveShopperString : updateShopperString;

        const finalPayload = JSON.stringify({
          shopper: shopperString,
          shop_id: shop_id,
          payment_method: paymentMethodType,
          cart: cartString,
          cart_total: grandTotal ?? 0.0,
          payment_attributes: paymentAttributes ? paymentAttributes : undefined,
          create_account: token ? true : createAccountFlag,
          route: window.location.pathname,
        });

        if (
          lastSessionCallPayload !== finalPayload &&
          lastSessionCallPayload !== "loading" &&
          !window?.location?.pathname.startsWith("/profile") &&
          shopperString &&
          cart?.length > 0
        ) {
          store.dispatch(setLastSessionCallPayload("loading"));
          const intentResponse = await fetch(PAYMENT_SESSION_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // "X-API-Key": key,
              Authorization: token ? `Bearer ${token}` : "",
            },
            body: finalPayload,
          });

          // trigger API Errors as Toasts
          const res = await intentResponse.json();
          if (res) {
            toastHandler(res, store.dispatch, setToastMessage);
          }
          if (!res.success) {
            store.dispatch(setIsPayButtonLoading(false));
            store.dispatch(setPaymentErrorOccurred(false));
            store.dispatch(setLastSessionCallPayload(undefined));
          }

          const { result } = res;
          const key = result?.key;

          if (key && createAccountFlag === true) {
            store.dispatch(setUserToken(key));
          }

          if (createAccountFlag !== true) {
            store.dispatch(setUserToken(undefined));
          }

          //* NEW User saving in state
          if (
            result?.shopper &&
            verificationState?.state !== verificationStates.verified
          ) {
            console.log("NEW User saving in state...");
            updateStateFromShopperObject(result?.shopper);
            updateStateFromPaymentMethodsBlock(result?.shopper);
            for (const segment of Object.keys(initialHiddenSegments)) {
              store.dispatch(disableFastCheckoutUISegment(segment));
            }

            store.dispatch(
              updateUserType({ user: applicationUserType.OLD_USER })
            );
            store.dispatch(
              setUserVerified({
                ...verificationState,
                state: verificationStates.verified,
                _: true,
                loggedInMethod: "otp",
                firstPayment: "pending",
              })
            );
            store.dispatch(enableFastCheckoutUISegment("ACCOUNT_SEGMENT"));
            store.dispatch(enableFastCheckoutUISegment("DETAILS_SEGMENT"));
            store.dispatch(
              enableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT")
            );
            store.dispatch(
              enableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT")
            );
            store.dispatch(
              enableFastCheckoutUISegment("PAYMENT_METHODS_SEGMENT")
            );
            store.dispatch(
              enableFastCheckoutUISegment("CREATE_ADUP_ACCOUNT_SEGMENT")
            );
            console.log("NEW User saved in state ✅");
          }

          if (result?.session) {
            const {
              id,
              psp,
              psp_method,
              type,
              public_key,
              client_secret,
              return_url,
              currency,
              amount,
              cart_id,
            } = result.session;

            store.dispatch(setPaymentErrorOccurred(false));
            store.dispatch(setStripeIntentId(id));
            store.dispatch(setStripePublicKey(public_key));
            store.dispatch(setStripeClientSecret(client_secret));
            store.dispatch(setStripeIntentType(psp_method));
            store.dispatch(setStripeReturnURL(return_url));
            store.dispatch(setCartSessionId(cart_id));

            store.dispatch(setIsPayButtonLoading(false));

            store.dispatch(
              setLastSessionCallPayload(
                JSON.stringify({
                  shopper: !token ? saveShopperString : updateShopperString,
                  shop_id: shop_id,
                  payment_method: paymentMethodType,
                  cart: cartString,
                  cart_total: grandTotal ?? 0.0,
                  payment_attributes: paymentAttributes
                    ? paymentAttributes
                    : undefined,
                  create_account: token ? true : createAccountFlag,
                  route: window.location.pathname,
                })
              )
            );
            return result.session;
          } else {
            store.dispatch(setPaymentErrorOccurred(true));
            store.dispatch(setIsPayButtonLoading(false));
            store.dispatch(setLastSessionCallPayload(undefined));
            return null;
          }
        } else {
          console.log(
            "Prevented unnecessary Session Calls due to unchanged data : ",
            lastSessionCallPayload
          );
          store.dispatch(setIsPayButtonLoading(false));
          store.dispatch(setPaymentErrorOccurred(false));
        }
      }
    } catch (error) {
      console.log("Catch @ getPaymentIntentForProcessing :", error);
      submitLogToBugsnag("error", `${t("SessionError")} : ${error}`);
      store.dispatch(
        setToastMessage({
          text: t("SessionError"),
          type: "ERROR",
        })
      );
      store.dispatch(setPaymentErrorOccurred(true));
      store.dispatch(setIsPayButtonLoading(false));
      store.dispatch(setLastSessionCallPayload(undefined));
    }
  }
}
