import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "../../store/store";
import CompactCart from "../compact-cart/CompactCart";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { ProfileSettingsSVG } from "../../assets/js/allCustomSVGs";
import { useTranslation } from "react-i18next";
import { isNotEmptyString } from "../../lib/utils/helper-functions";

interface Props {
  hideCompactCart?: boolean;
  forceShowCompactCart?: boolean;
}

const Header = ({ hideCompactCart, forceShowCompactCart }: Props) => {
  const { t } = useTranslation();
  const shopData: any = useSelector((state: RootState) => state.cart?.shop);
  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );
  const showCookieConsent = useSelector(
    (state: RootState) => state.app.UI.showCookieConsent
  );
  const app_mode = window.decodeURI(useLocation().pathname).split("/")[1];

  const isLogo = () => {
    if (
      shopData?.settings?.merchant_logo_light &&
      isNotEmptyString(shopData?.settings?.merchant_logo_light)
    ) {
      return true;
    }

    return false;
  };

  const renderCompanyLogo = () => {
    if (isLogo()) {
      return (
        <div className="img__container">
          {!showCookieConsent && (
            <LazyLoadImage
              loading="lazy"
              src={shopData?.settings?.merchant_logo_light}
              alt=""
            />
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  const renderCompanyName = (position: string) => {
    if (shopData?.name) {
      return (
        <div
          data-header-logo={isLogo()?.toString()}
          data-is-cart={showCompactCartLogic() ? "true" : "false"}
          className={`text__container header-text-container-${position}`}
        >
          <h1> {shopData?.name} </h1>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const renderProfileIcon = () => {
    return (
      <Link to="/profile">
        <div title={t("Profile")} className="img__container header-profile">
          <CustomSVGs svg={ProfileSettingsSVG} />
        </div>
      </Link>
    );
  };

  const showCompactCartLogic = () => {
    if (app_mode === "thank-you") {
      return false;
    } else if (forceShowCompactCart) {
      return true;
    } else if (settings?.fascheckout_ui === "compact" && !hideCompactCart) {
      return true;
    } else {
      return false;
    }
  };

  const renderEmptyDiv = () => {
    if (settings?.fascheckout_ui !== "compact" || !forceShowCompactCart) {
      return <div className="header-empty-div"></div>;
    } else if (settings?.fascheckout_ui === "compact" && hideCompactCart) {
      return <div className="header-empty-div"></div>;
    }
  };

  return (
    <>
      <div data-is-header-fixed={settings?.setting} className="header">
        <div
          data-is-cart={showCompactCartLogic() ? "true" : "false"}
          className="header-max-width-container"
        >
          <div className="header-left">
            {renderCompanyLogo()}
            {renderCompanyName("left")}
          </div>

          {renderCompanyName("middle")}

          {renderEmptyDiv()}
          <div className="header-right">
            {renderProfileIcon()}
            {showCompactCartLogic() && <CompactCart />}
          </div>
        </div>
      </div>
      <div data-is-header-fixed={settings?.setting} className="header-hidden" />
    </>
  );
};

export default Header;
