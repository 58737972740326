import { t } from "i18next";
import { useEffect, useState } from "react";
import { makeFirstLetterCapital } from "../../lib/utils/toast-handler";
import "../../sass/components/socialCommerce/variantSelector.scss";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

const VariantSelector = ({
  attributes,
  defaultSelectedAttributes,
  setMap,
}: any) => {
  const variants = Object.keys(attributes);
  const [selectedVariants, setSelectedVariants] = useState<any>();
  const [attributeSkuMap, setAttributeSkuMap] = useState<any>();
  const variantSelectors = [];
  const dispatch = useDispatch();

  useEffect(() => {
    let defaultSelectedVariants: { [key: string]: string } = {};
    if (
      typeof defaultSelectedAttributes === "object" &&
      defaultSelectedAttributes !== null
    ) {
      Object.keys(defaultSelectedAttributes).forEach((key) => {
        defaultSelectedVariants[
          key
        ] = `${key}_${defaultSelectedAttributes[key]}`;
      });
      setSelectedVariants(defaultSelectedVariants);
    } else {
      setSelectedVariants({});
    }
  }, [defaultSelectedAttributes]);

  useEffect(() => {
    if (!defaultSelectedAttributes) {
      setSelectedVariants({});
    }
  }, []);

  useEffect(() => {
    console.log("Variant attribute selection changed : ", selectedVariants);
    if (selectedVariants) {
      const isSelectionsNotTouched = Object.keys(selectedVariants).length === 0;
      let map;
      if (!isSelectionsNotTouched) {
        for (let variant of variants) {
          if (selectedVariants[variant]) {
            map = map
              ? `${map}_${selectedVariants[variant]}`
              : `${selectedVariants[variant]}`;
          }
        }
        setAttributeSkuMap(map);
      }
    }
  }, [selectedVariants]);

  useEffect(() => {
    setMap(attributeSkuMap);
  }, [attributeSkuMap]);

  if (variants.length > 0) {
    for (let variant of variants) {
      const variantAttributes = [];

      if (attributes?.[variant].length > 0) {
        for (let attribute of attributes?.[variant]) {
          variantAttributes.push(
            <option value={`${variant}_${attribute}`}>
              {makeFirstLetterCapital(attribute)}
            </option>
          );
        }
      }

      if (selectedVariants && variantAttributes.length > 0) {
        variantSelectors.push(
          <div className="variantSelector__select">
            <select
              onFocus={() => {
                dispatch(setInteractionsPerSessionCount());
              }}
              name="variantSelector-1"
              id="variantSelector-1"
              onChange={(e) => {
                setSelectedVariants({
                  ...selectedVariants,
                  [variant]: e.target.value,
                });
              }}
              value={`${selectedVariants[variant]}`}
            >
              <option value={``}>
                {t("Select")} {variant}
              </option>
              {variantAttributes}
            </select>
            <label htmlFor="variantSelector-1">
              {makeFirstLetterCapital(variant)}
            </label>
          </div>
        );
      }
    }
  }
  return (
    <div className="variantSelector">
      <div className="variantSelector--container">{variantSelectors}</div>
    </div>
  );
};

export default VariantSelector;
