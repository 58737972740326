import { useEffect, useState } from "react";
import FastCheckoutDesktopLayoutForPopups from "./desktop/FastCheckoutDesktopLayoutForPopups";
import FastCheckoutMobileLayoutForPopups from "./mobile/FastCheckoutMobileLayoutForPopups";

interface Props {
  app_mode: any;
  componentRenderLogics: (componentType: string) => boolean | undefined;
}

const FastCheckoutLayoutForPopups = ({
  app_mode,
  componentRenderLogics,
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth > 750 ? (
        <FastCheckoutDesktopLayoutForPopups
          app_mode={app_mode}
          componentRenderLogics={componentRenderLogics}
        />
      ) : (
        <FastCheckoutMobileLayoutForPopups
          app_mode={app_mode}
          componentRenderLogics={componentRenderLogics}
        />
      )}
    </>
  );
};

export default FastCheckoutLayoutForPopups;
