import { setPaymentMethodLastUsed } from "../../store/reducers/paymentSlice";
import {
  addNewUserAddress,
  setBillingAddress,
  setBillingIsShipping,
  setPhoneId,
  setShippingAddress,
  setUserPhone,
  setUserProfile,
  setUserToken,
  setUserWebAuthn,
} from "../../store/reducers/userSlice";
import store from "../../store/store";
import { setAppLanguage } from "../../store/reducers/appSlice";
import { AdupUserAddress } from "../../interfaces/AdupUserAddress";

export default function updateStateFromShopperObject(shopper: any) {
  const countryDialCodeMap = store.getState()?.app?.phone
    ?.countryDialCodeMap as any;
  if (!shopper) return;
  let billingLastUsed;
  let shippingLastUsed;

  const {
    id,
    first_name,
    last_name,
    email,
    contact,
    country_code,
    addresses,
    last_used_payment_method,
    webauthn,
    language,
    bearer_token,
  } = shopper;

  if (bearer_token) {
    store.dispatch(setUserToken(bearer_token));
  }

  if (language) {
    store.dispatch(setAppLanguage(language));
  }

  store.dispatch(
    setUserProfile({
      id,
      name: { first: first_name, second: last_name },
      email,
      meta: {},
    })
  );

  if (contact && country_code) {
    store.dispatch(
      setUserPhone({
        country: countryDialCodeMap["+" + country_code],
        number: contact,
      })
    );
    store.dispatch(setPhoneId(id));
  }

  if (addresses && Object.keys(addresses?.address_blocks)?.length > 0) {
    for (const addressId in addresses?.address_blocks) {
      const currentAddress = addresses?.address_blocks[addressId];
      const { address, id, nickname, shipping_rate } = currentAddress;

      const currentSavingAddressObject = {
        id,
        nickname,
        number: address?.house_number,
        postcode: address?.postcode,
        province: address?.province,
        street: address?.street,
        street2: address?.street2,
        city: address?.city,
        country_name: address?.country?.name,
        country_code: address?.country?.iso2,
        country: address?.country,
        shipping_rate,
      } as AdupUserAddress;

      store.dispatch(addNewUserAddress(currentSavingAddressObject));

      if (id === addresses?.last_used_billing_address) {
        billingLastUsed = currentSavingAddressObject;
      }
      if (id === addresses?.last_used_shipping_address) {
        shippingLastUsed = currentSavingAddressObject;
      }
      if (!billingLastUsed) {
        billingLastUsed = currentSavingAddressObject;
      }
      if (!shippingLastUsed) {
        shippingLastUsed = currentSavingAddressObject;
      }
    }

    if (billingLastUsed?.id === shippingLastUsed?.id) {
      store.dispatch(setBillingIsShipping(true));
    } else {
      store.dispatch(setBillingIsShipping(false));
    }
    if (shippingLastUsed) {
      store.dispatch(setShippingAddress(shippingLastUsed));
    }
    if (billingLastUsed) {
      store.dispatch(setBillingAddress(billingLastUsed));
    }
  }
  store.dispatch(setUserWebAuthn(Boolean(webauthn)));
  store.dispatch(
    setPaymentMethodLastUsed(last_used_payment_method?.common_alias)
  );
}
