import store from "../../../../store/store";
import { handlePaymentRequest } from "../../../api/payment-post-request";

/**
 * This will happen with cards bound to a profile
 */
export default async function cardPayFunction() {
  const paymentState = store.getState().payment;
  const dispatch = store.dispatch;

  const selectedPaymentMethod = paymentState.paymentMethodSelected;

  if (
    (selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "stripe" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !==
        "buckaroo" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "mollie" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "paynl" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !==
        "aduppay") ||
    selectedPaymentMethod?.name.toLocaleLowerCase() !== "card"
  )
    return;
  if (selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "stripe") {
    //* Handled at src/pgp-components/stripe-components/MakePaymentPage.tsx
  } else if (
    // selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "mollie" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "aduppay"
  ) {
    //* Handled at src/pgp-components/mollie-components/MollieContainer.tsx
  } else if (
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "mollie" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "buckaroo" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "paynl"
  ) {
    handlePaymentRequest();
  }
}
