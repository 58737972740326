import { ServiceProviderHandlerProps } from ".";
import {
  setStripeIntentId,
  setStripePublicKey,
  setStripeClientSecret,
  setStripeIntentType,
  setStripeReturnURL,
} from "../../../../store/reducers/paymentSlice";
import store from "../../../../store/store";

// if the selected payment method uses stripe as its payment service provider
export function handleStripeAsSelected({
  clientSecret,
  id,
  publicKey,
  returnURL,
  pspMethod,
}: ServiceProviderHandlerProps) {
  updateStripeGlobalStateValues(
    id,
    clientSecret,
    publicKey,
    returnURL,
    pspMethod
  );
}

function updateStripeGlobalStateValues(
  intentId: string,
  clientSecret: string,
  publicKey: string,
  returnURL: string,
  pspMethod: string
) {
  store.dispatch(setStripeIntentId(intentId));
  store.dispatch(setStripePublicKey(publicKey));
  store.dispatch(setStripeReturnURL(returnURL));
  store.dispatch(setStripeIntentType(pspMethod));
  store.dispatch(setStripeClientSecret(clientSecret));
}
