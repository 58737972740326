import { WALLET_SHOPPER_CREATION_URL } from "../constants/urls";
import store from "../../store/store";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  enableFastCheckoutUISegment,
  initialHiddenSegments,
  setToastMessage,
  updateUserType,
} from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";
import { setPaymentErrorOccurred } from "../../store/reducers/paymentSlice";
import {
  setUserToken,
  verificationStates,
  setUserVerified,
} from "../../store/reducers/userSlice";
import updateStateFromPaymentMethodsBlock from "../utils/payment-methods-to-state";
import updateStateFromShopperObject from "../utils/shopper-object-to-state";
import { LOGOUT_API } from "../constants/central-api";
import { RequestInfo } from "../../interfaces/RequestInfo";
import { asyncHandler } from "./async-handler";

/**
 * @param { any } walletDataObject wallet data
 */
export async function createShopperViaWalletData(walletDataObject: any) {
  const token = store.getState().users.currentUser.token;
  const shopId = store.getState().cart?.shop?.id;
  const { createAccountFlag } = store.getState().payment;
  const cartItems = store.getState().cart.items;
  const grandTotal = store.getState().cart?.priceCalculations?.total?.major;
  const verificationState = store.getState().users.currentUser.verified;

  const paymentMethodType =
    store.getState().payment.paymentMethodSelected?.name;
  const cart: any = [];
  for (const item of cartItems) {
    cart.push({ sku: item.sku, qty: item.quantity });
  }
  const cartString = JSON.stringify(cart);

  const saveShopperString = JSON.stringify(walletDataObject);

  const response = await fetch(WALLET_SHOPPER_CREATION_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "X-API-Key": key,
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify({
      shopper: saveShopperString,
      shop_id: shopId,
      payment_method: paymentMethodType,
      cart: cartString,
      cart_total: grandTotal ?? 0.0,
      create_account: token ? true : createAccountFlag,
    }),
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;
  const key = result?.authKey;

  if (key && createAccountFlag === true) {
    store.dispatch(setUserToken(key));
  }

  if (createAccountFlag !== true) {
    store.dispatch(setUserToken(undefined));
  }

  //* NEW User saving in state
  if (
    result?.shopper &&
    verificationState?.state !== verificationStates.verified
  ) {
    console.log("NEW User saving in state...");
    updateStateFromShopperObject(result?.shopper);
    updateStateFromPaymentMethodsBlock(result?.shopper);
    for (const segment of Object.keys(initialHiddenSegments)) {
      store.dispatch(disableFastCheckoutUISegment(segment));
    }

    store.dispatch(updateUserType({ user: applicationUserType.OLD_USER }));
    store.dispatch(
      setUserVerified({
        ...verificationState,
        state: verificationStates.verified,
        _: true,
        loggedInMethod: "otp",
        firstPayment: "pending",
      })
    );
    store.dispatch(enableFastCheckoutUISegment("ACCOUNT_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("DETAILS_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("PAYMENT_METHODS_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("CREATE_ADUP_ACCOUNT_SEGMENT"));
    console.log("NEW User saved in state ✅");
  }
  store.dispatch(setPaymentErrorOccurred(false));
  return result;
}

export async function shopperLogOut() {
  const key = store.getState().users.currentUser.token;
  if (key && key !== "") {
    const requestInfo: RequestInfo = {
      url: `${LOGOUT_API}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${key}`,
      },
    };

    const actionType = "SHOPPER_LOGOUT";
    const customMsg = "Shopper Logged Out Successfully.";

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;

    if (
      res &&
      res?.actionType === actionType &&
      response &&
      response?.success === true &&
      response?.message === "You are logged out."
    ) {
      store.dispatch(setUserToken(undefined));
      return response.success;
    } else {
      return null;
    }
  } else {
    return null;
  }
}
