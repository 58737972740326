import { BuckarooSdk } from "../../lib/buckaroo.nl-sdk/BuckarooApplePaySdk";
import "./payment-request-components.scss";
import { useEffect } from "react";
import {
  clearPaymentMethodSelected,
  setIsPaymentMethodEditable,
  setIsPaymentMethodsLoading,
  setIsWalletPaymentMethodInUse,
  setIsWalletPaymentsSupportedByDevice,
  setPaymentMethodSelected,
} from "../../store/reducers/paymentSlice";
import { useDispatch } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import store from "../../store/store";
import { useTranslation } from "react-i18next";
import { createShopperViaWalletData } from "../../lib/api/shopper-methods";
import { setShippingAddress } from "../../store/reducers/userSlice";
import { submitLogToBugsnag } from "../../lib/api/log";

const BuckarooPaymentRequestAdupContainer = (
  buckarooPaymentRequestAdupContainer: any
) => {
  const { t } = useTranslation();
  const grandTotal = store.getState().cart?.priceCalculations?.total?.major;
  const shippingCost =
    store.getState().cart?.priceCalculations?.shipping?.major;
  const isPaymentMethodEditable =
    store.getState().payment?.isPaymentMethodEditable;
  const merchantTheme = store.getState().users?.merchant?.config;
  const dispatch = useDispatch();

  // Get an instance of `PhoneNumberUtil`.
  const phoneUtil: any =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();

  const otherPayments = () => {
    dispatch(clearPaymentMethodSelected(null));
    buckarooPaymentRequestAdupContainer.setPaymentMethodChanged(true);
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsWalletPaymentsSupportedByDevice(false));
  };

  const skipAvailableWalletPayments = () => {
    dispatch(clearPaymentMethodSelected(null));
    dispatch(setIsWalletPaymentsSupportedByDevice(true));
    buckarooPaymentRequestAdupContainer.setPaymentMethodChanged(true);
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsPaymentMethodEditable(false));
  };

  useEffect(() => {
    dispatch(setIsPaymentMethodsLoading(true));
  }, []);

  useEffect(() => {
    if (BuckarooSdk.ApplePay) {
      dispatch(
        setPaymentMethodSelected(
          buckarooPaymentRequestAdupContainer.paymentMethod
        )
      );
      var merchantIdentifier =
        buckarooPaymentRequestAdupContainer.paymentMethod?.data?.guid;
      BuckarooSdk.ApplePay.checkApplePaySupport(merchantIdentifier)
        .then(function (applePaySupported: any) {
          console.log("merchantIdentifier mounted");
          if (applePaySupported) {
            console.log("ApplePay supported");
            init(merchantIdentifier);
          } else {
            console.log("ApplePay Not Supported");
            otherPayments();
          }
        })
        .catch((error: any) => {
          console.log(
            "Error in BuckarooSdk.ApplePay.checkApplePaySupport",
            error
          );
          submitLogToBugsnag(
            "error",
            `Error in BuckarooSdk.ApplePay.checkApplePaySupport : ${error}`
          );
          otherPayments();
        });
    } else {
      console.log("Error in BuckarooSdk ApplePay :", BuckarooSdk);
      otherPayments();
    }
  }, []);

  function init(merchantIdentifier: any) {
    var totalForDelivery = {
      label: `${merchantTheme?.merchant_name} Cart Total`,
      amount: grandTotal ?? 0.0,
      type: "final",
    };
    var subtotal = grandTotal ?? 0.0;
    var shipping = shippingCost ?? 0.0;
    var lineItemsForDelivery = [
      { label: "Subtotal", amount: subtotal, type: "final" },
      { label: "Delivery", amount: shipping, type: "final" },
    ];
    var shippingMethods = [
      {
        label: "Delivery",
        amount: shipping,
        identifier: "delivery",
        detail: "Your order will be delivered to your Shipping address",
      },
    ];
    var requiredContactFields = ["email", "name", "postalAddress"];
    var captureFunds = async function (payment: any) {
      var result = {}; // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentauthorizationresult

      //* Fetch Payer data from Wallet Payment - START *//
      let countryCode = "0";
      let phoneNumber = "0";
      try {
        const number = phoneUtil.parseAndKeepRawInput(
          payment?.billingContact?.phoneNumber
            ? payment?.billingContact?.phoneNumber.startsWith("+")
              ? payment?.billingContact?.phoneNumber
              : `+${payment?.billingContact?.phoneNumber}`
            : "0"
        );
        countryCode = number.getCountryCode();
        phoneNumber = number.getNationalNumber();
      } catch (error) {
        console.log("Wallet data phone number invalid :", error);
        submitLogToBugsnag(
          "error",
          `Wallet data phone number invalid : ${error}`
        );
      }
      const walletPayerInfo = {
        country_code: countryCode ?? "",
        phone_number: phoneNumber ?? "",
        first_name: payment?.billingContact?.givenName ?? "",
        last_name: payment?.billingContact?.familyName ?? "",
        shipping_address: {
          house_number: payment?.shippingContact?.postalAddress?.houseNumber,
          street: payment?.shippingContact?.postalAddress?.street,
          street2: "",
          city: payment?.shippingContact?.postalAddress?.city,
          province: payment?.shippingContact?.postalAddress?.state,
          postcode: payment?.shippingContact?.postalAddress?.postalCode,
          country: payment?.shippingContact?.postalAddress?.country,
        },
        billing_address: {
          house_number:
            payment?.billingContact.postalAddress.country !== ""
              ? payment?.billingContact?.postalAddress?.houseNumber
              : payment?.shippingContact?.postalAddress?.houseNumber,
          street:
            payment?.billingContact.postalAddress.country !== ""
              ? payment?.billingContact?.postalAddress?.street
              : payment?.shippingContact?.postalAddress?.street,
          street2: "",
          city:
            payment?.billingContact.postalAddress.country !== ""
              ? payment?.billingContact.postalAddress.city
              : payment?.shippingContact.postalAddress.city,
          province:
            payment?.billingContact.postalAddress.country !== ""
              ? payment?.billingContact?.postalAddress?.state
              : payment?.shippingContact?.postalAddress?.state,
          postcode:
            payment?.billingContact.postalAddress.country !== ""
              ? payment?.billingContact?.postalAddress?.postalCode
              : payment?.shippingContact?.postalAddress?.postalCode,
          country:
            payment?.billingContact.postalAddress.country !== ""
              ? payment?.billingContact?.postalAddress?.country
              : payment?.shippingContact?.postalAddress?.country,
        },
        email: payment?.billingContact?.emailAddress,
      };
      //* Fetch Payer data from Wallet Payment - END *//

      console.log("Wallet payer Info :", walletPayerInfo);
      const createdShopper = await createShopperViaWalletData(walletPayerInfo);
      console.log("Shopper created via wallet data :", createdShopper);

      return Promise.resolve({
        status: 0,
      });
    };
    var shippingMethodSelected = function (shippingMethod: any) {
      var result = {}; // https://developer.apple.com/documentation/apple_pay_on_the_web/applepayshippingmethodupdate
      return Promise.resolve(result);
    };
    var shippingContactSelected = function (shippingContact: any) {
      var result = {}; // https://developer.apple.com/documentation/apple_pay_on_the_web/applepayshippingcontactupdate
      // Log the updated address
      if (shippingContact) {
        console.log(
          "Updated BUCKAROO Wallet Shipping Address:",
          shippingContact
        );
      }
      // Update the shipping address in state based on the selected address : which will also trigger address change & shipping cost Calculation
      var selectedShippingAddress = {
        id: "",
        number: shippingContact?.houseNumber ?? "",
        street: shippingContact?.street ?? "",
        street2: "",
        city: shippingContact?.city ?? "",
        province: shippingContact?.state ?? "",
        postcode: shippingContact?.postalCode ?? "",
        country_name: shippingContact?.country ?? "",
        country_code: shippingContact?.countryCode ?? "",
      };
      dispatch(setShippingAddress(selectedShippingAddress));

      return Promise.resolve(result);
    };
    var cancel = function (event: any) {
      console.log("Payment UI is dismissed.");
    };
    var shippingOption = "shipping"; // https://developer.apple.com/documentation/apple_pay_on_the_web/applepayshippingtype
    var options = new BuckarooSdk.ApplePay.ApplePayOptions(
      "STORE_NAME", // store name
      "NL", // country code
      "EUR", // currency code
      "NL", // language
      merchantIdentifier, // your merchant guid
      lineItemsForDelivery, // default line items
      totalForDelivery, // default total line
      shippingOption, // default shipping option
      shippingMethods, // available shipping methods
      captureFunds // callback method for capturing funds
    );

    showButton(options);
  }

  function showButton(options: any) {
    var payment = new BuckarooSdk.ApplePay.ApplePayPayment(
      "#buckaroo-button", // selector for the element to use as the button
      options // the ApplePay payment options object
    );

    if (!payment) {
      console.log("Error in BuckarooSdk.ApplePay.ApplePayPayment");
      otherPayments();
    }

    payment.showPayButton(
      "black", // black, white or white-outline
      "" // plain, book, buy, check-out, donate, set-up or subscribe
    );

    console.log("Finally showPayButton ::", payment);
    dispatch(setIsWalletPaymentMethodInUse(true));
    dispatch(setIsPaymentMethodsLoading(false));
    if (isPaymentMethodEditable) {
      skipAvailableWalletPayments();
    }
  }

  return (
    <>
      <div className="change-pay-method-link" style={{ textAlign: "center" }}>
        <h2 onClick={skipAvailableWalletPayments}>
          {t("ChangePaymentMethod")}
        </h2>
      </div>
      <br />
      <AnimatePresence>
        <motion.div
          animate={{ transform: "translateY(20px" }}
          initial={{ transform: "translateY(500px)" }}
          transition={{
            delay: 0.2,
            duration: 0.51,
            type: "spring",
            bounce: 0.2,
          }}
          className="pay-button-holder-fixed"
        >
          <button className="pay-button-apple-pay" color="white">
            <div className="buckaroo-button" id="buckaroo-button"></div>
          </button>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default BuckarooPaymentRequestAdupContainer;
