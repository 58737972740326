import React, { useEffect, useState } from "react";
import { InputAmount } from "../input-number-controls/InputAmount";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementItemQuantity,
  incrementItemQuantity,
  markItemAsSelected,
} from "../../store/reducers/cartSlice";

import { motion } from "framer-motion";
import FakeCheckbox from "../checkboxes/FakeCheckbox";
import { RootState } from "../../store/store";
import { truncate } from "../../lib/utils/helper-functions";
import { useLocation, useNavigate } from "react-router-dom";
import { addShopperEvent } from "../../lib/api/events-methods";
import { debounce } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export interface CartItemProps {
  id: string;
  sku: string;
  index: number;
  priceFormatted: string;
  priceNumeric: number;
  totalPriceFormatted: string;
  totalPriceNumeric: number;
  quantity: number;
  stock: number;
  name: string;
  key?: number;
  currency: string;
  image: string | undefined;
  init?: any;
  anim?: any;
  transition?: any;
  exit?: any;
  showSelector?: boolean;
  isExpanded: boolean;
  isDeletePopupOpen: boolean;
  setIsDeletePopupOpen: (para: boolean) => void;
  setDeleteItemId: (para: string | null) => void;
}

export const CartItem: React.FC<CartItemProps> = ({
  id,
  sku,
  index,
  priceFormatted,
  priceNumeric,
  totalPriceFormatted,
  totalPriceNumeric,
  name,
  quantity,
  stock,
  currency,
  image,
  anim,
  init,
  transition,
  exit,
  showSelector = false,
  isExpanded,
  isDeletePopupOpen,
  setIsDeletePopupOpen,
  setDeleteItemId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = window.decodeURI(useLocation().pathname);

  const [isSelected, setIsSelected] = useState(false);
  const [isLastItemOnCart, setIsLastItemOnCart] = useState(false);
  const userEmail = useSelector(
    (state: RootState) => state.users.currentUser?.profile?.email
  );
  const setSelectedItems: any =
    useSelector((state: RootState) => state.cart.selectedItems) ?? [];

  const isAddToCartAPILoading = useSelector(
    (state: RootState) => state.payment?.isAddToCartAPILoading
  );
  const isProductsAPILoading = useSelector(
    (state: RootState) => state.app.UI.isProductsAPILoading
  );

  useEffect(() => {
    if (isSelected && setSelectedItems) {
      dispatch(markItemAsSelected({ id, mark: true }));
    } else if (setSelectedItems) {
      dispatch(markItemAsSelected({ id, mark: false }));
    }
  }, [isSelected]);

  /**
   *  increments the number of units of an item to purchase
   * @param { React.MouseEvent } event
   */
  function increaseItem(event: React.MouseEvent) {
    dispatch(incrementItemQuantity({ id }));
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    eventAPIDebounce(quantity + 1);
  }

  /**
   *  decrements the number of units of an item to purchase
   * @param { React.MouseEvent } event
   */
  function decreaseItem(event: React.MouseEvent) {
    if (quantity > 1) {
      dispatch(decrementItemQuantity({ id }));
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      eventAPIDebounce(quantity - 1);
    } else {
      setDeleteItemId(id);
      setIsDeletePopupOpen(!isDeletePopupOpen);
    }
  }

  const eventAPIDebounce = debounce(
    (qty: number) =>
      //* Triggering Events API on user actions
      addShopperEvent("add_to_cart", {
        url: `${window.location.href}`,
        product: { id, sku, name, priceNumeric },
        quantity: qty,
        email: userEmail && userEmail !== "" ? userEmail : undefined,
      }),
    400
  );

  const cartState = useSelector((state: RootState) => state.cart);

  const [markedItemsCount, setMarkedItemsCount] = useState(
    cartState.items.reduce((p, c) => (c.selected ? 1 : 0) + p, 0)
  );

  useEffect(() => {
    setMarkedItemsCount(
      cartState.items.reduce((p, c) => (c.selected ? 1 : 0) + p, 0)
    );
  }, [cartState.items]);

  // or "enable checkbox"
  const [showCheckBox, setShowCheckbox] = useState(false);

  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const app_mode = route.split("/")[1];
  const products = route.split("/")[3];

  useEffect(() => {
    if (app_mode === "ai-fast-checkout" && products && products !== "") {
      // Disable removing item when its the last item on cart
      if (cartState.items.length <= 1 && cartState.items?.[0]?.quantity <= 1) {
        console.log("It's the last item on cart & qty is 1 :", cartState.items);
        setIsLastItemOnCart(true);
      } else {
        setIsLastItemOnCart(false);
      }
    } else {
      setIsLastItemOnCart(false);
    }

    setShowCheckbox(
      () =>
        app_mode !== "ai-fast-checkout" ||
        isSelected ||
        markedItemsCount < cartState.items.length - 1
    );
  }, [showSelector, cartState.items, markedItemsCount, isSelected]);

  return (
    <>
      <div className={`cart-item-container`}>
        {showSelector && (
          <FakeCheckbox
            disabled={!showCheckBox}
            checked={isSelected}
            onClick={() => {
              setIsSelected((s) => !s);
            }}
          />
        )}
        <motion.div
          className={`cart-item ${
            (!isExpanded || isLastItemOnCart) && quantity > stock ? "error" : ""
          }`}
          animate={anim}
          transition={transition}
          initial={init}
          exit={exit}
          key={index}
          layoutId={id}
        >
          <div
            className={`cart-item-inner ${
              isExpanded && !isLastItemOnCart && quantity > stock ? "error" : ""
            }`}
            onClick={() => {
              if (isExpanded)
                navigate(`/ai-full-checkout/${cartState?.shop?.slug}/${sku}`);
            }}
          >
            <LazyLoadImage
              className={!isImgLoaded ? "cart-item-inner--placeholder" : ""}
              onLoad={() => {
                setIsImgLoaded(true);
              }}
              onError={() => {
                setIsImgLoaded(true);
              }}
              loading="lazy"
              src={image ? image : ""}
              alt="cart item"
            />
            {/* <img src={dummyImg} alt="cart item" /> */}
            <div className="cart-item-details">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="cart-item-name">{truncate(name, 75)}</span>
                <span className="cart-item-amount-container">
                  <div className="price-calc-holder">
                    <div>{priceFormatted}</div>
                    <b className="x-multiplication" style={{ color: "#999" }}>
                      X
                    </b>
                    <div>{quantity}</div>
                  </div>
                  <div className="cart-item-total-holder">
                    {!isAddToCartAPILoading && !isProductsAPILoading ? (
                      totalPriceFormatted && totalPriceFormatted !== "" ? (
                        totalPriceFormatted
                      ) : priceNumeric ? (
                        `${currency} ${(priceNumeric * quantity)?.toFixed(2)}`
                      ) : (
                        `${currency} 0,00`
                      )
                    ) : (
                      <Skeleton
                        duration={0.6}
                        width={`${
                          totalPriceFormatted
                            ? totalPriceFormatted?.trim().length
                            : 5
                        }ch`}
                      />
                    )}
                  </div>
                  {
                    //? Show low stock message
                    quantity > stock && (
                      <>
                        {stock > 0 ? (
                          <>
                            <p
                              className="low-stock-holder"
                              style={{ color: "red", fontWeight: "bold" }}
                            >
                              {t("SelectedQuantityUnavailable-1")}
                            </p>
                            <p className="low-stock-holder">
                              {t("SelectedQuantityUnavailable-2", {
                                stock: stock ?? 0,
                              })}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              className="low-stock-holder"
                              style={{ color: "red", fontWeight: "bold" }}
                            >
                              {t("SelectedProductUnavailable")}
                            </p>
                          </>
                        )}
                      </>
                    )
                  }
                </span>
              </div>
            </div>
          </div>
          <span className="input-amount-dial-holder">
            <InputAmount
              isLastItemOnCart={isLastItemOnCart}
              currentAmount={quantity}
              stock={stock}
              decrease={decreaseItem}
              increase={increaseItem}
              isDisabled={false}
            />
          </span>
        </motion.div>
      </div>
    </>
  );
};

export const CartItemLoading = () => (
  <div style={{ minHeight: "150px" }}>
    <div className="cart-item cart-item-loading">
      <div className="cart-item-inner">
        <div className="cart-image-loading animated-pulse" />
        <div className="cart-item-details">
          <span className="cart-item-name animated-pulse"></span>
          <span className="cart-item-amount-container">
            <div className="cart-item-amount animated-pulse"></div>
          </span>
          <span className="input-amount-dial-holder animated-pulse"></span>
        </div>
      </div>
    </div>
  </div>
);
