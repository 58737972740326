import { useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import OverviewPageLayout1 from "../../layout/layout1/OverviewPageLayout1";
import OverviewPageLayout2 from "../../layout/layout2/OverviewPageLayout2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { addShopperEvent } from "../../lib/api/events-methods";
import { getAllProductsByShopSlug } from "../../lib/api/products-methods";
import {
  setIsAICheckout,
  setToastMessage,
} from "../../store/reducers/appSlice";
import { addItem, setItems } from "../../store/reducers/cartSlice";
import { submitLogToBugsnag } from "../../lib/api/log";
import useTitle from "../../customHooks/useTitle";
import { AdupCartItem } from "../../interfaces/AdupCartItem";
import { setIsAddToCartAPILoading } from "../../store/reducers/paymentSlice";
import getImages from "../../lib/utils/get-images";

interface productsInterface {
  image: string;
  name: string;
  price: string;
  priceNumeric: number;
  cartItemsNum: number;
  currency: string;
  sku: string;
  type: string;
  id: string | number;
}

const OverviewPage = () => {
  const app_layout: any = useSelector(
    (state: RootState) => state.cart.shop.theme?.app_layout
  );

  /* ================================================================================ */
  const { t } = useTranslation();
  const route = window.decodeURI(useLocation().pathname);
  const shop: any = useSelector((state: RootState) => state.cart.shop);
  const userEmail = useSelector(
    (state: RootState) => state.users.currentUser?.profile?.email
  );

  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );
  const [allProducts, setAllProducts] = useState<productsInterface[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isShowMoreAvailable, setIsShowMoreAvailable] =
    useState<boolean>(false);
  const [isMoreProductsLoading, setIsMoreProductsLoading] =
    useState<boolean>(false);
  const [isGoBackAvailable, seIisGoBackAvailable] = useState<boolean>(true);
  const cartItems: any[] =
    useSelector((state: RootState) => state.cart.items) ?? [];
  const fastcheckoutCartItems: any =
    useSelector((state: RootState) => state.cart.fastcheckoutCartItems) ??
    ([] as AdupCartItem[]);
  const lastCartUrl: any =
    useSelector((state: RootState) => state.app?.UI?.lastCartUrl) ?? "";

  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );

  const shop_slug = route?.split("/")[1];

  //* In order to persist the fast-checkout cart; Update the actual cart items via fastcheckoutCartItems
  useEffect(() => {
    store.dispatch(setItems(fastcheckoutCartItems));
  }, []);

  useEffect(() => {
    //* Products Get All API Call
    fetchAllProducts();

    //* Triggering Events API on user actions
    addShopperEvent("view_page", {
      url: `${window.location.href}`,
      page: "products",
      email: userEmail && userEmail !== "" ? userEmail : undefined,
    });

    //* Preventing price loaders since cart is empty and it won't execute add-to-cart API call
    if (cartItems?.length === 0) {
      store.dispatch(setIsAddToCartAPILoading(false));
    }
  }, [route]);

  useEffect(() => {
    if (lastCartUrl !== "") {
      seIisGoBackAvailable(true);
    } else {
      seIisGoBackAvailable(false);
    }
  }, [lastCartUrl]);

  useTitle(`${shop.name} - Powered by ${merchantTheme?.merchant_name}`);

  const fetchAllProducts = () => {
    try {
      setIsShowMoreAvailable(true);
      setIsMoreProductsLoading(true);
      store.dispatch(setIsAICheckout(false));
      /* ==================== */
      const res: any = getAllProductsByShopSlug(shop_slug, page);
      res.then((data: any) => {
        if (data === null) {
          setIsMoreProductsLoading(false);
          setIsShowMoreAvailable(false);
          return;
        }

        const responseProducts = data?.data;

        //TODO: Remove this after adding pagination
        if (responseProducts?.length < 8) {
          setIsShowMoreAvailable(false);
        } else {
          setIsShowMoreAvailable(true);
        }

        if (responseProducts?.length > 0) {
          const finalArray: productsInterface[] = responseProducts.map(
            (item: any) => {
              const isProductUnavailable: boolean =
                (item?.type === "variable" &&
                  (item?.variants_count === 0 || item?.variants?.length < 1)) ||
                (item?.type === "simple" && item?.stock <= 0);
              return {
                disabled: isProductUnavailable,
                stock: item?.stock,
                name: item?.name,
                priceFormatted:
                  item?.price?.values?.[item?.price?.default]?.price?.formatted,
                priceNumeric:
                  item?.price?.values?.[item?.price?.default]?.price?.major,
                cartItemsNum: 1,
                image: getImages(item?.images, "medium", "only_first")[0],
                currency: item?.price?.default,
                sku: item?.sku,
                type: item?.type,
                id: item?.id,
              };
            }
          );
          setPage(page + 1);
          setAllProducts(allProducts.concat(finalArray));
          setIsMoreProductsLoading(false);
        } else {
          setAllProducts([]);
        }
      });
      /* ==================== */
    } catch (error) {
      setIsMoreProductsLoading(false);
      console.log("Products Get All API Error :", error);

      submitLogToBugsnag("error", `Products Get All API Error : ${error}`);

      store.dispatch(
        setToastMessage({
          text: t("ProductsError"),
          type: "ERROR",
        })
      );
    }
  };

  const addToFastcheckoutCart = (item: any) => {
    let sku = item?.sku;
    if (sku) {
      //? Checking if the product item is already in the Cart
      if (cartItems?.some((item) => item.sku === sku)) {
        store.dispatch(
          setToastMessage({
            text: t("ItemAlreadyInCart"),
            type: "INFO",
          })
        );
      } else {
        const isProductUnavailable: boolean =
          (item?.type === "variable" &&
            (item?.variants_count <= 0 || item?.variants?.length <= 0)) ||
          (item?.type === "simple" && item?.stock <= 0);
        store.dispatch(
          addItem({
            price: item?.priceNumeric ? item?.priceNumeric : 0,
            name: item?.name,
            currency: item.currency,
            image: item?.image,
            stock: item?.stock,
            disabled: isProductUnavailable,
            quantity: 1,
            id: item?.id,
            sku: item?.sku,
          })
        );
        store.dispatch(
          setToastMessage({
            text: t("AddedToCart"),
            type: "SUCCESS",
          })
        );
      }
    }
  };
  /* ================================================================================ */

  /* ======================= component render logics ========================= */
  const componentRenderLogics = (componentType: string) => {
    if (componentType === "fastCheckoutPageWrapper") {
      return isGoBackAvailable &&
        !isMoreProductsLoading &&
        cartItems?.length > 0
        ? true
        : false;
    }
    if (componentType === "singleProduct") {
      return allProducts.length > 0 ? true : false;
    }
    if (componentType === "overviewPageTitle") {
      return isGoBackAvailable ? true : false;
    }
    if (componentType === "noMoreProductsLoading") {
      return !isMoreProductsLoading ? true : false;
    }
    if (componentType === "customMessageBanner") {
      return settings?.is_show_custom_message && settings?.custom_message !== ""
        ? true
        : false;
    }
  };

  const renderLayout = () => {
    if (app_layout === "0") {
      return (
        <OverviewPageLayout2
          allProducts={allProducts}
          isMoreProductsLoading={isMoreProductsLoading}
          isShowMoreAvailable={isShowMoreAvailable}
          addToFastcheckoutCart={addToFastcheckoutCart}
          fetchAllProducts={fetchAllProducts}
          componentRenderLogics={componentRenderLogics}
        />
      );
    } else {
      return (
        <OverviewPageLayout1
          allProducts={allProducts}
          isMoreProductsLoading={isMoreProductsLoading}
          isShowMoreAvailable={isShowMoreAvailable}
          addToFastcheckoutCart={addToFastcheckoutCart}
          fetchAllProducts={fetchAllProducts}
          componentRenderLogics={componentRenderLogics}
        />
      );
    }
  };

  return renderLayout();
};

export default OverviewPage;
