import store from "../../../../store/store";
import { handlePaymentRequest } from "../../../api/payment-post-request";

export async function paysafecardPayFunction() {
  const paymentState = store.getState().payment;

  const selectedPaymentMethod = paymentState.paymentMethodSelected;

  if (
    (selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "paynl" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "mollie") ||
    selectedPaymentMethod?.name !== "paysafecard"
  ) {
    return;
  }
  handlePaymentRequest();
}
