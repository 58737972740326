import React, { useMemo } from "react";
import { PaymentMethodsView } from "../../../components/payment-methods-view/PaymentMethods";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setCartExpanded } from "../../../store/reducers/appSlice";
import { AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { BackArrowBlack } from "../../../assets/svg/svg-components/back-arrow-black";
import { motion } from "framer-motion";
import { AccountProfileForm } from "../../../components/account-profile-input-form/AccountProfileForm";
import SummaryThemeWise from "../../../components/theme-wise-switching/SummaryThemeWise";
import "../../../sass/layouts/layout2/desktop/FastCheckoutDesktopLayout2.scss";
import BillingAddressToggle from "../../../components/billing-is-shipping/BillingAddressToggle";
import Header from "../../../components/header/Header";
import PhoneForm from "../../../components/phone-form/PhoneForm";
import UserAddresses from "../../../components/user-addresses/UserAddresses";
import FastCheckoutBillingAddress from "../../../pages/fast-checkout/segments/FastCheckoutBillingAddress";
import FastCheckoutPaymentMount from "../../../pages/fast-checkout/segments/FastCheckoutPaymentMount";
import FastCheckoutShippingAddress from "../../../pages/fast-checkout/segments/FastCheckoutShippingAddress";
import AnimatedPayButtonBlack from "../../../pages/fast-checkout/subcomponents/AnimatedPayButtonBlack";
import FastCheckoutSinglePageWrapper from "../../../pages/fast-checkout/subcomponents/FastCheckoutSinglePageWrapper";
import UserSummaryTheme2 from "../../../themes/theme2/UserSummaryTheme2";
import CartOperatorTheme2 from "../../../themes/theme2/CartOperatorTheme2";
import PayButtonTheme2 from "../../../themes/theme2/PayButtonTheme2";

interface PageProps {
  app_mode: any;
  componentRenderLogics: (componentType: string) => boolean | undefined;
}

export const FastCheckoutDesktopLayout2: React.FC<PageProps> = ({
  app_mode,
  componentRenderLogics,
}) => {
  // we use this here for checking and setting the cart expansion state
  const isCartExpanded = useSelector(
    (state: RootState) => state.app?.UI?.cart?.expanded
  );

  const profileDetailsForm = useMemo(() => {
    return (
      <>
        <AccountProfileForm isCompactUserName={true} />
        <UserAddresses />
      </>
    );
  }, []);
  const dispatch = useDispatch();

  const renderUserDetails = () => {
    return (
      <>
        {componentRenderLogics("phoneInput") && (
          <div id="phoneInputRef001">
            <PhoneForm />
          </div>
        )}

        {componentRenderLogics("profileDetailsForm") && (
          <div id="profileDetailsFormRef001">{profileDetailsForm}</div>
        )}
      </>
    );
  };

  const renderNormalThemeContent = () => {
    return (
      <React.Suspense fallback={<div className="main-content-holder"></div>}>
        <div className="main-content-holder">
          {componentRenderLogics("header") && (
            <Header forceShowCompactCart={true} />
          )}
        </div>
        <div className="layout2Fastcheckout">
          <FastCheckoutSinglePageWrapper>
            {/* ===================================================================== */}
            <div className="layout2Fastcheckout__top">
              <motion.span
                className="heading-and-back-chevron"
                onClick={() => dispatch(setCartExpanded(false))}
              >
                <AnimatePresence>
                  {isCartExpanded && (
                    <motion.div
                      key={"back-chevron-black-container"}
                      animate={{ x: 0, opacity: 1 }}
                      initial={{ x: 10, opacity: 0 }}
                      exit={{ x: -10, opacity: 0 }}
                    >
                      <BackArrowBlack />
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.span>
              {componentRenderLogics("profileLink") && (
                <Link to={"/profile"}></Link>
              )}

              {/* ===================================================================== */}
              {componentRenderLogics("userSummary") && <UserSummaryTheme2 />}

              {componentRenderLogics("userDetails") && renderUserDetails()}

              {/* Only processed users can have multiple addresses saved */}
              {componentRenderLogics("shippingAddress") && (
                <FastCheckoutShippingAddress />
              )}

              {componentRenderLogics("billingAddress") && (
                <FastCheckoutBillingAddress />
              )}

              {componentRenderLogics("billingAddressToggle") && (
                <BillingAddressToggle />
              )}

              {/* {authState.user !== applicationUserType.OLD_USER && (
                  <CreateAccountToggle />
                )} */}

              {/* ===================================================================== */}

              <div
                id="fastCheckoutPaymentMountRef001"
                className="main-payment-card-content-holder"
              >
                {componentRenderLogics("paymentMount") && (
                  <FastCheckoutPaymentMount />
                )}
              </div>

              {componentRenderLogics("paymentMethods") && (
                <div id="paymentMethodsRef001">
                  <PaymentMethodsView />
                </div>
              )}

              {/* ===================================================================== */}
              {/* product quantity and add to cart */}
              {componentRenderLogics("cartOperatorSC") &&
                !componentRenderLogics("productUnavailable") &&
                !componentRenderLogics("productStockUnavailable") && (
                  <CartOperatorTheme2
                    onIncrement={() => {}}
                    onDecrement={() => {}}
                    cartItemNumber={3}
                    onAddToCartClick={() => {}}
                    isDisabled={false}
                  />
                )}
              {/* ===================================================================== */}
              {/* ===========pay button============= */}
              <PayButtonTheme2 />

              {componentRenderLogics("payButton") && <AnimatedPayButtonBlack />}

              {/* ================================================== */}
            </div>
            <div className="layout2Fastcheckout__bottom">
              <SummaryThemeWise app_mode={app_mode} />
            </div>
          </FastCheckoutSinglePageWrapper>
        </div>
      </React.Suspense>
    );
  };

  return <>{renderNormalThemeContent()}</>;
};
