import { useDispatch, useSelector } from "react-redux";
import { arrowHeadLeftSVG, cartSVG } from "../../assets/js/allCustomSVGs";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { RootState } from "../../store/store";
import FastCheckoutPageCartSegment from "../../pages/fast-checkout/segments/FastCheckoutPageCart";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { removeItem } from "../../store/reducers/cartSlice";
import DeleteCartItemMsg from "../delete-cart-item-message/DeleteCartItemMsg";

const CompactCart = () => {
  const dispatch = useDispatch();

  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);

  const cartData = useSelector((state: RootState) => state.cart);

  const isAnyUnavailableStocksDetected = useSelector(
    (state: RootState) => state.payment?.isAnyUnavailableStocksDetected
  );
  const isAddToCartAPILoading = useSelector(
    (state: RootState) => state.payment?.isAddToCartAPILoading
  );
  const isProductsAPILoading = useSelector(
    (state: RootState) => state.app.UI.isProductsAPILoading
  );

  let totalItems = 0;
  if (cartData.items.length > 0) {
    cartData.items.forEach((item) => {
      totalItems += item.quantity;
    });
  }
  const cartItemCount = cartData.items.length;
  const cartTotal = cartData?.priceCalculations?.subtotal?.formatted;

  useEffect(() => {
    if (cartItemCount <= 0) {
      setIsCartOpen(false);
    }
  }, [cartItemCount]);

  const deleteSelectedItems = () => {
    if (deleteItemId && deleteItemId !== "" && deleteItemId !== null) {
      dispatch(removeItem({ id: deleteItemId }));
    } else {
      dispatch(removeItem({}));
    }
  };

  const onCancelHandler = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
    setDeleteItemId(null);
  };

  return (
    <div
      data-compact-cart-open={isCartOpen ? "true" : "false"}
      className={`compactCart ${isAnyUnavailableStocksDetected ? "error" : ""}`}
    >
      <div
        className={`compactCart__count ${
          isAnyUnavailableStocksDetected ? "error" : ""
        }`}
      >
        {isAnyUnavailableStocksDetected && (
          <span style={{ fontSize: "20px" }}>!</span>
        )}
        {!isAnyUnavailableStocksDetected && <span>{totalItems}</span>}
      </div>
      {/*------------ hidden block start ------------*/}
      <div className="compactCart__itemsHidden">
        <div className="compactCart__itemsHidden--cart">
          <CustomSVGs svg={cartSVG} />
        </div>
        <div className="compactCart__itemsHidden--amount">
          {isAddToCartAPILoading || isProductsAPILoading ? (
            <h2
              style={{
                width: `${cartTotal ? cartTotal?.trim().length : 5}ch`,
                textAlign: "center",
              }}
            >
              <Skeleton duration={0.6} />
            </h2>
          ) : (
            <h2
              style={{
                width: `${cartTotal ? cartTotal?.trim().length : 5}ch`,
                textAlign: "center",
              }}
            >{`${cartTotal ? cartTotal : "0,00"}`}</h2>
          )}
        </div>
        <div className="compactCart__itemsHidden--arrow">
          <CustomSVGs svg={arrowHeadLeftSVG} />
        </div>
      </div>
      {/*------------ hidden block end --------------*/}
      <div className="compactCart__items">
        <div className="compactCart__items--cart">
          <CustomSVGs svg={cartSVG} />
        </div>
        <div className="compactCart__items--amount">
          {isAddToCartAPILoading || isProductsAPILoading ? (
            <h2
              style={{
                width: `${cartTotal ? cartTotal?.trim().length : 5}ch`,
                textAlign: "center",
              }}
            >
              <Skeleton duration={0.6} />
            </h2>
          ) : (
            <h2
              style={{
                width: `${cartTotal ? cartTotal?.trim().length : 5}ch`,
                textAlign: "center",
              }}
            >{`${cartTotal ? cartTotal : "0,00"}`}</h2>
          )}
        </div>
        {cartItemCount > 0 && (
          <div
            onClick={() => {
              if (cartItemCount) setIsCartOpen(!isCartOpen);
            }}
            className="compactCart__items--arrow"
          >
            <CustomSVGs svg={arrowHeadLeftSVG} />
          </div>
        )}
      </div>

      {isCartOpen && (
        <>
          <div className="compactCart__dropdown">
            {/* {app_mode === "ai-fast-checkout" &&
              shop_slug !== cartData?.shop?.slug && (
                <FastCheckoutPageCartSegment isCompactCart={true} />
              )} */}
            <FastCheckoutPageCartSegment
              isCompactCart={true}
              isDeletePopupOpen={isDeletePopupOpen}
              setIsDeletePopupOpen={setIsDeletePopupOpen}
              setDeleteItemId={setDeleteItemId}
            />
          </div>
          <div
            onClick={() => {
              setIsCartOpen(!isCartOpen);
            }}
            className="compactCart__overlay"
          ></div>
        </>
      )}
      {isDeletePopupOpen && (
        <DeleteCartItemMsg
          onDeleteClick={deleteSelectedItems}
          onCancelClick={onCancelHandler}
        />
      )}
    </div>
  );
};

export default CompactCart;
