import {
  fullBrowserVersion,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
  engineName,
  engineVersion,
} from "react-device-detect";
import { AdupCartItem } from "../../interfaces/AdupCartItem";
import store from "../../store/store";

export const truncate = (string: string, n: number): string => {
  return string?.length > n ? string.substr(0, n - 1) + "..." : string;
};

export const getAddressFromAddressesObj = (obj: object) => {
  //INPUT => Current user's addresses from user state as an (OBJECT)
  //RETURNS => The first address with counrty formated.
  //MOSTLY USED => To set the last used address after deleting an address.

  //getting address from user store
  const selectedAddress = Object.values(obj)[0];

  //coppying address from user store
  const selectedAddressCopy = Object.assign({}, selectedAddress);

  //getting country out of original address
  const selectedAddressCountry = selectedAddress?.country_name;

  //assigning selected country to coppied address
  selectedAddressCopy.country = selectedAddressCountry;

  return selectedAddressCopy;
};

export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export function capitalizeFirstLetter(string: string) {
  const stringInLowerCase = string.toLocaleLowerCase();
  return stringInLowerCase.charAt(0).toUpperCase() + stringInLowerCase.slice(1);
}

// convert (2022-10-19T05:38:59.000000Z) to => (2022-10-19 05:38 AM)
export function formatDate(isoString: string) {
  const date = new Date(isoString);
  const DateComponenents = (() => {
    const fields = ["YYYY", "MM", "DD", "HH", "mm", "ss", "SSS"];
    const pattern =
      /^(\d{4})\-(\d{2})\-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;
    return (date: any) => {
      return date
        .toISOString()
        .match(pattern)
        .slice(1)
        .reduce((r: any, v: any, i: any) => {
          return Object.assign(r, { [fields[i]]: v });
        }, {});
    };
  })();
  let c = DateComponenents(date); // Extract the componenents
  let hours: any = parseInt(c["HH"], 10); // Grab the 24-hour piece
  let meridiem = hours < 12 ? "AM" : "PM"; // Determine AM or PM
  hours %= 12; // Modular 0 - 12
  if (hours === 0) hours = 12; // Shift 0am to 12am
  hours = ("00" + hours).substr(-2); // Pad the hours
  return `${c["YYYY"]}-${c["MM"]}-${c["DD"]} ${hours}:${c["mm"]} ${meridiem}`;
}

export function getUniqueBrowserId() {
  const dt = new Date();
  let diffTZ = dt.getTimezoneOffset();
  var navigator_info: any = window.navigator;
  var screen_info: any = window.screen;

  const crypt = (salt: any, text: string) => {
    const textToChars = (text: string) =>
      text.split("").map((c: string) => c.charCodeAt(0));
    const byteHex = (n: any) => "0" + Number(n).toString(16);
    const applySaltToChar = (code: any) =>
      textToChars(salt).reduce((a: number, b: number) => a ^ b, code);

    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  };

  var uid: string = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent;
  uid += navigator_info.plugins.length;
  uid += screen_info.height || "";
  uid += screen_info.width || "";
  uid += screen_info.pixelDepth || "";

  const hash =
    diffTZ +
    engineName +
    fullBrowserVersion +
    mobileVendor +
    osVersion +
    mobileModel +
    engineVersion +
    osName +
    `${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

  // encrypting
  const encrypted_hash_val: string = crypt(uid, hash);

  return encrypted_hash_val;
}

export function generateFastCheckoutUrlUsingCartItems(
  cartItems: AdupCartItem[]
) {
  // Initialize an empty array to hold the SKUs with their QTYs
  let skuWithAmounts: any = [];

  cartItems.forEach((item) => {
    // Encode SKU to handle special characters correctly
    let encodedSku = encodeURIComponent(item?.sku);

    // Check if the QTY is a number, if not, default to 1
    let quantity = !isNaN(item?.quantity) ? item?.quantity : 1;

    // Append the encoded SKU along with the QTY in brackets
    skuWithAmounts.push(`${encodedSku}[${quantity}]`);
  });

  // Join the SKU and QTY pairs into a single string, separated by commas
  return skuWithAmounts.join(",");
}

export function generateAddToCartArrayUsingCartItems(
  cartItems: AdupCartItem[]
) {
  return cartItems.map((cartItems) => {
    return {
      sku: cartItems?.sku,
      qty: cartItems?.quantity,
    };
  });
}

export function getCountryCodeByCountryName(name: string) {
  const countries = store.getState().app?.appData?.countries;
  for (let key in countries) {
    if (countries[key].name === name) {
      return countries[key].cca2;
    }
  }
  return undefined;
}

//* Check if a string is empty or not
export function isNotEmptyString(string: string | null | undefined) {
  if (
    string === null ||
    string === undefined ||
    string === "" ||
    string === "null" ||
    string?.trim()?.length === 0
  ) {
    return false;
  } else {
    return true;
  }
}

//* get the current timestamp and append it as a query parameter to the base URL for cache busting
export default function getCacheBustedUrl(baseUrl: string): string {
  const timestamp = new Date().getTime(); // Get current timestamp
  return `${baseUrl}?t=${timestamp}`; // Append timestamp as query parameter
}
