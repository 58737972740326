import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import ProductInfoSC from "../socialCommerce/ProductInfoSC";
import VariantSelector from "../socialCommerce/VariantSelector";
import Theme1ProductTitle from "../../themes/theme1/Theme1ProductTitle";
import Theme1VariantSelector from "../../themes/theme1/Theme1VariantSelector";
import Theme1ProductPrice from "../../themes/theme1/Theme1ProductPrice";
import "../../sass/themes/theme1/theme1.scss";

interface Props {
  data: any;
  setLocalAttributeSkuMap: any;
}

const ProductDetailsThemeWise = ({ data, setLocalAttributeSkuMap }: Props) => {
  const { t } = useTranslation();
  const {
    allProducts,
    productName,
    productCurrency,
    productPrice,
    productDescription,
    delivery,
    isNoItemSelected,
    isInvalidVariantSelected,
    attributes,
    defaultSelectedAttributes,
    productType,
  } = data;

  const product_details_theme: any = useSelector(
    (state: RootState) => state.cart.shop.theme.product_details_theme
  );

  const renderProductDetailsThemeWise = () => {
    if (product_details_theme === "theme1") {
      return (
        <div className="product_details_theme1">
          {/* ----------------product details start---------------- */}
          {allProducts && (
            <Theme1ProductTitle
              productName={productName}
              productRating={"4.5"} //add the ratings value when the API contains such a property
              productSales={0}
            />
          )}
          <div className="theme1-productDescription">{productDescription}</div>

          {isNoItemSelected && !isInvalidVariantSelected && (
            <p
              className="socialCommerce__variantNotAvailable"
              style={{ marginBottom: 0 }}
            >
              {t("NoItemsChosen")}
            </p>
          )}
          {productType === "variable" && attributes && (
            <Theme1VariantSelector
              attributes={attributes}
              defaultSelectedAttributes={defaultSelectedAttributes}
              setMap={setLocalAttributeSkuMap}
            />
          )}
          {/* -------------------------------- */}
          <Theme1ProductPrice
            currency={""} //? Currency will be sent along with the formatted price via BE
            price={productPrice}
          />

          {/* ----------------product details end------------------ */}
        </div>
      );
    } else {
      return (
        <>
          {allProducts && (
            <ProductInfoSC
              title={productName}
              currency={""} //? Currency will be sent along with the formatted price via BE
              price={productPrice}
              productDetails={productDescription}
              deliveryDetails={delivery}
            />
          )}
          {isNoItemSelected && !isInvalidVariantSelected && (
            <p
              className="socialCommerce__variantNotAvailable"
              style={{ marginBottom: 0 }}
            >
              {t("NoItemsChosen")}
            </p>
          )}
          {productType === "variable" && attributes && (
            <VariantSelector
              attributes={attributes}
              defaultSelectedAttributes={defaultSelectedAttributes}
              setMap={setLocalAttributeSkuMap}
            />
          )}
        </>
      );
    }
  };

  return renderProductDetailsThemeWise();
};

export default ProductDetailsThemeWise;
