/* eslint-disable camelcase */
import store from "../../store/store";
import {
  setEncryptedBuckarooCardData,
  setLastAddToCartCallPayload,
  setPaymentErrorOccurred,
  setPaymentMethodOptionSelected,
} from "../../store/reducers/paymentSlice";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  enableFastCheckoutUISegment,
  initialHiddenSegments,
  setIsPayButtonLoading,
  setToastMessage,
  updateUserType,
} from "../../store/reducers/appSlice";
import {
  setUserToken,
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import { t } from "i18next";
import { getKeyByValue } from "../utils/helper-functions";
import updateStateFromShopperObject from "../utils/shopper-object-to-state";
import updateStateFromPaymentMethodsBlock from "../utils/payment-methods-to-state";
import { submitLogToBugsnag } from "./log";
import { Shopper, orderProcessCall } from "./payment-calls/order-payment";
import { setCartId, setShopWiseCartIds } from "../../store/reducers/cartSlice";

/**
 *@param { boolean } isWalletPayment
 *@param { Shopper } walletShopperInfo
 *@param { any } walletPaymentDetails
 */
export async function handlePaymentRequest(
  isWalletPayment?: boolean,
  walletShopperInfo?: Shopper,
  walletPaymentDetails?: any
) {
  const verificationState = store.getState().users.currentUser.verified;
  if (
    verificationState?.state === verificationStates.pending &&
    !isWalletPayment
  ) {
    console.log("Phone Number verification Pending");
    store.dispatch(
      setToastMessage({
        text: t("VerifyPrompt"),
        type: "INFO",
      })
    );
    store.dispatch(setIsPayButtonLoading(false));
    store.dispatch(setPaymentErrorOccurred(false));
  } else {
    try {
      const processResponse = await processUserPayment(
        isWalletPayment,
        walletShopperInfo,
        walletPaymentDetails
      );

      if (
        processResponse?.data?.payment?.checkout_url &&
        processResponse?.data?.payment?.checkout_url !== ""
      ) {
        const redirectURL = processResponse?.data?.payment?.checkout_url;

        // Clearing encrypted Card details from state after payment is successful
        store.dispatch(
          setEncryptedBuckarooCardData({
            value: "",
            issuer: "",
          })
        );

        //* Automatically redirecting to Checkout URL if non Wallet Payment; else redirection will be handled by Wallet Component
        if (!isWalletPayment) {
          window.parent.location.replace(redirectURL);
        }
      }

      store.dispatch(setIsPayButtonLoading(false));
      store.dispatch(setPaymentErrorOccurred(false));
      return processResponse;
    } catch (e: any) {
      console.log(e);
      store.dispatch(setIsPayButtonLoading(false));
      store.dispatch(setPaymentErrorOccurred(false));
      submitLogToBugsnag("error", `${e ? e.toString() : t("UnexpectedError")}`);
      store.dispatch(
        setToastMessage({
          text: e ? e.toString() : t("UnexpectedError"),
          type: "ERROR",
        })
      );
    }
  }
}

/**
 *@param { boolean } isWalletPayment
 *@param { Shopper } walletShopperInfo
 *@param { any } walletPaymentDetails
 */
async function processUserPayment(
  isWalletPayment?: boolean,
  walletShopperInfo?: Shopper,
  walletPaymentDetails?: any
) {
  try {
    store.dispatch(setIsPayButtonLoading(true));
    const shopSlug = store.getState().cart?.shop?.slug; // current shop slug
    const cartId = store.getState().cart?.shopWiseCartIds?.[shopSlug]; // current cart id attached to the current shop slug
    const { token, accountPhone, profile } = store.getState().users.currentUser;
    const verificationState = store.getState().users.currentUser.verified;
    const { paymentMethodOptionSelected, createAccountFlag } =
      store.getState().payment;
    const shippingAddress =
      store.getState().users?.currentUser?.shipping?.shippingAddress;
    const billingAddress =
      store.getState().users?.currentUser?.shipping?.billingAddress;
    const countryCode = accountPhone.country.dialCode.replace("+", "");
    const paymentMethods = store.getState().payment
      .availablePaymentMethods as any;
    const savedPaymentAttributes = store.getState().payment
      ?.savedPaymentAttributes as any;
    const paymentMethodType = store.getState().payment?.paymentMethodSelected
      .name as string;
    const bankOptions = store.getState().payment?.paymentMethodSelected
      ?.banks as any;
    const paymentMethodGateway = store
      .getState()
      .payment?.paymentMethodSelected.serviceProvider.toLocaleLowerCase() as string;
    const { encryptedMollieCardToken, bicNumber, ibanNumber } =
      store.getState().payment;

    const checkBankAvailability = (paymentMethod: string) => {
      if (bankOptions) {
        let bankAvailable = getKeyByValue(
          bankOptions,
          paymentMethodOptionSelected
        );
        if (!bankAvailable) {
          if (savedPaymentAttributes?.[paymentMethod]) {
            console.log(
              paymentMethodOptionSelected,
              ": Bank is not available",
              ", therefore previously saved bank is selected by default : ",
              savedPaymentAttributes?.[paymentMethod]
            );
            store.dispatch(
              setPaymentMethodOptionSelected({
                key: savedPaymentAttributes?.[paymentMethod] ?? "",
                optionData:
                  bankOptions[savedPaymentAttributes?.[paymentMethod]] ?? "",
              })
            );
            return savedPaymentAttributes?.[paymentMethod];
          } else {
            console.log(
              paymentMethodOptionSelected,
              ": Bank is not available",
              ", therefore 1st bank is selected by default : ",
              Object.keys(bankOptions)[0]
            );
            store.dispatch(
              setPaymentMethodOptionSelected({
                key: Object.keys(bankOptions)[0] ?? "",
                optionData: bankOptions[Object.keys(bankOptions)[0]] ?? "",
              })
            );
            return Object.keys(bankOptions)[0];
          }
        } else {
          console.log("Bank is available : ", paymentMethodOptionSelected);
          return bankAvailable;
        }
      }
    };

    const shopperObject = {
      country_code: countryCode,
      phone_number: accountPhone?.number,
      first_name: profile?.name?.first,
      last_name: profile?.name?.second,
      shipping_address: {
        house_number: shippingAddress?.number,
        street: shippingAddress?.street,
        street2: shippingAddress?.street2,
        city: shippingAddress?.city,
        province: shippingAddress?.province,
        postcode: shippingAddress?.postcode,
        country: shippingAddress?.country_name,
        country_code: shippingAddress?.country_code,
      },
      billing_address: {
        house_number:
          billingAddress?.country_name !== ""
            ? billingAddress?.number
            : shippingAddress?.number,
        street:
          billingAddress?.country_name !== ""
            ? billingAddress?.street
            : shippingAddress?.street,
        street2:
          billingAddress?.country_name !== ""
            ? billingAddress?.street2
            : shippingAddress?.street2,
        city:
          billingAddress?.country_name !== ""
            ? billingAddress?.city
            : shippingAddress?.city,
        province:
          billingAddress?.country_name !== ""
            ? billingAddress?.province
            : shippingAddress?.province,
        postcode:
          billingAddress?.country_name !== ""
            ? billingAddress?.postcode
            : shippingAddress?.postcode,
        country:
          billingAddress?.country_name !== ""
            ? billingAddress?.country_name
            : shippingAddress?.country_name,
        country_code:
          billingAddress?.country_name !== ""
            ? billingAddress?.country_code
            : shippingAddress?.country_code,
      },
      email: profile?.email,
    };

    let paymentAttributes;

    let paymentDetailsObject;

    const requireFields = Boolean(
      countryCode &&
        accountPhone?.number &&
        paymentMethodType &&
        paymentMethodGateway &&
        profile?.name?.first &&
        profile?.name?.second &&
        profile?.email &&
        shippingAddress?.number &&
        shippingAddress?.street &&
        shippingAddress?.city &&
        shippingAddress?.province &&
        shippingAddress?.postcode &&
        shippingAddress?.country_name
    );

    if (!requireFields && !isWalletPayment) {
      //? Prevent Process API execution if required fields are not available
      store.dispatch(setIsPayButtonLoading(false));
      store.dispatch(setPaymentErrorOccurred(false));
      submitLogToBugsnag("error", t("MissingProfileDetailsError"));
      store.dispatch(
        setToastMessage({
          text: t("MissingProfileDetailsError"),
          type: "INFO",
        })
      );
      return null;
    } else {
      //* Setting up paymentDetailsObject based on paymentMethodGateway  & paymentMethodType
      if (paymentMethodGateway === "buckaroo") {
        console.log("paymentMethodGateway : ", paymentMethodGateway);
        if (paymentMethodType === "ideal") {
          // For iDeal ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            issuer:
              paymentMethodOptionSelected && paymentMethodOptionSelected !== ""
                ? paymentMethodOptionSelected
                : savedPaymentAttributes?.ideal_bank
                ? bankOptions[savedPaymentAttributes?.ideal_bank]
                : bankOptions
                ? bankOptions[Object.keys(bankOptions)[0]]
                : "",
          };
        } else if (paymentMethodType === "giropay") {
          // For Giropay ( buckaroo )👇🏼
          if (
            bicNumber &&
            bicNumber !== "" &&
            ibanNumber &&
            ibanNumber !== ""
          ) {
            paymentDetailsObject = {
              bic: bicNumber,
              CustomerIBAN: ibanNumber,
            };
          } else {
            store.dispatch(
              setToastMessage({
                text: t("EmptyPaymentDetailsError"),
                type: "ERROR",
              })
            );
            return;
          }
        } else if (paymentMethodType === "p24") {
          // For przelewy24 ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            CustomerEmail: profile?.email,
            CustomerFirstName: profile?.name?.first,
            CustomerLastName: profile?.name?.second,
          };
        } else if (paymentMethodType === "bancontact") {
          // For bancontactmrcash ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            savetoken: true,
          };
        } else if (paymentMethodType === "klarna") {
          // For klarnakp ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            Street:
              billingAddress?.street !== ""
                ? billingAddress?.street
                : shippingAddress?.street,
            FirstName: profile?.name?.first,
            LastName: profile?.name?.second,
            City:
              billingAddress?.city !== ""
                ? billingAddress?.city
                : shippingAddress?.city,
            Country:
              billingAddress?.country?.iso2 !== ""
                ? billingAddress?.country?.iso2
                : shippingAddress?.country?.iso2,
            // ArticleNumber: "12345", // ?? //TODO
            // ArticleQuantity: "1", // ?? //TODO
            // ReservationNumber: "2377577xxx", // ?? //TODO
            // VatPercentage: "7", // ?? //TODO
            // Identifier: "Articlenumber12345", // ?? //TODO
            // StreetNumber: 90, // ?? //TODO
          };
        } else if (paymentMethodType === "billink") {
          // For billink ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            FirstName: profile?.name?.first,
            LastName: profile?.name?.second,
            Salutation: "Male",
            Street:
              billingAddress?.street !== ""
                ? billingAddress?.street
                : shippingAddress?.street,
            PostalCode:
              billingAddress?.postcode !== ""
                ? billingAddress?.postcode
                : shippingAddress?.postcode,
            City:
              billingAddress?.city !== ""
                ? billingAddress?.city
                : shippingAddress?.city,
            Country:
              billingAddress?.country?.iso2 !== ""
                ? billingAddress?.country?.iso2
                : shippingAddress?.country?.iso2,
            MobilePhone: accountPhone?.number,
            Email: profile?.email,
            // Trackandtrace: "TR0F123456789", // ?? //TODO
            // VATNumber: "2", // ?? //TODO
            // Quantity: "1", // ?? //TODO
            // Identifier: "Articlenumber12345", // ?? //TODO
            // Description: "Blue Toy Car", // ?? //TODO
            // VatPercentage: "7", // ?? //TODO
            // GrossUnitPriceIncl: "10", // ?? //TODO
            // GrossUnitPriceExcl: "5", // ?? //TODO
            // Category: "B2C", // ?? //TODO
            // CareOf: "John Smith", // ?? //TODO
            // ChamberOfCommerce: "Kvk123456789", // ?? //TODO
            // Initials: "T", // ?? //TODO
            // StreetNumber: 90, // ?? //TODO
            // BirthDate: "01-01-1990", // ?? //TODO
          };
        }
      } else if (
        paymentMethodGateway === "mollie" ||
        paymentMethodGateway === "aduppay"
      ) {
        console.log("paymentMethodGateway : ", paymentMethodGateway);
        if (paymentMethodType === "card") {
          // For Card ( mollie , aduppay ) 👇🏼
          paymentDetailsObject = {
            cardToken: encryptedMollieCardToken,
          };
        } else if (paymentMethodType === "ideal") {
          // For iDeal ( mollie , aduppay ) 👇🏼
          paymentDetailsObject = {
            issuer:
              paymentMethodOptionSelected && paymentMethodOptionSelected !== ""
                ? paymentMethodOptionSelected
                : savedPaymentAttributes?.ideal_bank
                ? bankOptions[savedPaymentAttributes?.ideal_bank]
                : bankOptions
                ? bankOptions[Object.keys(bankOptions)[0]]
                : "",
          };
        }
      } else if (paymentMethodGateway === "paynl") {
        console.log("paymentMethodGateway : ", paymentMethodGateway);
        if (paymentMethodType === "ideal") {
          // For iDeal ( paynl ) 👇🏼
          paymentDetailsObject = {
            issuer:
              paymentMethodOptionSelected && paymentMethodOptionSelected !== ""
                ? paymentMethodOptionSelected
                : savedPaymentAttributes?.ideal_bank
                ? bankOptions[savedPaymentAttributes?.ideal_bank]
                : bankOptions
                ? bankOptions[Object.keys(bankOptions)[0]]
                : "",
          };
        }
      }

      //* Setting up paymentAttributes based on paymentMethodType
      if (paymentMethodType === "ideal") {
        paymentAttributes = [
          {
            ideal_bank: checkBankAvailability("ideal_bank"),
          },
        ];
      } else if (bicNumber && ibanNumber && paymentMethodType === "giropay") {
        paymentAttributes = [
          {
            giropay_bic_number: bicNumber,
            giropay_iban_number: ibanNumber,
          },
        ];
      }

      const result = await orderProcessCall({
        cart_id: cartId,
        shop_slug: shopSlug,
        payment_method: paymentMethods?.[paymentMethodType]?.slug,
        payment_attributes: paymentAttributes ?? {},
        payment_details:
          isWalletPayment && walletPaymentDetails
            ? walletPaymentDetails
            : paymentDetailsObject ?? {},
        shopper:
          isWalletPayment && walletShopperInfo
            ? walletShopperInfo
            : shopperObject,
        create_account: token ? undefined : createAccountFlag,
      });

      if (
        result &&
        result?.success === true &&
        result?.message === "Order created"
      ) {
        //* revoke cartId & data after successfully processing the order
        store.dispatch(setCartId(undefined));
        store.dispatch(
          setShopWiseCartIds({ shopSlug: shopSlug, cartId: undefined })
        );
        store.dispatch(setLastAddToCartCallPayload(undefined));

        const key = result?.data?.access_token;
        const shopper = result?.data?.order?.shopper;

        if (key && createAccountFlag === true) {
          store.dispatch(setUserToken(key));
        }

        if (createAccountFlag !== true) {
          store.dispatch(setUserToken(undefined));
        }

        //* NEW User saving in state
        if (
          shopper &&
          verificationState?.state !== verificationStates.verified
        ) {
          console.log("NEW User saving in state...");
          updateStateFromShopperObject(shopper);
          updateStateFromPaymentMethodsBlock(shopper);
          for (const segment of Object.keys(initialHiddenSegments)) {
            store.dispatch(disableFastCheckoutUISegment(segment));
          }

          store.dispatch(
            updateUserType({ user: applicationUserType.OLD_USER })
          );
          store.dispatch(
            setUserVerified({
              ...verificationState,
              state: verificationStates.verified,
              _: true,
              loggedInMethod: "otp",
              firstPayment: "pending",
            })
          );
          store.dispatch(enableFastCheckoutUISegment("ACCOUNT_SEGMENT"));
          store.dispatch(enableFastCheckoutUISegment("DETAILS_SEGMENT"));
          store.dispatch(
            enableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT")
          );
          store.dispatch(
            enableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT")
          );
          store.dispatch(
            enableFastCheckoutUISegment("PAYMENT_METHODS_SEGMENT")
          );
          store.dispatch(
            enableFastCheckoutUISegment("CREATE_ADUP_ACCOUNT_SEGMENT")
          );
          console.log("NEW User saved in state ✅");
        }
        store.dispatch(setPaymentErrorOccurred(false));
      } else {
        store.dispatch(
          setToastMessage({
            text: result?.message ?? t("ProcessError"),
            type: "ERROR",
          })
        );
        store.dispatch(setPaymentErrorOccurred(false));
        store.dispatch(setIsPayButtonLoading(false));
      }

      return result;
    }
  } catch (error) {
    console.log("Catch @ processUserPayment :", error);
    submitLogToBugsnag("error", `Catch @ processUserPayment : ${error}`);
    store.dispatch(
      setToastMessage({
        text: t("ProcessError"),
        type: "ERROR",
      })
    );
    store.dispatch(setPaymentErrorOccurred(false));
    store.dispatch(setIsPayButtonLoading(false));
  }
}
