import {
  clearAllPaymentMethods,
  setBicNumber,
  setIbanNumber,
  setNewPaymentMethod,
  setPaymentMethodOptionSelected,
  setSavedPaymentAttributes,
} from "../../store/reducers/paymentSlice";
import store from "../../store/store";

export default function updateStateFromPaymentMethodsBlock(shopper: any) {
  const paymentMethods = store.getState().payment
    .availablePaymentMethods as any;
  const paymentSecrets = store.getState().payment
    .availablePaymentSecrets as any;

  let paymentAttributes;

  //* store all saved payment attributes to state
  store.dispatch(setSavedPaymentAttributes(shopper?.payment_attributes));

  const clearComplete = store.dispatch(clearAllPaymentMethods(null));

  for (const key in paymentMethods) {
    const paymentMethodItem = paymentMethods[key];
    if (clearComplete) {
      store.dispatch(
        setNewPaymentMethod({
          id: paymentMethodItem?.id, // "1"
          name: key, // card
          displayTitle: paymentMethodItem?.label, // Credit Card
          iconURL: paymentMethodItem?.icon, // "default_logo.png"
          serviceProvider: paymentMethodItem?.payment_service_provider?.slug, // "Stripe"
          processor: paymentMethodItem?.processed_by, // "frontend"
          banks: paymentMethodItem?.banks, // { "ABN": "ABN AMRO", "ASN": "ASN Bank", "BUNQ": "Bunq"}
          data: paymentSecrets[paymentMethodItem?.payment_service]?.access_keys, // { "public_key": "pk_test_", "secret..."}
          fields: paymentMethodItem?.fields, // "fields": [{ "id": 8, "name": "issuer", "label": "Issuer","type": "text", "is_required": null, "is_active": 1 }]
          session: paymentMethodItem.session, //TODO not available in the API response
        })
      );
    }

    if (key === "ideal") {
      if (shopper && shopper?.payment_attributes?.ideal_bank) {
        paymentAttributes = shopper?.payment_attributes?.ideal_bank;
      }
    } else if (key === "p24") {
      if (shopper && shopper?.payment_attributes?.p24_bank) {
        paymentAttributes = shopper?.payment_attributes?.p24_bank;
      }
    } else if (key === "eps") {
      if (shopper && shopper?.payment_attributes?.eps_bank) {
        paymentAttributes = shopper?.payment_attributes?.eps_bank;
      }
    } else if (key === "sepa_debit") {
      if (shopper && shopper?.payment_attributes?.iban_number) {
        paymentAttributes = shopper?.payment_attributes?.sepa_debit_iban_number;
        store.dispatch(setIbanNumber(paymentAttributes));
      }
    } else if (key === "giropay") {
      if (
        shopper &&
        shopper?.payment_attributes?.bic_number &&
        shopper?.payment_attributes?.iban_number
      ) {
        paymentAttributes = {
          bic_number: shopper?.payment_attributes?.giropay_bic_number,
          iban_number: shopper?.payment_attributes?.giropay_iban_number,
        };
        store.dispatch(setBicNumber(paymentAttributes?.bic_number));
        store.dispatch(setIbanNumber(paymentAttributes?.iban_number));
      }
    }

    //* Setting up the Payment methods with option default value
    if (shopper?.last_used_payment_method?.common_alias === key) {
      if (key === "ideal" || key === "p24" || key === "eps") {
        if (paymentMethodItem?.data && paymentMethodItem?.banks) {
          const bankOptions = paymentMethodItem?.banks;
          store.dispatch(
            setPaymentMethodOptionSelected({
              key: paymentAttributes ?? Object.keys(bankOptions)[0],
              optionData:
                bankOptions[paymentAttributes] ??
                bankOptions[Object.keys(bankOptions)[0]],
            })
          );
        }
      }
    }
  }
}
