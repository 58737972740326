import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdupPaymentMethodSelection } from "../../interfaces/AdupPaymentMethod";

// Selected payment method structure
export const initialPaymentSelected: AdupPaymentMethodSelection = {
  id: "",
  name: "",
  displayTitle: "",
  iconURL: "",
  serviceProvider: "",
  processor: "frontend",
};

// Payment progress types
export const paymentProgressTypes = {
  PAYMENT_NOT_STARTED: "PAYMENT_NOT_STARTED",
  PAYMENT_STARTED: "PAYMENT_STARTED",
  PAYMENT_SUCCEEDED: "PAYMENT_SUCCEEDED",
  PAYMENT_FAILED: "PAYMENT_FAILED",
};

const initialAdupPaymentMethods: {
  [index: string]: AdupPaymentMethodSelection;
} = {};

const initialState = {
  allPaymentMethods: initialAdupPaymentMethods,
  availablePaymentMethods: {},
  availablePaymentSecrets: {},
  paymentMethodSelected: initialPaymentSelected,
  paymentMethodOptionSelected: "",
  paymentProgress: paymentProgressTypes.PAYMENT_NOT_STARTED,
  cartSessionId: "",
  discount: {},
  encryptedBuckarooCardData: { value: "", issuer: "" },
  encryptedMollieCardToken: "",
  isWalletPaymentsSupportedByDevice: false,
  isWalletPaymentMethodInUse: false,
  walletPaymentMethodTypeInUse: "",
  isPaymentMethodEditable: false,
  isPaymentMethodsLoading: false,
  paymentMethodLastUsed: "",
  ibanNumber: "",
  bicNumber: "",
  errorOccurred: false,
  isInitApiFetched: false,
  isInitAPILoading: true,
  isAnyUnavailableStocksDetected: true,
  isRequiredFieldsNotFilled: false,
  hasApiError: null,
  savedPaymentAttributes: null,
  lastSessionCallPayload: undefined,
  lastAddToCartCallPayload: undefined,
  isAddToCartAPILoading: true,
  createAccountFlag: false,
  stripe: {
    intentId: "",
    intentType: "",
    publicKey: "",
    clientSecret: "",
    returnURL: "",
  },
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentProgress: (
      state,
      action: PayloadAction<keyof typeof paymentProgressTypes>
    ) => {
      state.paymentProgress = action.payload;
    },
    setAvailablePaymentMethods: (state, action: any) => {
      state.availablePaymentMethods = action.payload;
    },
    setAvailablePaymentSecrets: (state, action: any) => {
      state.availablePaymentSecrets = action.payload;
    },
    setNewPaymentMethod: (
      state,
      action: PayloadAction<AdupPaymentMethodSelection>
    ) => {
      state.allPaymentMethods[action.payload.name] = action.payload;
    },
    clearAllPaymentMethods: (state, action) => {
      state.allPaymentMethods = {};
    },
    setPaymentMethodSelected: (
      state,
      action: PayloadAction<AdupPaymentMethodSelection>
    ) => {
      state.paymentMethodSelected = action.payload;
    },
    clearPaymentMethodSelected: (state, _) => {
      state.paymentMethodSelected = initialPaymentSelected;
    },
    setPaymentMethodOptionSelected: (
      state,
      action: PayloadAction<{ key: string; optionData: any }>
    ) => {
      state.paymentMethodOptionSelected = action.payload.optionData;
    },
    clearPaymentMethodOptionSelected: (state, _) => {
      state.paymentMethodOptionSelected = "";
    },
    setCartSessionId: (state, action: PayloadAction<string>) => {
      state.cartSessionId = action.payload;
    },
    setEncryptedBuckarooCardData: (
      state,
      action: PayloadAction<{ value: string; issuer: string }>
    ) => {
      state.encryptedBuckarooCardData = action.payload;
    },
    setEncryptedMollieCardToken: (state, action: PayloadAction<string>) => {
      state.encryptedMollieCardToken = action.payload;
    },
    setIsWalletPaymentsSupportedByDevice: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isWalletPaymentsSupportedByDevice = action.payload;
    },
    setIsWalletPaymentMethodInUse: (state, action: PayloadAction<boolean>) => {
      state.isWalletPaymentMethodInUse = action.payload;
    },
    setWalletPaymentMethodTypeInUse: (state, action: PayloadAction<any>) => {
      state.walletPaymentMethodTypeInUse = action.payload;
    },
    setIsPaymentMethodEditable: (state, action: PayloadAction<boolean>) => {
      state.isPaymentMethodEditable = action.payload;
    },
    setIsPaymentMethodsLoading: (state, action: PayloadAction<boolean>) => {
      state.isPaymentMethodsLoading = action.payload;
    },
    setPaymentMethodLastUsed: (state, action: PayloadAction<any>) => {
      state.paymentMethodLastUsed = action.payload;
    },
    setIbanNumber: (state, action: PayloadAction<string>) => {
      state.ibanNumber = action.payload;
    },
    setBicNumber: (state, action: PayloadAction<string>) => {
      state.bicNumber = action.payload;
    },
    setPaymentErrorOccurred: (state, action: PayloadAction<boolean>) => {
      state.errorOccurred = action.payload;
    },
    setIsInitApiFetched: (state, action: PayloadAction<boolean>) => {
      state.isInitApiFetched = action.payload;
    },
    setIsInitAPILoading: (state, action: PayloadAction<boolean>) => {
      state.isInitAPILoading = action.payload;
    },
    setIsAnyUnavailableStocksDetected: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAnyUnavailableStocksDetected = action.payload;
    },
    setIsRequiredFieldsNotFilled: (state, action: PayloadAction<boolean>) => {
      state.isRequiredFieldsNotFilled = action.payload;
    },
    setHasApiError: (state, action: PayloadAction<any>) => {
      state.hasApiError = action.payload;
    },
    setSavedPaymentAttributes: (state, action: PayloadAction<any>) => {
      state.savedPaymentAttributes = action.payload;
    },
    deleteSavedPaymentAttributes: (state, action) => {
      if (state.savedPaymentAttributes) {
        delete state.savedPaymentAttributes[action.payload];
        console.log("A Payment attribute was deleted : ", action.payload);
      }
    },
    setLastSessionCallPayload: (state, action: PayloadAction<any>) => {
      state.lastSessionCallPayload = action.payload;
    },
    setLastAddToCartCallPayload: (state, action: PayloadAction<any>) => {
      state.lastAddToCartCallPayload = action.payload;
    },
    setIsAddToCartAPILoading: (state, action: PayloadAction<boolean>) => {
      state.isAddToCartAPILoading = action.payload;
    },
    setCreateAccountFlag: (state, action: PayloadAction<boolean>) => {
      state.createAccountFlag = action.payload;
    },
    setStripeIntentId: (state, action: PayloadAction<string>) => {
      state.stripe.intentId = action.payload;
    },
    setStripeIntentType: (state, action: PayloadAction<string>) => {
      state.stripe.intentType = action.payload;
    },
    setStripePublicKey: (state, action: PayloadAction<string>) => {
      state.stripe.publicKey = action.payload;
    },
    setStripeClientSecret: (state, action: PayloadAction<string>) => {
      state.stripe.clientSecret = action.payload;
    },
    setStripeReturnURL: (state, action: PayloadAction<string>) => {
      state.stripe.returnURL = action.payload;
    },
  },
});

export const {
  setPaymentProgress,
  setNewPaymentMethod,
  setPaymentMethodSelected,
  clearPaymentMethodSelected,
  setCartSessionId,
  clearAllPaymentMethods,
  setAvailablePaymentMethods,
  setAvailablePaymentSecrets,
  clearPaymentMethodOptionSelected,
  setPaymentMethodOptionSelected,
  setEncryptedBuckarooCardData,
  setEncryptedMollieCardToken,
  setIsWalletPaymentsSupportedByDevice,
  setIsWalletPaymentMethodInUse,
  setIsPaymentMethodEditable,
  setIsPaymentMethodsLoading,
  setWalletPaymentMethodTypeInUse,
  setPaymentMethodLastUsed,
  setIbanNumber,
  setBicNumber,
  setPaymentErrorOccurred,
  setIsInitApiFetched,
  setIsInitAPILoading,
  setIsAnyUnavailableStocksDetected,
  setIsRequiredFieldsNotFilled,
  setHasApiError,
  setSavedPaymentAttributes,
  deleteSavedPaymentAttributes,
  setLastSessionCallPayload,
  setLastAddToCartCallPayload,
  setIsAddToCartAPILoading,
  setCreateAccountFlag,
  setStripeIntentId,
  setStripeIntentType,
  setStripePublicKey,
  setStripeClientSecret,
  setStripeReturnURL,
} = paymentSlice.actions;

export default paymentSlice.reducer;
