import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationUserType,
  setToastMessage,
} from "../../store/reducers/appSlice";
import {
  clearPaymentMethodSelected,
  setIsPaymentMethodEditable,
  setIsPaymentMethodsLoading,
  setIsWalletPaymentMethodInUse,
  setIsWalletPaymentsSupportedByDevice,
  setPaymentMethodSelected,
  setPaymentProgress,
} from "../../store/reducers/paymentSlice";
import { RootState } from "../../store/store";
import "./payment-request-components.scss";
import { submitLogToBugsnag } from "../../lib/api/log";
import {
  Address,
  Shopper,
  orderSessionCall,
} from "../../lib/api/payment-calls/order-payment";
import { addToCart } from "../../lib/api/cart-calls/add-to-cart";
import { AdupUserAddress } from "../../interfaces/AdupUserAddress";
import { handlePaymentRequest } from "../../lib/api/payment-post-request";
import { isNotEmptyString } from "../../lib/utils/helper-functions";

declare const window: any;

const MolliePaymentRequestAdupContainer = (
  molliePaymentRequestAdupContainerProps: any
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector((state: RootState) => state.cart.shop.currency);
  const cartId = useSelector((state: RootState) => state.cart?.id) ?? "";
  const authState = useSelector((state: RootState) => state.app.auth);
  const shop: any = useSelector((state: RootState) => state.cart?.shop);
  const grandTotal = useSelector(
    (state: RootState) => state.cart?.priceCalculations?.total?.major
  );
  const shippingCost = useSelector(
    (state: RootState) => state.cart?.priceCalculations?.shipping?.major
  );
  const isPaymentMethodEditable = useSelector(
    (state: RootState) => state.payment?.isPaymentMethodEditable
  );
  const { accountPhone, profile, shipping } = useSelector(
    (state: RootState) => state.users?.currentUser
  );
  const isAddToCartAPILoading = useSelector(
    (state: RootState) => state.payment?.isAddToCartAPILoading
  );
  const paymentMethods = useSelector(
    (state: RootState) => state.payment?.availablePaymentMethods
  ) as any;

  const [isApplePayButtonLoading, setIsApplePayButtonLoading] = useState(false);
  const [isApplePaySupported, setIsApplePaySupported] = useState(false);
  const [applePayRequest, setApplePayRequest] = useState({
    countryCode: "NL", //? Hardcoded as "NETHERLANDS" as the payment is processed via AdUp.
    supportedNetworks: ["amex", "maestro", "masterCard", "visa", "vPay"],
    merchantCapabilities: ["supports3DS"],
    requiredShippingContactFields: ["phone", "email", "name", "postalAddress"],
    shippingType: "shipping",
  }) as any;

  const otherPayments = () => {
    dispatch(clearPaymentMethodSelected(null));
    molliePaymentRequestAdupContainerProps.setPaymentMethodChanged(true);
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsWalletPaymentsSupportedByDevice(false));
  };

  const skipAvailableWalletPayments = () => {
    dispatch(clearPaymentMethodSelected(null));
    dispatch(setIsWalletPaymentsSupportedByDevice(true));
    molliePaymentRequestAdupContainerProps.setPaymentMethodChanged(true);
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsPaymentMethodEditable(false));
  };

  useEffect(() => {
    dispatch(setIsPaymentMethodsLoading(true));
  }, []);

  useEffect(() => {
    if (isApplePaySupported) dispatch(setIsWalletPaymentMethodInUse(true));
  }, [isApplePaySupported]);

  useEffect(() => {
    let updatedApplePayRequest: any = {
      ...applePayRequest,
      currencyCode: currency.toLocaleUpperCase(),
      total: {
        label: `${shop?.name} ${t("CartTotal")}`,
        amount: grandTotal ?? 0.0,
      },
      shippingMethods: [
        {
          label: `${t("Shipping")}`,
          detail: `${t("WalletPayShippingDescription")}`,
          amount: shippingCost ?? 0.0,
          identifier: "shipping",
        },
      ],
    };

    // if (
    //   authState.user === applicationUserType.NEW_USER &&
    //   accountPhone &&
    //   accountPhone?.number &&
    //   accountPhone?.country?.countryCode &&
    //   accountPhone?.number?.length > 3
    // ) {
    //   updatedApplePayRequest.shippingContact = {
    //     phoneNumber: accountPhone?.country?.dialCode + accountPhone?.number,
    //     givenName: undefined,
    //     familyName: undefined,
    //     emailAddress: undefined,
    //     addressLines: undefined,
    //     locality: undefined,
    //     administrativeArea: undefined,
    //     postalCode: undefined,
    //     country: undefined,
    //     countryCode: undefined,
    //   };
    // } else
    if (authState.user === applicationUserType.OLD_USER) {
      updatedApplePayRequest.shippingContact = {
        phoneNumber: accountPhone?.country?.dialCode + accountPhone?.number,
        givenName: profile?.name?.first,
        familyName: profile?.name?.second,
        emailAddress: profile?.email,
        addressLines: [
          `${shipping?.shippingAddress?.number}`,
          `${shipping?.shippingAddress?.street}`,
          `${shipping?.shippingAddress?.street2}`,
        ],
        locality: shipping?.shippingAddress?.city,
        administrativeArea: shipping?.shippingAddress?.province,
        postalCode: shipping?.shippingAddress?.postcode,
        country: shipping?.shippingAddress?.country_name,
        countryCode: shipping?.shippingAddress?.country_code,
      };
    }

    setApplePayRequest(updatedApplePayRequest);

    if (window.ApplePaySession) {
      try {
        setIsApplePaySupported(window.ApplePaySession.canMakePayments());
        console.log(
          "isApplePaySupported",
          window.ApplePaySession.canMakePayments()
        );
        dispatch(setIsPaymentMethodsLoading(false));
        if (isPaymentMethodEditable) {
          skipAvailableWalletPayments();
        }
      } catch (error) {
        console.log("ApplePay Not Supported", error);
        submitLogToBugsnag("error", `ApplePay Not Supported : ${error}`);
        dispatch(setIsPaymentMethodsLoading(false));
        otherPayments();
        setIsApplePaySupported(false);
      }
    } else {
      dispatch(setIsPaymentMethodsLoading(false));
      otherPayments();
      setIsApplePaySupported(false);
    }
  }, [
    currency,
    grandTotal,
    shippingCost,
    authState.user,
    accountPhone,
    profile,
    shipping,
  ]);

  const handleApplePayment = () => {
    let session: any = undefined;

    dispatch(setPaymentProgress("PAYMENT_STARTED"));
    setIsApplePayButtonLoading(true);

    try {
      //* create a new ApplePaySession
      session = new window.ApplePaySession(3, applePayRequest);

      if (session) {
        dispatch(
          setPaymentMethodSelected(
            molliePaymentRequestAdupContainerProps.paymentMethod
          )
        );

        //* Begin the payment session
        session.begin();
        setIsApplePayButtonLoading(false);

        session.onvalidatemerchant = async function (event: any) {
          const sessionResponse = await orderSessionCall({
            cart_id: cartId,
            shop_slug: shop?.slug,
            payment_details: {
              validationURL: event?.validationURL,
            },
            payment_method:
              paymentMethods?.[molliePaymentRequestAdupContainerProps?.name]
                ?.slug,
          });

          if (sessionResponse.success === true) {
            session.completeMerchantValidation(sessionResponse?.data?.session);
          } else {
            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
            submitLogToBugsnag(
              "error",
              `Error handling Apple Payment : ${sessionResponse?.message}`
            );
            dispatch(
              setToastMessage({
                text: `${sessionResponse?.message}`,
                type: "ERROR",
              })
            );
            //* abort the previous session if exists
            session && session?.abort();
          }
        };

        session.onshippingcontactselected = async (event: {
          shippingContact: any;
        }) => {
          let shippingContact = event.shippingContact;

          //* address change & shipping cost Calculation via addToCart API
          const selectedShippingAddress = {
            id: "",
            number: isNotEmptyString(shippingContact?.addressLines?.[0])
              ? shippingContact?.addressLines?.[0]
              : "-",
            street: isNotEmptyString(shippingContact?.subLocality)
              ? shippingContact?.subLocality
              : "-",
            street2: "",
            city: isNotEmptyString(shippingContact?.locality)
              ? shippingContact?.locality
              : "-",
            province: isNotEmptyString(shippingContact?.administrativeArea)
              ? shippingContact?.administrativeArea
              : "-",
            postcode: isNotEmptyString(shippingContact?.postalCode)
              ? shippingContact?.postalCode
              : "-",
            country_name: isNotEmptyString(shippingContact?.country)
              ? shippingContact?.country
              : "-",
            country_code: isNotEmptyString(shippingContact?.countryCode)
              ? shippingContact?.countryCode
              : "-",
          } as AdupUserAddress;

          const addToCartResponse: any = await addToCart(
            selectedShippingAddress
          );

          session.completeShippingContactSelection({
            status: window.ApplePaySession.STATUS_SUCCESS,
            newTotal: {
              label: `${shop?.name} ${t("CartTotal")}`,
              amount:
                addToCartResponse?.price_calculations?.total?.major ??
                grandTotal ??
                0.0,
            },
            newShippingMethods: [
              {
                label: `${t("Shipping")}`,
                detail: `${t("WalletPayShippingDescription")}`,
                amount:
                  addToCartResponse?.price_calculations?.shipping?.major ??
                  shippingCost ??
                  0.0,
                identifier: "shipping",
              },
            ],
          });
        };

        session.onpaymentauthorized = async (event: { payment: any }) => {
          const { payment } = event;

          //* Fetch Payer data from Wallet Payment - START *//
          // *Get an instance of `PhoneNumberUtil`.
          const phoneUtil: any =
            require("google-libphonenumber").PhoneNumberUtil.getInstance();
          let countryCode = "0";
          let phoneNumber = "0";
          try {
            const unformattedNumber = isNotEmptyString(
              payment?.shippingContact?.phoneNumber
            )
              ? payment?.shippingContact?.phoneNumber
              : "0";
            const number = phoneUtil.parseAndKeepRawInput(
              unformattedNumber
                ? unformattedNumber.startsWith("+")
                  ? unformattedNumber
                  : `+${unformattedNumber}`
                : "0"
            );
            countryCode = number.getCountryCode()?.toString();
            phoneNumber = number.getNationalNumber()?.toString();
          } catch (error) {
            console.log("Wallet data phone number invalid :", error);
            session.completePayment(
              window.ApplePaySession.STATUS_INVALID_SHIPPING_CONTACT
            );
            submitLogToBugsnag(
              "error",
              `Wallet data phone number invalid : ${error}`
            );
          }

          const walletPayerShippingAddress: Address = {
            house_number: isNotEmptyString(
              payment?.shippingContact?.addressLines?.[0]
            )
              ? payment?.shippingContact?.addressLines?.[0]
              : "-",
            street: isNotEmptyString(
              payment?.shippingContact?.addressLines?.[1]
            )
              ? payment?.shippingContact?.addressLines?.[1]
              : isNotEmptyString(payment?.shippingContact?.subLocality)
              ? payment?.shippingContact?.subLocality
              : "-",
            street2: payment?.shippingContact?.addressLines?.[2],
            city: isNotEmptyString(payment?.shippingContact?.locality)
              ? payment?.shippingContact?.locality
              : "-",
            province: isNotEmptyString(
              payment?.shippingContact?.administrativeArea
            )
              ? payment?.shippingContact?.administrativeArea
              : "-",
            postcode: isNotEmptyString(payment?.shippingContact?.postalCode)
              ? payment?.shippingContact?.postalCode
              : "-",
            country: isNotEmptyString(payment?.shippingContact?.country)
              ? payment?.shippingContact?.country
              : "-",
            country_code: isNotEmptyString(
              payment?.shippingContact?.countryCode
            )
              ? payment?.shippingContact?.countryCode
              : "-",
          };

          const walletPayerInfo: Shopper = {
            country_code:
              countryCode ?? accountPhone?.country?.countryCode ?? "",
            phone_number: phoneNumber ?? accountPhone?.number ?? "",
            email: isNotEmptyString(payment?.shippingContact?.emailAddress)
              ? payment?.shippingContact?.emailAddress
              : "",
            first_name: isNotEmptyString(payment?.shippingContact?.givenName)
              ? payment?.shippingContact?.givenName
              : "",
            last_name: isNotEmptyString(payment?.shippingContact?.familyName)
              ? payment?.shippingContact?.familyName
              : "",
            shipping_address: walletPayerShippingAddress,
            billing_address: walletPayerShippingAddress, //? instructed to use shipping address as billing address
          };
          //* Fetch Payer data from Wallet Payment - END *//

          const orderProcessResponse: any = await handlePaymentRequest(
            //* isWalletPayment
            true,
            //* walletShopperInfo
            walletPayerInfo,
            //* walletPaymentDetails
            {
              applePayPaymentToken: JSON.stringify(payment?.token),
            }
          );

          if (
            orderProcessResponse &&
            orderProcessResponse?.success === true &&
            orderProcessResponse?.data?.payment?.checkout_url &&
            orderProcessResponse?.data?.payment?.checkout_url !== ""
          ) {
            //* Complete the payment authorization & redirect to Checkout URL
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
            window.parent.location.replace(
              orderProcessResponse?.data?.payment?.checkout_url
            );
          } else {
            //* Cancel the payment authorization & show error message
            if (orderProcessResponse?.message?.includes("shipping")) {
              session.completePayment(
                window.ApplePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS
              );
            } else if (orderProcessResponse?.message?.includes("billing")) {
              session.completePayment(
                window.ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS
              );
            } else if (orderProcessResponse?.message?.includes("phone")) {
              session.completePayment(
                window.ApplePaySession.STATUS_INVALID_SHIPPING_CONTACT
              );
            } else if (orderProcessResponse?.message?.includes("name")) {
              session.completePayment(
                window.ApplePaySession.STATUS_INVALID_SHIPPING_CONTACT
              );
            } else if (orderProcessResponse?.message?.includes("email")) {
              session.completePayment(
                window.ApplePaySession.STATUS_INVALID_SHIPPING_CONTACT
              );
            } else {
              session.completePayment(window.ApplePaySession.STATUS_FAILURE);
            }
            submitLogToBugsnag(
              "error",
              `Error processing Apple Payment : ${orderProcessResponse?.message}`
            );
            dispatch(
              setToastMessage({
                text: `${orderProcessResponse?.message}`,
                type: "ERROR",
              })
            );
          }
        };
      } else {
        console.log("Error @ handleApplePayment", "ApplePaySession not found");
        setIsApplePayButtonLoading(false);
        //* abort the previous session if exists
        session && session?.abort();
      }
    } catch (error) {
      console.log("Error @ handleApplePayment", error);
      setIsApplePayButtonLoading(false);
      submitLogToBugsnag("error", `Error handling Apple Payment : ${error}`);
      // dispatch(
      //   setToastMessage({
      //     text: `${error}`,
      //     type: "ERROR",
      //   })
      // );
      //* abort the previous session if exists
      session && session?.abort();
    }
  };

  if (isApplePaySupported) {
    return (
      <>
        <div className="change-pay-method-link" style={{ textAlign: "center" }}>
          <h2 onClick={skipAvailableWalletPayments}>
            {t("ChangePaymentMethod")}
          </h2>
        </div>
        <br />
        <AnimatePresence>
          <motion.div
            animate={{ transform: "translateY(20px" }}
            initial={{ transform: "translateY(500px)" }}
            transition={{
              delay: 0.2,
              duration: 0.51,
              type: "spring",
              bounce: 0.2,
            }}
            className="pay-button-holder-fixed"
            onClick={() => {
              if (!isAddToCartAPILoading && !isApplePayButtonLoading)
                handleApplePayment();
            }}
            style={{
              cursor:
                isAddToCartAPILoading || isApplePayButtonLoading
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            <button
              className="pay-button-apple-pay"
              disabled={isAddToCartAPILoading || isApplePayButtonLoading}
              style={{
                cursor:
                  isAddToCartAPILoading || isApplePayButtonLoading
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              <div
                style={{
                  fontSize: "35px",
                  cursor:
                    isAddToCartAPILoading || isApplePayButtonLoading
                      ? "not-allowed"
                      : "pointer",
                }}
                className="apple-pay-button apple-pay-button-black"
                onClick={() => {
                  if (!isAddToCartAPILoading && !isApplePayButtonLoading)
                    handleApplePayment();
                }}
              ></div>
            </button>
          </motion.div>
        </AnimatePresence>
      </>
    );
  } else return null;
};

export default MolliePaymentRequestAdupContainer;
