import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Lottie from "lottie-react";
import lottieBanner from "../../assets/json/lottie_animation/click_1.json";
import { isNotEmptyString } from "../../lib/utils/helper-functions";

const SplashScreen = () => {
  const { t } = useTranslation();
  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );

  let imageName;

  if (isNotEmptyString(merchantTheme?.merchant_logo_dark)) {
    imageName = merchantTheme?.merchant_logo_dark;
  } else if (isNotEmptyString(merchantTheme?.merchant_icon)) {
    imageName = merchantTheme?.merchant_icon;
  } else {
    imageName = null;
  }

  const imgURL = `${imageStaticUrl}/medium/${imageName}`;

  return (
    <motion.div className="splash-screen">
      <div className="splash-screen-container">
        {imageName ? (
          <LazyLoadImage
            loading="lazy"
            src={imgURL}
            alt={`${merchantTheme?.merchant_name} logo`}
          />
        ) : (
          <div className="splash-screen-lottie">
            <Lottie animationData={lottieBanner} loop={true} />
          </div>
        )}

        <h1>{t("SplashScreenSlogan1")}</h1>
        {/* <p>{t("SplashScreenSlogan2")}</p> */}
      </div>
    </motion.div>
  );
};

export default SplashScreen;
