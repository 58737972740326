import React, { ChangeEvent, useState } from "react";

interface RadioInputProps {
  checked: boolean;
}

const RadioButton: React.FC<RadioInputProps> = ({ checked }) => {
  const [value, setValue] = useState<boolean>(checked);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
  };

  return (
    <input
      className="radioButton"
      checked={checked}
      type="radio"
      onChange={handleChange}
      value={value.toString()}
      readOnly
    />
  );
};

export default RadioButton;
