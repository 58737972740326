import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";
import FastCheckoutPageWrapper from "../../../components/page-wrappers/FastCheckoutPageWrapper";
import { setInteractionsPerSessionCount } from "../../../store/reducers/statisticsSlice";
import ProductDetailsThemeWise from "../../../components/theme-wise-switching/ProductDetailsThemeWise";
import Header from "../../../components/header/Header";
import CartBannerSC from "../../../components/socialCommerce/CartBannerSC";
import CartOperatorSC from "../../../components/socialCommerce/CartOperatorSC";
import ImageSliderSC from "../../../components/socialCommerce/ImageSliderSC";
import IntroBannerSC from "../../../components/socialCommerce/IntroBannerSC";
import ProductImagesSlider from "../../../components/socialCommerce/ProductImagesSlider";
import { generateFastCheckoutUrlUsingCartItems } from "../../../lib/utils/helper-functions";
import CustomMessageBanner from "../../../components/custom-message-banner/CustomMessageBanner";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";

type Props = {
  isHeartBtnActive: boolean;
  setIsHeartBtnActive: any;
  isDemoActive: boolean;
  productImages: any;
  setLocalAttributeSkuMap: any;
  productsData: any;
  onIncrement: any;
  onDecrement: any;
  addToFastcheckoutCart: any;
  chosenProduct: any;
  cartItemNumber: any;
  availableStock: any;
  componentRenderLogics: (componentType: string) => boolean | undefined;
};

const AICheckoutDesktopLayout1 = ({
  isHeartBtnActive,
  setIsHeartBtnActive,
  isDemoActive,
  productImages,
  setLocalAttributeSkuMap,
  productsData,
  onIncrement,
  onDecrement,
  addToFastcheckoutCart,
  chosenProduct,
  cartItemNumber,
  availableStock,
  componentRenderLogics,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lastSessionCallPayload = useSelector(
    (state: RootState) => state.payment?.lastSessionCallPayload
  );
  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );
  const fastcheckoutCartItems: any =
    useSelector((state: RootState) => state.cart.fastcheckoutCartItems) ?? [];
  const shop: any = useSelector((state: RootState) => state.cart.shop);
  const allProducts: any = useSelector(
    (state: RootState) => state.cart.allProducts[0]
  );
  const isProductsAPILoading = useSelector(
    (state: RootState) => state.app.UI.isProductsAPILoading
  );
  const navigate = useNavigate();

  return (
    <React.Suspense fallback={<div className="socialCommerce"></div>}>
      <Header hideCompactCart={true} />
      <div className="socialCommerce">
        <div className="max-width-container">
          <div className="max-width-container__content">
            <IntroBannerSC />
            {componentRenderLogics("customMessageBanner") && (
              <CustomMessageBanner />
            )}
            {!isProductsAPILoading && (
              <>
                {componentRenderLogics("cartBanner") && (
                  <CartBannerSC
                    isHeartFilled={isHeartBtnActive}
                    cartNumber={fastcheckoutCartItems.length}
                    onCartClick={() => {
                      const fastCheckoutUrl = `/ai-fast-checkout/${
                        shop?.slug
                      }/${generateFastCheckoutUrlUsingCartItems(
                        fastcheckoutCartItems
                      )}`;
                      if (!isDemoActive) navigate(fastCheckoutUrl);
                    }}
                    onHeartClick={() => {
                      setIsHeartBtnActive(!isHeartBtnActive);
                    }}
                  />
                )}
                <div className="socialCommerce__desktop--container">
                  <div className="socialCommerce__desktop--container-left">
                    {componentRenderLogics("productImagesSlider") && (
                      <>
                        <div className="socialCommerce__desktop--container-left-mobileSlider">
                          <ImageSliderSC
                            imageArray={productImages}
                            isDemoActive={isDemoActive}
                          />
                        </div>
                        <div className="socialCommerce__desktop--container-left-desktopSlider">
                          <ProductImagesSlider
                            images={productImages}
                            isDemoActive={isDemoActive}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="socialCommerce__desktop--container-right">
                    <ProductDetailsThemeWise
                      data={{
                        allProducts,
                        ...productsData,
                      }}
                      setLocalAttributeSkuMap={setLocalAttributeSkuMap}
                    />
                    {componentRenderLogics("cartOperatorSC") &&
                      !componentRenderLogics("productUnavailable") &&
                      !componentRenderLogics("productStockUnavailable") && (
                        <CartOperatorSC
                          onIncrement={onIncrement}
                          onDecrement={onDecrement}
                          cartItemNumber={cartItemNumber}
                          availableStock={availableStock}
                          onAddToCartClick={
                            !isDemoActive ? addToFastcheckoutCart : () => {}
                          }
                          isDisabled={lastSessionCallPayload === "loading"}
                        />
                      )}
                    {productsData?.isInvalidVariantSelected && (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          color: "red",
                          paddingTop: "2vh",
                        }}
                      >
                        <span>{t("VariantCombinationNotFound")}</span>
                      </div>
                    )}
                    {componentRenderLogics("productUnavailable") &&
                      !productsData?.isInvalidVariantSelected && (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            color: "red",
                            paddingTop: "2vh",
                          }}
                        >
                          <span>{t("SelectedProductUnavailable")}</span>
                        </div>
                      )}
                    {componentRenderLogics("productStockUnavailable") &&
                      !componentRenderLogics("productUnavailable") && (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            paddingTop: "2vh",
                          }}
                        >
                          {availableStock > 0 ? (
                            <>
                              <span style={{ color: "red" }}>
                                {t("SelectedQuantityUnavailable-1")}
                              </span>
                              <br />
                              <span>
                                {t("SelectedQuantityUnavailable-2", {
                                  stock: availableStock,
                                })}
                              </span>
                            </>
                          ) : (
                            <span style={{ color: "red" }}>
                              {t("SelectedProductUnavailable")}
                            </span>
                          )}
                        </div>
                      )}
                    <div className="socialCommerce__goto-links--container">
                      <h3
                        onClick={() => {
                          if (!isDemoActive)
                            navigate(`/${shop?.slug}/products`);
                          dispatch(setInteractionsPerSessionCount());
                        }}
                        className="socialCommerce__browseMoreLink"
                      >
                        {t("BrowseMore")}
                      </h3>

                      {componentRenderLogics("goToWebshop") && (
                        <h3 className="socialCommerce__goToLink">
                          {t("Or-go-to")}
                          <a
                            target={"_blank"}
                            href={settings?.webshop_url}
                            rel="noreferrer"
                          >
                            {t("Webshop")}
                          </a>
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
                <div className="divider">
                  <div className="divider-line"></div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {!isProductsAPILoading &&
        componentRenderLogics("fastCheckoutPageWrapper") && (
          <FastCheckoutPageWrapper showHeader={false} />
        )}
      {isProductsAPILoading && <LoadingSpinner />}
    </React.Suspense>
  );
};

export default AICheckoutDesktopLayout1;
