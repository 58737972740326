import React, { Fragment, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CartView } from "../../../components/cart-view/CartView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import HeadingWithRightButton from "../../../components/heading/HeadingWithRightButton";
import { useTranslation } from "react-i18next";
import editIcon from "../../../assets/svg/edit-icon.svg";
import deleteIcon from "../../../assets/svg/trash-icon.svg";
import { BackArrowBlack } from "../../../assets/svg/svg-components/back-arrow-black";
import { setToastMessage } from "../../../store/reducers/appSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import cartIcon from "../../../assets/svg/empty-cart.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

type EditCartButtonProps = {
  onClick: () => void;
};

type DeleteCartButtonProps = {
  onClick: () => void;
};

const EditCartButton: React.FC<EditCartButtonProps> = ({ onClick }) => {
  return (
    <Fragment>
      <button onClick={() => onClick()}>
        <LazyLoadImage loading="lazy" alt="edit cart" src={editIcon} />
      </button>
    </Fragment>
  );
};

const DeleteCartItemButton: React.FC<DeleteCartButtonProps> = ({ onClick }) => {
  return (
    <Fragment>
      <button onClick={() => onClick()}>
        <LazyLoadImage loading="lazy" alt="edit cart" src={deleteIcon} />
      </button>
    </Fragment>
  );
};

interface Props {
  isCompactCart: boolean;
  isDeletePopupOpen: boolean;
  setIsDeletePopupOpen: (para: boolean) => void;
  setDeleteItemId: (para: any) => void;
}

// const FastCheckoutPageCartSegment = React.forwardRef((props, ref) => {
const FastCheckoutPageCartSegment = ({
  isCompactCart,
  isDeletePopupOpen,
  setIsDeletePopupOpen,
  setDeleteItemId,
}: Props) => {
  const cartData = useSelector((state: RootState) => state.cart);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isEditingCart, setIsEditingCart] = useState(false);

  const variants = {
    spreadDown: { display: "flex" },
  };

  const [expanded, setIsExpanded] = useState(false);

  const [editOrDeleteButton, setEditOrDeleteButton] = useState(
    <EditCartButton
      onClick={() => {
        setIsEditingCart(!isEditingCart);
      }}
    />
  );

  useEffect(() => {
    if (isEditingCart) {
      const isSelected = cartData?.items.some(function (item) {
        return item.selected === true;
      });
      setEditOrDeleteButton(
        <DeleteCartItemButton
          onClick={() =>
            isSelected
              ? setIsDeletePopupOpen(!isDeletePopupOpen)
              : dispatch(
                  setToastMessage({
                    text: t("CartRemoveError"),
                    type: "INFO",
                  })
                )
          }
        />
      );
    } else {
      setEditOrDeleteButton(
        <EditCartButton
          onClick={() => {
            setIsEditingCart(!isEditingCart);
          }}
        />
      );
    }
  }, [isEditingCart, cartData]);

  const closeCart = () => {
    setIsExpanded(false);
    setIsEditingCart(false);
  };

  return (
    <>
      <div className="fast-checkout-page-top ">
        <AnimatePresence>
          <HeadingWithRightButton
            headingText={t("Cart")}
            button={cartData.items.length > 1 ? editOrDeleteButton : <></>}
            closeCartFunction={isCompactCart ? () => {} : closeCart}
          >
            {expanded && cartData.items.length > 1 && !isCompactCart && (
              <div className="back-button" onClick={closeCart}>
                <BackArrowBlack />
              </div>
            )}
          </HeadingWithRightButton>
        </AnimatePresence>
        {cartData.items.length <= 0 && (
          <div className="order-cart-summary">
            <h3 style={{ margin: 40, textAlign: "center" }}>
              <LazyLoadImage
                loading="lazy"
                style={{ width: 30 }}
                src={cartIcon}
              ></LazyLoadImage>
              <br />
              Cart is empty
            </h3>
          </div>
        )}
        {cartData.items.length > 0 && (
          <motion.div
            variants={variants}
            className="cart-view-holder"
            animate={cartData.items.length > 1 ? "spreadDown" : ""}
            transition={{ duration: 10 }}
          >
            <CartView
              items={
                cartData.items.map &&
                cartData?.items?.map((item: any, key) => ({
                  key,
                  id: item.id,
                  sku: item?.sku,
                  name: item.name,
                  quantity: item.quantity,
                  stock: item.stock,
                  priceFormatted: item.priceFormatted,
                  priceNumeric: item.priceNumeric,
                  totalPriceFormatted: item.totalPriceFormatted,
                  totalPriceNumeric: item.totalPriceNumeric,
                  currency: getSymbolFromCurrency(item.currency),
                  image: item.image,
                }))
              }
              stacked={cartData.items.length > 1}
              onExpand={
                cartData.items.length > 1
                  ? [(e) => setIsExpanded(e)]
                  : [(e) => closeCart()]
              }
              defaultExpanded={expanded}
              inEditMode={isEditingCart}
              isDeletePopupOpen={isDeletePopupOpen}
              setIsDeletePopupOpen={setIsDeletePopupOpen}
              setDeleteItemId={setDeleteItemId}
            />
          </motion.div>
        )}
      </div>
    </>
  );
};

export default FastCheckoutPageCartSegment;
