import "../../sass/components/socialCommerce/cartOperatorSC.scss";
import plusSVG from "../../assets/svg/plus-black-SVG.svg";
import minusSVG from "../../assets/svg/minus-black-SVG.svg";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { MinusSVG, PlusSVG } from "../../assets/js/allCustomSVGs";

interface Props {
  onIncrement: (event: React.MouseEvent) => void;
  onDecrement: (event: React.MouseEvent) => void;
  cartItemNumber: number;
  availableStock: number;
  onAddToCartClick: (event: React.MouseEvent) => void;
  isDisabled: boolean;
}

const CartOperatorSC = ({
  onIncrement,
  onDecrement,
  availableStock,
  cartItemNumber,
  onAddToCartClick,
  isDisabled,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(setInteractionsPerSessionCount());
      }}
      className="cartOperatorSC"
    >
      <div className="cartOperatorSC--container">
        <div className="cartOperatorSC__top">
          <h2 className="cartOperatorSC__top--left">{`${t("Quantity")}:`}</h2>
          <div className="cartOperatorSC__top--right">
            <div
              onClick={
                cartItemNumber <= 1 || isDisabled ? () => {} : onDecrement
              }
              className="cartOperatorSC__top--right-minusBtn"
              style={
                cartItemNumber <= 1 || isDisabled
                  ? { backgroundColor: "#dddddd", cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <CustomSVGs svg={MinusSVG} />
            </div>
            <p className="cartOperatorSC__top--right-number">
              {cartItemNumber?.toString().length === 1
                ? `0${cartItemNumber}`
                : cartItemNumber}
            </p>
            <div
              onClick={
                cartItemNumber >= availableStock || isDisabled
                  ? () => {}
                  : onIncrement
              }
              className="cartOperatorSC__top--right-plusBtn"
              style={
                cartItemNumber >= availableStock || isDisabled
                  ? { backgroundColor: "#dddddd", cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <CustomSVGs svg={PlusSVG} />
            </div>
          </div>
        </div>
        <div className="cartOperatorSC__bottom">
          <button onClick={onAddToCartClick}>{t("AddToCart")}</button>
        </div>
      </div>
    </div>
  );
};

export default CartOperatorSC;
