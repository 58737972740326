import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import {
  setUserToken,
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import updateStateFromShopperObject from "../../lib/utils/shopper-object-to-state";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  initialHiddenSegments,
  updateUserType,
} from "../../store/reducers/appSlice";
import { WebAuthnTypes } from "../../lib/webAuthn/detect-webAuthn-type";
import touchIdLogo from "../../assets/svg/webAuthn/Touch ID.gif";
import faceIdLogo from "../../assets/svg/webAuthn/Face ID.gif";
import androidFingerPrintLogo from "../../assets/svg/webAuthn/Android Fingerprint.svg";
import androidFaceUnlockLogo from "../../assets/svg/webAuthn/Android Face.svg";
import biometricsLogo from "../../assets/svg/webAuthn/Biometrics.png";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import updateStateFromPaymentMethodsBlock from "../../lib/utils/payment-methods-to-state";
import { submitLogToBugsnag } from "../../lib/api/log";
import { v4 as uuidv4 } from "uuid";
import {
  APP_ENV,
  LOGIN_API,
  WEBAUTHN_LOGIN_API,
} from "../../lib/constants/central-api";
import { sendOTPCode } from "../../lib/api/otp-methods";

declare const window: any;

interface LoginWebAuthnProps {
  webAuthType: WebAuthnTypes;
  hideSegment: () => void;
}

const backdropOpacityAnimation = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
    delay: 0.5,
  },
  exit: {
    opacity: 0,
  },
};

const slideUpAnimation = {
  animate: {
    transform: "translateY(0px)",
  },
  initial: {
    transform: "translateY(1000px)",
  },
  exit: {
    transform: "translateY(1000px)",
  },
  transition: {
    delay: 0,
    duration: 0.5,
  },
};

export const LoginWebAuthn: React.FC<LoginWebAuthnProps> = ({
  webAuthType,
  hideSegment,
}) => {
  const { t } = useTranslation();

  const [verifyingWebAuthn, setVerifyingWebAuthn] = useState(false);

  const accountPhone = useSelector(
    (state: RootState) => state.users.currentUser.accountPhone
  );

  const currentState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  const selfRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    selfRef.current.focus();
  }, []);

  async function triggerWebAuthn() {
    setVerifyingWebAuthn(true);
    try {
      const loginUUID = uuidv4();

      const assertOptionsConfig = {
        path: `${WEBAUTHN_LOGIN_API}/options`,
        body: {
          key: loginUUID,
          contact: accountPhone.number,
          country_code: accountPhone?.country.dialCode.substring(1),
        },
      };

      const assertConfig = {
        path: LOGIN_API,
        body: {
          webauthn: true,
          key: loginUUID,
        },
      };

      try {
        console.log("WebAuthn : Authenticating...");

        const webpass = window.Webpass.create({
          rpId: APP_ENV === "LIVE" ? "adup.io" : "kodeia.com",
        });

        const { user: response, success }: any = await webpass.assert(
          assertOptionsConfig,
          assertConfig
        );

        if (success) {
          // set the state to verified user
          store.dispatch(
            setUserVerified({
              ...currentState,
              state: verificationStates.verified,
              _: true,
              loggedInMethod: "webauthn",
              firstPayment: "pending",
            })
          );

          store.dispatch(setUserToken(response?.data?.access_token));

          updateStateFromShopperObject({
            ...response?.data?.user,
            bearer_token: response?.data?.access_token,
          });
          updateStateFromPaymentMethodsBlock(response?.data?.user);

          for (const segment of Object.keys(initialHiddenSegments)) {
            store.dispatch(disableFastCheckoutUISegment(segment));
          }

          store.dispatch(
            updateUserType({ user: applicationUserType.OLD_USER })
          );

          console.log("WebAuthn : Login successful!");
        } else {
          console.log("WebAuthn : Login failed.");
          setVerifyingWebAuthn(false);
          hideSegment();
        }
      } catch (error) {
        console.log("WebAuthn : Login error.", error);
        submitLogToBugsnag(
          "error",
          `Error in webauthn Login endpoint: ${error}`
        );
        setVerifyingWebAuthn(false);
        hideSegment();
      }
    } catch (error) {
      console.log("WebAuthn : Login error.", error);
      submitLogToBugsnag("error", `Error in webauthn Login endpoint: ${error}`);
      setVerifyingWebAuthn(false);
      hideSegment();
    }
    setVerifyingWebAuthn(false);
  }

  return (
    <motion.div ref={selfRef} className="verify-phone-number" tabIndex={0}>
      <motion.div
        key="backdrop"
        className="backdrop"
        {...backdropOpacityAnimation}
      ></motion.div>
      <motion.div
        key="phonenumberprompt"
        {...slideUpAnimation}
        className="verify-phone-number-prompt"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <h1>{t("WebAuthnText-LogIn-1")}</h1>
        <p>{t("WebAuthnText-LogIn-2")}</p>

        <div className="verification-methods">
          <div
            className="send-again-link"
            onClick={() => {
              if (!verifyingWebAuthn) {
                backdropOpacityAnimation.transition = {
                  ...backdropOpacityAnimation.transition,
                  delay: 0.5,
                };
                slideUpAnimation.transition = {
                  ...slideUpAnimation.transition,
                  delay: 0,
                };

                sendOTPCode(
                  accountPhone?.country.dialCode.substring(1),
                  accountPhone?.number
                );
                hideSegment();
              }
            }}
          >
            {t("VerifyUsingSMS")}
          </div>
          <button
            className={
              "btn-use-email" + (verifyingWebAuthn ? " lighten-button" : "")
            }
            onClick={() => {
              triggerWebAuthn();
            }}
          >
            {(verifyingWebAuthn && (
              <div className="verifying-now-text">{t("Verifying")}...</div>
            )) || (
              <div className="webAuthn-text">
                <LazyLoadImage
                  loading="lazy"
                  src={
                    webAuthType === "Face Id"
                      ? faceIdLogo
                      : webAuthType === "Touch Id"
                      ? touchIdLogo
                      : webAuthType === "Fingerprint"
                      ? androidFingerPrintLogo
                      : webAuthType === "Face Unlock"
                      ? androidFaceUnlockLogo
                      : webAuthType === "Biometrics"
                      ? biometricsLogo
                      : ""
                  }
                  width={
                    webAuthType === "Touch Id"
                      ? 75
                      : webAuthType === "Face Id" ||
                        webAuthType === "Biometrics"
                      ? 60
                      : 40
                  }
                  style={
                    webAuthType === "Fingerprint" ||
                    webAuthType === "Biometrics" ||
                    webAuthType === "Face Unlock"
                      ? { marginRight: 8 }
                      : {}
                  }
                ></LazyLoadImage>
                {t("VerifyUsing")} {webAuthType} &nbsp;
              </div>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};
