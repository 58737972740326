import { debounce } from "lodash";
import { AdupPaymentMethodSelection } from "../../../interfaces/AdupPaymentMethod";
import {
  initialPriceCalculations,
  setCartPriceCalculations,
} from "../../../store/reducers/cartSlice";
import {
  initialPaymentSelected,
  setIsAddToCartAPILoading,
  setLastAddToCartCallPayload,
} from "../../../store/reducers/paymentSlice";
import store, { RootState } from "../../../store/store";
import { submitLogToBugsnag } from "../../api/log";
import { addToCart } from "../../api/cart-calls/add-to-cart";

/**
 * Intent should be re-fetched when payment methods are edited
 */
function selectPaymentMethod(state: RootState) {
  return state.payment.paymentMethodSelected;
}
function getCartGrandTotalBeforeConvenienceFee(state: RootState) {
  return state.cart?.grandTotalBeforeConvenienceFee;
}

let currentPaymentMethod: AdupPaymentMethodSelection = initialPaymentSelected;
async function handlePaymentMethodChange() {
  try {
    const currentCartItems = store.getState().cart?.items;
    let previousPaymentMethod = currentPaymentMethod;
    currentPaymentMethod = selectPaymentMethod(store.getState());

    if (previousPaymentMethod !== currentPaymentMethod) {
      console.log(
        "Payment Method changed from",
        previousPaymentMethod,
        "to",
        currentPaymentMethod
      );

      //* Execute Add-To-Cart API call if cart is not empty
      if (currentCartItems && currentCartItems.length > 0) {
        store.dispatch(setIsAddToCartAPILoading(true));
        addToCartCallDebounce();
      } else {
        //* Set Price Calculations to initial state if cart is empty
        store.dispatch(setCartPriceCalculations(initialPriceCalculations));
        store.dispatch(setLastAddToCartCallPayload(undefined));
      }
    }
  } catch (error) {
    console.log("Error : ", error);
    submitLogToBugsnag("error", error);
  }
}

const addToCartCallDebounce = debounce(() => addToCart(), 400);

export const unsubscribeHandlePaymentMethodChange = store.subscribe(
  handlePaymentMethodChange
);
// unsubscribe();
