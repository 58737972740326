import "../../sass/themes/theme1/theme1-orderSummary.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface Props {
  app_mode: string;
}

const Theme1OrderSummary = ({ app_mode }: Props) => {
  const priceCalculations = useSelector(
    (state: RootState) => state.cart?.priceCalculations
  );

  const isAddToCartAPILoading = useSelector(
    (state: RootState) => state.payment?.isAddToCartAPILoading
  );
  const isProductsAPILoading = useSelector(
    (state: RootState) => state.app.UI.isProductsAPILoading
  );

  const { t } = useTranslation();
  const subTotal = priceCalculations?.subtotal?.formatted;
  const shippingFee = priceCalculations?.shipping?.formatted;
  const tax =
    priceCalculations?.tax?.major && Number(priceCalculations?.tax?.major) !== 0
      ? priceCalculations?.tax?.formatted
      : "0";
  const discount =
    priceCalculations?.discount?.major &&
    Number(priceCalculations?.discount?.major) !== 0
      ? priceCalculations?.discount?.formatted
      : "0";
  const convenienceFee =
    priceCalculations?.convenience_fee?.major &&
    Number(priceCalculations?.convenience_fee?.major) !== 0
      ? priceCalculations?.convenience_fee?.formatted
      : "0";
  const grandTotal = priceCalculations?.total?.formatted;

  return (
    /*  <h2 className="heading-2-regular fast-checkout-segment-heading">
        {app_mode === "ai-fast-checkout" ? t("CartSummary") : t("OrderSummary")}
      </h2> */
    <div className="theme1-order-cart-summary">
      <div className="theme1-order-amounts">
        <div className="theme1-order-summary-heading">
          <h5>
            {app_mode === "ai-fast-checkout"
              ? t("CartSummary")
              : t("OrderSummary")}
          </h5>
        </div>
        <hr></hr>
        <div className="theme1-order-amount-field">
          {app_mode === "ai-fast-checkout" ? t("CartTotal") : t("OrderTotal")}
          {isAddToCartAPILoading || isProductsAPILoading ? (
            <Skeleton duration={0.6} className="skeleton-loader" />
          ) : (
            <div>{`${subTotal}`}</div>
          )}
        </div>
        <div className="theme1-order-amount-field">
          {t("Shipping")}
          {isAddToCartAPILoading || isProductsAPILoading ? (
            <Skeleton duration={0.6} className="skeleton-loader" />
          ) : (
            <div>{`${shippingFee}`}</div>
          )}
        </div>
        {tax && tax !== "0" && (
          <div className="theme1-order-amount-field">
            {t("Tax")}
            {isAddToCartAPILoading || isProductsAPILoading ? (
              <Skeleton duration={0.6} className="skeleton-loader" />
            ) : (
              <div>{`${tax}`}</div>
            )}
          </div>
        )}
        {discount && discount !== "0" && (
          <div className="theme1-order-amount-field">
            {t("Discount")}
            {isAddToCartAPILoading || isProductsAPILoading ? (
              <Skeleton duration={0.6} className="skeleton-loader" />
            ) : (
              <div>{`- ${discount}`}</div>
            )}
          </div>
        )}
        {convenienceFee && convenienceFee !== "0" && (
          <div className="order-amount-field">
            {t("ConvenienceFee")}
            {isAddToCartAPILoading || isProductsAPILoading ? (
              <Skeleton duration={0.6} className="skeleton-loader" />
            ) : (
              <div>{convenienceFee}</div>
            )}
          </div>
        )}
        <br></br>
        <hr></hr>
        <div className="theme1-order-total-holder">
          {t("GrandTotal")}
          {isAddToCartAPILoading || isProductsAPILoading ? (
            <Skeleton duration={0.6} className="skeleton-loader" />
          ) : (
            <div>{grandTotal}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Theme1OrderSummary;
