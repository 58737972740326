import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IToast } from "../../components/toasts/BasicToastNotification";
import {
  buildDialCodeMap,
  convertToCountryMap,
} from "../../lib/utils/country-map-builder";
import releaseSpec from "../../release-spec/package.json";

/**
 * The application can be accessed by either a new user or an old (existing) user
 */
export const applicationUserType = {
  OLD_USER: "OLD_USER",
  NEW_USER: "NEW_USER",
};

export const initialHiddenSegments: { [index: string]: boolean } = {
  ACCOUNT_SEGMENT: false,
  PAYMENT_METHODS_SEGMENT: false,
  DETAILS_SEGMENT: false,
  SHIPPING_ADDRESS_SEGMENT: false,
  BILLING_ADDRESS_SEGMENT: false,
  CREATE_ADUP_ACCOUNT_SEGMENT: false,
};

export const emptyToast: IToast = {
  text: "",
  type: "",
};

interface Country {
  dial_code: string;
  name: string;
  flag: string;
}

export interface Countries {
  [key: string]: Country;
}

const demoData = {
  shop: {
    shop_slug: "demo",
    id: 3,
    initial: "DEMO",
    subscription_status: "true",
    name: undefined,
    currency: "EUR",
    status: true,
    subscription_lock: false,
    shop_id: 0,
    settings: {
      background_image: null,
      merchant_logo_light: null,
      merchant_icon: null,
      main_brand_color: "#00f6cd",
      font_color: "#000000",
      background_color: "#f6f6f6",
      default_shipping_cost: 0,
      min_free_shipping: 0,
      fixed_shipping: true,
      send_monthly_newsletter: false,
      facebook_pixel_id: null,
      tiktok_pixel_id: null,
      google_analytics_id: null,
      currency: "EUR",
    },
  },
  products: [
    {
      "demo-sku": {
        id: 0,
        sku: "demo-sku",
        type: "simple",
        name: undefined,
        slug: "demo",
        currency: "EUR",
        description: undefined,
        display_price: undefined,
        images: [],
        attributes: null,
        variant: {
          "demo-sku": {
            id: 1,
            name: undefined,
            sku: "demo-sku",
            description: undefined,
            images: [],
            total_sales: "100",
            status: "true",
            price: undefined,
            attributes: null,
          },
        },
      },
    },
  ],
  user_data: {
    device_ip: "123",
    device_country: {
      country: "Netherlands",
      country_code: "NL",
    },
    language: "en",
    browser_name: "Mobile Safari",
    is_bot: false,
    os: "iOS 15",
  },
  app_data: {
    static_base_url: "",
    api_version: "v1",
    countries: {
      AU: {
        name: "Australia",
        dial_code: "+61",
        flag: "\ud83c\udde6\ud83c\uddfa",
      },
      AE: {
        name: "United Arab Emirates",
        dial_code: "+971",
        flag: "\ud83c\udde6\ud83c\uddea",
      },
      GB: {
        name: "United Kingdom",
        dial_code: "+44",
        flag: "\ud83c\uddec\ud83c\udde7",
      },
      DE: {
        name: "Germany",
        dial_code: "+49",
        flag: "\ud83c\udde9\ud83c\uddea",
      },
      NL: {
        name: "Netherlands",
        dial_code: "+31",
        flag: "\ud83c\uddf3\ud83c\uddf1",
      },
      LK: {
        name: "Sri Lanka",
        dial_code: "+94",
        flag: "\ud83c\uddf1\ud83c\uddf0",
      },
      US: {
        name: "United States",
        dial_code: "+1",
        flag: "\ud83c\uddfa\ud83c\uddf8",
      },
    },
  },
  payment_method: {
    card: [
      {
        icon: "payment-method/card.svg",
        label: "Credit Cards",
        processor: "frontend",
        fields: null,
        data: [],
        gateway: "stripe",
      },
    ],
    ideal: [
      {
        icon: "payment-method/ideal.svg",
        label: "Ideal Payments",
        processor: "frontend",
        fields: null,
        data: {
          bank: {
            "ABN AMRO": "abn_amro",
            "ASN Bank": "asn_bank",
            ING: "ing",
            Rabobank: "rabobank",
            "SNS Bank": "sns_bank",
            "Triodos Bank": "triodos_bank",
            Revolut: "revolut",
            RegioBank: "regiobank",
            Knab: "asn_bank",
            Bunq: "bunq",
            Handelsbanken: "handelsbanken",
            "Van Lanschot": "van_lanschot",
          },
        },
        gateway: "stripe",
      },
    ],
    giropay: [
      {
        icon: "payment-method/giropay.svg",
        label: "Giropay",
        processor: "frontend",
        fields: null,
        data: [],
        gateway: "stripe",
      },
    ],
    google_pay: [
      {
        icon: "payment-method/google_pay.png",
        label: "Google Pay",
        processor: "frontend",
        fields: null,
        data: [],
        gateway: "stripe",
      },
    ],
    sofort: [
      {
        icon: "payment-method/sofort.svg",
        label: "Sofort",
        processor: "frontend",
        fields: null,
        data: [],
        gateway: "stripe",
      },
    ],
    eps: [
      {
        icon: "payment-method/eps.svg",
        label: "EPS",
        processor: "frontend",
        fields: null,
        data: {
          bank: {
            "Ärzte- und Apothekerbank": "arzte_und_apotheker_bank",
            "Austrian Anadi Bank AG": "austrian_anadi_bank_ag",
            "Bank Austria": "bank_austria",
            "Bankhaus Carl Spängler & Co.AG": "bankhaus_carl_spangler",
            "Bankhaus Schelhammer & Schattera AG":
              "bankhaus_schelhammer_und_schattera_ag",
            "BAWAG P.S.K. AG": "bawag_psk_ag",
            "BKS Bank AG": "bks_bank_ag",
            "Brüll Kallmus Bank AG": "brull_kallmus_bank_ag",
            "BTV VIER LÄNDER BANK": "btv_vier_lander_bank",
            "Capital Bank Grawe Gruppe AG": "capital_bank_grawe_gruppe_ag",
            Dolomitenbank: "dolomitenbank",
            "Easybank AG": "easybank_ag",
            "Erste Bank und Sparkassen": "erste_bank_und_sparkassen",
            "Hypo Alpe-Adria-Bank International AG":
              "hypo_alpeadriabank_international_ag",
            "HYPO NOE LB für Niederösterreich u. Wien":
              "hypo_noe_lb_fur_niederosterreich_u_wien",
            "HYPO Oberösterreich, Salzburg, Steiermark":
              "hypo_oberosterreich_salzburg_steiermark",
            "Hypo Tirol Bank AG": "hypo_tirol_bank_ag",
            "Hypo Vorarlberg Bank AG": "hypo_vorarlberg_bank_ag",
            "HYPO-BANK BURGENLAND Aktiengesellschaft":
              "hypo_bank_burgenland_aktiengesellschaft",
            "Marchfelder Bank": "marchfelder_bank",
            "Oberbank AG": "oberbank_ag",
            "Raiffeisen Bankengruppe Österreich":
              "raiffeisen_bankengruppe_osterreich",
            "Schoellerbank AG": "schoellerbank_ag",
            "Sparda-Bank Wien": "sparda_bank_wien",
            "Volksbank Gruppe": "volksbank_gruppe",
            "Volkskreditbank AGL": "volkskreditbank_ag",
            "VR-Bank Braunau": "vr_bank_braunau",
          },
        },
        gateway: "stripe",
      },
    ],
    blik: [
      {
        icon: "payment-method/blik.svg",
        label: "Blik",
        processor: "frontend",
        fields: null,
        data: [],
        gateway: "stripe",
      },
    ],
    p24: [
      {
        icon: "payment-method/p24.svg",
        label: "P24",
        processor: "frontend",
        fields: null,
        data: {
          bank: {
            "Alior Bank": "alior_bank",
            "Bank Millenium": "bank_millennium",
            "Bank Nowy BFG S.A.": "bank_nowy_bfg_sa",
            "Bank PEKAO S.A": "bank_pekao_sa",
            "Banki SpBdzielcze": "banki_spbdzielcze",
            "Blik via redirect": "blik",
            "BNP Paribas": "bnp_paribas",
            BOZ: "boz",
            CitiHandlowy: "citi_handlowy",
            "Credit Agricole": "credit_agricole",
            "e-Transfer Poctowy24": "etransfer_pocztowy24",
            "Getin Bank": "getin_bank",
            IdeaBank: "ideabank",
            ING: "ing",
            inteligo: "inteligo",
            "mBank-mtransfer": "mbank_mtransfer",
            "Nest Przelew": "nest_przelew",
            "Noble Pay": "noble_pay",
            "PBac z iPKO (PKO+BP)": "pbac_z_ipko",
            "Plus Bank": "plus_bank",
            "Santander-przelew24": "santander_przelew24",
            "Toyota Bank": "toyota_bank",
            "Volkswagen Bank": "volkswagen_bank",
          },
        },
        gateway: "stripe",
      },
    ],
    sepa_debit: [
      {
        icon: "payment-method/sepa.svg",
        label: "Sepa Direct Debit",
        processor: "frontend",
        fields: null,
        data: [],
        gateway: "stripe",
      },
    ],
    bancontact: [
      {
        icon: "payment-method/bancontact.svg",
        label: "Bancontact",
        processor: "frontend",
        fields: null,
        data: [],
        gateway: "stripe",
      },
    ],
    klarna: [
      {
        icon: "payment-method/klarna.svg",
        label: "Klarna",
        processor: "frontend",
        fields: null,
        data: [],
        gateway: "stripe",
      },
    ],
  },
  attribute_sku_map: [],
};

const initialState = {
  UI: {
    // scrollable: true,
    pages: {
      fastCheckout: {
        hiddenSegments: initialHiddenSegments,
      },
    },

    toastMessage: emptyToast,
    lastCartUrl: "",
    isPayButtonLoading: false,
    isPaymentOnPopUpViaIframe: false,
    isProductsAPILoading: false,
    showCookieConsent: true,
    socialMediaCookie: false,
    isOtpOverlayVisible: false,
    isAICheckout: false,
    language: "en",

    cart: {
      expanded: false,
    },
  },

  settings: { ipAddressCountryCode: "" },

  phone: {
    countryCodes: {},
    countryDialCodeMap: {},
  },

  auth: {
    user: applicationUserType.NEW_USER,
  },

  version: releaseSpec?.version as string,

  lastVersionCheckTimestamp: new Date().getTime() as number,

  appData: {} as any,

  //* Setting up Demo Data ---- *//
  demoCheckoutInitData: demoData,
  //*Demo Data End ---- *//
};

const ApplicationSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateUserType: (state, action: PayloadAction<{ user: string }>) => {
      state.auth.user = action.payload.user;
    },
    setIPCountry: (state, action) => {
      state.settings.ipAddressCountryCode = action.payload.countryCode;
    },
    setPageScroll: (state, action) => {
      // state.UI.scrollable = action.payload.scrollable;
    },
    setToastMessage: (state, action: PayloadAction<IToast>) => {
      state.UI.toastMessage = action.payload;
    },
    clearToastMessage: (state, _) => {
      state.UI.toastMessage = emptyToast;
    },
    setLastCartUrl: (state, action: PayloadAction<string>) => {
      state.UI.lastCartUrl = action.payload;
    },
    disableFastCheckoutUISegment: (state, action: PayloadAction<string>) => {
      state.UI.pages.fastCheckout.hiddenSegments[action.payload] = true;
    },
    enableFastCheckoutUISegment: (state, action: PayloadAction<string>) => {
      state.UI.pages.fastCheckout.hiddenSegments[action.payload] = false;
    },
    setIsPayButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.UI.isPayButtonLoading = action.payload;
    },
    setIsPaymentOnPopUpViaIframe: (state, action: PayloadAction<boolean>) => {
      state.UI.isPaymentOnPopUpViaIframe = action.payload;
    },
    setIsProductsAPILoading: (state, action: PayloadAction<boolean>) => {
      state.UI.isProductsAPILoading = action.payload;
    },
    setShowCookieConsent: (state, action: PayloadAction<boolean>) => {
      state.UI.showCookieConsent = action.payload;
    },
    setAcceptSocialMediaCookie: (state, action: PayloadAction<boolean>) => {
      state.UI.socialMediaCookie = action.payload;
    },
    setIsOtpOverlayVisible: (state, action: PayloadAction<boolean>) => {
      state.UI.isOtpOverlayVisible = action.payload;
    },
    setIsAICheckout: (state, action: PayloadAction<boolean>) => {
      state.UI.isAICheckout = action.payload;
    },
    setAppLanguage: (state, action: PayloadAction<string>) => {
      state.UI.language = action.payload;
    },
    setAppData: (state, action: PayloadAction<any>) => {
      const countriesObject = {} as Countries;
      action?.payload?.countries.forEach((country: any) => {
        const countryCode = country?.cca2;
        countriesObject[countryCode] = {
          name: country?.common_name,
          dial_code: country?.dial_code,
          flag: country?.flag,
          ...country,
        };
      });
      state.appData = {
        api_version: action?.payload?.api_version,
        static_base_url: action?.payload?.static_base_url,
        countries: countriesObject,
      };
      state.phone.countryCodes = convertToCountryMap(countriesObject);
      state.phone.countryDialCodeMap = buildDialCodeMap(countriesObject);
    },
    setCartExpanded: (state, action) => {
      state.UI = {
        ...state?.UI,
        cart: {
          expanded: action.payload,
        },
      };
    },
    resetLastVersionCheckTimestamp: (state, _) => {
      state.lastVersionCheckTimestamp = new Date().getTime() as number;
    },

    //* Setting up Demo Data ---- *//
    setDemoCompanyName: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.shop.name = action.payload;
    },
    setDemoProductName: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.products[0]["demo-sku"].name = action.payload;
      state.demoCheckoutInitData.products[0]["demo-sku"].variant[
        "demo-sku"
      ].name = action.payload;
    },
    setDemoProductDescription: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.products[0]["demo-sku"].description =
        action.payload;
      state.demoCheckoutInitData.products[0]["demo-sku"].variant[
        "demo-sku"
      ].description = action.payload;
    },
    setDemoProductPrice: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.products[0]["demo-sku"].display_price =
        action.payload;
      state.demoCheckoutInitData.products[0]["demo-sku"].variant[
        "demo-sku"
      ].price = action.payload;
    },
    setDemoProductImage: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.products[0]["demo-sku"].images =
        action.payload;
      state.demoCheckoutInitData.products[0]["demo-sku"].variant[
        "demo-sku"
      ].images = action.payload;
    },
    //*Demo Data End ---- *//
  },
});

export const {
  updateUserType,
  setIPCountry,
  setPageScroll,
  disableFastCheckoutUISegment,
  enableFastCheckoutUISegment,
  setToastMessage,
  clearToastMessage,
  setLastCartUrl,
  setIsPayButtonLoading,
  setIsPaymentOnPopUpViaIframe,
  setIsProductsAPILoading,
  setShowCookieConsent,
  setAcceptSocialMediaCookie,
  setIsOtpOverlayVisible,
  setIsAICheckout,
  setAppLanguage,
  setAppData,
  setDemoCompanyName,
  setDemoProductName,
  setDemoProductDescription,
  setDemoProductPrice,
  setDemoProductImage,
  setCartExpanded,
  resetLastVersionCheckTimestamp,
} = ApplicationSlice.actions;

export default ApplicationSlice.reducer;
