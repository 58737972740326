import { useDispatch } from "react-redux";
import "../../sass/components/toggleInputTheme1.scss";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

interface Props {
  title?: string;
  isOn?: boolean;
  onClick?: any;
  classes?: string;
  id?: any;
  defaultChecked?: boolean;
  disabled?: boolean;
}

const ToggleInputTheme1 = ({
  title,
  onClick,
  isOn,
  classes,
  id,
  defaultChecked,
  disabled,
}: Props) => {
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(setInteractionsPerSessionCount());
    onClick();
  };

  return (
    <div
      data-toggle-input-on={isOn ? "true" : "false"}
      className={`toggleInputTheme1 ${classes ? classes : ""}`}
    >
      <div className="toggleInputTheme1-container">
        <input
          onChange={handleToggle}
          type="checkbox"
          defaultChecked={defaultChecked}
          disabled={disabled}
        />

        <svg
          className="toggleInputTheme1-indicator-svg"
          viewBox="0 0 24 24"
          id="ghq-svg-check"
          role="presentation"
          aria-hidden="true"
        >
          <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
        </svg>
        <div className="toggleInputTheme1-indicator-circle"></div>
      </div>
    </div>
  );
};

export default ToggleInputTheme1;
