import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import "../../sass/components/customMessageBanner.scss";

const CustomMessageBanner = () => {
  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );
  return (
    <div className="customMessageBanner">
      <h3>
        {settings?.is_show_custom_message && settings?.custom_message !== ""
          ? settings?.custom_message
          : ""}
      </h3>
    </div>
  );
};

export default CustomMessageBanner;
