import { useEffect, useState } from "react";
import { FastCheckoutDesktopLayout1 } from "./desktop/FastCheckoutDesktopLayout1";
import { FastCheckoutMobileLayout1 } from "./mobile/FastCheckoutMobileLayout1";

interface Props {
  app_mode: any;
  componentRenderLogics: (componentType: string) => boolean | undefined;
}

const FastCheckoutLayout1 = ({ app_mode, componentRenderLogics }: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth > 750 ? (
        <FastCheckoutDesktopLayout1
          app_mode={app_mode}
          componentRenderLogics={componentRenderLogics}
        />
      ) : (
        <FastCheckoutMobileLayout1
          app_mode={app_mode}
          componentRenderLogics={componentRenderLogics}
        />
      )}
    </>
  );
};

export default FastCheckoutLayout1;
