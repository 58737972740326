// import { ChangeEvent } from "react";
import { cssRedBorderClass } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";

type AddressFieldProps = {
  id: string;
  value: string;
  onChange: (event: any) => void;
  placeHolder: string;
  required: boolean;
};

const AddressCountrySelector: React.FC<AddressFieldProps> = ({
  id,
  onChange,
  value,
  placeHolder,
  required,
}) => {
  const dispatch = useDispatch();
  const allCountries = useSelector(
    (state: RootState) => state.app.appData.countries
  );

  const { t } = useTranslation();

  const countries = { ...allCountries };

  countries.NON = { name: t("SelectCountry") };

  //   console.log(value);
  return (
    <div
      className={`manual-address-field  manual-address-field-select ${
        (!value || value === "") && required
          ? "custom-input-red-border-bottom addressCountrySelector-red-text"
          : ""
      }`}
    >
      <select
        onFocus={() => {
          dispatch(setInteractionsPerSessionCount());
        }}
        onChange={(e) => onChange(e)}
        // defaultValue={"US"}
        id={id}
        value={value ? value : "NON"}
        style={{ fontFamily: "var(--main-font-secondary)" }}
      >
        {Object.keys(countries).map((countryKey: any, key: number) => {
          if (countryKey === "NON") {
            return (
              <option
                className="addressCountrySelector-option"
                disabled
                key={key}
                value={countryKey}
              >
                {countries[countryKey].name}
              </option>
            );
          } else {
            return (
              <option
                className="addressCountrySelector-option"
                key={key}
                value={countryKey}
              >
                {countries[countryKey].name}
              </option>
            );
          }
        })}
      </select>
      {/* <label className="manual-address-field-label" htmlFor={id}>
        {placeHolder}
      </label> */}
    </div>
  );
};

export default AddressCountrySelector;
