import { useEffect } from "react";
// import store from "../store/store";

const useTitle = (title: string) => {
  useEffect(() => {
    const prevTitle = document.title;

    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  });
};

export default useTitle;
