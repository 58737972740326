import { motion } from "framer-motion";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Country from "../../interfaces/Country";
import { t } from "i18next";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { crossSVG } from "../../assets/js/allCustomSVGs";

type CountrySearchModalProps = {
  setCountry: Dispatch<SetStateAction<Country>>;
  hide: () => void;
  selectedCountry?: Country;
  setPhoneNumber: (inputString: string) => void;
  currentDialCode: string;
  phoneNumberValue: string;
};

export const CountrySearchModal: React.FC<CountrySearchModalProps> = ({
  setCountry,
  hide,
  selectedCountry,
  phoneNumberValue,
  setPhoneNumber,
  currentDialCode,
}) => {
  const availableCountryCodes = useSelector(
    (state: RootState) => state.app.phone.countryCodes
  ) as any;

  const searchArray = useMemo(
    () =>
      Object.keys(availableCountryCodes).map((key: any) => ({
        ...availableCountryCodes[key],
        countryCode: key,
      })),
    [availableCountryCodes]
  );

  const [displayedCountries, setDisplayedCountries] = useState(searchArray);

  /**
   *
   * @param {string} name
   */
  function searchCountries(searchString: string) {
    setDisplayedCountries(
      searchArray.filter(
        (country: any) =>
          country.name.substring(0, searchString.length).toLowerCase() ===
            searchString.toLowerCase() ||
          country.dialCode?.startsWith(`+${searchString}`) ||
          country.dialCode?.startsWith(searchString)
      )
    );
  }

  const resolvePhoneNumber = (newDialCode: string) => {
    if (newDialCode) {
      setPhoneNumber(newDialCode + phoneNumberValue);
    }
  };
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (
        event.key === "Escape" ||
        event.keyCode === 27 ||
        event.key === "End"
      ) {
        event.preventDefault();
        console.log("pressed Esc ╳");
        hide();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    // 👇️ clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <React.Fragment>
      <motion.div
        className="modal-backdrop-fullscreen"
        key={"backdrop"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.15 }}
        onClick={() => hide()}
      ></motion.div>
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          e.preventDefault();
        }}
        className="phone-number-search-modal-container"
        key={"modal"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }}
      >
        <div className="phone-number-search-modal">
          <div className="searchInput-title-container">
            <div className="searchInput-title">
              <h1>{t("SelectCountry")}</h1>
            </div>
            <div className="searchInput-close-button">
              <div
                onClick={() => {
                  hide();
                }}
                className="orderDetails-title-svg"
              >
                <CustomSVGs svg={crossSVG} />
              </div>
            </div>
          </div>
          <div className="countries-list-container">
            <input
              type="search"
              style={{ fontFamily: "var(--main-font-secondary)" }}
              onChange={(e) => {
                searchCountries(e.target.value);
              }}
              placeholder="Search"
            ></input>

            <div className="countries-list-holder">
              <ul className="countries-list">
                {displayedCountries.map((country: Country, key: number) => {
                  return (
                    <li
                      className={
                        selectedCountry === country
                          ? "list-selected-country"
                          : ""
                      }
                      key={key}
                      onClick={() => {
                        resolvePhoneNumber(country.dialCode);
                        setCountry(country);
                        hide();
                      }}
                    >
                      {" "}
                      <div>
                        <div>{country.flag}</div>
                        <div>{country.name}</div>
                      </div>
                      <b>{country.dialCode}</b>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </motion.div>
    </React.Fragment>
  );
};
