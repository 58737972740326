import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { arrowHeadLeftSVG } from "../../assets/js/allCustomSVGs";
import { RootState } from "../../store/store";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isNotEmptyString } from "../../lib/utils/helper-functions";

interface Props {
  backRoute: any;
  backRouteTitle: string;
}

const HeaderProfile = ({ backRoute, backRouteTitle }: Props) => {
  const navigate = useNavigate();
  const shopData: any = useSelector((state: RootState) => state.cart?.shop);
  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );
  const slug = useSelector((state: RootState) => state.cart.shop.slug);

  const isLogo = () => {
    if (
      shopData?.settings?.merchant_logo_light &&
      isNotEmptyString(shopData?.settings?.merchant_logo_light)
    ) {
      return true;
    }

    return false;
  };

  const renderCompanyLogo = () => {
    if (isLogo()) {
      return (
        <div
          onClick={() => {
            navigate(`/${slug}/products`);
          }}
          className="img__container"
        >
          <LazyLoadImage
            loading="lazy"
            src={`${imageStaticUrl}/medium/${shopData?.settings?.merchant_logo_light}`}
            alt="company logo"
          />
        </div>
      );
    } else {
      return <div className="img__container"></div>;
    }
  };

  return (
    <>
      <div
        data-is-header-profile-fixed={settings?.setting}
        className="headerProfile"
      >
        <div className="headerProfile-max-width-container">
          <div
            onClick={() => {
              if (backRoute === -1) {
                navigate(-1);
              } else {
                navigate(`${backRoute}`);
              }
            }}
            className="headerProfile__left"
          >
            <CustomSVGs svg={arrowHeadLeftSVG} />
            <div>
              <h2>{backRouteTitle}</h2>
            </div>
          </div>

          {renderCompanyLogo()}
        </div>
      </div>
      <div
        data-is-header-profile-fixed={settings?.setting}
        className="headerProfile-hidden"
      />
    </>
  );
};

export default HeaderProfile;
