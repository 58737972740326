import React, { Fragment, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { PaymentReceivedView } from "./pages/payment-received/PaymentReceived";
import "./sass/app.scss";
import { unsubscribeHandleCartChange } from "./lib/state/subscriptions/cart-change-intent";
import { unsubscribeHandleAddressChange } from "./lib/state/subscriptions/address-change-intent";
import { unsubscribeHandlePaymentMethodChange } from "./lib/state/subscriptions/payment-method-change-intent";
import { useDispatch, useSelector } from "react-redux";
import { AdupUserProfile } from "./interfaces/AdupUserProfile";
import { RootState } from "./store/store";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  emptyToast,
  enableFastCheckoutUISegment,
  setIsPayButtonLoading,
  setToastMessage,
  updateUserType,
} from "./store/reducers/appSlice";
import {
  setCreateAccountFlag,
  setIsInitAPILoading,
  setPaymentErrorOccurred,
} from "./store/reducers/paymentSlice";
import { CookieConsent } from "./components/cookie-consent/CookieConsent";
import i18n from "./internationalization/i18n";
import { t } from "i18next";
import { useFeature } from "@growthbook/growthbook-react";
import { submitLogToBugsnag } from "./lib/api/log";
import { AUTH_SSO_URL } from "./lib/constants/urls";
import appThemeEngine from "./lib/utils/appThemeEngine";
import { setUserToken, verificationStates } from "./store/reducers/userSlice";
import { logOutUser } from "./lib/state/clear-user-data";
import { growthBookConfig } from ".";
import FastCheckoutPageWrapper from "./components/page-wrappers/FastCheckoutPageWrapper";
import ToastContainer from "./components/toasts/ToastContainer";
import DummyCheckoutGenerator from "./pages/demo/DummyCheckoutGenerator";
import ProfilePage from "./pages/profile/profilePage/ProfilePage";
import RootPage from "./pages/root/RootPage";
import OverviewPage from "./pages/socialCommerce/OverviewPage";
import SocialCommercePage from "./pages/socialCommerce/SocialCommercePage";
import { setGrandTotal } from "./store/reducers/cartSlice";
import { initialAPICall } from "./lib/api/initial-api-call";
import { AnimatePresence } from "framer-motion";
import SplashScreen from "./components/splash-screen/SplashScreen";
import { productsAPICall } from "./lib/api/products-api-call";
import { runCacheBuster } from "./lib/cache-buster/cache-bust-handler";
import { CACHE_BUSTER_EXECUTION_INTERVAL } from "./lib/constants/central-api";

const App: React.FunctionComponent = () => {
  const head: any = document.querySelector("head");

  const route = window.decodeURI(useLocation().pathname);

  const dispatch = useDispatch();

  const currentUserProfile: AdupUserProfile = useSelector(
    (state: RootState) => state.users.currentUser.profile
  );

  const sessionId = useSelector(
    (state: RootState) => state.statistics?.session_id
  );

  const country = useSelector(
    (state: RootState) => state.users.device_country?.countryName
  );

  const userData = useSelector((state: RootState) => state.users.currentUser);

  const toast = useSelector((state: RootState) => state.app.UI.toastMessage);

  const isInitApiFetched = useSelector(
    (state: RootState) => state.payment?.isInitApiFetched
  );

  const isInitAPILoading = useSelector(
    (state: RootState) => state.payment.isInitAPILoading
  );

  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );

  const shopSlug: any = useSelector((state: RootState) => state.cart.shop.slug);

  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );

  const merchantTheme = useSelector(
    (state: RootState) => state.users.merchant.config
  );

  const socialMediaCookie = useSelector(
    (state: RootState) => state.app.UI.socialMediaCookie
  );

  const lastVersionCheckTimestamp = useSelector(
    (state: RootState) => state.app.lastVersionCheckTimestamp ?? 0
  );

  const appLanguage = useSelector((state: RootState) => state.app.UI.language);

  const cartData = useSelector((state: RootState) => state.cart);

  const [bgVideoUrl, setBgVideoUrl] = useState<any>();

  //* ******************** Run Cache Buster (FE version check) at given interval --- START ******************** *//
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime: number = new Date().getTime();
      const hoursDifference: number =
        (currentTime - lastVersionCheckTimestamp) / (1000 * 60 * 60);

      //? Check if the last version check was more than 12 hours ago 👇🏼
      if (isInitApiFetched && hoursDifference >= 12) {
        console.log(
          "🔄 CacheBuster : (FE version check) 12 hour interval reached ⏰"
        );
        runCacheBuster();
      }
    }, CACHE_BUSTER_EXECUTION_INTERVAL);

    return () => clearInterval(interval);
  }, [isInitApiFetched]);
  //* ******************** Run Cache Buster (FE version check) at given interval --- END ******************** *//

  //* ******************** Application Initialization >> INIT API Call --- START ******************** *//
  useEffect(() => {
    if (!isInitApiFetched) {
      const app_mode = route.split("/")[1];
      if (app_mode !== "" && app_mode !== "demo" && app_mode !== "thank-you") {
        dispatch(setIsInitAPILoading(true));
        console.log("*** INIT API executing ***");
        initialAPICall(route);
      } else {
        dispatch(setIsInitAPILoading(false));
        console.log("*** INIT API execution prevented in unwanted routes ***");
      }
    }
  }, [route, isInitApiFetched]);
  //* ******************** Application Initialization >> INIT API Call --- END ******************** *//

  //* ******************** Checkout Pages >> Products API Call --- START ******************** *//
  useEffect(() => {
    if (!isInitAPILoading && isInitApiFetched) {
      const app_mode = route.split("/")[1];
      if (app_mode === "ai-fast-checkout" || app_mode === "ai-full-checkout") {
        if (shopSlug && shopSlug !== "") {
          console.log("*** Checkout Page > Products API executing ***");
          productsAPICall(route);
        } else {
          console.log(
            "*** INIT API re-executing : since Shop is not in state ***"
          );
          initialAPICall(route);
        }
      } else {
        console.log("*** Non Checkout Page > Products API not executed ***");
      }
    }
  }, [route, shopSlug, isInitAPILoading]);
  //* ******************** Checkout Pages >> Products API Call --- END ******************** *//

  //* ******************** Growthbook feature --- START ******************** *//
  const feature_id = useFeature("theme");
  const feature_theme = feature_id?.value || "classic";
  const [testTheme, setTestTheme] = useState<any>(feature_theme);

  useEffect(() => {
    console.log("🚀 GB > feature_theme", feature_theme);
    setTestTheme(feature_theme);
  }, [feature_theme]);
  //* ******************** Growthbook feature --- END ******************** *//

  //* ******************** CART : Grad Total update handler --- START ******************** *//
  useEffect(() => {
    const updatedGradTotal = (
      +cartData?.total +
      +cartData?.shipping -
      +cartData?.discount +
      +cartData?.convenienceFee
    ).toFixed(2);
    dispatch(setGrandTotal(+updatedGradTotal));
    console.log("Updating Cart Grand Total :: ", +updatedGradTotal);
  }, [
    cartData?.total,
    cartData?.shipping,
    cartData?.discount,
    cartData?.convenienceFee,
  ]);
  //* ******************** CART : Grad Total update handler --- END ******************** *//

  //* ******************** Submit Analytics data to server at given interval --- START ******************** *//
  // useEffect(() => {
  //TODO: disabled analytics for now. Add later
  // let session_id;
  // if (!sessionId || sessionId === 0) {
  //   console.log("NEW session");
  //   // generating a unique id for new sessions
  //   session_id = Math.floor(Math.random() * Date.now() + Date.now());
  //   dispatch(setStatisticsSessionId(session_id));
  // } else {
  //   console.log("OLD session");
  //   session_id = sessionId;
  // }
  // // Set user attributes for targeting (from cookie, auth system, etc.)
  // growthBookConfig.setAttributes({
  //   id: session_id,
  //   country,
  // });
  // const interval = setInterval(() => {
  //   console.log("Analytics data submission interval reached ⏰");
  //   //? Sending statistics data to Analytics Endpoint at  given interval 👇🏼
  //   submitStatisticsDataForAnalytics();
  // }, ANALYTICS_EXECUTION_INTERVAL);
  // return () => clearInterval(interval);
  // }, []);
  //* ******************** Submit Analytics data to server at given interval --- END ******************** *//

  //* ******************** Initial UI Segment loader --- START ******************** *//
  useEffect(() => {
    if (userData?.token && userData?.token !== "") {
      dispatch(updateUserType({ user: applicationUserType.OLD_USER }));
      dispatch(disableFastCheckoutUISegment("ACCOUNT_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("DETAILS_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("PAYMENT_METHODS_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("CREATE_ADUP_ACCOUNT_SEGMENT"));
    } else {
      dispatch(updateUserType({ user: applicationUserType.NEW_USER }));
      dispatch(enableFastCheckoutUISegment("ACCOUNT_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("DETAILS_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("PAYMENT_METHODS_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("CREATE_ADUP_ACCOUNT_SEGMENT"));
    }
  }, []);
  //* ******************** Initial UI Segment loader --- END ******************** *//

  //* ******************** AdUp SSO mechanism --- START ******************** *//
  useEffect(() => {
    // Create an iframe and append it to the body
    const iframe = document.createElement("iframe");

    var isBrowserSafari = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );

    console.log("🌍🍎 Is browser Safari ==> ", isBrowserSafari);

    // Hide the iframe
    iframe.style.display = "none";

    iframe.src = AUTH_SSO_URL;
    iframe.id = "adUp-SSO-iFrame";

    // Append it directly to the body
    document.body?.appendChild(iframe);

    // Wait for the AdUp-SSO iframe to load & Send a Global Token request to the AdUp-SSO iframe
    iframe.onload = () => {
      console.log("*** Requesting Global token ***");
      const message = { action: "GET_GLOBAL_TOKEN", key: "globalToken" };
      iframe.contentWindow?.postMessage(message, AUTH_SSO_URL);
    };

    // Listen for Global Tokens sent via the AdUp-SSO iframe
    const handleMessage = (event: any) => {
      if (event.origin !== AUTH_SSO_URL) return;

      const { action, value } = event.data;

      if (action === "RECEIVE_GLOBAL_TOKEN") {
        if (value && value !== "null" && value !== "") {
          if (userData?.token && userData?.token === value) {
            console.log("*** User is already Logged IN ***");
          } else {
            console.log(
              "*** Global Token received successfully & logged IN the user ***"
            );
            dispatch(setUserToken(value));
            window.location.reload();
          }
        } else {
          if (userData?.token && userData?.token !== "") {
            console.log(
              "*** Global Token not available  & logged OUT the user ***"
            );
            logOutUser();
            window.location.reload();
          } else {
            console.log("*** User is already Logged OUT ***");
          }
        }
      }
    };

    // Register the event listener for 'message' events
    window.addEventListener("message", handleMessage);

    // Specify how to clean up after this effect
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    if (userData?.token && userData?.token !== "") {
      console.log("*** Updating Global Token ***");
      const iframe: any = document.getElementById("adUp-SSO-iFrame");
      const message = {
        action: "SET_GLOBAL_TOKEN",
        key: "globalToken",
        value: userData?.token,
      };
      if (iframe && iframe?.contentWindow) {
        iframe.contentWindow?.postMessage(message, AUTH_SSO_URL);
      }
    }
  }, [userData]);
  //* ******************** AdUp SSO mechanism --- END ******************** *//

  //* ******************** Set CreateAccountFlag based on log in state --- START ******************** *//
  useEffect(() => {
    if (
      userData?.verified?._ &&
      userData?.verified?.state === verificationStates.verified
    ) {
      dispatch(setCreateAccountFlag(true));
    } else {
      dispatch(setCreateAccountFlag(false));
    }
  }, [userData?.verified]);
  //* ******************** Set CreateAccountFlag based on log in state --- END ******************** *//

  //* ******************** Autocomplete listener --- START ******************** *//
  useEffect(() => {
    document.addEventListener("onautocomplete", function (e) {
      if ((e.target as HTMLElement).hasAttribute("autocompleted")) {
        const element = e.target as HTMLElement;

        if (element.classList.contains("form-input")) {
          element.parentElement?.classList.add("filled");
          element.parentElement?.classList.add("focused");
        }
      }
      // e.preventDefault(); // prevent autocomplete
    });
  }, []);
  //* ******************** Autocomplete listener --- END ******************** *//

  //* ******************** App Language handler --- START ******************** *//
  useEffect(() => {
    try {
      i18n.changeLanguage(appLanguage);
    } catch (exception) {
      console.warn("Invalid language sent from server");
      submitLogToBugsnag("warning", "Invalid language sent from server");
      dispatch(
        setToastMessage({
          text: t("LanguageError"),
          type: "ERROR",
        })
      );
    }
  }, [appLanguage]);
  //* ******************** App Language handler --- END ******************** *//

  //* ******************** App Theme handler --- START ******************** *//
  useEffect(() => {
    const BgVideoUrlReturned = appThemeEngine(
      route,
      isInitAPILoading,
      imageStaticUrl,
      settings,
      merchantTheme
    );
    setBgVideoUrl(BgVideoUrlReturned);
  }, [settings, merchantTheme, isInitAPILoading, imageStaticUrl]);
  //* ******************** App Theme handler --- END ******************** *//

  //* ******************** Social Media Cookie handler --- START ******************** *//
  useEffect(() => {
    if (!isInitAPILoading && socialMediaCookie && settings) {
      //? Injecting Facebook Pixel Code
      if (settings?.facebook_pixel_id) {
        let facebookPixelCode1 = document.createElement("script");

        facebookPixelCode1.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${settings?.facebook_pixel_id}');
    fbq('track', 'PageView');`;

        let facebookPixelCode2 = document.createElement("noscript");

        facebookPixelCode2.innerHTML = `
      <img height="1" width="1" style="display:none" 
           src="https://www.facebook.com/tr?id=${settings?.facebook_pixel_id}&ev=PageView&noscript=1"/>`;

        head.appendChild(facebookPixelCode1);
        head.appendChild(facebookPixelCode2);
        console.log(
          "Facebook Pixel Code is injected to the App HTML Head",
          settings?.facebook_pixel_id
        );
      }

      //? Injecting Tiktok Pixel Code
      if (settings?.tiktok_pixel_id) {
        let tiktokPixelCode = document.createElement("script");

        tiktokPixelCode.innerHTML = `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        ttq.load('${settings?.tiktok_pixel_id}');
        ttq.page();
      }(window, document, 'ttq');`;

        head.appendChild(tiktokPixelCode);
        console.log(
          "Tiktok Pixel Code is injected to the App HTML Head",
          settings?.tiktok_pixel_id
        );
      }
    }
  }, [settings, socialMediaCookie, isInitAPILoading]);
  //* ******************** Social Media Cookie handler --- END ******************** *//

  //* ******************** Attaching subscribers to the Redux Store --- START ******************** *//
  useEffect(() => {
    dispatch(setIsPayButtonLoading(false));
    dispatch(setPaymentErrorOccurred(false));
    dispatch(setToastMessage(emptyToast));
    growthBookConfig.loadFeatures({ autoRefresh: true });
    return () => {
      unsubscribeHandleCartChange();
      unsubscribeHandleAddressChange();
      unsubscribeHandlePaymentMethodChange();
    };
  }, []);
  //* ******************** Attaching subscribers to the Redux Store --- END ******************** *//

  //* ******************** Scroll to Page Top at route changes --- START ******************** *//
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [route]);
  //* ******************** Scroll to Page Top at routes change --- END ******************** *//

  //* ******************** Redirection handler for OLD urls --- START ******************** *//
  function Redirect({ element }: { element: string }) {
    let { shopSlug, productId } = useParams();

    if (element === "FastCheckoutPageWrapper") {
      console.log("⮑ ⮑ ⮑ Redirecting to /ai-fast-checkout/ ⮑ ⮑ ⮑");
      window.open(`/ai-fast-checkout/${shopSlug}/${productId}`, "_self");
      return null;
    } else if (element === "SocialCommercePage") {
      console.log("⮑ ⮑ ⮑ Redirecting to /ai-full-checkout/ ⮑ ⮑ ⮑");
      window.open(`/ai-full-checkout/${shopSlug}/${productId}`, "_self");
      return null;
    } else if (element === "OverviewPage") {
      console.log("⮑ ⮑ ⮑ Redirecting to /products/ ⮑ ⮑ ⮑");
      window.open(`/${shopSlug}/products`, "_self");
      return null;
    } else {
      return <Navigate to={`/`} />;
    }
  }
  //* ******************** Redirection handler for OLD urls --- END ******************** *//

  return (
    <Fragment>
      <React.Suspense fallback={<div className="App app-container"></div>}>
        <div className="App app-container">
          {/* //* BG Video element --- START */}
          {!isInitAPILoading && bgVideoUrl && (
            <video autoPlay muted loop id="bgVideo" src={bgVideoUrl} />
          )}
          {/* //* BG Video element --- END */}

          {/* //* ToastContainer --- START */}
          <React.Fragment>
            {toast.text && toast.text !== "" && (
              <ToastContainer text={toast.text} type={toast.type} />
            )}
          </React.Fragment>
          {/* //* ToastContainer --- END */}

          {/* //* CookieConsent --- START */}
          <React.Fragment>
            {!isInitAPILoading &&
              route !== "/demo" &&
              route !== "/demo/social-commerce" &&
              route !== "/" && <CookieConsent />}
          </React.Fragment>
          {/* //* CookieConsent --- END */}

          {/* //* App Routes --- START */}
          <React.Fragment>
            {isInitAPILoading ? (
              <AnimatePresence>
                <SplashScreen />
              </AnimatePresence>
            ) : (
              <Routes>
                {/* //? Root Page - Secure Checkout marketing banner */}
                <Route path="/" element={<RootPage />}></Route>

                {/* //? Demo Page - Dummy Checkout Generator */}
                <Route
                  path="/demo"
                  element={<DummyCheckoutGenerator />}
                ></Route>

                {/* //? Demo Page - Dummy Full Checkout Page */}
                <Route
                  path="/demo/social-commerce"
                  element={<SocialCommercePage />}
                ></Route>

                {/* //? AI Fast Checkout Page */}
                <Route
                  path="/ai-fast-checkout/:shopSlug/:productId"
                  element={<FastCheckoutPageWrapper showHeader={true} />}
                ></Route>

                {/* //? AI Full Checkout Page */}
                <Route
                  path="/ai-full-checkout/:shopSlug/:productId"
                  element={<SocialCommercePage />}
                ></Route>

                {/* //? Product Catalog Page */}
                <Route
                  path="/:shopSlug/products"
                  element={<OverviewPage />}
                ></Route>

                {/* //*? Profile Page */}
                <Route
                  path="/profile/*"
                  element={
                    <ProfilePage
                      userData={userData}
                      profile={currentUserProfile}
                    />
                  }
                ></Route>

                {/* //? Thank You / Order Completed Page */}
                <Route
                  path="/thank-you/:cartId"
                  element={<PaymentReceivedView />}
                ></Route>

                {/* //? Redirection of old URLs */}
                <Route
                  path="/fastcheckout/:shopSlug/:productId"
                  element={<Redirect element="FastCheckoutPageWrapper" />}
                ></Route>
                <Route
                  path="/:shopSlug/:productId"
                  element={<Redirect element="SocialCommercePage" />}
                ></Route>
                <Route
                  path="/:shopSlug"
                  element={<Redirect element="OverviewPage" />}
                ></Route>
              </Routes>
            )}
          </React.Fragment>
          {/* //* App Routes --- END */}
        </div>
      </React.Suspense>
    </Fragment>
  );
};

export default App;
