import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCreateAccountFlag } from "../../store/reducers/paymentSlice";
import { RootState } from "../../store/store";
import "../../sass/components/createAdUpAccountToggleTheme1.scss";
import ToggleInputTheme1 from "./ToggleInputTheme1";

const CreateAdUpAccountToggleTheme1 = () => {
  const { t } = useTranslation();

  const isSame = useSelector(
    (state: RootState) => state.payment?.createAccountFlag
  ) as any;
  const merchantTheme = useSelector(
    (state: RootState) => state.users.merchant.config
  );
  const dispatch = useDispatch();

  return (
    <div className="createAdUpAccountToggleTheme1">
      <div
        data-is-create-account-true={isSame ? "true" : "false"}
        className="createAdUpAccountToggleTheme1-content"
        onClick={() => dispatch(setCreateAccountFlag(isSame ? !isSame : true))}
      >
        <div className="createAdUpAccountToggleTheme1-content-text">
          {merchantTheme?.create_account_text ? (
            merchantTheme.create_account_text
          ) : (
            <>
              {`${t("CreateAdUpAccountToggleText1", {
                merchant_name: merchantTheme?.merchant_name
                  ? merchantTheme.merchant_name
                  : "",
              })} ${t("CreateAdUpAccountToggleText2")} ${t(
                "CreateAdUpAccountToggleText3"
              )}`}
            </>
          )}
        </div>
        &nbsp; &nbsp;
        <ToggleInputTheme1
          key={isSame ?? true}
          onClick={() =>
            dispatch(setCreateAccountFlag(isSame ? !isSame : true))
          }
          isOn={isSame ? true : false}
        />
      </div>
    </div>
  );
};

export default CreateAdUpAccountToggleTheme1;
