import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import "./internationalization/i18n";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import {
  APP_ENV,
  BUGSNAG_API_KEY,
  GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY,
} from "./lib/constants/central-api";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

// * disabling console.logs & debuggers in LIVE instance
if (
  !(
    process.env.NODE_ENV === "development" ||
    window.location.href.split("/")[2].includes("dev") ||
    window.location.href.split("/")[2].includes("staging")
  )
) {
  console.log = () => {};
  console.debug = () => {};
}

//? Create a GrowthBook instance
export const growthBookConfig = new GrowthBook({
  apiHost: GROWTHBOOK_API_HOST,
  clientKey: GROWTHBOOK_CLIENT_KEY,
  // Enable easier debugging during development
  enableDevMode: true,
});

//? Create a Bugsnag instance
Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: APP_ENV,
  enabledReleaseStages: ["LIVE", "STAGING"],
});
BugsnagPerformance.start({
  apiKey: BUGSNAG_API_KEY,
  releaseStage: APP_ENV,
  enabledReleaseStages: ["LIVE", "STAGING"],
});
const ErrorBoundary =
  APP_ENV !== "DEV"
    ? Bugsnag.getPlugin &&
      (Bugsnag.getPlugin("react")?.createErrorBoundary(React) as any)
    : ({ children }: any) => children;

ReactDOM.render(
  <GrowthBookProvider growthbook={growthBookConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <React.StrictMode>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </React.StrictMode>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </GrowthBookProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
