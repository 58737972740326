import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import StripeContainer from "../../../pgp-components/stripe-components/StripeContainer";
import { RootState } from "../../../store/store";
import SepaDebitContainer from "../../../pgp-components/sepa-debit-components/SepaDebitContainer";
import GiropayContainer from "../../../pgp-components/giropay-components/GiropayContainer";
import MollieContainer from "../../../pgp-components/mollie-components/MollieContainer";
import AnimatedPayButtonBlack from "../subcomponents/AnimatedPayButtonBlack";

const FastCheckoutPaymentMount: React.FC = ({ children }) => {
  const hiddenSegments = useSelector(
    (state: RootState) => state.app.UI.pages.fastCheckout.hiddenSegments
  );
  const paymentState = useSelector((state: RootState) => state.payment);

  const [paymentMethod, setPaymentMethod] = useState<any>(
    paymentState.paymentMethodSelected
  );

  useEffect(() => {
    setPaymentMethod(paymentState.paymentMethodSelected);
  }, [paymentState.paymentMethodSelected]);

  return (
    <div className="fast-checkout-page-segment">
      {paymentMethod?.name.toLocaleLowerCase() === "card" &&
        paymentMethod?.serviceProvider.toLocaleLowerCase() === "stripe" && (
          <StripeContainer
            PUBLIC_KEY_STRIPE={paymentMethod?.data?.public_key ?? ""}
          />
        )}
      {/* {paymentMethod?.name.toLocaleLowerCase() === "card" &&
        paymentMethod?.serviceProvider.toLocaleLowerCase() === "buckaroo" && (
          <BuckarooContainer />
        )} */}
      {paymentMethod?.name.toLocaleLowerCase() === "card" &&
        // paymentMethod?.serviceProvider.toLocaleLowerCase() === "mollie" ||
        paymentMethod?.serviceProvider.toLocaleLowerCase() === "aduppay" && (
          <MollieContainer
            MOLLIE_PROFILE_ID={paymentMethod?.data?.profile_id ?? ""}
          />
        )}
      {paymentMethod?.name.toLocaleLowerCase() === "card" &&
        (paymentMethod?.serviceProvider.toLocaleLowerCase() === "mollie" ||
          paymentMethod?.serviceProvider.toLocaleLowerCase() === "paynl" ||
          paymentMethod?.serviceProvider.toLocaleLowerCase() ===
            "buckaroo") && <AnimatedPayButtonBlack />}
      {paymentMethod?.name.toLocaleLowerCase() === "sepa_debit" &&
        paymentMethod?.serviceProvider.toLocaleLowerCase() === "stripe" && (
          <SepaDebitContainer />
        )}
      {paymentMethod?.name.toLocaleLowerCase() === "giropay" &&
        paymentMethod?.serviceProvider.toLocaleLowerCase() === "buckaroo" && (
          <GiropayContainer />
        )}
    </div>
  );
};

export default FastCheckoutPaymentMount;
