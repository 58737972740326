import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import "./otp-code-input.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { verificationStates } from "../../store/reducers/userSlice";
import { submitLogToBugsnag } from "../../lib/api/log";

interface OTPCodeInputProps {
  numberOfFields?: number;
  parseActivationCode?: () => void;
  onComplete?: (otpValue: string) => void;
}

export const OTPCodeInput: React.FC<OTPCodeInputProps> = ({
  numberOfFields = 4,
  parseActivationCode,
  onComplete,
}) => {
  const [fields, setFields] = useState<JSX.Element[]>([]);

  const [otpValue, setOtpValue] = useState<string>("");

  const invitationCodeDigits = useRef<HTMLDivElement | null>(null);

  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified.state
  );

  const isBrowserSafari = !!navigator.userAgent.match(
    /Version\/[\d\.]+.*Safari/
  );

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (
      verificationState === verificationStates.failed &&
      inputRefs.current[0]
    ) {
      setTimeout(() => {
        inputRefs.current[0]?.focus();
      }, 0);
    }
  }, [verificationState]);

  useEffect(() => {
    if (verificationState === verificationStates.failed) {
      setOtpValue(() => "");
    }
  }, [verificationState]);

  /**
   *
   * @param {KeyboardEvent} event
   */
  function handleBackspaceDigit(event: any) {
    // Use 'keyCode' for cross-browser compatibility
    const key = event.key || event.keyCode;

    // Check for both 'Backspace' and keyCode 8
    if ((key === "Backspace" || key === 8) && otpValue.length > 0) {
      setOtpValue((currentValue) => currentValue.slice(0, -1));

      // Prevent any default action to ensure the event is handled correctly
      event.preventDefault();
    }
  }

  /**
   *
   * @param {any} event
   */
  function validateCodeDigit(event: any) {
    const val = event.target.value;

    if (isBrowserSafari) {
      // Get the last character of the input
      const lastChar = val.charAt(val.length - 1);
      if (val.trim().length > 1 && val.trim().length === numberOfFields) {
        event.clipboardData = {
          getData: () => val.trim(),
        };
        checkPasteFromClipboard(event);
      }

      // Check if the character is a numeric digit
      if (lastChar.match(/^[0-9]$/)) {
        // Replace the character at the correct position in otpValue
        setOtpValue((prev) => {
          // If the length of prev is less than numberOfFields, append the digit
          if (prev.length < numberOfFields) {
            return prev + lastChar;
          }
          // Otherwise, replace the last digit
          return prev;
        });
      }
    } else {
      // Check if the character is non-numeric and prevent input
      if (!val.match(/^[0-9]$/)) {
        event.preventDefault();
      } else {
        if (val.trim().length > 1 && val.trim().length === numberOfFields) {
          event.clipboardData = {
            getData: () => val.trim(),
          };
          checkPasteFromClipboard(event);
        }
        if (event.nativeEvent?.data) {
          try {
            const intValue = event.nativeEvent.data;
            if (otpValue.length < numberOfFields) {
              setOtpValue((val) => val + intValue);
            }
          } catch (exception) {
            console.log(exception);
            submitLogToBugsnag("error", exception);
          }
        }
      }
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  function checkPasteFromClipboard(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      navigator.clipboard.readText().then((clipText) => {
        if (clipText.length === numberOfFields) {
          try {
            parseInt(clipText);

            setOtpValue((val) => clipText);
          } catch (exception) {
            console.log("invalid data sent from clipboard");
            submitLogToBugsnag(
              "error",
              `Invalid data sent from clipboard : ${exception}`
            );
          }
        }
      });
    }
  }

  useEffect(() => {
    // send a request to the server when the OTP is completed
    if (otpValue.length === numberOfFields) {
      if (onComplete) onComplete(otpValue);
    }
  }, [otpValue]);

  useEffect(() => {
    const startBox = document
      .getElementsByClassName("otp-input-field-box")
      .item(0);
    if (startBox)
      (startBox as HTMLInputElement).setAttribute("autofocus", "true");
  }, []);

  useEffect(() => {
    const inputFields: JSX.Element[] = [];
    //TODO: Fix for Safari
    // if (isBrowserSafari) {
    //   inputFields.push(
    //     <input
    //       className="otp-input-field-box light-gray-border"
    //       style={{
    //         fontFamily: "var(--main-font-secondary)",
    //         letterSpacing: "30px",
    //       }}
    //       key="one-time-code"
    //       name="one-time-code"
    //       type="text" // Keep as text to handle iOS limitations
    //       inputMode="numeric" // Helps mobile keyboards to show numeric keypad
    //       autoComplete="one-time-code"
    //       disabled={otpValue.length === numberOfFields}
    //       onKeyDown={handleBackspaceDigit}
    //       onChange={validateCodeDigit}
    //       value={otpValue}
    //       autoFocus
    //     ></input>
    //   );
    // } else
    {
      for (let idx = 0; idx < numberOfFields; idx++) {
        inputFields.push(
          <input
            ref={(el) => (inputRefs.current[idx] = el)}
            data-idx={idx}
            className="otp-input-field-box light-gray-border"
            style={{ fontFamily: "var(--main-font-secondary)" }}
            key={idx}
            name="one-time-code"
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            autoFocus={idx === 0}
            maxLength={1}
            disabled={otpValue.length === numberOfFields}
            onKeyDown={handleBackspaceDigit}
            onChange={validateCodeDigit}
            value={otpValue.charAt(idx)}
          ></input>
        );
      }
    }

    setFields(() => inputFields);
  }, [otpValue]);

  useEffect(() => {
    // console.log(otpValue);
    if (invitationCodeDigits?.current?.children) {
      for (
        let idx = 0;
        idx < invitationCodeDigits.current.children.length;
        idx++
      ) {
        if (
          otpValue.charAt(idx) &&
          invitationCodeDigits.current.children.item(idx + 1)
        ) {
          (
            invitationCodeDigits.current.children.item(
              idx + 1
            ) as HTMLInputElement
          ).focus();
        } else if (otpValue.charAt(idx)) {
          (
            invitationCodeDigits.current.children.item(idx) as HTMLInputElement
          ).focus();
        }
        if (otpValue === "") {
          (
            invitationCodeDigits.current.children.item(0) as HTMLInputElement
          ).focus();
        }
      }
    }
  }, [otpValue]);

  return (
    <div className="otp-inputs-holder" ref={invitationCodeDigits}>
      {fields.map((field) => field)}
    </div>
  );
};
